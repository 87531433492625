export const Promo = () => {
  const data = [
    { src: "/banner-ads1.jpeg" },
    { src: "/banner-ads2.jpeg" },
    { src: "/banner-ads3.jpeg" },
    { src: "/banner-ads4.jpeg" },
    { src: "/banner-ads5.jpeg" },
    { src: "/banner-ads6.jpeg" },
    { src: "/banner-ads7.jpeg" },
    { src: "/banner-ads8.jpeg" },
  ];
  return (
    <div>
      <p className="my-8">Berbagai Macam Promo Menarik</p>
      {data.map((item) => (
        <img
          key={item.src}
          src={item.src}
          alt="promo"
          className="mb-3 rounded-lg shadow-lg"
        />
      ))}
    </div>
  );
};
