import moment from "moment/moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Articles } from "../../components/mobile/Articles";
import { Loading } from "../../components/mobile/Loading";
import { PromoPopUp } from "../../components/mobile/PromoPopUp";
import { RunningText } from "../../components/mobile/RunningText";
import { WalletAndBanner } from "../../components/mobile/WalletAndBanner";
import { useStateContext } from "../../contexts/ContextProvider";
import { getUser } from "../../services/service";
import { NewWallet } from "../../components/mobile/NewWallet";
import FAB from "../../components/mobile/FAB";
import { StoreTab } from "../../components/mobile/Store/StoreTab";
import { StoreContent } from "../../components/mobile/Store/StoreContent";

export const HomePage = () => {
  const { setUser, loading } = useStateContext();
  const [promoShow, setPromoShow] = useState({});
  const navigate = useNavigate();

  const handleCheckboxChange = () => {
    localStorage.setItem(
      "promo-show",
      JSON.stringify({
        enable: !promoShow.enable,
        time: moment().add(1, "days").format("DD/MM/YYYY"),
      })
    );
    setPromoShow(JSON.parse(localStorage.getItem("promo-show")));
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setPromoShow(
      JSON.parse(localStorage.getItem("promo-show")) || {
        enable: true,
        time: "",
      }
    );
    getUser()
      .then((response) => {
        localStorage.setItem("user", JSON.stringify(response.data));
        setUser(response.data);
      })
      .catch((error) => {
        if (error.response.data.message === "Unauthenticated.") {
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          window.location.reload();
          navigate("/login", { replace: true });
        }
        console.log(error);
      });
    if (promoShow.date <= moment().format("DD/MM/YYYY")) {
      localStorage.removeItem("promo-show");
    }
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="font-poppins w-screen sm:w-128 mx-auto shadow bg-white">
      {promoShow.enable && (
        <PromoPopUp
          isShowing={promoShow.enable}
          handleCheckboxChange={handleCheckboxChange}
          close={() => setPromoShow({ enable: false, time: "" })}
        />
      )}
      <RunningText />
      <NewWallet />
      <div className="h-4"></div>
      <StoreTab />
      <StoreContent />
      <div className="mb-4 mt-10">
        <WalletAndBanner />
      </div>
      <Articles />
      <FAB />
      <div className="h-20" />
    </div>
  );
};
