import React, { useState } from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import { HiOutlineAdjustments } from "react-icons/hi";
import { useTranslation } from "react-i18next";

export const FilterButton = (props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("All");
  const { setIsFilters, setOpenFilter } = useStateContext();
  const handleClick = (filterWord) => {
    const result = props.data?.filter(
      (e) => e[props.target].toString() === filterWord
    );
    setSelectedItem(filterWord);
    setIsFilters({ [props.type]: filterWord === "All" ? props.data : result });
    setOpenFilter({ [props.type]: true });
  };
  return (
    <>
      <div
        className="w-8 h-8 rounded-lg p-2 bg-gray-200 mx-4"
        onClick={() => setIsOpen(!isOpen)}
      >
        <HiOutlineAdjustments />
      </div>
      {isOpen && (
        <>
          <div
            className="fixed w-screen sm:w-128 h-screen top-0 mx-auto bg-black opacity-20"
            onClick={() => setIsOpen(!isOpen)}
          ></div>
          <div className="fixed w-screen sm:w-128  bottom-0 mx-auto bg-white p-3 text-left">
            <span>{t("selectStatus")}</span>
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 text-center mt-2 mb-20">
              {props.text.map((item) => (
                <div
                  className={`${
                    selectedItem === item && "border-2 border-orange-500"
                  } py-2 px-4 rounded-full cursor-pointer border`}
                  onClick={() => handleClick(item)}
                  key={JSON.stringify(item)}
                >
                  {item}
                </div>
              ))}
              {props.children}
            </div>
          </div>
        </>
      )}
    </>
  );
};
