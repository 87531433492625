import { useEffect } from "react";
import { TicketContent } from "../../components/mobile/Ticket/TicketContent";
import { TicketTab } from "../../components/mobile/Ticket/TicketTab";

export const TicketPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div className=" w-screen sm:w-128 mx-auto text-center shadow min-h-screen bg-white">
      <TicketTab />
      <TicketContent />
    </div>
  );
};
