import React, { useEffect, useState } from "react";
import { useStateContext } from "../../../contexts/ContextProvider";
import { getCommission } from "../../../services/service";
import { Input } from "../Input";
import { OrangeButton } from "../OrangeButton";

export const TakeAffiliateForm = (props) => {
  const [showPopUp, setShowPopUp] = useState(false);
  const { valueCommission, setValueCommission } = useStateContext();

  const [commissionRemains, setCommissionRemains] = useState(0);

  const handleSemuaButton = (e) => {
    if (commissionRemains > props.max) {
      setShowPopUp(true);
      setValueCommission(props.max);
      setTimeout(() => {
        setShowPopUp(false);
      }, 3000);
    } else {
      setValueCommission(commissionRemains);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    props.setPopUp(true);
  };

  useEffect(() => {
    getCommission()
      .then((response) => {
        setCommissionRemains(response.data.data.commission);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      {showPopUp && (
        <div className="absolute top-0 z-50 bg-yellow-200 text-yellow-500 p-4 w-full sm:w-128">
          Maksimum penarikan hanya Rp {Number(props.max).toLocaleString("id")} /
          Hari
        </div>
      )}
      <div className="p-4">
        <p className="text-gray-400 text-xs mt-4 mb-2">Commission Amount</p>
        <p className="text-2xl font-bold">
          Rp {Number(commissionRemains).toLocaleString("id")}
        </p>
        <div className="mt-8 w-full">
          <form onSubmit={handleSubmit}>
            <Input
              label="Nominal Tarik"
              value={valueCommission}
              change={(e) => setValueCommission(e.target.value)}
              type="number"
              icon={
                <p
                  onClick={handleSemuaButton}
                  className="text-orange-400 text-sm pr-4 cursor-pointer"
                >
                  Semua
                </p>
              }
            />
            <Input label="Minimum Withdraw" value="10.000" disabled={true} />
            <p className="text-xs mt-8">
              <span className="text-red-400">*</span> Penarikan komisi dibatasi
              hanya 1 hari sekali, dengan proses pencarian max 3 hari kerja.
            </p>
            <OrangeButton type="submit" title="Submit" />
          </form>
        </div>
      </div>
    </>
  );
};
