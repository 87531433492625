import React from "react";
import { useStateContext } from "../../../contexts/ContextProvider";
import { News } from "./News";
import { Notification } from "./Notification";
import { Promo } from "./Promo";
import { useTranslation } from "react-i18next";

export const NotificationContent = (props) => {
  const { t } = useTranslation();
  const { selectedTabNotification } = useStateContext();

  switch (selectedTabNotification) {
    case t("notification"):
      return <Notification />;
    case t("updates"):
      return <News />;
    case t("promo"):
      return <Promo />;
    default:
      return null;
  }
};
