export const Tab = (props) => (
  <div className="flex mx-4">
    {props.items.map((item) => (
      <div
        className={`text-center cursor-pointer text-gray-500 text-sm font-bold py-2 flex-grow flex-shrink border-b-4 border-transparent break-all flex-basis-0 relative ${
          props.selectedTab === item && "border-blue-bisnison"
        }`}
        onClick={() => props.onChange(item)}
        key={JSON.stringify(item)}
      >
        {item}
      </div>
    ))}
  </div>
);
