import React from "react";
import { ExpandColumn } from "../ExpandColumn";
import moment from "moment";
import { t } from "i18next";

export const ExpandHistoryTopUp = (props) => {
  return (
    <div className="w-full px-2">
      <div
        className="w-full bg-orange-400 p-4 rounded-xl text-white"
        key={props.key}
      >
        <ExpandColumn
          title={t("topupId")}
          value={props.id}
          icons="/assets/copy.svg"
        />
        <ExpandColumn
          title={t("date")}
          value={moment(props.tanggal).format("dddd, DD MMMM YYYY")}
        />
        <ExpandColumn title="Bank" value={props.bank} />
        <ExpandColumn title="Invoice" value={props.invoice} />
        <ExpandColumn title={t("menu.saldo")} value={props.saldo_type} />
        <ExpandColumn
          title={t("quantity")}
          value={"Rp " + props.jumlah.toLocaleString("id")}
        />
        <ExpandColumn
          title={t("earned")}
          value={"Rp " + props.dapat.toLocaleString("id")}
        />
        <ExpandColumn title="Bonus" value={props.bonus} />
        <ExpandColumn title="Status" value={props.status} />
      </div>
    </div>
  );
};
