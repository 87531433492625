import React, { useEffect, useRef, useState } from "react";
import { UserChat } from "./UserChat";
import { AdminChat } from "./AdminChat";
import { getChats, postChat } from "../../../services/service";
import { useLocation } from "react-router-dom";
import moment from "moment";

export const ChatTicket = () => {
  const location = useLocation();

  const bottomRef = useRef(null);
  const [chats, setChats] = useState([]);
  const [message, setMessage] = useState("");

  const handleMesageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    postChat({ id: location.state.id, chat: message })
      .then((response) => {
        response.data.status === "success" && window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getChats(location.state.id)
      .then((response) => {
        setChats(response.data);
        // 👇️ scroll to bottom every time messages change
        bottomRef.current?.scrollIntoView({ behavior: "smooth" });
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="full">
      <div className="h-full w-full">
        <div className="bg-blue-bisnison p-2">
          <span className="mx-3 text-white">Ticket Detail</span>
        </div>
        <div className="px-4 w-full shadow">
          <div className="flex flex-row justify-between w-full relative">
            <div className="flex flex-col justify-evenly h-32 font-bold">
              <div>
                <span>ID</span>
                <span className="text-sm font-normal mx-2">{chats.id}</span>
              </div>
              <div>
                <span>Subject</span>
                <span className="text-sm font-normal mx-2">
                  {chats.subject}
                </span>
              </div>
              <div>
                <span>Date</span>
                <span className="text-sm font-normal mx-2">
                  {moment(chats.created_at).format("DD/MMM/YYYY")}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col h-128 relative w-full overflow-scroll p-4">
          <UserChat
            message={chats?.message}
            time={moment(chats?.created_at).format("HH:mm")}
          />

          {chats?.ticket_replies?.map((chat, index) =>
            chat.user_id === chat.created_by ? (
              <UserChat
                key={index}
                message={chat.message}
                time={moment(chat.created_at).format("HH:mm")}
              />
            ) : (
              <AdminChat
                key={index}
                message={chat.message}
                time={moment(chat.created_at).format("HH:mm")}
              />
            )
          )}
          <div ref={bottomRef} />
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="bg-blue-400 bottom-0 w-full">
          <div className="ml-4 py-2 flex justify-between">
            <div className="w-full relative flex flex-row">
              <input
                type="text"
                className="rounded-full py-2 px-4 w-full"
                placeholder="Write here"
                onChange={handleMesageChange}
              />
            </div>
            <button type="submit" className="mx-2">
              <img src="/assets/send.svg" alt="" className="w-10" />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
