import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const NotAllowed = () => {
  const { t } = useTranslation();
  return (
    <div
      className=" w-screen sm:w-128 mx-auto shadow min-h-screen flex flex-col justify-between text-center p-4"
      style={{
        background:
          "radial-gradient(89% 2958.4% at 93.31% 11%, #252525 0%, #101010 100%)",
        color: "#FFDA75",
      }}
    >
      <div>
        <img src="/assets/topmonthly/sorry.png" alt="sorry" />
        <p style={{ lineHeight: 2 }}>{t("premiumContentMessage")}</p>
      </div>
      <Link to="/">
        <div
          className="rounded-full py-2 px-4"
          style={{
            background:
              "linear-gradient(180deg, #FFDA75 0%, #FFDE84 0.01%, #E9C258 100%)",
            color: "#252525",
            fontWeight: 500,
          }}
        >
          {t("backToHome")}
        </div>
      </Link>
    </div>
  );
};
