import React from "react";
import { useStateContext } from "../../../contexts/ContextProvider";

export const TopMember = () => {
  const { rankings } = useStateContext();
  return (
    <div className="w-full p-4">
      <div className="w-full flex flex-col">
        <p>Top 10 Member Of The Last 30 Days</p>
        <p className="text-sm mb-2 ">
          Based on the number of purchases the last 30 days
        </p>
      </div>
      <div
        className="w-full my-4"
        style={{ height: 2, backgroundColor: "#E9C258" }}
      />
      <div className="flex justify-around w-full">
        {rankings.mo30.map(
          (user, index) =>
            index === 1 && (
              <div className="relative top-32 text-center">
                <img
                  src="/assets/topmonthly/juara2.png"
                  alt="juara"
                  width={120}
                />
                <p>{user.username}</p>
                <p>Rp {Number(user.jml).toLocaleString("id")}</p>
              </div>
            )
        )}
        {rankings.mo30.map(
          (user, index) =>
            index === 0 && (
              <div className="text-center">
                <img
                  src="/assets/topmonthly/juara1.png"
                  alt="juara"
                  width={120}
                />
                <p>{user.username}</p>
                <p>Rp {Number(user.jml).toLocaleString("id")}</p>
              </div>
            )
        )}
        {rankings.mo30.map(
          (user, index) =>
            index === 2 && (
              <div className="relative top-32 text-center">
                <img
                  src="/assets/topmonthly/juara3.png"
                  alt="juara"
                  width={120}
                />
                <p>{user.username}</p>
                <p>Rp {Number(user.jml).toLocaleString("id")}</p>
              </div>
            )
        )}
      </div>
      <div className="h-32" />
      {rankings.mo30.map(
        (data, index) =>
          index > 2 && (
            <div
              key={index}
              className=" flex flex-row justify-between mb-4 place-items-center"
            >
              <div className="flex space-x-4 place-items-center">
                <img
                  src={`/assets/topmonthly/juara${index + 1}.png`}
                  alt=""
                  className="w-12"
                />
                <div>
                  <p>{data.username}</p>
                  <span className=" text-xs">
                    Rp {Number(data.jml).toLocaleString("id")}
                  </span>
                </div>
              </div>
              <div
                className=" font-bold px-3 py-1 rounded-full h-max"
                style={{ color: "#3D3D3D", backgroundColor: "#B9B9B9" }}
              >
                #{index + 1}
              </div>
            </div>
          )
      )}
    </div>
  );
};

// const data = [
//   {
//     username: "shitcode",
//     jml: 123712937,
//   },
//   {
//     username: "shitcode",
//     jml: 123712937,
//   },
//   {
//     username: "shitcode",
//     jml: 123712937,
//   },
//   {
//     username: "shitcode",
//     jml: 123712937,
//   },
//   {
//     username: "shitcode",
//     jml: 123712937,
//   },
//   {
//     username: "shitcode",
//     jml: 123712937,
//   },
//   {
//     username: "shitcode",
//     jml: 123712937,
//   },
//   {
//     username: "shitcode",
//     jml: 123712937,
//   },
//   {
//     username: "shitcode",
//     jml: 123712937,
//   },
//   {
//     username: "shitcode",
//     jml: 123712937,
//   },
// ];
