import React, { useEffect } from "react";
import { HistoryTopUp } from "../../components/mobile/TopUp/HistoryTopUp";
import { useStateContext } from "../../contexts/ContextProvider";
import { getListTopUp } from "../../services/service";

export const HistoryTopUpPage = () => {
  const { setHistoryTopUp } = useStateContext();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    getListTopUp()
      .then((response) => {
        setHistoryTopUp(response.data.data);
      })
      .catch((error) => console.log(error));
  }, []);
  return (
    <div className=" w-screen sm:w-128 mx-auto shadow bg-white">
      <HistoryTopUp />
    </div>
  );
};
