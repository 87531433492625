import { OrangeButton } from "../OrangeButton";
import { Input } from "../Input";
import { SelectInput } from "../SelectInput";
import { DetailService } from "./DetailService";
import { useEffect, useState } from "react";
import { putOrderService } from "../../../services/service";
import { useStateContext } from "../../../contexts/ContextProvider";
import { SuccessPopUp } from "../SuccessPopUp";
import { ErrorPopUp } from "../ErrorPopUp";
import { TextareaInput } from "./TextareaInput";
import { useTranslation } from "react-i18next";

export const SingleOrder = () => {
  const {
    user,
    service,
    optionsSMM,
    setSelectedCategorySMM,
    setSelectedServiceSMM,
    select,
    setSelect,
  } = useStateContext();
  const [price, setPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const { t } = useTranslation();

  const [popUp, setPopUp] = useState({
    success: false,
    failed: false,
    orderId: 0,
    errorMessage: "",
  });

  const [body, setBody] = useState({
    service: 0,
    quantity: 0,
    link: "",
    target2: "",
    comment: "",
    posts: "",
    min: 0,
    max: 0,
    delay: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  const priceCalculation = () => {
    if (service) {
      if (service.discounts[0]) {
        const rawPrice = (service.price / service.unit) * body.quantity;
        const resultDiscount = (rawPrice * service.discounts[0].discount) / 100;
        const total = rawPrice - resultDiscount;
        const totalDiscount =
          (service.price / service.unit) *
          body.quantity *
          ((service.discounts[0].discount || 0) / 100);
        if (user.currency === "USD") {
          setDiscount(totalDiscount).toLocaleString("us");
          setPrice(total).toLocaleString("us");
        } else {
          setDiscount(Math.floor(totalDiscount).toLocaleString("id"));
          setPrice(Math.floor(total).toLocaleString("id"));
        }
      } else {
        setPrice((service.price / service.unit) * body.quantity);
      }
    }
  };

  const handleCustomComment = (e) => {
    setBody((prevState) => ({ ...prevState, comment: e.target.value }));
    e.target.value.split("\n") &&
      setBody((prevState) => ({
        ...prevState,
        quantity: e.target.value.split(/\r\n|\r|\n/).length,
      }));
  };

  const handleSelectCategory = (id, nama, dataType) => {
    setSelect((prevState) => ({ ...prevState, category: nama }));
    setSelect((prevState) => ({ ...prevState, service: "" }));
    setSelectedCategorySMM([id, dataType]);
  };

  const handleSelectService = (id, nama) => {
    setSelect((prevState) => ({ ...prevState, service: nama }));
    setSelectedServiceSMM(id);
  };

  const handleChangeQuantity = (e) => {
    setBody((prevState) => ({
      ...prevState,
      quantity: Number(e.target.value),
    }));
  };

  const handleChangeUsername = (e) => {
    setBody((prevState) => ({ ...prevState, target2: e.target.value }));
  };

  const handleChangeLink = (e) => {
    setBody((prevState) => ({ ...prevState, link: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    putOrderService(body)
      .then((response) => {
        if (response.data.success) {
          setPopUp((prevState) => ({
            ...prevState,
            success: true,
            orderId: response.data.orderId,
          }));
        } else {
          setPopUp((prevState) => ({
            ...prevState,
            failed: true,
            errorMessage: response.data.error,
          }));
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setPopUp((prevState) => ({ ...prevState, failed: true }));
      });
  };

  useEffect(() => {
    setBody((prevState) => ({
      ...prevState,
      service: service?.id,
      min: service?.min,
      max: service?.max,
    }));
    priceCalculation();
  }, [service, body.quantity]);

  return (
    <>
      {popUp.success && (
        <SuccessPopUp
          isShowing={popUp.success}
          orderId={popUp.orderId}
          link={body.link}
          service={service?.server}
          quantity={body.quantity}
          close={() =>
            setPopUp((prevState) => ({ ...prevState, success: false }))
          }
        />
      )}
      {popUp.failed && (
        <ErrorPopUp
          isShowing={popUp.failed}
          text={popUp.errorMessage}
          close={() =>
            setPopUp((prevState) => ({ ...prevState, failed: false }))
          }
        />
      )}
      <form onSubmit={handleSubmit} className="px-4">
        <SelectInput
          text={t("selectCategory", "Select a category")}
          label={t("categoryLabel", "Category")}
          data={optionsSMM.categories}
          handleSelectItem={handleSelectCategory}
          select={select.category}
          isSearchable={true}
          dataType="category"
          placeholder={t(
            "selectCategoryPlaceholder",
            "Please select a category"
          )}
        />
        <div className="flex justify-between text-left text-sm mt-4">
          <p>{t("servicesLabel", "Services")}</p>
          <p>
            {/* {service?.price.toLocaleString("id")
          ? `Rp ${service?.price.toLocaleString("id")}/1K`
          : "Rate/1K"} */}
          </p>
        </div>
        <SelectInput
          text={t("selectCategoryFirst", "Select a category first please")}
          data={optionsSMM.services}
          handleSelectItem={handleSelectService}
          select={select.service}
          isSearchable={true}
          placeholder={t(
            "selectServicePlaceholder",
            "Please select a category first"
          )}
        />
        <DetailService />
        <Input
          label={t("linkTargetLabel", "Link Target")}
          placeholder={t("linkTargetPlaceholder", "Input your target")}
          change={handleChangeLink}
        />
        {service && service.id_type === 2 && (
          <TextareaInput
            label="Custom Comment"
            onChange={handleCustomComment}
          />
        )}
        {service && service.id_type === 4 && (
          <Input label={t("username")} change={handleChangeUsername} />
        )}
        <Input
          type="number"
          label={t("quantity")}
          value={body.quantity || ""}
          placeholder="0"
          change={handleChangeQuantity}
        />
        <div className="flex justify-between space-x-2">
          <Input
            disabled={true}
            label={`${t("discount")} ${parseInt(
              service?.discounts[0]?.discount || 0
            )}%`}
            placeholder={`${user.currency === "USD" ? "$" : "Rp"} ${discount}`}
          />
          <Input
            disabled={true}
            label={t("cost")}
            placeholder={`${user.currency === "USD" ? "$" : "Rp"} ${price}`}
          />
        </div>
        {service?.discounts[0] && (
          <span className="text-xs text-orange-500">
            {service?.discounts[0].member_notes}
          </span>
        )}
        <OrangeButton
          type="submit"
          title={t("buyNow", "Buy Now")}
          isLoading={isLoading}
        />
      </form>
    </>
  );
};
