import React from "react";
import { useStateContext } from "../../../contexts/ContextProvider";
import { Tab } from "../Tab";
import { useTranslation } from "react-i18next";

export const TopUpTab = () => {
  const { t } = useTranslation();
  const { selectedTabTopUp, setSelectedTabTopUp } = useStateContext();
  const items = ["Top up", t("history")];

  const handlePress = (index) => {
    setSelectedTabTopUp(index);
  };

  return (
    <Tab items={items} onChange={handlePress} selectedTab={selectedTabTopUp} />
  );
};
