import { useEffect, useState } from "react";
import { getPageInfo } from "../../services/service";

/* eslint-disable jsx-a11y/no-distracting-elements */
const Marquee = () => {
  const [headline, setHeadline] = useState("");
  useEffect(() => {
    getPageInfo("headline")
      .then((response) => {
        setHeadline(response.data.info);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }, []);
  return (
    <div className="w-full pb-1 pt-2 px-4  bg-orange-400">
      <marquee behavior="" direction="">
        <div
          className="justify-center truncate text-sm "
          dangerouslySetInnerHTML={{
            __html: headline,
          }}
        />
      </marquee>
    </div>
  );
};

export default Marquee;
