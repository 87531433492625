import React from "react";
import { ChatTicket } from "../../components/mobile/Ticket/ChatTicket";

export const TicketChatsPage = () => {
  return (
    <div className=" w-screen sm:w-128 mx-auto shadow bg-white">
      <ChatTicket />
    </div>
  );
};
