import { useEffect } from "react";
import { NotificationContent } from "../../components/mobile/Notification/NotificationContent";
import { NotificationTab } from "../../components/mobile/Notification/NotificationTab";

export const NotificationPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className=" w-screen sm:w-128 mx-auto shadow bg-white min-h-screen p-4">
      <NotificationTab />
      <NotificationContent />
    </div>
  );
};
