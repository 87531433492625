import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

const TelegramContent = () => {
  const { t } = useTranslation();

  return (
    <ol className="list-disc">
      <li>Telegram: @bisnison ({t("fastResponse")})</li>
      <li>
        Link Telegram :{" "}
        <a href="https://t.me/bisnison" className="text-orange-400">
          https://t.me/bisnison
        </a>
      </li>
    </ol>
  );
};

const WhatsappContent = () => {
  const { t } = useTranslation();

  return (
    <ol className="list-disc grid gap-3">
      <li>
        {t("whatsappRegularMember")}: 0838-7666-3600 (
        {t("chatRepliesBasedOnQueue")})
      </li>
      <li>
        {t("whatsappPremiumMember")} : {t("upgradeToPremium")}
      </li>
    </ol>
  );
};
export const SupportPage = () => {
  const [isOpen, setIsOpen] = useState({ telegram: false, whatsapp: false });

  return (
    <div className="w-screen sm:w-128 mx-auto p-4 shadow bg-white min-h-screen">
      <div
        className="flex flex-row justify-between text font-medium"
        onClick={() =>
          setIsOpen({ telegram: !isOpen.telegram, whatsapp: isOpen.whatsapp })
        }
      >
        <p>Telegram</p>
        {isOpen.telegram !== true ? (
          <FiChevronDown className="mr-2" />
        ) : (
          <FiChevronUp className="mr-2" />
        )}
      </div>
      {isOpen.telegram && (
        <div className="w-full p-4">
          <TelegramContent />
        </div>
      )}

      <div
        className="flex flex-row justify-between text font-medium mt-4"
        onClick={() =>
          setIsOpen({ telegram: isOpen.telegram, whatsapp: !isOpen.whatsapp })
        }
      >
        <p>Whatsapp</p>
        {isOpen.whatsapp !== true ? (
          <FiChevronDown className="mr-2" />
        ) : (
          <FiChevronUp className="mr-2" />
        )}
      </div>
      {isOpen.whatsapp && (
        <div className="w-full p-4">
          <WhatsappContent />
        </div>
      )}
    </div>
  );
};
