import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Input } from "../../components/mobile/Input";
import { OrangeButton } from "../../components/mobile/OrangeButton";
import { getDetailLogin, postForgotPassword } from "../../services/service";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../components/desktop/LanguageSwitcher";

const ForgotPasswordPageDesktop = () => {
  const { t } = useTranslation(); // Initialize useTranslation
  const [isLoading, setIsLoading] = useState(false);
  const [infoLogin, setInfoLogin] = useState();
  const [message, setMessage] = useState({
    error: "",
    message: "",
  });
  const [email, setEmail] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  function transformResponse(response) {
    const emailMatch = response.email.match(/send to (.+)/);
    const whatsappMatch = response.whatsapp.match(/success send to (.+)/);

    const email = emailMatch ? emailMatch[1] : "";
    const whatsapp = whatsappMatch ? whatsappMatch[1] : "";

    return `${whatsapp} & ${email}`;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage("");
    setIsLoading(true);
    postForgotPassword(email)
      .then((response) => {
        setMessage({
          success: `${t(
            "forgotPasswordPage.successSendTo"
          )} ${transformResponse(response.data)}`,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        setMessage({ error: error.response.data.message });
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getDetailLogin()
      .then((response) => {
        setInfoLogin(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {t(
            "forgotPasswordPage.pageTitle",
            "Forgot Password SMM Panel Indonesia BisnisOn"
          )}
        </title>
      </Helmet>
      <div className="grid gap-x-8 grid-cols-2 h-screen p-4">
        <div className="h-full bg-blue-bisnison rounded-3xl p-4 flex flex-col justify-center text-white">
          <div
            className="justify-center h-screen overflow-y-scroll text-sm mt-4"
            dangerouslySetInnerHTML={{
              __html: infoLogin?.info,
            }}
          />
        </div>
        <div className="h-full">
          <div className="w-full flex justify-between items-center ml-auto">
            <LanguageSwitcher />
          </div>
          <div
            className="flex flex-col px-2 mx-2 pb-4 justify-center"
            style={{ height: "85%" }}
          >
            <img
              src="/assets/bisnison.svg"
              alt=""
              className="w-36 mx-auto pt-2 mb-10"
            />
            <form onSubmit={handleSubmit}>
              <p className="text-3xl font-semibold mb-4">
                {t("troubleLoggingIn")}?
              </p>
              <div className=" mt-4 w-full">
                <div className="flex justify-between text-left text-sm">
                  <label className="text-center">
                    {t("inputEmailUsernamePhone")}
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    onChange={handleEmailChange}
                    className={`w-full rounded-full py-2 px-4 border border-gray-300 text-sm`}
                    placeholder={t("forgotPasswordPlaceholder")}
                  />
                </div>
              </div>
              <div className="mt-4">
                {message.error && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="bg-red-200 text-red-500 p-2 rounded w-full"
                  >
                    {message.error}
                  </motion.div>
                )}
                {message.success && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="bg-green-200 text-green-500 p-2 rounded w-full"
                  >
                    {message.success}
                  </motion.div>
                )}
              </div>
              <OrangeButton
                type="submit"
                className="rounded-xl bg-orange-500 w-full p-2 mt-2 text-white text-center"
                title={t("submit", "Submit")}
                isLoading={isLoading}
                disabled={isLoading}
              />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordPageDesktop;
