import { useState } from "react";
import { motion } from "framer-motion";

const FAB = () => {
  const [fabOpen, setFabOpen] = useState(false);
  return (
    <div style={{ marginLeft: "83%" }}>
      <a href="https://wa.me/+6283876663600" target="_blank" rel="noreferrer">
        <motion.button
          title="WhatsApp"
          initial={{
            y: 0,
          }}
          transition={{ delay: 0.2, damping: 10, stiffness: 100 }}
          animate={{
            y: fabOpen ? -130 : 0,
          }}
          // className="fixed z-90 bottom-10 right-8 bg-blue-600 rounded-full drop-shadow-lg flex justify-center items-center text-white text-4xl hover:bg-blue-700 hover:drop-shadow-2xl hover:animate-bounce duration-300"
          className={`fixed bottom-24 bg-green-400 rounded-full drop-shadow-lg h-14 w-14 text-white`}
        >
          <i className="fa fa-whatsapp text-2xl" aria-hidden="true"></i>
        </motion.button>
      </a>
      <a href="https://t.me/bisnison" target="_blank" rel="noreferrer">
        <motion.button
          title="Telegram"
          initial={{
            y: 0,
          }}
          transition={{ delay: 0.2, damping: 10, stiffness: 100 }}
          animate={{
            y: fabOpen ? -65 : 0,
          }}
          // className="fixed z-90 bottom-10 right-8 bg-blue-600 rounded-full drop-shadow-lg flex justify-center items-center text-white text-4xl hover:bg-blue-700 hover:drop-shadow-2xl hover:animate-bounce duration-300"
          className="fixed bg-blue-400 rounded-full drop-shadow-lg h-14 w-14 text-white"
          style={{ bottom: "96px" }}
        >
          <i className="fa fa-telegram text-2xl" aria-hidden="true"></i>
        </motion.button>
      </a>
      <button
        onClick={() => setFabOpen(!fabOpen)}
        title="Contact Sale"
        // className="fixed z-90 bottom-10 right-8 bg-blue-600 rounded-full drop-shadow-lg flex justify-center items-center text-white text-4xl hover:bg-blue-700 hover:drop-shadow-2xl hover:animate-bounce duration-300"
        className="fixed bottom-24 bg-blue-bisnison p-3 rounded-full drop-shadow-lg h-14 w-14 text-white"
      >
        {!fabOpen ? (
          <img
            alt="cs"
            src="https://img.icons8.com/external-smashingstocks-glyph-smashing-stocks/66/FFFFFF/external-customer-service-tech-support-smashingstocks-glyph-smashing-stocks.png"
          />
        ) : (
          <img
            alt="cancel"
            src="https://img.icons8.com/ios-filled/50/FFFFFF/delete-sign--v1.png"
          />
        )}
      </button>
    </div>
  );
};

export default FAB;
