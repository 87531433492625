import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Input } from "../../components/mobile/Input";
import { OrangeButton } from "../../components/mobile/OrangeButton";
import { OrangeButtonOutline } from "../../components/mobile/OrangeButtonOutline";
import { getDetailBank, deleteBank, putBank } from "../../services/service";
import { useTranslation } from "react-i18next";

export const AffiliateEditBankPage = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [detailBank, setDetailBank] = useState({});
  const location = useLocation();
  const navigate = useNavigate();

  //   const handleOpenMedia = () => {
  //     navigator.getMedia =
  //       navigator.getUserMedia ||
  //       navigator.webkitGetUserMedia ||
  //       navigator.mozGetUserMedia ||
  //       navigator.msGetUserMedia;
  //   };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (detailBank.main !== 1) {
      setIsLoading(true);
      putBank(location.state.id)
        .then((response) => {
          setIsLoading(false);
          navigate("/affiliate/my-bank");
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    }
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getDetailBank(location.state.id)
      .then((response) => {
        setDetailBank(response.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="w-screen sm:w-128 mx-auto">
      {popUp && <ConfirmPopUp handleClose={() => setPopUp(false)} />}
      <div className="w-screen sm:w-128 mx-auto min-h-screen p-4 bg-white">
        <form onSubmit={handleSubmit}>
          <Input
            label={t("destinationAccount")}
            value={detailBank.no_rekening}
            disabled={true}
          />
          <Input
            label={t("accountNumber")}
            value={detailBank.nama_bank}
            disabled={true}
          />
          <Input
            label={t("accountName")}
            value={detailBank.atas_nama}
            disabled={true}
          />
          {/* <Link to="/affiliate/capture">
              <div className="mt-4">
                <label className="text-sm">
                  Verification KTP / Residence Indentification Card
                </label>
                <div
                  className="w-full py-4 text-gray-300 rounded-full border border-gray-300 mt-2 border-dashed flex flex-col justify-center place-items-center"
                  onClick={handleOpenMedia}
                >
                  <BsCamera size={40} />
                  <p className="text-sm mt-2">Take Photo</p>
                </div>
              </div>
            </Link> */}
          <OrangeButton
            type="submit"
            title={t("setMainBank")}
            isLoading={isLoading}
            disabled={isLoading}
          />
        </form>
        <OrangeButtonOutline
          title={t("deleteBank")}
          onClick={() => setPopUp(true)}
        />
      </div>
    </div>
  );
};

const ConfirmPopUp = (props) => {
  const { t } = useTranslation();
  const [scale, setScale] = useState("scale-0");
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const handleDelete = () => {
    setIsLoading(true);
    deleteBank(location.state.id)
      .then((response) => {
        navigate("/affiliate/my-bank");
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    setScale("scale-100");
  }, []);
  return (
    <>
      <div className="w-screen sm:w-128 mx-auto h-full bg-black opacity-50 fixed z-40" />
      <div className="fixed z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-screen sm:w-128 p-6 ">
        <div
          className={`transition ease-in-out delay-150 bg-white rounded-xl p-4 shadow-lg ${scale}`}
        >
          <div>
            <p className="mb-2">{t("confirmDeleteAccount")}</p>
            <p className="text-sm">{t("cannotRecoverMessage")}</p>
            <div className="flex flex-col space-y-2 mt-4">
              <button
                className="text-white p-2 w-full rounded-lg"
                style={{ backgroundColor: "#E1281D" }}
                onClick={handleDelete}
                disabled={isLoading}
              >
                {isLoading ? t("loading") : t("delete")}
              </button>
              <button
                className="bg-blue-200 text-blue-500 p-2 w-full rounded-lg"
                onClick={props.handleClose}
              >
                {t("cancel")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
