import { useTranslation } from "react-i18next";
import { useStateContext } from "../../../contexts/ContextProvider";
import { Tab } from "../Tab";

export const StoreTab = () => {
  const { t } = useTranslation();
  const { selectedTab, setSelectedTab } = useStateContext();
  const items = [t("orderTypes.singleOrder"), t("orderTypes.massOrder")];

  const handlePress = (index) => {
    setSelectedTab(index);
  };

  return <Tab items={items} onChange={handlePress} selectedTab={selectedTab} />;
};
