import { ImSpinner8 } from "react-icons/im";
import { motion } from "framer-motion";

export const OrangeButton = (props) => {
  return (
    <motion.button
      initial={{
        scale: 1,
      }}
      onClick={props.onClick}
      whileHover={{ scale: 1.03 }}
      whileTap={{ scale: 1.02 }}
      type={props.type}
      disabled={props.disabled}
      className={`${
        props.disabled
          ? "bg-gray-400 text-gray-600"
          : "bg-orange-400 text-white"
      } w-full py-3 mt-4 rounded-full border-none `}
    >
      {props.isLoading ? (
        <ImSpinner8 size={30} className="animate-spin mx-auto" />
      ) : (
        props.title
      )}
    </motion.button>
  );
};
