import React, { useState } from "react";
import { FaQuestionCircle } from "react-icons/fa";
import Tippy from "@tippyjs/react";

export const HelpsIcons = ({ helptext }) => {
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  return (
    <Tippy
      content={
        <div className="ml-4 bg-orange-400 py-1 pb-2 px-2 rounded-xl ">
          <span className="text-white text-xs">{helptext}</span>
        </div>
      }
      visible={visible}
      onClickOutside={hide}
    >
      <div onClick={visible ? hide : show} className="text-orange-400">
        <FaQuestionCircle />
      </div>
    </Tippy>
  );
};
