import React from "react";
import { ExpandColumn } from "../ExpandColumn";

export const ExpandPremiumBenefits = (props) => {
  return (
    <div className="w-full px-4">
      <div
        className="w-full p-4 rounded-xl"
        style={{
          backgroundImage: "linear-gradient(180deg, #FFDA75, #FFDE84, #E9C258)",
          color: "#000",
        }}
        key={props.key}
      >
        <ExpandColumn
          title="Tanggal Upgrade"
          value={props.tanggal}
          icons="/assets/copy.svg"
        />
        <ExpandColumn
          title="Komisi"
          value={props.komisi}
          icons="/assets/copy.svg"
        />
      </div>
    </div>
  );
};
