import React, { useEffect, useState } from "react";
import { OrangeButton } from "../../components/mobile/OrangeButton";
import { Input } from "../../components/mobile/Input";
import { getUser, postAPIChange } from "../../services/service";
import { BiCopy } from "react-icons/bi";
import { useStateContext } from "../../contexts/ContextProvider";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const APIPage = () => {
  const { t } = useTranslation();

  const { user, setUser } = useStateContext();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({
    error: "",
    message: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage("");
    setIsLoading(true);
    postAPIChange()
      .then((response) => {
        response.data.success
          ? setMessage({ success: t("success") })
          : setMessage({ error: t("failed") });
        setIsLoading(false);
        window.location.reload();
      })
      .catch((error) => {
        setMessage({ error: error.response.data.message });
        setIsLoading(false);
      });
  };

  const handleCopyText = () => {
    navigator.clipboard.writeText(user.key).then(() => alert(t("copied")));
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getUser()
      .then((response) => {
        setUser(response.data);
        localStorage.setItem("user", JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <div className="p-4 w-screen sm:w-128 mx-auto shadow bg-white min-h-screen">
      <p className="text-center text-xl mt-4">{t("apiKey")}</p>
      {message.error && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="bg-red-200 text-red-500 p-2 rounded w-full"
        >
          {message.error}
        </motion.div>
      )}
      {message.success && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="bg-green-200 text-green-500 p-2 rounded w-full"
        >
          {message.success}
        </motion.div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="flex place-items-end">
          <Input
            label={t("apiKey")}
            type="text"
            disabled={true}
            value={user.key}
          />
          <BiCopy
            className="mx-1 bg-white mb-1"
            onClick={handleCopyText}
            size={30}
          />
        </div>
        <OrangeButton
          type="submit"
          className="rounded-xl bg-orange-500 w-full p-2 mt-2 text-white text-center"
          title={t("generateApiKey")}
          isLoading={isLoading}
        />
      </form>
      <div className="flex justify-between">
        <a
          href="https://bisnison.com/api-docs"
          rel="noreferrer"
          target="_blank"
          className={` text-blue-bisnison underline text-center  mt-4 `}
        >
          {t("apiDocs")}
        </a>
        <a
          href="https://smm.bisnison.com/price-list"
          rel="noreferrer"
          target="_blank"
          className={` text-blue-bisnison underline text-center  mt-4  `}
        >
          {t("getServiceId")}
        </a>
      </div>
    </div>
  );
};
