import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { getHistoryWd } from "../../../services/service";
import { Loading } from "../Loading";

export const TakeAffiliateHistory = () => {
  const [historyWd, setHistoryWd] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    getHistoryWd()
      .then((response) => {
        setHistoryWd(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);
  return (
    <div className="mt-4">
      <div
        className="overflow-x-auto overflow-y-auto"
        style={{ height: "80vh" }}
      >
        <table className="w-full text-sm text-left text-gray-500">
          <thead
            className="text-xs text-white uppercase sticky top-0"
            style={{ backgroundColor: "#FF8D23" }}
          >
            <tr>
              <th scope="col" className="px-3 py-3  whitespace-nowrap">
                ID
              </th>
              <th scope="col" className="px-3 py-3  ">
                Status
              </th>
              <th scope="col" className="px-3 py-3 whitespace-nowrap">
                Nominal
              </th>
              <th scope="col" className="px-3 py-3 whitespace-nowrap">
                Tanggal
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading && <Loading />}
            {historyWd.map((history, index) => {
              return (
                <tr key={index} className="bg-white border-b text-sm">
                  <td className="px-3 py-3 ">
                    <p className="" style={{ width: "" }}>
                      {(index += 1)}
                    </p>
                  </td>
                  <td className="px-3 py-3 uppercase font-semibold">
                    {history?.status}
                  </td>
                  <td className="px-3 py-3 ">
                    Rp {Number(history?.value || 0).toLocaleString("id")}
                  </td>
                  <td className="px-3 py-3 whitespace-nowrap ">
                    {moment(history?.created_at || "").format("DD MMM YYYY")}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
