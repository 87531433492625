import { useCallback, useEffect, useRef, useState } from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import { BsCamera } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { OrangeButton } from "../../components/mobile/OrangeButton";
import { OrangeButtonOutline } from "../../components/mobile/OrangeButtonOutline";
import { getStatusKTP, postVerifikasiKTP } from "../../services/service";
import { Camera } from "react-camera-pro";
import { useTranslation } from "react-i18next";

export const CaptureIDPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getStatusKTP()
      .then((response) => {
        response.data.forEach((element) => {
          if (element.status === "accept") {
            navigate("/affiliate/my-bank");
          }
        });
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="w-screen sm:w-128 mx-auto min-h-screen bg-white p-4">
      <TakePhoto />
    </div>
  );
};

const TakePhoto = () => {
  const { t } = useTranslation();
  const [image, setImage] = useState("");
  const [upload, setUpload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const webcamRef = useRef(null);
  const formData = new FormData();

  function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  }
  const capture = useCallback(() => {
    setImage(webcamRef.current.takePhoto());
  }, [webcamRef]);

  const retake = () => {
    setImage(null);
    setMessage("");
  };

  const uploadKTP = () => {
    setIsLoading(true);
    const file = dataURItoBlob(image);
    formData.append("ktp", file, "ktp.jpg");
    postVerifikasiKTP(formData)
      .then((response) => {
        if (response.data.success) {
          setUpload(true);
        } else {
          setMessage("Gagal Upload KTP");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setMessage(error.response.data.data.message);
        console.log(error);
        setIsLoading(false);
      });
  };

  return (
    <div>
      <p className="text-xl mb-4">{t("takePhoto")}</p>
      <div
        className="w-full rounded-lg flex place-items-center"
        style={{ backgroundColor: "#606060" }}
      >
        {image ? (
          <img src={image} alt="ID" />
        ) : (
          <Camera
            ref={webcamRef}
            aspectRatio={16 / 9}
            facingMode="environment"
          />
          // <Webcam
          //   audio={false}
          //   ref={webcamRef}
          //   screenshotFormat="image/jpeg"
          //   mirrored={true}
          // />
        )}
      </div>
      <div
        className={`w-max p-3 rounded-full ${
          upload ? "bg-green-500" : "bg-orange-500"
        } relative -top-8 text-white mx-auto`}
      >
        {upload ? (
          <AiFillCheckCircle size={40} />
        ) : image ? (
          <BsCamera size={40} />
        ) : (
          <BsCamera size={40} onClick={capture} />
        )}
      </div>
      {upload ? (
        <p className="text-center relative -top-5">{t("photoSent")}</p>
      ) : (
        <p className="text-center relative -top-5">
          {message
            ? message
            : "Place your ID card according to the instructions"}
        </p>
      )}
      {upload ? (
        <Link to="/">
          <OrangeButton title="Home" />
        </Link>
      ) : (
        image && (
          <div className="flex justify-between space-x-2">
            <OrangeButtonOutline title="Retake" onClick={retake} />
            <div className="w-full" onClick={uploadKTP}>
              <OrangeButton
                disabled={isLoading}
                title={t("submit")}
                isLoading={isLoading}
                type="submit"
              />
            </div>
          </div>
        )
      )}
    </div>
  );
};
