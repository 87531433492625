import React, { useEffect, useState } from "react";
import { Input } from "../../components/mobile/Input";
import { BiCopy } from "react-icons/bi";
import { AiFillExclamationCircle } from "react-icons/ai";
import { AffiliateDetail } from "../../components/mobile/Affiliate/AffiliateDetail";
import {
  getCommission,
  getFetchAffiliate,
  getStatusKTP,
} from "../../services/service";
import { Link, useNavigate } from "react-router-dom";
import { BsFillCreditCardFill } from "react-icons/bs";
import { HiOutlineUserGroup } from "react-icons/hi";
import { BsPersonCheckFill } from "react-icons/bs";
import { FaMoneyBill } from "react-icons/fa";
import FAB from "../../components/mobile/FAB";
import Navbar from "../../components/mobile/Navbar";
import { useStateContext } from "../../contexts/ContextProvider";
import { useTranslation } from "react-i18next";

export const AffiliatePage = () => {
  const { t } = useTranslation();
  const { setSelectedTabTakeAffiliate } = useStateContext();
  const [showPopUp, setShowPopUp] = useState({
    benefit: false,
    incoming: false,
  });
  const user = JSON.parse(localStorage.getItem("user"));
  const [affiliateData, setAffiliateData] = useState([]);
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [commissionRemains, setCommissionRemains] = useState(0);
  const [totalWithdrawed, setTotalWithdrawed] = useState(0);
  const navigate = useNavigate();

  const handleCopyText = (e) => {
    user?.affiliate_code &&
      navigator.clipboard
        .writeText("https://app.bisnison.com/register/" + user.affiliate_code)
        .then(() => alert(`${t("copied")}!`));
  };

  const handleClickBenefit = (e) => {
    setShowPopUp((prevState) => ({
      ...prevState,
      benefit: !showPopUp.benefit,
    }));
  };

  const handleNavigateToHistoryAffiliate = () => {
    setSelectedTabTakeAffiliate("History");
    navigate("take");
  };

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getFetchAffiliate()
      .then((response) => {
        setAffiliateData(response.data);
      })
      .catch((error) => console.log(error));
    getStatusKTP()
      .then((response) => {
        response.data.forEach((element) => {
          if (element.status === "accept") {
            setIsVerified(true);
          }
        });
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
    getCommission()
      .then((response) => {
        setCommissionRemains(response.data.data.commission);
        setTotalWithdrawed(response.data.data.withdrawals[0]?.withdrawed || 0);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className=" w-screen sm:w-128 mx-auto shadow bg-white min-h-screen">
      <Navbar isBack="/" />

      {showPopUp.incoming && (
        <ComingSoon
          close={() =>
            setShowPopUp((prevState) => ({ ...prevState, incoming: false }))
          }
        />
      )}
      {showPopUp.benefit && (
        <>
          <div className="w-screen sm:w-128 mx-auto h-full bg-black opacity-50 fixed z-40" />
          <div className="fixed z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 sm:w-4/12 w-3/4 text-xs bg-white rounded-xl p-4 shadow">
            <p className="text-center text-orange-400 font-bold">Regular</p>
            <ul className="px-4">
              <li>{t("commission1")}</li>
              <li>{t("commission2")}.</li>
            </ul>
            <p className="text-center text-orange-400 font-bold mt-4">
              Premium
            </p>
            <ul className="px-4">
              <li>{t("commission3")}</li>
              <li>{t("commission4")}</li>
              <li>{t("commission5")}</li>
            </ul>
            <p className="text-orange-400 text-xs mt-3">{t("bonusTopup")}</p>
            <button
              onClick={handleClickBenefit}
              className="rounded-full bg-gray-100 py-2 px-4 text-xs mt-4"
            >
              {t("close")}
            </button>
          </div>
        </>
      )}
      <div className="p-4 ">
        <p
          className="text-xs text-orange-400 flex justify-center cursor-pointer"
          onClick={handleClickBenefit}
        >
          {t("benefitAffiliate")}
          <span>
            {" "}
            <AiFillExclamationCircle className="ml-1" />
          </span>
        </p>
        <div className="flex place-items-end justify-center">
          <Input
            label={t("Affiliate Link")}
            type="text"
            disabled={true}
            value={
              user.affiliate_code &&
              `https://app.bisnison.com/register/${user.affiliate_code}`
            }
          />
          <BiCopy className="mx-3 mb-1" onClick={handleCopyText} size={30} />
        </div>
      </div>
      <div>
        <div className="w-full grid grid-rows-2 grid-flow-col gap-2 sm:gap-4 px-3">
          <div className="p-4 rounded-xl bg-gray-100 text-sm text-blue-bisnison">
            <div className="flex space-x-2">
              <HiOutlineUserGroup size={20} className="mb-4" />
              <p className="text-xs sm:text-base">{t("numberVisitors")}</p>
            </div>
            <p>
              {affiliateData.affiliate_visitors <= 1
                ? `${affiliateData.affiliate_visitors || 0} ${t("person")}`
                : `${affiliateData.affiliate_visitors || 0} ${t("people")}`}
            </p>
          </div>
          <div
            className=" p-4 rounded-xl bg-gray-100 text-sm"
            style={{ color: "#FF8D23" }}
          >
            <div className="flex space-x-2">
              <FaMoneyBill size={20} className="mb-4" />
              <p className="text-xs sm:text-base">{t("commissionAmount")}</p>
            </div>
            <p>Rp {Number(commissionRemains).toLocaleString("id")}</p>
          </div>
          <div className="p-4 rounded-xl bg-gray-100 text-sm text-blue-bisnison">
            <div className="flex space-x-2">
              <BsPersonCheckFill size={20} className="mb-4" />
              <p className="text-xs sm:text-base">{t("registeredAmount")}</p>
            </div>
            <p>
              {affiliateData.downlines?.length <= 1
                ? `${affiliateData.downlines?.length || 0} ${t("person")}`
                : `${affiliateData.downlines?.length || 0} ${t("people")}`}
            </p>
          </div>

          <div
            onClick={handleNavigateToHistoryAffiliate}
            className="p-4 rounded-xl bg-gray-100 text-sm text-blue-bisnison cursor-pointer"
          >
            <div className="flex space-x-2">
              <BsFillCreditCardFill size={20} className="mb-4" />
              <p className="text-xs sm:text-base">{t("withdrawalAffiliate")}</p>
            </div>
            <p>Rp {Number(totalWithdrawed).toLocaleString("id")}</p>
          </div>
        </div>
        <div className="mt-4">
          <Link to={isVerified ? "/affiliate/my-bank" : "/affiliate/guide"}>
            <button
              disabled={isLoading}
              className="px-4 py-2 rounded-lg text-white text-sm text-center w-max ml-3 cursor-pointer"
              style={{ backgroundColor: "#FF8D23" }}
            >
              {t("takeCommission")}
            </button>
          </Link>
        </div>
        <AffiliateDetail
          data={affiliateData}
          showPopUp={() =>
            setShowPopUp((prevState) => ({
              ...prevState,
              incoming: true,
            }))
          }
        />
      </div>
      <FAB />
    </div>
  );
};

const ComingSoon = (props) => {
  const { t } = useTranslation();
  const [scale, setScale] = useState("scale-0");
  useEffect(() => {
    setScale("scale-100");
  }, []);
  return (
    <>
      <div className="w-screen sm:w-128 mx-auto h-full bg-black opacity-50 fixed z-40" />
      <div className="fixed z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-screen sm:w-128 p-6 ">
        <div
          className={`transition ease-in-out delay-150 bg-blue-500 rounded-xl p-4 ${scale}`}
        >
          <div className="text-white text-center flex flex-col font-medium">
            <span className="">{t("comingSoon")} !</span>
            <div className="mx-auto my-4">
              <AiFillExclamationCircle size={120} />
            </div>
            <p>{t("featureNotAvailable")}</p>
          </div>
          <div
            className="p-2 border-2 border-white rounded-full text-center text-white mt-4 w-36 cursor-pointer mx-auto"
            onClick={props.close}
          >
            OK
          </div>
        </div>
      </div>
    </>
  );
};
