import { useTranslation } from "react-i18next";
import { useStateContext } from "../../../contexts/ContextProvider";
import { CardDetail } from "./CardDetail";
import { DescriptionDetail } from "./DescriptionDetail";

export const DetailService = () => {
  const { t } = useTranslation();
  const { service } = useStateContext();

  function convertHMS(value) {
    const sec = parseInt(value, 10);
    let hours = Math.floor(sec / 3600);
    let minutes = Math.floor((sec - hours * 3600) / 60);
    return value
      ? `${hours} ${t("hours")} ${minutes} ${t("minutes")}`
      : t("notEnoughData");
  }

  return (
    <div>
      <div className="grid grid-cols-2 text-center w-full">
        <CardDetail
          title={t("qualityTitle")}
          detail={t("qualityDetail")}
          helptext={t("qualityHelptext")}
        />
        <CardDetail
          title={t("speedAverageTitle")}
          detail={convertHMS(service?.speed || 0)}
          helptext={t("speedAverageHelptext")}
        />
        <CardDetail
          title={t("minimumTitle")}
          detail={service && service.min}
          helptext={t("minimumHelptext")}
        />
        <CardDetail
          title={t("maximumTitle")}
          detail={service && service.max}
          helptext={t("maximumHelptext")}
        />
      </div>
      <DescriptionDetail
        desc={service ? service.member_info : t("descriptionPlaceholder")}
      />
    </div>
  );
};
