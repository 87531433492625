import { useEffect, useState } from "react";
import {
  HiHome,
  HiShoppingCart,
  HiChevronDown,
  HiChevronUp,
  HiUser,
} from "react-icons/hi";
import { TbHeartRateMonitor } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import DashboardDesktop from "../../components/desktop/Dashboard/DashboardDesktop";
import MonitoringLayananDesktop from "../../components/desktop/MonitoringService/MonitoringLayananDesktop";
import HistoryOrderDesktop from "../../components/desktop/Order/HistoryOrderDesktop";
import OrderDesktop from "../../components/desktop/Order/OrderDesktop";
import PriceListDesktop from "../../components/desktop/PriceList/PriceListDesktop";
import SaldoDesktop from "../../components/desktop/Saldo/SaldoDesktop";
import TradePointDesktop from "../../components/desktop/Saldo/TradePointDesktop";
import { useStateContext } from "../../contexts/ContextProvider";
import { getUser, postLogout } from "../../services/service";
import packageInfo from "../../../package.json";
import { IoLogOutOutline } from "react-icons/io5";
import FABDesktop from "../../components/desktop/FABDesktop";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../components/desktop/LanguageSwitcher";

const HomePageDesktop = () => {
  const { t } = useTranslation();
  const {
    setUser,
    selectedMenuDesktop,
    setSelectedMenuDesktop,
    selectedMenuOrder,
    setSelectedMenuOrder,
  } = useStateContext();
  const [selectedMenuSaldo, setSelectedMenuSaldo] = useState("Topup Saldo");
  const navigate = useNavigate();
  const handleClick = (menu) => {
    setSelectedMenuDesktop(menu);
  };

  const handleLogout = () => {
    postLogout()
      .then((response) => {
        if (response.data.success) {
          localStorage.removeItem("user");
          localStorage.removeItem("token");

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSelectedMenu = () => {
    switch (selectedMenuDesktop) {
      case "Dashboard":
        return <DashboardDesktop />;

      case "Order":
        if (selectedMenuOrder === "Order") {
          return <OrderDesktop />;
        } else if (selectedMenuOrder === "History") {
          return <HistoryOrderDesktop />;
        } else {
          return <OrderDesktop />;
        }

      case "Saldo":
        if (selectedMenuSaldo === "Topup Saldo") {
          return <SaldoDesktop />;
        } else if (selectedMenuSaldo === "Trade Point") {
          return <TradePointDesktop />;
        } else {
          return <SaldoDesktop />;
        }

      case "Monitoring Service":
        return <MonitoringLayananDesktop />;

      case "Price List":
        return <PriceListDesktop />;

      default:
        <DashboardDesktop />;
        break;
    }
  };

  useEffect(() => {
    getUser()
      .then((response) => {
        localStorage.setItem("user", JSON.stringify(response.data));
        setUser(response.data);
      })
      .catch((error) => {
        if (error.response.data.message === "Unauthenticated.") {
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          window.location.reload();
          navigate("/login", { replace: true });
        }
        console.log(error);
      });
  }, []);

  return (
    <>
      <div className="flex overflow-hidden ">
        <div className="h-screen w-96 bg-white p-8 flex flex-col space-y-4">
          <div className="flex justify-between items-center">
            <a href="/">
              <img src="logo-long.png" alt={t("logo.alt", "logo bisnison")} />
            </a>
            <LanguageSwitcher />
          </div>
          <div className="h-10"></div>
          <div
            onClick={() => handleClick("Dashboard")}
            className={`${
              selectedMenuDesktop === "Dashboard"
                ? "text-white bg-blue-bisnison rounded-full px-4 py-2"
                : "text-black bg-white rounded-full px-4 py-2"
            } cursor-pointer flex place-items-center space-x-3`}
          >
            <HiHome />
            <p className="font-semibold">{t("menu.dashboard", "Dashboard")}</p>
          </div>
          <div className="">
            <div
              onClick={() => handleClick("Order")}
              className={`${
                selectedMenuDesktop === "Order"
                  ? "text-white bg-blue-bisnison rounded-full px-4 py-2"
                  : "text-black bg-white rounded-full px-4 py-2"
              } cursor-pointer flex place-items-center space-x-3`}
            >
              <HiShoppingCart />
              <p className="font-semibold">{t("menu.order", "Order")}</p>
              {selectedMenuDesktop === "Order" ? (
                <HiChevronUp />
              ) : (
                <HiChevronDown />
              )}
            </div>
            {selectedMenuDesktop === "Order" && (
              <div className="text-sm flex flex-col space-y-2 mt-2">
                <div
                  onClick={() => {
                    setSelectedMenuOrder("Order");
                  }}
                  className={`${
                    selectedMenuOrder === "Order"
                      ? "text-white bg-orange-400 rounded-lg px-2 py-1"
                      : "text-black px-2 py-1"
                  } cursor-pointer`}
                >
                  <p>{t("menu.order", "Order")}</p>
                </div>
                <div
                  onClick={() => {
                    setSelectedMenuOrder("History");
                  }}
                  className={`${
                    selectedMenuOrder === "History"
                      ? "text-white bg-orange-400 rounded-lg px-2 py-1"
                      : "text-black px-2 py-1"
                  } cursor-pointer`}
                >
                  <p>{t("menu.history", "History")}</p>
                </div>
              </div>
            )}
          </div>
          <div className="">
            <div
              onClick={() => handleClick("Saldo")}
              className={`${
                selectedMenuDesktop === "Saldo"
                  ? "text-white bg-blue-bisnison rounded-full px-4 py-2"
                  : "text-black bg-white rounded-full px-4 py-2"
              } cursor-pointer flex place-items-center space-x-3`}
            >
              <HiUser />
              <p className="font-semibold">Saldo</p>
              {selectedMenuDesktop === "Saldo" ? (
                <HiChevronUp />
              ) : (
                <HiChevronDown />
              )}
            </div>
            {selectedMenuDesktop === "Saldo" && (
              <div className="text-sm flex flex-col space-y-2 mt-2">
                <div
                  onClick={() => {
                    setSelectedMenuSaldo("Topup Saldo");
                  }}
                  className={`${
                    selectedMenuSaldo === "Topup Saldo"
                      ? "text-white bg-orange-400 rounded-lg px-2 py-1"
                      : "text-black px-2 py-1"
                  } cursor-pointer`}
                >
                  <p>{t("menu.topupSaldo")}</p>
                </div>
                <div
                  onClick={() => {
                    setSelectedMenuSaldo("Trade Point");
                  }}
                  className={`${
                    selectedMenuSaldo === "Trade Point"
                      ? "text-white bg-orange-400 rounded-lg px-2 py-1"
                      : "text-black px-2 py-1"
                  } cursor-pointer`}
                >
                  <p>{t("menu.tradePoint")}</p>
                </div>
              </div>
            )}
          </div>
          <div
            onClick={() => handleClick("Monitoring Service")}
            className={`${
              selectedMenuDesktop === "Monitoring Service"
                ? "text-white bg-blue-bisnison rounded-full px-4 py-2"
                : "text-black bg-white rounded-full px-4 py-2"
            } cursor-pointer flex place-items-center space-x-3`}
          >
            <TbHeartRateMonitor />
            <p className="font-semibold">
              {t("menu.monitoringService", "Monitoring Service")}
            </p>
          </div>

          <div
            onClick={() => handleClick("Price List")}
            className={`${
              selectedMenuDesktop === "Price List"
                ? "text-white bg-blue-bisnison rounded-full px-4 py-2"
                : "text-black bg-white rounded-full px-4 py-2"
            } cursor-pointer flex place-items-center space-x-3`}
          >
            <TbHeartRateMonitor />
            <p className="font-semibold">{t("menu.priceList", "Price List")}</p>
          </div>
          <div
            onClick={handleLogout}
            className="text-black bg-white rounded-full px-4 py-2 cursor-pointer flex place-items-center space-x-3"
          >
            <IoLogOutOutline />
            <p className="font-semibold">{t("menu.logout", "Logout")}</p>
          </div>
          <div className="h-full  flex flex-col justify-end">
            <p className="text-xs text-center ">
              {t("version", "BisnisOn Version")} {packageInfo.version}
            </p>
          </div>
        </div>
        <div
          id="main-content"
          className="h-screen w-full bg-gray-50 relative overflow-y-auto "
        >
          <main>{handleSelectedMenu()}</main>
        </div>
      </div>
      <FABDesktop />
    </>
  );
};

export default HomePageDesktop;
