import { useEffect, useRef } from "react";
import { IconButton } from "../../components/mobile/IconButton";
import { HeaderProfile } from "../../components/mobile/Profiles/HeaderProfile";
import { postLogout } from "../../services/service";
import FAB from "../../components/mobile/FAB";
import { useTranslation } from "react-i18next";

export const ProfilePage = () => {
  const { t } = useTranslation();
  const topRef = useRef();

  const handleLogout = () => {
    postLogout()
      .then((response) => {
        if (response.data.success) {
          localStorage.removeItem("user");
          localStorage.removeItem("token");

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div
      className="h-screen w-screen sm:w-128 mx-auto shadow bg-white"
      ref={topRef}
    >
      <HeaderProfile />
      <div className="mx-4 h-2/4 justify-evenly flex flex-col ">
        <IconButton
          text={t("myProfile")}
          icons="/assets/users.svg"
          link="/my-profile"
        />
        <IconButton
          text={t("changePassword")}
          icons="/assets/changepass.svg"
          link="/change-password"
        />
        <IconButton text={t("apiKey")} icons="/assets/apikey.svg" link="/api" />
        <IconButton
          text={t("support")}
          icons="/assets/support.svg"
          link="/support"
        />
        <IconButton
          text={t("menu.logout")}
          icons="/assets/logout.svg"
          link="/login"
          onClick={handleLogout}
        />
      </div>
      <FAB />
    </div>
  );
};
