import React from "react";

export const TopService = () => {
  return (
    <div className="w-full p-4">
      <div className="w-full flex flex-col">
        <p>Top 10 SERVICE Of The Last 30 Days</p>
        <p className="text-sm mb-2">
          Based on the number of purchases the last 30 days
        </p>
      </div>
      <div
        className="w-full my-4"
        style={{ height: 2, backgroundColor: "#E9C258" }}
      />
      <div className="blur w-full">
        {data?.map(
          (data, index) =>
            index <= 2 && (
              <div key={index}>
                <div className=" flex flex-row justify-between mb-4 place-items-center">
                  <div className="flex space-x-4 place-items-center">
                    <div
                      className=" font-bold px-3 py-1 rounded-full h-max"
                      style={{
                        background:
                          index === 0
                            ? "linear-gradient(180deg, #FFDA75 0%, #FFDE84 0.01%, #E9C258 100%)"
                            : index === 1
                            ? "linear-gradient(180deg, #AEAEAE 0%, #7D7D7D 100%)"
                            : index === 2 &&
                              "linear-gradient(180deg, #A56953 0%, #6D4534 100%)",
                        color: "#252525",
                      }}
                    >
                      #{index + 1}
                    </div>
                    {data.service.toLowerCase().includes("instagram") ? (
                      <img
                        src={"/assets/news/instagram.png"}
                        alt=""
                        className="rounded-xl w-10 h-10"
                      />
                    ) : data.service.toLowerCase().includes("tiktok") ? (
                      <img
                        src={"/assets/news/tiktok.png"}
                        alt=""
                        className="rounded-xl w-10 h-10"
                      />
                    ) : data.service.toLowerCase().includes("youtube") ? (
                      <img
                        src={"/assets/news/youtube.png"}
                        alt=""
                        className="rounded-xl w-10 h-10"
                      />
                    ) : data.service.toLowerCase().includes("facebook") ? (
                      <img
                        src={"/assets/news/facebook.png"}
                        alt=""
                        className="rounded-xl w-10 h-10"
                      />
                    ) : data.service.toLowerCase().includes("twitter") ? (
                      <img
                        src={"/assets/news/twitter.png"}
                        alt=""
                        className="rounded-xl w-10 h-10"
                      />
                    ) : data.service.toLowerCase().includes("telegram") ? (
                      <img
                        src={"/assets/news/telegram.png"}
                        alt=""
                        className="rounded-xl w-10 h-10"
                      />
                    ) : data.service.toLowerCase().includes("bisnison") ? (
                      <img
                        src={"/assets/news/bisnison.png"}
                        alt=""
                        className="rounded-xl w-10 h-10"
                      />
                    ) : (
                      <img
                        src={"/assets/news/web.png"}
                        alt=""
                        className="rounded-xl w-10 h-10"
                      />
                    )}
                    <p className="w-60">{data.service}</p>
                  </div>
                  <span>Rp {Number(data.jml).toLocaleString("id")}</span>
                </div>
              </div>
            )
        )}
      </div>
      <div
        className="w-full my-4 blur"
        style={{ height: 2, backgroundColor: "#E9C258" }}
      />
      <div className="blur">
        {data?.map(
          (data, index) =>
            index > 2 && (
              <div key={index}>
                <div className=" flex flex-row justify-between mb-4 place-items-center">
                  <div className="flex space-x-4 place-items-center">
                    <div
                      className=" font-bold px-3 py-1 rounded-full h-max"
                      style={{ color: "#3D3D3D", backgroundColor: "#B9B9B9" }}
                    >
                      #{index + 1}
                    </div>
                    {data.service.toLowerCase().includes("instagram") ? (
                      <img
                        src={"/assets/news/instagram.png"}
                        alt=""
                        className="rounded-xl w-10 h-10"
                      />
                    ) : data.service.toLowerCase().includes("tiktok") ? (
                      <img
                        src={"/assets/news/tiktok.png"}
                        alt=""
                        className="rounded-xl w-10 h-10"
                      />
                    ) : data.service.toLowerCase().includes("youtube") ? (
                      <img
                        src={"/assets/news/youtube.png"}
                        alt=""
                        className="rounded-xl w-10 h-10"
                      />
                    ) : data.service.toLowerCase().includes("facebook") ? (
                      <img
                        src={"/assets/news/facebook.png"}
                        alt=""
                        className="rounded-xl w-10 h-10"
                      />
                    ) : data.service.toLowerCase().includes("twitter") ? (
                      <img
                        src={"/assets/news/twitter.png"}
                        alt=""
                        className="rounded-xl w-10 h-10"
                      />
                    ) : data.service.toLowerCase().includes("telegram") ? (
                      <img
                        src={"/assets/news/telegram.png"}
                        alt=""
                        className="rounded-xl w-10 h-10"
                      />
                    ) : data.service.toLowerCase().includes("bisnison") ? (
                      <img
                        src={"/assets/news/bisnison.png"}
                        alt=""
                        className="rounded-xl w-10 h-10"
                      />
                    ) : (
                      <img
                        src={"/assets/news/web.png"}
                        alt=""
                        className="rounded-xl w-10 h-10"
                      />
                    )}
                    <p className="w-60">{data.service}</p>
                  </div>
                  <span>Rp {Number(data.jml).toLocaleString("id")}</span>
                </div>
              </div>
            )
        )}
      </div>
    </div>
  );
};

const data = [
  {
    service: "ID 1233 - Instagram Reel Video Access + Display (100/5M) ️⚡ ",
    jml: 123712937,
  },
  {
    service: "ID 622 - Youtube Likes HQ 10K [2K/D - G30]",
    jml: 123712937,
  },
  {
    service: "ID 1233 - Instagram Reel Video Access + Display (100/5M) ️⚡ ",
    jml: 123712937,
  },
  {
    service: "ID 1233 - Instagram Reel Video Access + Display (100/5M) ️⚡ ",
    jml: 123712937,
  },
  {
    service: "ID 1233 - Instagram Reel Video Access + Display (100/5M) ️⚡ ",
    jml: 123712937,
  },
  {
    service: "ID 622 - Youtube Likes HQ 10K [2K/D - G30]",
    jml: 123712937,
  },
  {
    service: "ID 1233 - Instagram Reel Video Access + Display (100/5M) ️⚡ ",
    jml: 123712937,
  },
  {
    service: "ID 1233 - Instagram Reel Video Access + Display (100/5M) ️⚡ ",
    jml: 123712937,
  },
  {
    service: "ID 1233 - Instagram Reel Video Access + Display (100/5M) ️⚡ ",
    jml: 123712937,
  },
  {
    service: "ID 1233 - Instagram Reel Video Access + Display (100/5M) ️⚡ ",
    jml: 123712937,
  },
];
