import React from "react";
import { BsPersonFill } from "react-icons/bs";
import { useStateContext } from "../../../contexts/ContextProvider";

export const HeaderProfile = () => {
  const { user } = useStateContext();
  return (
    <div className="w-full py-4 bg-blue-bisnison text-white">
      <div className="flex flex-col my-auto h-full justify-center mx-4 ">
        <div className="flex place-items-center rounded-full h-20 w-20 p-2 bg-gray-200">
          <BsPersonFill className="text-gray-600" size={120} />
        </div>
        <p className="text-lg mt-2">{user?.nama}</p>
        <p className="text-xs">
          {user?.user_type} MEMBER - {user?.user_level?.level}
        </p>
      </div>
    </div>
  );
};
