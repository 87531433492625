import { SelectInput } from "../SelectInput";
import { OrangeButton } from "../OrangeButton";
import { useNavigate } from "react-router-dom";
import { Input } from "../Input";
import { useEffect, useState } from "react";
import { useStateContext } from "../../../contexts/ContextProvider";
import {
  getBonusTopUp,
  getCategoryBank,
  getDetailFund,
  postReqTopUp,
} from "../../../services/service";
import { useTranslation } from "react-i18next";

export const FormTopUp = () => {
  const { t } = useTranslation();
  const {
    user,
    categoriesBank,
    setCategoriesBank,
    bankById,
    listBank,
    topUpBody,
    setTopUpBody,
    topUpPending,
    bonusTopUp,
    setBonusTopUp,
  } = useStateContext();
  const [isUsd, setIsUsd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [exchangeRate, setExchangeRate] = useState("");
  const [minTopUp, setMinTopUp] = useState(0);
  const [labelValue, setLabelValue] = useState();
  const [phoneValue, setPhoneValue] = useState();
  const [isPulsa, setIsPulsa] = useState(false);
  const [select, setSelect] = useState({
    saldo_type: "",
    categoryId: "",
    bankId: "",
  });
  const [message, setMessage] = useState({
    success: "",
    failed: "",
  });
  const [nominalYangDidapat, setNominalYangDidapat] = useState(0);
  const [bonusBank, setBonusBank] = useState(0);
  const [fund, setFund] = useState();

  const navigate = useNavigate();

  const targetSaldo = [
    {
      label: "Biscoin",
      value: 1,
    },
    // {
    //   label: "PPOB",
    //   value: 2,
    // },
  ];
  const handleSelectTargetSaldo = (id, nama) => {
    setBonusTopUp([]);
    setTopUpBody({ saldo_type: id });
    setSelect({ saldo_type: nama });
  };

  const handleSelectCategoryBank = (id, nama) => {
    setBonusTopUp([]);
    setIsPulsa(false);

    setTopUpBody((prevState) => ({ ...prevState, categoryId: id, bankId: "" }));
    setSelect((prevState) => ({ ...prevState, categoryId: nama, bankId: "" }));
  };

  const handleSelectBank = (id, nama) => {
    setBonusTopUp([]);
    setIsPulsa(false);
    setTopUpBody((prevState) => ({ ...prevState, bankId: id, saldo: "" }));
    setSelect((prevState) => ({ ...prevState, bankId: nama }));
    getBonusTopUp(id)
      .then((response) => {
        setBonusBank(response.data.data.bank);
        setBonusTopUp(response.data.data.topup_config);
      })
      .catch((error) => console.log(error));
    if (id === 21 || id === 27) {
      setIsPulsa(true);
    } else {
      setIsPulsa(false);
    }
  };

  const handleChangeSaldo = (e) => {
    // const addCommas = (num) =>
    //   num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    // const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");
    // setLabelValue(addCommas(removeNonNumeric(e.target.value)));
    // setTopUpBody((prevState) => ({
    //   ...prevState,
    //   saldo: e.target.value.replaceAll(".", ""),
    // }));
    setTopUpBody((prevState) => ({
      ...prevState,
      saldo: e.target.value,
    }));
  };

  const handleChangePhone = (e) => {
    setPhoneValue(e.target.value);
    setTopUpBody((prevState) => ({
      ...prevState,
      noHp: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (topUpBody.saldo >= minTopUp) {
      setIsLoading(true);
      postReqTopUp(topUpBody)
        .then((response) => {
          setIsLoading(false);
          if (response.data.token) {
            localStorage.setItem("midtrans_token", response.data.token);
          }
          if (response.data.success) {
            navigate("/topup/confirm", { replace: true });
          } else {
            setMessage({ failed: response.data.message });
          }
        })
        .catch((error) => {
          console.log(error);
          setMessage({ failed: "Connection Error" });
          setIsLoading(false);
        });
    } else {
      setMessage({
        failed: `Minimum Top Up ${
          user.currency === "IDR" ? "Rp" : "$"
        } ${minTopUp.toLocaleString("id")}`,
      });
    }
  };
  useEffect(() => {
    let saldo = 0;
    if (topUpBody.saldo) {
      saldo = Number(topUpBody.saldo.replace(".", ""));
    }
    bonusTopUp.forEach((item) => {
      if (topUpBody.saldo >= item.top_up) {
        if (bonusBank >= item.bonus) {
          setNominalYangDidapat(
            user.currency === "IDR"
              ? Math.floor(
                  Number(saldo * exchangeRate) * (bonusBank / 100) +
                    Number(saldo * exchangeRate)
                )
              : Number(saldo * exchangeRate) * (bonusBank / 100) +
                  Number(saldo * exchangeRate)
          );
        } else {
          setNominalYangDidapat(
            user.currency === "IDR"
              ? Math.floor(
                  Number(saldo * exchangeRate) * (item.bonus / 100) +
                    Number(saldo * exchangeRate)
                )
              : Number(saldo * exchangeRate) * (item.bonus / 100) +
                  Number(saldo * exchangeRate)
          );
        }
      }
    });
  }, [topUpBody.saldo]);

  useEffect(() => {
    listBank.forEach((bank) => {
      if (bank.id === topUpBody.bankId) {
        setExchangeRate(bank.exchange_rate);
        if (bank.is_usd) {
          setIsUsd(true);
        } else {
          setIsUsd(false);
        }
        setMinTopUp(bank.min_topup);
      }
    });
  }, [topUpBody.bankId]);

  useEffect(() => {
    // if (selectedWallet.name === "Wallet Biscoin") {
    //   setBonusTopUp([]);
    //   setTopUpBody({ saldo_type: 2 });
    //   setSelect({ saldo_type: "PPOB" });
    // } else {
    setBonusTopUp([]);
    setTopUpBody({ saldo_type: 1 });
    setSelect({ saldo_type: "Biscoin" });
    // }
    getCategoryBank()
      .then((response) => {
        response.data?.id === 3
          ? setCategoriesBank([
              {
                value: response.data.id,
                label: response.data.category,
              },
            ])
          : setCategoriesBank(
              response.data.map((c) => {
                return (
                  c.category !== "Disable" &&
                  c.category !== "Manual" && {
                    value: c.id,
                    label: c.category,
                  }
                );
              })
            );
      })
      .catch((error) => console.log(error));
    if (topUpPending) {
      topUpPending.status === "Pending" &&
        navigate("/topup/confirm", { replace: true });
    }
    getDetailFund()
      .then((response) => {
        setFund(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="p-4">
          <div>
            <SelectInput
              data={targetSaldo}
              handleSelectItem={handleSelectTargetSaldo}
              select={select.saldo_type}
              label={t("targetSaldo")}
              placeholder={t("chooseTargetSaldo")}
            />
          </div>
          <div className="mt-2">
            <SelectInput
              data={select.saldo_type ? categoriesBank : []}
              handleSelectItem={handleSelectCategoryBank}
              select={select.categoryId}
              label={t("paymentMethod")}
              placeholder={t("chooseTargetSaldoFirst")}
            />
          </div>
          <div className="mt-2">
            <SelectInput
              data={select.categoryId ? bankById : []}
              handleSelectItem={handleSelectBank}
              select={select.bankId}
              label={t("destinationAccount")}
              placeholder={t("chooseDestinationAccount")}
            />
          </div>
          <div className="mt-2">
            <Input
              label={t("minimumTopUp")}
              type="number"
              disabled={true}
              placeholder={`${isUsd ? "$" : "Rp"} ${minTopUp.toLocaleString(
                "id"
              )}`}
            />
          </div>
          <div className="mt-2">
            <Input
              label={t("nominalTopUp")}
              value={labelValue}
              change={handleChangeSaldo}
              disabled={select.bankId ? false : true}
            />
          </div>
          {isPulsa && (
            <div className="mt-2">
              <Input
                label={t("mobileNumber")}
                value={phoneValue}
                change={handleChangePhone}
              />
            </div>
          )}
          <div className="mt-2">
            <Input
              label={t("receivedNominal")}
              type="number"
              disabled={true}
              placeholder={`${
                user.currency === "IDR" ? "Rp" : "$"
              } ${nominalYangDidapat.toLocaleString("id")}`}
            />
          </div>

          {message.failed && (
            <div className="text-red-400 bg-red-200 rounded p-4 mt-2">
              {message.failed}
            </div>
          )}
          <OrangeButton
            type="submit"
            title={t("submit")}
            isLoading={isLoading}
          />

          <div
            className="justify-center truncate text-sm mt-6"
            dangerouslySetInnerHTML={{
              __html: fund?.info,
            }}
          />
        </div>
      </form>
    </>
  );
};
