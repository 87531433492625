import moment from "moment";
import React, { useEffect, useState } from "react";
const NewsContentDesktop = (props) => {
  moment.locale("id");
  const [status, setStatus] = useState({
    className: "bg-green-100 text-green-500 p-2 rounded",
    status: "",
    image: "",
  });
  const [image, setImage] = useState("");

  useEffect(() => {
    if (props.status_old === 1 && props.status_new === 1) {
      if (props.rate_old > props.rate_new) {
        setStatus({
          status: `Rp ${props.rate_old.toLocaleString(
            "id"
          )} -> Rp ${props.rate_new.toLocaleString("id")}`,
          className: "bg-green-100 text-green-500 p-2 rounded flex",
          image: "decreased",
        });
      } else if (props.rate_old < props.rate_new) {
        setStatus({
          status: `Rp ${props.rate_old.toLocaleString(
            "id"
          )} -> Rp ${props.rate_new.toLocaleString("id")}`,
          className: "bg-yellow-100 text-yellow-500 p-2 rounded flex",
          image: "increased",
        });
      }
    } else if (props.status_new === 0) {
      setStatus({
        status: "Disabled",
        className: "bg-red-100 text-red-500 p-2 rounded",
      });
    } else if (props.status_new === 1) {
      setStatus({
        status: `Enabled Rp ${props.rate_new.toLocaleString("id")}`,
        className: "bg-purple-100 text-purple-500 p-2 rounded",
      });
    }

    if (props.nama_category.toLowerCase().includes("instagram")) {
      setImage("/assets/news/instagram.png");
    } else if (props.nama_category.toLowerCase().includes("tiktok")) {
      setImage("/assets/news/tiktok.png");
    } else if (props.nama_category.toLowerCase().includes("youtube")) {
      setImage("/assets/news/youtube.png");
    } else if (props.nama_category.toLowerCase().includes("telegram")) {
      setImage("/assets/news/telegram.png");
    } else if (props.nama_category.toLowerCase().includes("facebook")) {
      setImage("/assets/news/facebook.png");
    } else if (props.nama_category.toLowerCase().includes("twitter")) {
      setImage("/assets/news/twitter.png");
    } else if (props.nama_category.toLowerCase().includes("bisnison")) {
      setImage("/logo-round.png");
    } else {
      setImage("/assets/news/web.png");
    }
  }, []);
  return (
    <div className="flex flex-row text-start w-full justify-evenly my-4">
      <img src={image} alt="" className="rounded-xl w-10 h-10 mt-2" />
      <div className="ml-2 w-full">
        <div className="flex flex-row justify-between pt-2 text-xs align-bottom">
          <div className={status.className}>
            {status.image === "increased" && (
              <div className="h-1">
                <img
                  src="/assets/price-increased.png"
                  alt="price-increased"
                  width={18}
                  className="mr-1"
                />
              </div>
            )}
            {status.image === "decreased" && (
              <div className="h-1">
                <img
                  src="/assets/price-decreased.png"
                  alt="price-decreased"
                  width={18}
                  className="mr-1"
                />
              </div>
            )}
            {status.status}
          </div>
          <div className="bg-white text-gray500 pt-2 rounded">
            {moment(props.tanggal).format("DD MMM YYYY, HH:mm ")}
          </div>
        </div>
        <p className="text-sm mt-2">
          [{props.product_id}] {props.content}
        </p>
      </div>
    </div>
  );
};

export default NewsContentDesktop;
