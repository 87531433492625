import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../../contexts/ContextProvider";
import {
  getDetailFund,
  getListTopUp,
  getTopUpPending,
  putCancelTopUp,
} from "../../../services/service";
import Header from "../Header";
import Marquee from "../Marquee";
import TopUpForm from "./TopUpForm";
import TopUpPending from "./TopUpPending";

const SaldoDesktop = () => {
  const {
    topUpPending,
    setTopUpPending,
    historyTopUp,
    setHistoryTopUp,
    list,
    isFilters,
    openFilter,
    setIsFilters,
    setOpenFilter,
    setList,
  } = useStateContext();
  const [fund, setFund] = useState();
  const navigate = useNavigate();

  const [isSearch, setIsSearch] = useState("");

  const [selectedItem, setSelectedItem] = useState("All");

  const [data, setData] = useState([]);

  const filter = ["All", "Paid", "Cancel"];

  const handleClick = (filterWord) => {
    const result = historyTopUp?.filter(
      (e) => e.status.toString() === filterWord
    );
    setSelectedItem(filterWord);
    setIsFilters({
      historyTopUp: filterWord === "All" ? historyTopUp : result,
    });
    setOpenFilter({ historyTopUp: true });
  };

  const handleCancelTopUp = (e) => {
    putCancelTopUp(e)
      .then((response) => {
        response.data.status === "success" && navigate("/", { replace: true });
        localStorage.removeItem("midtrans_token");
      })
      .catch((error) => {
        if (
          error.response.data.message ===
          "Topup status has been cancelled, no changes have been made"
        ) {
          navigate("/", { replace: true });
        }
        console.log(error);
      });
  };

  useEffect(() => {
    const result = data?.filter((items) =>
      items.id?.toString().toLowerCase().includes(isSearch.toLowerCase())
    );
    setList(isSearch ? result : data);
  }, [isSearch, data]);

  useEffect(() => {
    setData(openFilter.historyTopUp ? isFilters.historyTopUp : historyTopUp);
  }, [historyTopUp, isFilters.historyTopUp]);

  useEffect(() => {
    // const snapSrcUrl = "https://app.sandbox.midtrans.com/snap/snap.js";
    // const myMidtransClientKey = "SB-Mid-client-oEWqtn1bpyN1DjCT";

    // for production environments
    const myMidtransClientKey = "Mid-client-dGUkpvAawY8Srn1T"; //change this according to your client-key
    const snapSrcUrl = "https://app.midtrans.com/snap/snap.js";
    const midtrans_token = localStorage.getItem("midtrans_token");

    const script = document.createElement("script");
    script.src = snapSrcUrl;
    script.setAttribute("data-client-key", myMidtransClientKey);
    script.async = true;

    document.body.appendChild(script);

    getListTopUp()
      .then((response) => {
        setHistoryTopUp(response.data.data);
      })
      .catch((error) => console.log(error));

    getTopUpPending()
      .then((response) => {
        if (response.data.data) {
          if (
            response.data.data?.bank.payment_gateway === "midtrans" &&
            response.data.data.status === "Pending" &&
            midtrans_token
          ) {
            window.snap.pay(midtrans_token, {
              gopayMode: "qr",
              onSuccess: function (result) {
                localStorage.removeItem("midtrans_token");
              },
              onPending: function (result) {
                /* You may add your own implementation here */
                alert("waiting your payment!");
              },
              onError: function (result) {
                /* You may add your own implementation here */
                handleCancelTopUp(topUpPending?.id);
                alert("payment failed!");
              },
              onClose: function (result) {
                alert("payment closed!");
              },
            });
          }
        }
        setTopUpPending(response.data.data);
      })
      .catch((error) => console.log(error));

    getDetailFund()
      .then((response) => {
        setFund(response.data);
      })
      .catch((error) => console.log(error));

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <Header label={"Saldo"} />
      <Marquee />
      <div className="grid grid-cols-2 p-8 gap-8">
        {topUpPending ? <TopUpPending /> : <TopUpForm />}
        <div
          className="bg-white shadow-lg rounded-lg p-4 overflow-y-auto"
          style={{ height: 650 }}
        >
          <div
            className="justify-center truncate text-sm mt-6"
            dangerouslySetInnerHTML={{
              __html: fund?.info,
            }}
          />
        </div>
      </div>
      <div className="px-8">
        <div className="w-full bg-white shadow-lg h-128 overflow-y-auto rounded-lg">
          <div className="flex flex-row justify-between m-4">
            <div className="flex gap-4 text-center mt-2">
              {filter.map((item) => (
                <div
                  className={`${
                    selectedItem === item && " bg-orange-500 text-white"
                  } py-1 px-4 rounded-lg cursor-pointer border`}
                  onClick={() => handleClick(item)}
                  key={JSON.stringify(item)}
                >
                  {item}
                </div>
              ))}
            </div>

            <input
              className="rounded-lg bg-white border px-2 w-96"
              type="text"
              onChange={(e) => setIsSearch(e.target.value)}
              placeholder="Search By Topup ID"
            />
          </div>
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-white uppercase sticky top-0 bg-blue-bisnison">
              <tr>
                <th scope="col" className="px-3 py-3  whitespace-nowrap">
                  Topup ID
                </th>
                <th scope="col" className="px-3 py-3  whitespace-nowrap">
                  Nominal
                </th>
                <th scope="col" className="px-3 py-3  whitespace-nowrap">
                  Bank
                </th>
                <th scope="col" className="px-3 py-3 ">
                  Invoice ID
                </th>
                <th scope="col" className="px-3 py-3 ">
                  Status
                </th>
                <th scope="col" className="px-3 py-3 ">
                  Date
                </th>
              </tr>
            </thead>

            {list.map((history, index) => {
              let colorCondition = "text-green-500";
              if (history.status === "Cancel") {
                colorCondition = "text-red-500";
              } else if (history.status === "Paid") {
                colorCondition = "text-green-500";
              } else {
                colorCondition = "text-red-500";
              }
              return (
                <tbody key={history?.id} className="w-full text-xs">
                  <tr key={index} className="bg-white border-b text-sm">
                    <td className="px-3 py-3 ">{history.id}</td>
                    <td className="px-3 py-3 ">
                      {history.jumlah < 10000
                        ? `$ ${history.jumlah}`
                        : `Rp ${history.jumlah.toLocaleString("id")}`}
                    </td>
                    <td className="px-3 py-3 ">{history.bank?.nama}</td>
                    <td className="px-3 py-3 ">{history.invoice}</td>
                    <td className={`px-3 py-3 ${colorCondition}`}>
                      {history.status}
                    </td>
                    <td className="px-3 py-3 whitespace-nowrap ">
                      {moment(history.tanggal).format("DD/MMM/YYYY, HH:mm")}
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </table>
        </div>
      </div>
    </div>
  );
};

export default SaldoDesktop;
