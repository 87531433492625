import axios from "axios";

const url = process.env.REACT_APP_BASE_URL;
const token = localStorage.getItem("token");

export const postLogin = (props) => {
  return axios.post(`${url}/api/auth/login`, {
    username: props.username,
    password: props.password,
    "g-recaptcha-response": props.token,
  });
};

export const postLogout = () => {
  return axios.post(
    `${url}/api/logout`,
    {},
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const postRegister = (props) => {
  return axios.post(`${url}/api/register`, {
    username: props.username,
    email: props.email,
    password: props.password,
    password_confirmation: props.passwordConfirmation,
    hp: props.phoneNumber,
    otp: props.otp,
    code: props.code,
    ip: props.ip,
    "g-recaptcha-response": props.token,
  });
};

export const postForgotPassword = (email) => {
  return axios.post(`${url}/api/forgot-password`, {
    to: email,
  });
};

export const postResetPassword = (props) => {
  return axios.post(`${url}/api/forgot-password/reset`, {
    email: props.email,
    password: props.password,
    password_confirmation: props.confirmPassword,
    token: props.token,
  });
};

export const postSendOtp = (props) => {
  return axios.post(`${url}/api/register/sendotp`, {
    hp: props.phoneNumber,
    username: props.username,
    email: props.email,
  });
};

export const postCheckOtp = (props) => {
  return axios.post(`${url}/api/register/checkotp`, {
    hp: props.phoneNumber,
    otp: props.otp,
  });
};

export const postReqTopUp = (body) => {
  return axios.post(`${url}/api/req-topup`, body, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const postTicket = (body) => {
  return axios.post(`${url}/api/tickets`, body, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const postChat = (props) => {
  return axios.post(
    `${url}/api/tickets/${props.id}`,
    {
      message: props.chat,
    },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};
export const postAPIChange = (props) => {
  return axios.post(
    `${url}/api/user/api-key/change`,
    {},
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const postTransferSaldo = (props) => {
  return axios.post(
    `${url}/api/transfer-saldo`,
    {
      transferType: props.transferType,
      jumlah: props.jumlah,
    },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const postExchangePoints = (points) => {
  return axios.post(
    `${url}/api/point`,
    {
      points: points,
    },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const postSendOTPVerifikasiWA = (hp) => {
  return axios.post(
    `${url}/api/get-verification-otp`,
    {
      new_phone: hp,
    },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const postCheckOTPVerifikasiWA = (hp, otp) => {
  return axios.post(
    `${url}/api/check-verification-otp`,
    {
      new_phone: hp,
      otp: otp,
    },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const postVisitorCount = (affiliate_code) => {
  return axios.post(
    `${url}/api/affiliate-visitors`,
    {
      affiliate_code: affiliate_code,
    },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const postPremiumUpgrade = (gmail) => {
  return axios.post(
    `${url}/api/upgrade-premium`,
    {
      gmail: gmail,
    },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const postVerifikasiKTP = (image) => {
  return axios.post(`${url}/api/user-ktp`, image, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const postAddBank = (codeBank, noRekening, atasNama) => {
  return axios.post(
    `${url}/api/user-bank`,
    {
      code_bank: codeBank,
      no_rekening: noRekening,
      atas_nama: atasNama,
    },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const postHightlight = (data) => {
  return axios.post(
    `${url}/api/highlights`,
    {
      highlight: data,
    },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const postCategoryBanner = (data) => {
  return axios.post(
    `${url}/api/banner-category`,
    {
      category: data,
    },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const postBanner = (data) => {
  return axios.post(`${url}/api/banner`, data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const postPricelistView = (pricelist_view) => {
  return axios.post(
    `${url}/api/user-pricelist-view`,
    { view: pricelist_view },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const postWdCommission = (commission) => {
  return axios.post(
    `${url}/api/affiliate-withdrawal`,
    {
      value: commission,
    },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const putCancelTopUp = (props) => {
  return axios.put(
    `${url}/api/cancel-topup/${props}`,
    {},
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const putChangePassword = (props) => {
  return axios.put(
    `${url}/api/change-password`,
    {
      passlama: props.passlama,
      passbaru1: props.passbaru1,
      passbaru2: props.passbaru2,
    },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const putChangeCurrency = (currency) => {
  return axios.put(
    `${url}/api/currency/id`,
    {
      currency: currency,
    },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const putOrderService = (props) => {
  return axios.put(
    `${url}/api/order/submit`,
    {
      service: props.service,
      quantity: props.quantity,
      link: props.link,
      target2: props.target,
      comment: props.comment,
      posts: props.posts,
      min: props.min,
      max: props.max,
      delay: props.delay,
    },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const putBank = (id) => {
  return axios.put(
    `${url}/api/user-bank/${id}/update`,
    {},
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const getCategoriesSMM = () => {
  return axios.get(`${url}/api/categories`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getFetchAffiliate = () => {
  return axios.get(`${url}/api/affiliate-fetch`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getFetchAffiliateBonus = () => {
  return axios.get(`${url}/api/affiliate-fetch-bonus`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getServicesByCategories = (props) => {
  return axios.get(`${url}/api/services-by-categories?sorter=${props.sorter}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getServicesByCategoryId = (props) => {
  return axios.get(`${url}/api/order/category/${props.id}/services`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getDetailService = (props) => {
  return axios.get(`${url}/api/order/service/${props.id}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getCategoriesPPOB = () => {
  return axios.get(`${url}/api/category-ppob`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getPPOBById = (props) => {
  return axios.get(`${url}/api/ppob?ppobCategoryId=${props.id}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getHistoryOrderSMM = (props) => {
  return axios.get(
    `${url}/api/history-order?sorter=updated_at${
      props?.id ? `&page=${props.id}` : ""
    }`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const getSortirHistoryOrderSMM = (props) => {
  return axios.get(
    `${url}/api/history-order?sorter=${props.sorter}&page=${props.id}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const getHistoryOrderPPOB = (props) => {
  return axios.get(
    `${url}/api/history-ppob${props?.id ? `?page=${props.id}` : ""}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    },
    props.params
  );
};

export const getTickets = () => {
  return axios.get(`${url}/api/tickets`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getChats = (props) => {
  return axios.get(`${url}/api/tickets/${props}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getNews = (props) => {
  return axios.get(`${url}/api/news`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getSales = (props) => {
  return axios.get(`${url}/api/sales`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getHistorySalesThisWeek = (props) => {
  return axios.get(`${url}/api/history/sales_this_week`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getHistoryCount = (props) => {
  return axios.get(`${url}/api/history/count`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getListTopUp = (props) => {
  return axios.get(`${url}/api/list-topup`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getCategoryBank = (props) => {
  return axios.get(`${url}/api/category-bank`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getBankById = (props) => {
  return axios.get(`${url}/api/list-bank?categoryId=${props}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getTopUpPending = (props) => {
  return axios.get(`${url}/api/topup-pending`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getArticles = () => {
  return axios.get("https://bisnison.com/wp-json/wp/v2/posts");
};

export const getArticlesByCategory = ({ id }) => {
  return axios.get(
    `https://bisnison.com/wp-json/wp/v2/posts?categories=${id}&per_page=10`
  );
};

export const getRankings = () => {
  return axios.get(`${url}/api/rankings`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getPageInfo = (props) => {
  return axios.get(`${url}/api/page-info?page_name=${props}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getUser = () => {
  return axios.get(`${url}/api/user`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getTags = () => {
  return axios.get(`${url}/api/tags`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getProductTags = (id) => {
  return axios.get(`${url}/api/product-tags/${id}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getServiceById = (id) => {
  return axios.get(`${url}/api/order/service/${id}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getBonusTopUp = (id) => {
  return axios.get(`${url}/api/bonus-topup?bank_id=${id}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getCheckAffiliateCode = (code, ip) => {
  return axios.get(
    `${url}/api/check-affiliate-code?affiliate_code=${code}&ip=${ip}`
  );
};

export const getProductNotification = () => {
  return axios.get(`${url}/api/product-notification`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getStatusKTP = () => {
  return axios.get(`${url}/api/user-ktp`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getListBank = () => {
  return axios.get(`${url}/api/user-bank`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getSpecialPrice = () => {
  return axios.get(`${url}/api/user-special-price`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getDetailBank = (id) => {
  return axios.get(`${url}/api/user-bank/${id}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const deleteBank = (id) => {
  return axios.delete(`${url}/api/user-bank/${id}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getHightlights = () => {
  return axios.get(`${url}/api/highlights`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getCategoryBanners = () => {
  return axios.get(`${url}/api/banner-category`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getBanners = () => {
  return axios.get(`${url}/api/banners`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getPricelistView = () => {
  return axios.get(`${url}/api/user-pricelist-view`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const postMonitoringLayanan = (props) => {
  return axios.post(
    `${url}/api/monitoring-service`,
    {
      sorter: props.sort,
      page: props.page,
      day: props.day,
      category_id: props.category_id,
      product_major_category_id: props.product_major_category_id,
    },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const getCheckService = (id) => {
  return axios.get(`${url}/api/service-check/${id}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getProductMajorCategories = () => {
  return axios.get(`${url}/api/product-major-categories`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getWdMinMax = () => {
  return axios.get(`${url}/api/withdrawal-min-max`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getHistoryWd = () => {
  return axios.get(`${url}/api/list-affiliate-withdrawal`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getCommission = () => {
  return axios.get(`${url}/api/bonus-commission`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getDetailFund = () => {
  return axios.get(`${url}/api/page-info?page_name=fund`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getDetailLogin = () => {
  return axios.get(`${url}/api/page-info?page_name=login`);
};

export const getDetailRegister = () => {
  return axios.get(`${url}/api/page-info?page_name=register`);
};

export const getIpPublic = () => {
  return axios.get("https://api.ipify.org/?format=json");
};
