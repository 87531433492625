import { BiArrowBack } from "react-icons/bi";
import { IoLogOutOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { postLogout } from "../../../services/service";

export const TopNavbar = () => {
  const handleLogout = () => {
    postLogout()
      .then((response) => {
        if (response.data.success) {
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <header className="sticky top-0 z-10 transition-shadow w-screen sm:w-128 mx-auto">
      <nav className="transition-colors">
        <ul className="flex items-center justify-between p-4 ">
          <div className="w-1/4">
            <Link to="/">
              <BiArrowBack size={30} className="text-white" />
            </Link>
          </div>
          <Link to="/">
            <img
              src="/logo-long.png"
              alt="logo-long"
              size={130}
              className="max-w-3/6"
            />
          </Link>
          <div className="w-1/4 flex place-content-end">
            {/* <NotificationIcon /> */}
            <IoLogOutOutline
              className="ml-2 text-white"
              size={30}
              onClick={handleLogout}
            />
          </div>
        </ul>
      </nav>
    </header>
  );
};
