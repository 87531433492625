import ReactEcharts from "echarts-for-react";
import { useTranslation } from "react-i18next";

const SalesGraphic = ({ historySalesThisWeek }) => {
  const { t } = useTranslation();
  const optionLineChart = {
    tooltip: {
      trigger: "axis",
    },
    legend: {
      data: [
        t("statuses.pending"),
        t("statuses.inProgress"),
        t("statuses.done"),
      ],
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      // data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        name: historySalesThisWeek[0].id,
        type: "line",
        stack: "Total",
        data: historySalesThisWeek[0].data,
      },
      {
        name: historySalesThisWeek[1].id,
        type: "line",
        stack: "Total",
        data: historySalesThisWeek[1].data,
      },
      {
        name: historySalesThisWeek[2].id,
        type: "line",
        stack: "Total",
        data: historySalesThisWeek[2].data,
      },
    ],
  };

  return (
    <div className="p-4">
      <p className="text-xl">{t("salesGraphic")}</p>
      <div className="w-full">
        <ReactEcharts option={optionLineChart} />
      </div>
    </div>
  );
};

export default SalesGraphic;
