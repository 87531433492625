import { useEffect } from "react";
import {
  BiMessageSquareDots,
  BiHistory,
  BiHomeAlt,
  BiNews,
  BiUser,
} from "react-icons/bi";
import { useStateContext } from "../../contexts/ContextProvider";
import { BottomNavbarItem } from "./BottomNavbarItem";
import { useTranslation } from "react-i18next";

export default function BottomNavbar(props) {
  const { t } = useTranslation();
  const { selectedMenu, setSelectedMenu } = useStateContext();
  const items = [
    {
      text: t("menu.help"),
      icon: <BiMessageSquareDots size={25} className="mx-auto" />,
      url: "/bantuan",
    },
    {
      text: t("menu.history"),
      icon: <BiHistory size={25} className="mx-auto" />,
      url: "/history",
    },
    {
      text: t("menu.home"),
      icon: <BiHomeAlt size={25} className="mx-auto" />,
      url: "/",
    },
    {
      text: t("menu.affiliate"),
      icon: <BiNews size={25} className="mx-auto" />,
      url: "/affiliate",
    },
    {
      text: t("menu.profile"),
      icon: <BiUser size={25} className="mx-auto" />,
      url: "/profile",
    },
  ];

  const handlePress = (index) => {
    setSelectedMenu(index);
  };

  useEffect(() => {
    switch (window.location.pathname) {
      case "/bantuan":
        setSelectedMenu(items[0].text);
        break;
      case "/history":
        setSelectedMenu(items[1].text);
        break;
      case "/":
        setSelectedMenu(items[2].text);
        break;
      case "/affiliate":
        setSelectedMenu(items[3].text);
        break;
      case "/profile":
        setSelectedMenu(items[4].text);
        break;

      default:
        setSelectedMenu(items[0].text);
        break;
    }
  });

  return (
    <div className="w-screen sm:w-128 mx-auto block fixed bottom-0 inset-x-0 z-10">
      <nav className="bg-white transition-colors shadow-inner">
        <ul className="text-gray-400 text-sm mx-auto flex items-center justify-around">
          {items.map((item) => {
            return (
              <BottomNavbarItem
                text={item.text}
                icon={item.icon}
                onChange={handlePress}
                selectedMenu={selectedMenu}
                key={item.text}
                url={item.url}
              />
            );
          })}
        </ul>
      </nav>
    </div>
  );
}
