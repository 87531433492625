import { useState } from "react";
import { BiCopy } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../../contexts/ContextProvider";
import { putCancelTopUp } from "../../../services/service";
import { Input } from "../../mobile/Input";
import moment from "moment";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { OrangeButton } from "../../mobile/OrangeButton";
import { useTranslation } from "react-i18next";

const TopUpPending = () => {
  const { t } = useTranslation();
  const { user, topUpPending, setTopUpPending } = useStateContext();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTutorTopup, setSelectedTutorTopup] = useState(0);

  const transferMethods = [
    {
      bank: "bri",
      methods: [
        {
          method: "Mobile Banking BRI",
          steps: [
            "<p>Buka aplikasi Mobile Banking BRI.</p>",
            "<p>Masuk ke akun Anda.</p>",
            '<p>Pilih "Transfer."</p>',
            '<p>Pilih "Ke Rekening BRI."</p>',
            `<p>Masukkan nomor rekening tujuan <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">${
              topUpPending?.bank.no_rek
            }</span> dan jumlah yang akan ditransfer <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">Rp ${topUpPending?.jumlah.toLocaleString(
              "id"
            )}</span>.</p>`,
            "<p>Konfirmasi transaksi dan ikuti petunjuk selanjutnya.</p>",
          ],
        },
        {
          method: "Internet Banking BRI",
          steps: [
            "<p>Buka situs web Internet Banking BRI.</p>",
            "<p>Masuk ke akun Anda.</p>",
            '<p>Pilih "Transfer."</p>',
            '<p>Pilih "Ke Rekening BRI."</p>',
            `<p>Masukkan nomor rekening tujuan <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">${
              topUpPending?.bank.no_rek
            }</span> dan jumlah yang akan ditransfer <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">Rp ${topUpPending?.jumlah.toLocaleString(
              "id"
            )}</span>.</p>`,
            "<p>Konfirmasi transaksi dan ikuti petunjuk selanjutnya.</p>",
          ],
        },
        {
          method: "ATM BRI",
          steps: [
            "<p>Masukkan kartu ATM BRI dan PIN Anda.</p>",
            '<p>Pilih "Menu Transaksi Lainnya."</p>',
            '<p>Pilih "Transfer."</p>',
            '<p>Pilih "Ke Rekening BRI."</p>',
            `<p>Masukkan nomor rekening tujuan <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">${topUpPending?.bank.no_rek}</span>.</p>`,
            `<p>Masukkan jumlah yang akan ditransfer <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">Rp ${topUpPending?.jumlah.toLocaleString(
              "id"
            )}</span>.</p>`,
            "<p>Konfirmasi transaksi dan simpan bukti transaksi.</p>",
          ],
        },
      ],
    },
    {
      bank: "bni",
      methods: [
        {
          method: "Mobile Banking BNI",
          steps: [
            "<p>Buka aplikasi Mobile Banking BNI.</p>",
            "<p>Masuk ke akun Anda.</p>",
            '<p>Pilih "Transfer."</p>',
            '<p>Pilih "Transfer antar rekening BNI."</p>',
            `<p>Masukkan nomor rekening tujuan <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">${
              topUpPending?.bank.no_rek
            }</span> dan jumlah yang akan ditransfer <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">Rp ${topUpPending?.jumlah.toLocaleString(
              "id"
            )}</span>.</p>`,
            "<p>Konfirmasi transaksi dan ikuti petunjuk selanjutnya.</p>",
          ],
        },
        {
          method: "Internet Banking BNI",
          steps: [
            "<p>Buka situs web Internet Banking BNI.</p>",
            "<p>Masuk ke akun Anda.</p>",
            '<p>Pilih "Transfer."</p>',
            '<p>Pilih "Transfer antar rekening BNI."</p>',
            `<p>Masukkan nomor rekening tujuan <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">${
              topUpPending?.bank.no_rek
            }</span> dan jumlah yang akan ditransfer <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">Rp ${topUpPending?.jumlah.toLocaleString(
              "id"
            )}</span>.</p>`,
            "<p>Konfirmasi transaksi dan ikuti petunjuk selanjutnya.</p>",
          ],
        },
        {
          method: "ATM BNI",
          steps: [
            "<p>Masukkan kartu ATM BNI dan PIN Anda.</p>",
            '<p>Pilih "Menu Transfer."</p>',
            '<p>Pilih "Transfer antar rekening BNI."</p>',
            `<p>Masukkan nomor rekening tujuan <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">${topUpPending?.bank.no_rek}</span>.</p>`,
            `<p>Masukkan jumlah yang akan ditransfer <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">Rp ${topUpPending?.jumlah.toLocaleString(
              "id"
            )}</span>.</p>`,
            "<p>Konfirmasi transaksi dan simpan bukti transaksi.</p>",
          ],
        },
      ],
    },
    {
      bank: "mandiri",
      methods: [
        {
          method: "Mobile Banking Mandiri",
          steps: [
            "<p>Buka aplikasi Mobile Banking Mandiri.</p>",
            "<p>Masuk ke akun Anda.</p>",
            '<p>Pilih "Transfer."</p>',
            '<p>Pilih "Ke Rekening Bank Lain."</p>',
            '<p>Pilih "Transfer ke Rekening Mandiri."</p>',
            `<p>Masukkan nomor rekening tujuan <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">${
              topUpPending?.bank.no_rek
            }</span> dan jumlah yang akan ditransfer <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">Rp ${topUpPending?.jumlah.toLocaleString(
              "id"
            )}</span>.</p>`,
            "<p>Konfirmasi transaksi dan ikuti petunjuk selanjutnya.</p>",
          ],
        },
        {
          method: "Internet Banking Mandiri",
          steps: [
            "<p>Buka situs web Internet Banking Mandiri.</p>",
            "<p>Masuk ke akun Anda.</p>",
            '<p>Pilih "Transfer."</p>',
            '<p>Pilih "Ke Rekening Bank Lain."</p>',
            '<p>Pilih "Transfer ke Rekening Mandiri."</p>',
            `<p>Masukkan nomor rekening tujuan <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">${
              topUpPending?.bank.no_rek
            }</span> dan jumlah yang akan ditransfer <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">Rp ${topUpPending?.jumlah.toLocaleString(
              "id"
            )}</span>.</p>`,
            "<p>Konfirmasi transaksi dan ikuti petunjuk selanjutnya.</p>",
          ],
        },
        {
          method: "ATM Mandiri",
          steps: [
            "<p>Masukkan kartu ATM Mandiri dan PIN Anda.</p>",
            '<p>Pilih "Menu Transfer."</p>',
            '<p>Pilih "Ke Rekening Bank Lain."</p>',
            '<p>Pilih "Transfer ke Rekening Mandiri."</p>',
            `<p>Masukkan nomor rekening tujuan <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">${topUpPending?.bank.no_rek}</span>.</p>`,
            `<p>Masukkan jumlah yang akan ditransfer <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">Rp ${topUpPending?.jumlah.toLocaleString(
              "id"
            )}</span>.</p>`,
            "<p>Konfirmasi transaksi dan simpan bukti transaksi.</p>",
          ],
        },
      ],
    },
    {
      bank: "bca",
      methods: [
        {
          method: "Mobile Banking BCA",
          steps: [
            "<p>Buka aplikasi Mobile Banking BCA.</p>",
            "<p>Masuk ke akun Anda.</p>",
            '<p>Pilih "Transfer."</p>',
            '<p>Pilih "Ke Rekening BCA."</p>',
            `<p>Masukkan nomor rekening tujuan <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">${
              topUpPending?.bank.no_rek
            }</span> dan jumlah yang akan ditransfer <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">Rp ${topUpPending?.jumlah.toLocaleString(
              "id"
            )}</span>.</p>`,
            "<p>Konfirmasi transaksi dan ikuti petunjuk selanjutnya.</p>",
          ],
        },
        {
          method: "Internet Banking BCA",
          steps: [
            "<p>Buka situs web Internet Banking BCA.</p>",
            "<p>Masuk ke akun Anda.</p>",
            '<p>Pilih "Transfer."</p>',
            '<p>Pilih "Ke Rekening BCA."</p>',
            `<p>Masukkan nomor rekening tujuan <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">${
              topUpPending?.bank.no_rek
            }</span> dan jumlah yang akan ditransfer <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">Rp ${topUpPending?.jumlah.toLocaleString(
              "id"
            )}</span>.</p>`,
            "<p>Konfirmasi transaksi dan ikuti petunjuk selanjutnya.</p>",
          ],
        },
        {
          method: "ATM BCA",
          steps: [
            "<p>Masukkan kartu ATM BCA dan PIN Anda.</p>",
            '<p>Pilih "Menu Transfer."</p>',
            '<p>Pilih "Ke Rekening BCA."</p>',
            `<p>Masukkan nomor rekening tujuan <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">${topUpPending?.bank.no_rek}</span>.</p>`,
            `<p>Masukkan jumlah yang akan ditransfer <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">Rp ${topUpPending?.jumlah.toLocaleString(
              "id"
            )}</span>.</p>`,
            "<p>Konfirmasi transaksi dan simpan bukti transaksi.</p>",
          ],
        },
      ],
    },
  ];

  const handleItemClick = (item) => {
    if (selectedTutorTopup === item) {
      // Jika item yang sama diklik lagi, tutup dropdown
      setSelectedTutorTopup(null);
    } else {
      setSelectedTutorTopup(item);
    }
  };

  const handleCancelTopUp = (e) => {
    setIsLoading(true);
    putCancelTopUp(e)
      .then((response) => {
        if (response.data.status === "success") {
          setTopUpPending();
          localStorage.removeItem("midtrans_token");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (
          error.response.data.message ===
          "Topup status has been cancelled, no changes have been made"
        ) {
          window.location.reload();
        }
        setIsLoading(false);
        console.log(error);
      });
  };

  const handleCopyText = (text) => {
    window.navigator.clipboard.writeText(text).then(
      () => {
        alert("Copied!");
      },
      () => {
        alert("Failed!");
      }
    );
  };

  const handleBankImage = (bank) => {
    const bankToLower = bank.toLowerCase();
    if (bankToLower.includes("qris")) {
      return "assets/banks/qris.png";
    } else if (bankToLower.includes("bca")) {
      return "assets/banks/bca.png";
    } else if (bankToLower.includes("bni")) {
      return "assets/banks/bni.png";
    } else if (bankToLower.includes("akulaku")) {
      return "assets/banks/akulaku.png";
    } else if (bankToLower.includes("bri")) {
      return "assets/banks/bri.png";
    } else if (bankToLower.includes("permata")) {
      return "assets/banks/permata.png";
    } else if (bankToLower.includes("mandiri")) {
      return "assets/banks/mandiri.png";
    } else if (bankToLower.includes("xl")) {
      return "assets/banks/xl.png";
    } else if (bankToLower.includes("telkomsel")) {
      return "assets/banks/telkomsel.png";
    } else if (bankToLower.includes("perfect")) {
      return "assets/banks/pm.png";
    }
  };

  return (
    <div className="bg-white shadow-lg rounded-lg p-4 h-max">
      <div className="mt-4 flex flex-row justify-between">
        <div className="">
          <img
            src="/logo-long.png"
            alt="logo-long"
            size={130}
            className="max-w-3/6"
          />
          <p className="text-xl">{t("pendingTopUp")}</p>
          <p className="text-orange-500 text-xs">
            Pay Before{" "}
            {moment(topUpPending?.date_request)
              .add(1, "days")
              .format("HH:mm:ss - DD MMMM YYYY")}
          </p>
        </div>
        <div className="">
          <p className="font-semibold text-xl text-right">
            {t("paymentMethod")}
          </p>
          <img
            src={handleBankImage(topUpPending?.type)}
            alt="bank"
            className="ml-auto"
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <Input
          label={t("topupId")}
          disabled={true}
          value={topUpPending?.id}
          icon={
            <button onClick={() => handleCopyText(topUpPending?.id)}>
              <BiCopy className="mx-3 text-orange-500" />
            </button>
          }
        />

        <Input
          label={t("targetTopupId")}
          disabled={true}
          value={topUpPending?.saldo_type}
        />
      </div>
      <Input
        label={t("accountNumber")}
        type="text"
        disabled={true}
        value={topUpPending?.bank.no_rek}
        icon={
          <button onClick={() => handleCopyText(topUpPending?.bank.no_rek)}>
            <BiCopy className="mx-3 text-orange-500" />
          </button>
        }
      />
      <Input
        label={t("accountName")}
        disabled={true}
        value={topUpPending?.bank.atas_nama}
      />
      <Input
        label={t("totalPayment")}
        type="text"
        disabled={true}
        value={`${
          topUpPending?.type === "perfectmoney" ? "$" : "Rp"
        } ${topUpPending?.jumlah.toLocaleString("id")}`}
        icon={
          <button onClick={() => handleCopyText(topUpPending?.jumlah)}>
            <BiCopy className="mx-3 text-orange-500" />
          </button>
        }
      />

      <div className="flex flex-col space-y-2 mt-4 border-y-2 pt-2 ">
        {transferMethods.map((item) => {
          if (item.bank === topUpPending?.bank.bank) {
            return (
              <div key={item.bank} className="">
                <div className="flex items-center justify-between mb-2">
                  <p>
                    {t("transferInstructions")} {item.bank.toUpperCase()}
                  </p>
                </div>
                <div className={``}>
                  <div>
                    {item.methods.map((i, index) => {
                      return (
                        <div className="">
                          <div
                            key={i.method}
                            className={`w-11/12 flex justify-between cursor-pointer `}
                            onClick={() => handleItemClick(index)}
                          >
                            <p className="font-semibold mb-2">{i.method}</p>

                            {selectedTutorTopup === index ? (
                              <BsChevronUp />
                            ) : (
                              <BsChevronDown />
                            )}
                          </div>
                          <div
                            className={`${
                              selectedTutorTopup === index ? "block" : "hidden"
                            } border-b border-gray-200 mb-2`}
                          >
                            {i.steps.map((step, indexStep) => {
                              return (
                                <div
                                  key={step}
                                  className="flex space-x-2 mb-2  "
                                >
                                  <div className="w-fit">
                                    <div className="flex justify-center items-center rounded-full h-5 w-5 text-xs text-white bg-orange-500 ">
                                      <span>{indexStep + 1}</span>
                                    </div>
                                  </div>
                                  <div className="">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: step,
                                      }}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          } else {
            return <></>;
          }
        })}
      </div>
      <div className="">
        <OrangeButton
          className="rounded-xl bg-orange-500 w-full p-2 mt-2 text-white text-center"
          title={t("alreadyTransferred")}
          isLoading={isLoading}
          disabled={isLoading}
          onClick={() => navigate("/")}
        />
        <div className="h-5" />
        <p
          onClick={() => handleCancelTopUp(topUpPending?.id)}
          className="cursor-pointer text-center font-semibold text-sm text-red-500"
        >
          {t("cancelTopup")}
        </p>
      </div>
    </div>
  );
};

export default TopUpPending;
