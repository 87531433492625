import React, { useEffect, useState } from "react";
import { useStateContext } from "../../contexts/ContextProvider";

export const SearchTable = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSearch, setIsSearch] = useState("");

  const { setList } = useStateContext();

  useEffect(() => {
    const result = props.data?.filter((items) =>
      items[props.searchWord]
        ?.toString()
        .toLowerCase()
        .includes(isSearch.toLowerCase())
    );
    setList(isSearch ? result : props.data);
  }, [isSearch, props.data]);

  return (
    <div className="flex-row flex">
      {isOpen && (
        <input
          type="text"
          className="text-sm rounded-full border px-4"
          onChange={(e) => setIsSearch(e.target.value)}
          placeholder={props.searchText}
        />
      )}
      <div
        className="w-8 h-8 rounded-full p-2 bg-gray-200 mx-2"
        onClick={() => setIsOpen(!isOpen)}
      >
        <img src="/assets/search.svg" alt="" className="w-6" />
      </div>
    </div>
  );
};
