import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TopUpContent } from "../../components/mobile/TopUp/TopUpContent";
import { TopUpTab } from "../../components/mobile/TopUp/TopUpTab";
import { getTopUpPending } from "../../services/service";

export const TopUpPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getTopUpPending()
      .then((response) => {
        if (response.data.data.status === "Pending") {
          navigate("confirm");
        }
      })
      .catch((error) => console.log(error));
  }, []);
  return (
    <div className=" w-screen sm:w-128 min-h-screen mx-auto shadow bg-white">
      <TopUpTab />
      <TopUpContent />
    </div>
  );
};
