import { Link } from "react-router-dom";
import { useStateContext } from "../../contexts/ContextProvider";
import { useTranslation } from "react-i18next";

export const NewWallet = () => {
  const { t } = useTranslation();
  const { user, setSelectedWallet } = useStateContext();
  return (
    <div className="w-11/12 mx-auto rounded bg-blue-bisnison shadow py-4 px-2 flex justify-between mt-4">
      <Link
        to={"/wallet"}
        onClick={() =>
          setSelectedWallet({
            name: t("wallet.walletIdr"),
            value: user.saldo.toLocaleString("id"),
          })
        }
        className="w-full px-1"
      >
        <div>
          <div className="flex place-items-center justify-center">
            <img
              src="/assets/biscoin.svg"
              alt="saldo"
              width={20}
              className="mr-1"
            />
            <p className="text-xs text-white">
              {user.saldo.toLocaleString("id")}
            </p>
          </div>
          <p className="text-xs text-gray-100 mt-1 text-center">
            {t("wallet.walletBiscoin")}
          </p>
        </div>
      </Link>
      <div className="h-10 w-px bg-gray-200" />
      <Link
        to={"/wallet"}
        onClick={() =>
          setSelectedWallet({
            name: t("wallet.walletBiscoin"),
            value: user.ppob_saldo.toLocaleString("id"),
          })
        }
        className="w-full px-1"
      >
        <div>
          <div className="flex place-items-center justify-center">
            <img
              src="/assets/ppob.svg"
              alt="saldo"
              width={20}
              className="mr-1"
            />
            <p className="text-xs text-white">
              {user.ppob_saldo.toLocaleString("id")}
            </p>
          </div>
          <p className="text-xs text-gray-100 mt-1 text-center">
            {t("wallet.walletIdr")}
          </p>
        </div>
      </Link>
      <div className="h-10 w-px bg-gray-200" />
      <Link
        to={
          user.user_type === "PREMIUM"
            ? "/premium-benefits"
            : "/premium-upgrade"
        }
        className="w-full px-1"
      >
        <div>
          <div className="flex place-items-center justify-center">
            <img
              src="/assets/crown_premium.png"
              alt="premium"
              width={20}
              className="mr-1"
            />
            <p className="text-xs font-semibold" style={{ color: "#E9C258" }}>
              {t("wallet.premium")}
            </p>
          </div>
          <p className="text-xs text-gray-100 mt-1 text-center">
            {user.user_type === "PREMIUM"
              ? t("wallet.seeBenefits")
              : t("wallet.clickToUpgrade")}
          </p>
        </div>
      </Link>
    </div>
  );
};
