import { OrangeButton } from "./OrangeButton";
import { BiArrowBack } from "react-icons/bi";
import React, { memo, useState, useEffect, useCallback } from "react";
import SingleInput from "./SingleInput";
import { ImSpinner8 } from "react-icons/im";
import { useTranslation } from "react-i18next";

export function OTPInputComponent(props) {
  const { t } = useTranslation();
  const {
    length,
    isNumberInput,
    autoFocus,
    inCorrect,
    disabled,
    onChangeOTP,
    inputClassName,
    inputStyle,
    cancel,
    hp,
    handleSubmit,
    sendOtp,
    countDown,
    isLoading,
    otpLoading,
    ...rest
  } = props;

  const [scale, setScale] = useState("scale-0");
  const [activeInput, setActiveInput] = useState(0);
  const [otpValues, setOTPValues] = useState([""]);

  // Helper to return OTP from inputs
  const handleOtpChange = useCallback(
    (otp) => {
      const otpValue = otp.join("");
      onChangeOTP(otpValue);
    },
    [onChangeOTP]
  );

  // Helper to return value with the right type: 'text' or 'number'
  const getRightValue = useCallback(
    (str) => {
      let changedValue = str;

      if (!isNumberInput || !changedValue) {
        return changedValue;
      }

      return Number(changedValue) >= 0 ? changedValue : "";
    },
    [isNumberInput]
  );

  // Change OTP value at focussing input
  const changeCodeAtFocus = useCallback(
    (str) => {
      const updatedOTPValues = [...otpValues];
      updatedOTPValues[activeInput] = str[0] || "";
      setOTPValues(updatedOTPValues);
      handleOtpChange(updatedOTPValues);
    },
    [activeInput, handleOtpChange, otpValues]
  );

  // Focus `inputIndex` input
  const focusInput = useCallback(
    (inputIndex) => {
      const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);
      setActiveInput(selectedIndex);
    },
    [length]
  );

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1);
  }, [activeInput, focusInput]);

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1);
  }, [activeInput, focusInput]);

  // Handle onFocus input
  const handleOnFocus = useCallback(
    (index) => () => {
      focusInput(index);
    },
    [focusInput]
  );

  // Handle onChange value for each input
  const handleOnChange = useCallback(
    (e) => {
      const val = getRightValue(e.currentTarget.value);
      if (!val) {
        e.preventDefault();
        return;
      }
      changeCodeAtFocus(val);
      focusNextInput();
    },
    [changeCodeAtFocus, focusNextInput, getRightValue]
  );

  // Handle onBlur input
  const onBlur = useCallback(() => {
    setActiveInput(-1);
  }, []);

  // Handle onKeyDown input
  const handleOnKeyDown = useCallback(
    (e) => {
      const pressedKey = e.key;

      switch (pressedKey) {
        case "Backspace":
        case "Delete": {
          e.preventDefault();
          if (otpValues[activeInput]) {
            changeCodeAtFocus("");
          } else {
            focusPrevInput();
          }
          break;
        }
        case "ArrowLeft": {
          e.preventDefault();
          focusPrevInput();
          break;
        }
        case "ArrowRight": {
          e.preventDefault();
          focusNextInput();
          break;
        }
        default: {
          if (pressedKey.match(/^[^a-zA-Z0-9]$/)) {
            e.preventDefault();
          }

          break;
        }
      }
    },
    [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, otpValues]
  );

  const handleOnPaste = useCallback(
    (e) => {
      e.preventDefault();
      const pastedData = e.clipboardData
        .getData("text/plain")
        .trim()
        .slice(0, length - activeInput)
        .split("");
      if (pastedData) {
        let nextFocusIndex = 0;
        const updatedOTPValues = [...otpValues];
        updatedOTPValues.forEach((val, index) => {
          if (index >= activeInput) {
            const changedValue = getRightValue(pastedData.shift() || val);
            if (changedValue) {
              updatedOTPValues[index] = changedValue;
              nextFocusIndex = index;
            }
          }
        });
        setOTPValues(updatedOTPValues);
        setActiveInput(Math.min(nextFocusIndex + 1, length - 1));
      }
    },
    [activeInput, getRightValue, length, otpValues]
  );

  useEffect(() => {
    setScale("scale-100");
  }, []);

  return (
    <>
      <div className="w-screen sm:w-128 mx-auto h-full bg-black opacity-50 fixed z-50" />
      <div className="fixed z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-screen sm:w-128 p-6">
        <div
          className={`transition ease-in-out delay-150 bg-white rounded-xl p-4 ${scale} shadow-xl`}
        >
          <div className="flex justify-between">
            <BiArrowBack size={25} onClick={cancel} />
            <span>{t("otpVerification")}</span>
            <div />
          </div>
          <div className="my-6">
            <p className="text-center">{t("otpSentMessage")}</p>
            <p className="text-orange-500 text-center">{hp}</p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="flex justify-around">
              {Array(length)
                .fill("")
                .map((_, index) => (
                  <SingleInput
                    key={`SingleInput-${index}`}
                    type={isNumberInput ? "number" : "text"}
                    focus={activeInput === index}
                    value={otpValues && otpValues[index]}
                    autoFocus={autoFocus}
                    inCorrect={inCorrect}
                    onFocus={handleOnFocus(index)}
                    onChange={handleOnChange}
                    onKeyDown={handleOnKeyDown}
                    onBlur={onBlur}
                    onPaste={handleOnPaste}
                    style={inputStyle}
                    className={inputClassName}
                    disabled={disabled}
                  />
                ))}
            </div>
            <OrangeButton
              title={t("submit")}
              type="submit"
              isLoading={isLoading}
            />
          </form>
          {countDown <= 0 ? (
            <div onClick={sendOtp} className="w-full">
              {otpLoading ? (
                <ImSpinner8 className="animate-spin mt-6 text-orange-500 cursor-pointer mx-auto" />
              ) : (
                <p className="text-orange-500 text-center mt-6 text-xs underline cursor-pointer">
                  {t("resendOTP")}
                </p>
              )}
            </div>
          ) : (
            <p className="text-center mt-6 text-xs">
              {t("resendIn")}{" "}
              <span className="text-orange-500 ">{countDown}</span>
            </p>
          )}
        </div>
      </div>
    </>
  );
}

const OTPInput = memo(OTPInputComponent);
export default OTPInput;
