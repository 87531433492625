import { useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { Input } from "../../components/mobile/Input";
import { OrangeButton } from "../../components/mobile/OrangeButton";
import { motion } from "framer-motion";
import { postResetPassword } from "../../services/service";

export const ResetPasswordPage = () => {
  const { pathname } = window.location;
  const arrayPath = pathname.split("/");
  const lastPathname = arrayPath[arrayPath.length - 1];
  const [isEyeOpen, setIsEyeOpen] = useState(false);
  const [body, setBody] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    token: lastPathname,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({
    success: "",
    failed: "",
  });

  const handleEyeOpen = () => {
    setIsEyeOpen(!isEyeOpen);
  };

  const handleEmailChange = (e) => {
    setBody((prevState) => ({ ...prevState, email: e.target.value }));
  };
  const handlePasswordChange = (e) => {
    setBody((prevState) => ({ ...prevState, password: e.target.value }));
  };
  const handleConfirmPasswordChange = (e) => {
    setBody((prevState) => ({ ...prevState, confirmPassword: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage({
      success: "",
      failed: "",
    });
    if (
      body.password === body.confirmPassword &&
      body.password.length >= 8 &&
      body.email
    ) {
      setIsLoading(true);
      postResetPassword({
        email: body.email,
        password: body.password,
        confirmPassword: body.confirmPassword,
        token: body.token,
      })
        .then((response) => {
          if (response.data.status === "success") {
            setMessage((prevState) => ({
              prevState,
              success: response.data.message,
            }));
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setMessage((prevState) => ({
            prevState,
            failed: error.response.data.message,
          }));
          setIsLoading(false);
        });
    }
  };
  return (
    <div className="w-screen sm:w-128 mx-auto p-4 shadow bg-white">
      <form onSubmit={handleSubmit}>
        <p className="text-center">Reset Password Page</p>
        <Input placeholder="Email" type="email" change={handleEmailChange} />
        <Input
          placeholder="New Password"
          type={isEyeOpen ? "text" : "password"}
          autocomplete="new-password"
          change={handlePasswordChange}
          icon={
            isEyeOpen ? (
              <AiFillEyeInvisible
                onClick={handleEyeOpen}
                size={20}
                className="text-orange-500 my-auto mx-3"
              />
            ) : (
              <AiFillEye
                onClick={handleEyeOpen}
                size={20}
                className="text-orange-500 my-auto mx-3"
              />
            )
          }
        />
        {body.password.length < 8 && (
          <p className={"text-red-400 text-sm mt-2 text-right"}>
            {body.password.length || 0} / 8
          </p>
        )}
        <Input
          placeholder="Confirm Password"
          type={isEyeOpen ? "text" : "password"}
          autocomplete="new-password"
          change={handleConfirmPasswordChange}
          icon={
            isEyeOpen ? (
              <AiFillEyeInvisible
                onClick={handleEyeOpen}
                size={20}
                className="text-orange-500 my-auto mx-3"
              />
            ) : (
              <AiFillEye
                onClick={handleEyeOpen}
                size={20}
                className="text-orange-500 my-auto mx-3"
              />
            )
          }
        />
        {message.success && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="bg-green-200 text-green-500 p-4 rounded mt-4"
          >
            {message.success}
          </motion.div>
        )}
        {message.failed && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="bg-red-200 text-red-500 p-4 rounded mt-4"
          >
            {message.failed}
          </motion.div>
        )}
        <OrangeButton
          type="submit"
          title="Reset Password"
          isLoading={isLoading}
        />
      </form>
    </div>
  );
};
