import moment from "moment";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../../contexts/ContextProvider";
import { getTickets } from "../../../services/service";
import { SearchTable } from "../SearchTable";
import { useTranslation } from "react-i18next";

export const ListHistoryTicket = () => {
  const { t } = useTranslation();
  const { tickets, setTickets, list } = useStateContext();

  const navigate = useNavigate();

  const handlePress = (id) => {
    navigate("/ticket/chat", { state: { id: id } });
  };

  useEffect(() => {
    getTickets()
      .then((response) => {
        setTickets(response.data.reverse());
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="h-128 w-full">
      <div className="flex flex-row my-2 justify-between mx-4">
        <SearchTable
          data={tickets}
          searchWord="id"
          searchText={t("searchByOrderId")}
        />
      </div>
      <table className="font-normal w-full table-fixed">
        <thead className=" w-full relative border border-y-1">
          <tr className="">
            <th className="py-3">Order ID</th>
            <th className="py-3">{t("date")}</th>
            <th className="py-3">{t("subject")}</th>
            <th className="py-3">Status</th>
          </tr>
        </thead>
        {list?.map((ticket) => {
          let colorCondition = "text-green-500";
          if (ticket.status === 1) {
            colorCondition = "text-green-500";
          } else {
            colorCondition = "text-orange-500";
          }
          return (
            <tbody key={ticket.id} className="text-xs">
              <tr
                className="py-4 cursor-pointer"
                onClick={() => handlePress(ticket.id)}
              >
                <td className="py-3">{ticket.id}</td>
                <td className="py-3">
                  {moment(ticket.created_at).format("DD MMMM YYYY")}
                </td>
                <td className="py-3">{ticket.subject}</td>
                <td className={colorCondition}>
                  {ticket.status === 1 ? "Answered" : "Pending"}
                </td>
              </tr>
            </tbody>
          );
        })}
      </table>
    </div>
  );
};
