// import { Card } from "./Card";

// const data = [
//   {
//     category: "Top up",
//     status: "../assets/verify.png",
//     title: "Orderanmu sudah berhasil",
//     detail: "OrderID #12412 | 116123 -  Top up SMM Rp.400.000  ",
//     date: "09 Mei",
//     time: "22:00",
//   },
//   {
//     category: "SMM",
//     status: "../assets/failed.png",
//     title: "Orderanmu gagal",
//     detail: "OrderID #12412 | 116123 -  Top up SMM Rp.400.000  ",
//     date: "09 Mei",
//     time: "22:00",
//   },
//   {
//     category: "Top up",
//     status: "../assets/partial.png",
//     title: "Orderanmu partial",
//     detail: "OrderID #12412 | 116123 -  Top up SMM Rp.400.000  ",
//     date: "09 Mei",
//     time: "22:00",
//   },
//   {
//     category: "Top up",
//     status: "../assets/unpaid.png",
//     title: "Mari bayar topup saldomu",
//     detail: "OrderID #12412 | 116123 -  Top up SMM Rp.400.000  ",
//     date: "09 Mei",
//     time: "22:00",
//   },
//   {
//     category: "Top up",
//     status: "../assets/ticket.png",
//     title: "Wuih lagi ada promo nih",
//     detail: "OrderID #12412 | 116123 -  Top up SMM Rp.400.000  ",
//     date: "09 Mei",
//     time: "22:00",
//   },
// ];

export const Notification = () => {
  return (
    <div>
      <p className="mt-10 text-center text-orange-500">Coming Soon</p>
      {/* <p className="my-8">Terbaru</p>
      {data.map((item, index) => {
        return (
          <Card
            key={index}
            status={item.status}
            category={item.category}
            title={item.title}
            detail={item.detail}
            date={item.date}
            time={item.time}
          />
        );
      })} */}
    </div>
  );
};
