import { PremiumContent } from "../../components/mobile/Premium/PremiumContent";

export const PremiumPage = () => {
  // const { selectedTabPremium, setSelectedTabPremium } = useStateContext();
  // const handlePressNext = (index) => {
  //   let item = selectedTabPremium;
  //   setSelectedTabPremium(item + 1);
  // };
  // const handlePressPrev = (index) => {
  //   let item = selectedTabPremium;
  //   setSelectedTabPremium(item - 1);
  // };
  return (
    <div className=" w-screen sm:w-128 mx-auto shadow bg-white">
      <div className="bg-blue-800 pt-4">
        <img src="/assets/premium.png" alt="" />
      </div>
      <div className="bg-white w-screen sm:w-128 mx-auto h-114 absolute -bottom-12 rounded-t-xl p-4 ">
        <div className="w-full">
          <PremiumContent />
        </div>

        {/* <div className="flex flex-row justify-end pb-4">
          {selectedTabPremium > 1 && (
            <button onClick={handlePressPrev}>
              <BsArrowLeftSquareFill className="text-blue-400 text-2xl " />
            </button>
          )}
          <button onClick={handlePressNext}>
            <BsArrowRightSquareFill
              className={`${
                selectedTabPremium === 3 && "hidden"
              } text-blue-400 text-2xl ml-4`}
            />
          </button>
        </div> */}
      </div>
    </div>
  );
};
