import { useStateContext } from "../../../contexts/ContextProvider";
import { SingleOrder } from "./SingleOrder";
import { MassOrderV2 } from "./MassOrderV2";
import { useTranslation } from "react-i18next";

export const StoreContent = () => {
  const { t } = useTranslation();
  const { selectedTab } = useStateContext();

  switch (selectedTab) {
    case t("orderTypes.singleOrder"):
      return <SingleOrder />;
    case t("orderTypes.massOrder"):
      return <MassOrderV2 />;
    default:
      return null;
  }
};
