import React from "react";
import { ExpandColumn } from "../ExpandColumn";
import { useTranslation } from "react-i18next";

export const ExpandPriceList = (props) => {
  const { t } = useTranslation();
  // const convertHMS = (value) => {
  //   const sec = parseInt(value, 10);
  //   let hours = Math.floor(sec / 3600);
  //   let minutes = Math.floor((sec - hours * 3600) / 60);
  //   return value ? `${hours} Jam ${minutes} Menit` : "Not Enough Data";
  // };
  return (
    <div className="w-full px-2">
      <div
        className="w-full bg-orange-400 p-4 rounded-xl text-white"
        key={props.id}
      >
        <ExpandColumn
          title={t("serviceId")}
          value={props.id}
          icons="/assets/copy.svg"
        />
        <ExpandColumn title={t("servicesLabel")} value={props.server} />
        <ExpandColumn title={t("refill")} value={props.refill} />
        <ExpandColumn title={t("minMax")} value={props.min + "/" + props.max} />
        <ExpandColumn title={t("speedAvg")} value={props.speed} />
        <ExpandColumn title={t("ratePer1K")} value={props.rate + "/ 1K"} />
        <ExpandColumn title={t("description")} value={props.member_info} />
        {/* <div className="flex flex-row w-full mt-10">
          <button className="w-full rounded-full bg-white text-orange-400 flex flex-row justify-center py-2 px-6">
            <span>Buy This Service</span>
          </button>
        </div> */}
      </div>
    </div>
  );
};
