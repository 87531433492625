import { useStateContext } from "../../contexts/ContextProvider";
import { BsChevronRight } from "react-icons/bs";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const WalletPage = () => {
  const { t } = useTranslation();
  const { user, selectedWallet, setSelectedWallet } = useStateContext();
  const data = [
    { src: "/banner-ads1.jpeg" },
    { src: "/banner-ads2.jpeg" },
    { src: "/banner-ads3.jpeg" },
    { src: "/banner-ads4.jpeg" },
    { src: "/banner-ads5.jpeg" },
    { src: "/banner-ads6.jpeg" },
    { src: "/banner-ads7.jpeg" },
    { src: "/banner-ads8.jpeg" },
  ];

  const handleChangeWallet = () => {
    selectedWallet.name === "Wallet Biscoin"
      ? setSelectedWallet({
          name: t("wallet.walletIdr"),
          value: user.saldo.toLocaleString("id"),
        })
      : setSelectedWallet({
          name: t("wallet.walletBiscoin"),
          value: user.ppob_saldo.toLocaleString("id"),
        });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="w-screen sm:w-128 mx-auto min-h-screen bg-white">
      <div className="h-48 w-screen sm:w-128 mx-auto bg-blue-bisnison flex flex-col justify-end">
        <div className="absolute w-screen sm:w-128 mx-auto h-48 flex place-items-center">
          <div className="">
            <p className="ml-4 text-white mb-2">
              {selectedWallet.value === user.saldo.toLocaleString("id")
                ? t("wallet.walletBiscoin")
                : t("wallet.walletIdr")}
            </p>
            <div className="flex w-screen sm:w-128 mx-auto px-4 justify-between">
              <div className="text-2xl text-white flex">
                <img
                  src={
                    selectedWallet.value === user.saldo.toLocaleString("id")
                      ? "/assets/biscoin.svg"
                      : "/assets/ppob.svg"
                  }
                  alt="saldo"
                  width={20}
                  className="mr-2"
                />
                {selectedWallet.value}
              </div>
              <div
                className="rounded-full p-2 text-xs text-white bg-blue-400 flex place-items-center cursor-pointer"
                onClick={handleChangeWallet}
              >
                {selectedWallet.name} <BsChevronRight />
              </div>
            </div>
          </div>
        </div>
        <div className="w-2/3 mx-auto rounded bg-white shadow py-3 px-6 flex justify-between relative -bottom-10">
          <Link to="/topup">
            <div className="flex flex-col place-items-center">
              <img src="/assets/topup-outline.svg" alt="topup" width={40} />
              <span className="text-xs text-orange-400">Top up</span>
            </div>
          </Link>
          <Link to="/history-topup">
            <div className="flex flex-col place-items-center">
              <img src="/assets/history-outline.svg" alt="history" width={40} />
              <span className="text-xs text-orange-400">{t("myHistory")}</span>
            </div>
          </Link>
          <Link to="/point">
            <div className="flex flex-col place-items-center">
              <img src="/assets/points-outline.svg" alt="mypoint" width={40} />
              <span className="text-xs text-orange-400">{t("myPoint")}</span>
            </div>
          </Link>
        </div>
      </div>
      <div className="mt-20 px-4">
        <p className="mb-4">{t("promotion")}</p>
        {data.map((item) => (
          <img
            key={item.src}
            src={item.src}
            alt="promo"
            className="mb-4 rounded-lg shadow-lg"
          />
        ))}
      </div>
    </div>
  );
};
