import React, { useEffect, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useStateContext } from "../../contexts/ContextProvider";
import { getPageInfo } from "../../services/service";
import FAB from "../../components/mobile/FAB";
import { useTranslation } from "react-i18next";

export const BantuanPage = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState({
    caraPesan: false,
    faq: false,
    contactAdmin: false,
    levelMember: false,
    sk: false,
    api: false,
  });

  const { infoPage, setInfoPage } = useStateContext();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getPageInfo("contact")
      .then((response) => {
        setInfoPage((prevState) => ({
          ...prevState,
          contact: response.data.info,
        }));
      })
      .catch((error) => console.log(error));
    getPageInfo("api")
      .then((response) => {
        setInfoPage((prevState) => ({
          ...prevState,
          api: response.data.info,
        }));
      })
      .catch((error) => console.log(error));
    getPageInfo("faq")
      .then((response) => {
        setInfoPage((prevState) => ({
          ...prevState,
          faq: response.data.info,
        }));
      })
      .catch((error) => console.log(error));
    getPageInfo("terms")
      .then((response) => {
        setInfoPage((prevState) => ({
          ...prevState,
          terms: response.data.info,
        }));
      })
      .catch((error) => console.log(error));
    getPageInfo("order_howto")
      .then((response) => {
        setInfoPage((prevState) => ({
          ...prevState,
          cara_pesan: response.data.info,
        }));
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="w-screen sm:w-128 mx-auto shadow bg-white min-h-screen">
      <div
        className="w-full p-4"
        onClick={() => setIsOpen({ caraPesan: !isOpen.caraPesan })}
      >
        <div className="flex flex-row justify-between">
          <span className="text font-medium">{t("faq")}</span>
          {isOpen.caraPesan !== true ? <FaChevronDown /> : <FaChevronUp />}
        </div>
        {isOpen.caraPesan && (
          <>
            <div className="p-4 w-full">
              <div dangerouslySetInnerHTML={{ __html: infoPage.cara_pesan }} />
            </div>
          </>
        )}
      </div>
      <div
        className="w-full p-4"
        onClick={() => setIsOpen({ faq: !isOpen.faq })}
      >
        <div className="flex flex-row justify-between">
          <span className="text font-medium">{t("howToOrder")}</span>
          {isOpen.faq !== true ? <FaChevronDown /> : <FaChevronUp />}
        </div>
        {isOpen.faq && (
          <>
            <div className="p-4 w-full">
              <div dangerouslySetInnerHTML={{ __html: infoPage.faq }} />
            </div>
          </>
        )}
      </div>
      <div
        className="w-full p-4"
        onClick={() => setIsOpen({ contactAdmin: !isOpen.contactAdmin })}
      >
        <div className="flex flex-row justify-between">
          <span className="text font-medium">{t("contactAdmin")}</span>
          {isOpen.contactAdmin !== true ? <FaChevronDown /> : <FaChevronUp />}
        </div>
        {isOpen.contactAdmin && (
          <>
            <div className="p-4 w-full">
              <div dangerouslySetInnerHTML={{ __html: infoPage.contact }} />
            </div>
          </>
        )}
      </div>
      <div className="w-full p-4" onClick={() => setIsOpen({ sk: !isOpen.sk })}>
        <div className="flex flex-row justify-between">
          <span className="text font-medium">{t("termsAndConditions")}</span>
          {isOpen.sk !== true ? <FaChevronDown /> : <FaChevronUp />}
        </div>
        {isOpen.sk && (
          <>
            <div className="p-4 w-full">
              <div dangerouslySetInnerHTML={{ __html: infoPage.terms }} />
            </div>
          </>
        )}
      </div>
      <FAB />
    </div>
  );
};
