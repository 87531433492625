import { useEffect, useState } from "react";
import { getSortirHistoryOrderSMM } from "../../../services/service";
import moment from "moment";
import { useStateContext } from "../../../contexts/ContextProvider";
import { Loading } from "../Loading";
import { useTranslation } from "react-i18next";

const HistorySMMTable = (props) => {
  const { t } = useTranslation();
  const { setHistoriesOrderSMM, historiesOrderSMM } = useStateContext();

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);

  const handleSorter = (e) => {
    setLoading(true);
    getSortirHistoryOrderSMM({ sorter: e, id: props.currentPage })
      .then((response) => {
        setHistoriesOrderSMM(response.data.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    const result = historiesOrderSMM.filter((items) =>
      items["data"]
        ?.toString()
        .toLowerCase()
        .includes(props.isSearch.toLowerCase())
    );
    setList(props.isSearch ? result : historiesOrderSMM);
  }, [props.isSearch, historiesOrderSMM]);

  if (loading) {
    return <Loading />;
  }
  return (
    <div className=" overflow-x-auto h-screen">
      <table className="w-full text-sm text-left text-gray-500">
        <thead className="text-xs text-white uppercase bg-blue-bisnison sticky top-0">
          <tr>
            <th scope="col" className="px-6 py-3">
              <div className="flex items-center">
                ID
                <div onClick={() => handleSorter("id")}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-3 h-3 ml-1"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 320 512"
                  >
                    <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" />
                  </svg>
                </div>
              </div>
            </th>
            <th scope="col" className="px-6 py-3">
              <div className="flex items-center ">{t("date")}</div>
            </th>
            <th scope="col" className="px-6 py-3">
              <div className="flex items-center">{t("service")}</div>
            </th>
            <th scope="col" className="px-6 py-3">
              <div className="flex items-center">
                {t("target")}
                <div onClick={() => handleSorter("data")}>
                  {/* ... your existing code ... */}
                </div>
              </div>
            </th>
            <th scope="col" className="px-6 py-3">
              <div className="flex items-center">{t("quantity")}</div>
            </th>
            <th scope="col" className="px-6 py-3">
              <div className="flex items-center">{t("startCounts")}</div>
            </th>
            <th scope="col" className="px-6 py-3">
              <div className="flex items-center">{t("remains")}</div>
            </th>
            <th scope="col" className="px-6 py-3">
              <div className="flex items-center">{t("price")}</div>
            </th>
            <th scope="col" className="px-6 py-3">
              <div className="flex items-center">
                {t("balance")}
                <img
                  src="/assets/biscoin.svg"
                  alt="balance"
                  width={20}
                  className="ml-1 float-right w-max"
                />
              </div>
            </th>
            <th scope="col" className="px-6 py-3">
              <div className="flex items-center">{t("comment")}</div>
            </th>
            <th scope="col" className="px-6 py-3">
              <div className="flex items-center">{t("status")}</div>
            </th>
            <th scope="col" className="px-6 py-3">
              <div className="flex items-center">
                {t("lastUpdate")}
                <div onClick={() => handleSorter("updated_at")}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-3 h-3 ml-1"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 320 512"
                  >
                    <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" />
                  </svg>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {list.map((item) => {
            return (
              <tr key={item.id} className="bg-white border-b text-black">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium whitespace-nowrap"
                >
                  {item.id}
                </th>
                <td className="px-6 py-4 ">
                  <p className="whitespace-nowrap">
                    {moment(item.tanggal).format("DD MMMM YYYY")}
                  </p>
                  {moment(item.tanggal).format("H:mm:ss")}
                </td>
                <td className="px-6 py-4 text-left">
                  {item.product?.id} - {item.product?.service_name}
                </td>
                <td className="px-6 py-4">{item.data}</td>
                <td className="px-6 py-4">{item.jumlah}</td>
                <td className="px-6 py-4">{item.start_counts}</td>
                <td className="px-6 py-4">{item.remains}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <img
                    src="/assets/biscoin.svg"
                    alt="balance"
                    width={20}
                    className="mr-2 float-left w-max"
                  />
                  <p>{item.harga.toLocaleString("id")}</p>
                </td>
                {/* <td className="px-6 py-4">{item.biscoin}</td> */}
                <td className="px-6 py-4 whitespace-nowrap">
                  <img
                    src="/assets/biscoin.svg"
                    alt="balance"
                    width={20}
                    className="mr-2 float-left w-max"
                  />
                  <p>{item.sisa_saldo.toLocaleString("id")}</p>
                </td>
                <td className="px-6 py-4">{item.komen}</td>

                <td
                  className={`px-6 py-4 font-semibold ${
                    item.status === "Completed"
                      ? "text-green-400"
                      : item.status === "Canceled"
                      ? "text-red-400"
                      : ""
                  }`}
                >
                  {item.status}
                </td>
                <td className="px-6 py-4 ">
                  <p className="whitespace-nowrap">
                    {moment(item.updated_at).format("DD MMMM YYYY")}
                  </p>
                  {moment(item.updated_at).format("H:mm:ss")}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="h-24"></div>
    </div>
  );
};

export default HistorySMMTable;
