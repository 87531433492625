import { useEffect, useState } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { useStateContext } from "../../../contexts/ContextProvider";
import { FilterButton } from "../Filter";
import { SearchTable } from "../SearchTable";
import { ExpandHistoryTopUp } from "./ExpandedHistoryTopUp";
import { useTranslation } from "react-i18next";

export const HistoryTopUp = () => {
  const { t } = useTranslation();
  const { historyTopUp, list, isFilters, openFilter } = useStateContext();
  const [isOpen, setIsOpen] = useState(true);
  const [data, setData] = useState([]);

  const filter = [t("allLabel"), t("paid"), t("cancel")];

  function toggleExpander(idService) {
    if (isOpen !== idService) {
      setIsOpen(idService);
    } else {
      setIsOpen(undefined);
    }
  }

  useEffect(() => {
    setData(openFilter.historyTopUp ? isFilters.historyTopUp : historyTopUp);
  }, [historyTopUp, isFilters.historyTopUp]);

  return (
    <div className="w-full min-h-screen">
      <div className="flex flex-row justify-between">
        <FilterButton
          data={historyTopUp}
          text={filter}
          target="status"
          type="historyTopUp"
        />
        <SearchTable
          data={data}
          searchWord="id"
          searchText={t("searchByOrderId")}
        />
      </div>
      <table className="font-normal w-full table-fixed mt-2">
        <thead className=" w-full relative border border-y-1">
          <tr className="">
            <th className="py-3">{t("topupId")}</th>
            <th className="py-3">{t("earned")}</th>
            <th className="py-3">Status</th>
          </tr>
        </thead>

        {list.map((history, index) => {
          let colorCondition = "text-green-500";
          if (history.status === "Cancel") {
            colorCondition = "text-red-500";
          } else if (history.status === "Paid") {
            colorCondition = "text-green-500";
          } else {
            colorCondition = "text-red-500";
          }
          return (
            <tbody key={history?.id} className="w-full text-xs">
              <tr
                width={20}
                className="py-4 cursor-pointer"
                onClick={() => toggleExpander(index)}
              >
                <td className="py-3 flex flex-row text-center justify-center">
                  {isOpen !== index ? (
                    <FiChevronDown className="mr-2" />
                  ) : (
                    <FiChevronUp className="mr-2" />
                  )}
                  {history.id}
                </td>
                <td width={40} className="py-3 text-center truncate">
                  {"Rp " + history.dapat.toLocaleString("id")}
                </td>
                <td width={40} className={`${colorCondition} text-center`}>
                  {history.status}
                </td>
              </tr>
              <tr>
                <td colSpan={3}>
                  {isOpen === index && (
                    <ExpandHistoryTopUp
                      id={history.id}
                      tanggal={history.date_request}
                      bank={history.bank.nama}
                      invoice={history.invoice}
                      saldo_type={history.saldo_type}
                      jumlah={history.jumlah}
                      dapat={history.dapat}
                      bonus={history.bonus}
                      status={history.status}
                    />
                  )}
                </td>
              </tr>
            </tbody>
          );
        })}
      </table>
    </div>
  );
};
