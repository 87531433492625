import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useStateContext } from "../../contexts/ContextProvider";
import { InfoPopUp } from "./InfoPopUp";
import packageInfo from "../../../package.json";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { IoLogOutOutline } from "react-icons/io5";
import { postLogout } from "../../services/service";

export const Sidebar = () => {
  const { t } = useTranslation();
  const { isSidebarOpen, setIsSidebarOpen, showComingSoon, setShowComingSoon } =
    useStateContext();
  const [popUp, setPopUp] = useState({
    text: "",
    url: "",
    linkText: "",
  });

  const [sidebarPosition, setSidebarPosition] = useState("-left-full");

  const handleShowComingSoon = (text, url, linkText) => {
    setShowComingSoon(true);
    setPopUp({
      text: text,
      url: url,
      linkText: linkText,
    });
  };

  const handleLogout = () => {
    postLogout()
      .then((response) => {
        if (response.data.success) {
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    isSidebarOpen
      ? setSidebarPosition("left-0")
      : setSidebarPosition("-left-full");
  }, [isSidebarOpen]);

  if (!isSidebarOpen) {
    setShowComingSoon(false);
    return;
  }

  return (
    <div className="text-xs">
      {showComingSoon && (
        <InfoPopUp
          text={popUp.text}
          url={popUp.url}
          linkText={popUp.linkText}
          close={() => setShowComingSoon(!showComingSoon)}
        />
      )}
      <div className="w-full sm:w-128 fixed mx-auto text-center right-0 left-0 z-20">
        <AnimatePresence>
          {sidebarPosition === "left-0" && (
            <motion.div
              key="background_sidebar"
              initial={{
                backgroundColor: "transparent",
                opacity: 0,
              }}
              animate={{
                backgroundColor: "#000",
                opacity: 0.4,
              }}
              exit={{ opacity: 0 }}
              style={{ height: "100vh" }}
              className="w-full"
              onClick={() => setIsSidebarOpen(false)}
            />
          )}
        </AnimatePresence>
        <AnimatePresence>
          {isSidebarOpen && (
            <motion.div
              key="list_sidebar"
              initial={{
                x: -300,
                opacity: 0,
              }}
              transition={{ delay: 0.2, damping: 10, stiffness: 100 }}
              animate={{
                x: 0,
                opacity: 1,
              }}
              exit={{
                x: -300,
                opacity: 0,
              }}
              style={{ height: "100vh" }}
              className="w-max pr-8 bg-white flex flex-col justify-evenly fixed top-0"
            >
              <div className="mx-auto w-32 ">
                <img src="/bisnison.png" alt="logo" className="w-32" />
              </div>
              {/* <div className="ml-2">
                <LanguageSwitcher />
              </div> */}
              <Link to="/statistic" className="no-underline text-black">
                <div
                  className="ml-4 w-32 flex flex-row "
                  onClick={() => setIsSidebarOpen(false)}
                >
                  <img
                    src="/assets/statistic.png"
                    alt=""
                    className="w-6 h-6 mt-1"
                  />
                  <p className="my-2 mx-3">{t("statistic")}</p>
                </div>
              </Link>
              <Link to="/pricelist" className="no-underline text-black">
                <div
                  className="ml-4 w-38 flex flex-row "
                  onClick={() => setIsSidebarOpen(false)}
                >
                  <img
                    src="/assets/pricelist.png"
                    alt=""
                    className="w-6 h-6 mt-1"
                  />
                  <p className="my-2 mx-3">{t("priceList")}</p>
                </div>
              </Link>
              <Link
                to="/monitoring-layanan"
                className="no-underline text-black"
              >
                <div
                  className="ml-4 w-38 flex flex-row "
                  onClick={() => setIsSidebarOpen(false)}
                >
                  <img
                    src="/assets/monitoring.png"
                    alt=""
                    className="w-6 h-6 mt-1"
                  />
                  <p className="my-2 mx-3">{t("monitoringServices")}</p>
                </div>
              </Link>
              <Link to="/top-monthly" className="no-underline text-black">
                <div
                  className="ml-4 w-38 flex flex-row "
                  onClick={() => setIsSidebarOpen(false)}
                >
                  <img
                    src="/assets/ranking.png"
                    alt=""
                    className="w-6 h-6 mt-1"
                  />
                  <p className="my-2 mx-3">{t("topMonthly")}</p>
                </div>
              </Link>
              <div
                className="ml-4 w-38 flex flex-row my-2 cursor-pointer"
                onClick={() =>
                  handleShowComingSoon(t("ticketPageNotAvailable"))
                }
              >
                <img src="/assets/helps.png" alt="" className="w-6 h-6" />
                <p className="mt-1 mx-3">
                  {t("ticket")} -{" "}
                  <span className="bg-blue-bisnison rounded-full px-2 py-1 text-white text-xs">
                    {t("comingSoon")}
                  </span>
                </p>
              </div>
              <div
                className="ml-4 w-38 flex flex-row my-2 cursor-pointer"
                onClick={() =>
                  handleShowComingSoon(
                    t("premiumPageNotAvailable"),
                    "https://smm.bisnison.com/users/upgrade",
                    t("viewPremiumDetails")
                  )
                }
              >
                <img src="/assets/crown.png" alt="" className="w-6 h-6" />
                <p className="mt-1 mx-3">
                  {t("premium")} -{" "}
                  <span className="bg-blue-bisnison rounded-full px-2 py-1 text-white text-xs">
                    {t("comingSoon")}
                  </span>
                </p>
              </div>
              <div
                className="ml-4 w-38 flex flex-row my-2 cursor-pointer"
                onClick={() =>
                  handleShowComingSoon(t("refillPageNotAvailable"))
                }
              >
                <img src="/assets/refill.png" alt="" className="w-6 h-6" />
                <p className="mt-1 mx-3">
                  {t("refill")} -{" "}
                  <span className="bg-blue-bisnison rounded-full px-2 py-1 text-white text-xs">
                    {t("comingSoon")}
                  </span>
                </p>
              </div>

              <a
                className="ml-4 w-38 flex flex-row my-2 cursor-pointer"
                href="https://smm.bisnison.com"
              >
                <img src="/assets/repeat.svg" alt="repeat" />
                <p className="mt-1 mx-3">{t("backToOldVersion")}</p>
              </a>

              <button className="ml-4 flex items-center" onClick={handleLogout}>
                <IoLogOutOutline size={25} />
                <p className="ml-2">{t("menu.logout")}</p>
              </button>

              <div className="w-full text-center mx-auto">
                <img
                  src="/assets/rocketastro.png"
                  className="mx-auto"
                  alt=""
                  width={200}
                />
                <div className="text-xs">
                  <p>
                    {t("version")} {packageInfo.version}
                  </p>
                  {/* <InstallRoute /> */}
                </div>
              </div>
              <div id="histats_counter"></div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};
