import ReactEcharts from "echarts-for-react";
import React, { useEffect, useState } from "react";
import { Loading } from "../../components/mobile/Loading";
import { getHistorySalesThisWeek, getSales } from "../../services/service";
import { useTranslation } from "react-i18next";

export const StatisticPage = () => {
  const { t } = useTranslation();
  const [sales, setSales] = useState({
    pending: 0,
    in_progress: 0,
    completed: 0,
  });
  const [historySalesThisWeek, setHistorySalesThisWeek] = useState([
    {
      data: [],
    },
    {
      data: [],
    },
    {
      data: [],
    },
  ]);
  const [loading, setLoading] = useState({
    sales: false,
    historySalesThisWeek: false,
  });

  useEffect(() => {
    setLoading(true);
    getSales()
      .then((response) => {
        setSales(response.data.data);
        setLoading((prevState) => ({ ...prevState, sales: false }));
      })
      .catch((error) => {
        console.log(error);
        setLoading((prevState) => ({ ...prevState, sales: false }));
      });
    getHistorySalesThisWeek()
      .then((response) => {
        let formattedData = [];
        response.data.forEach((dataSales, indexSales) => {
          switch (dataSales.id) {
            case "pending":
              formattedData.push({ id: t("statuses.pending"), data: [] });
              break;
            case "in_progress":
              formattedData.push({
                id: t("statuses.inProgress"),
                data: [],
              });
              break;
            case "done":
              formattedData.push({ id: t("statuses.done"), data: [] });
              break;
          }
          dataSales.data.map((item) =>
            formattedData[indexSales].data.push(item.y)
          );
        });
        setHistorySalesThisWeek(formattedData);
        setLoading((prevState) => ({
          ...prevState,
          historySalesThisWeek: false,
        }));
      })
      .catch((error) => {
        console.log(error);
        setLoading((prevState) => ({
          ...prevState,
          historySalesThisWeek: false,
        }));
      });
  }, []);

  const optionPieChart = {
    tooltip: {
      trigger: "item",
    },
    legend: {
      top: "0%",
      left: "center",
    },
    series: [
      {
        name: "Access From",
        type: "pie",
        radius: ["40%", "70%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 20,
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: false,
        },
        data: [
          { value: sales.pending, name: t("statuses.pending") },
          { value: sales.in_progress, name: t("statuses.inProgress") },
          { value: sales.completed, name: t("statuses.done") },
        ],
      },
    ],
  };

  const optionLineChart = {
    tooltip: {
      trigger: "axis",
    },
    legend: {
      data: [
        t("statuses.pending"),
        t("statuses.inProgress"),
        t("statuses.done"),
      ],
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      // data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        name: historySalesThisWeek[0].id,
        type: "line",
        stack: "Total",
        data: historySalesThisWeek[0].data,
      },
      {
        name: historySalesThisWeek[1].id,
        type: "line",
        stack: "Total",
        data: historySalesThisWeek[1].data,
      },
      {
        name: historySalesThisWeek[2].id,
        type: "line",
        stack: "Total",
        data: historySalesThisWeek[2].data,
      },
    ],
  };

  if (loading.sales || loading.historySalesThisWeek) {
    return <Loading />;
  }
  return (
    <div className=" w-screen sm:w-128 mx-auto shadow bg-white">
      <div className="flex flex-col  sm:justify-between sm:gap-2">
        <p className="text-xl ml-4">{t("salesStatistic")}</p>
        <div className="flex flex-col w-full place-items-center mt-4">
          <div className="w-full">
            <ReactEcharts option={optionPieChart} />
          </div>
          <div className="flex justify-around w-full">
            <div className="flex flex-col place-items-center text-center text-sm">
              <img src="/assets/checked.png" alt="Completed" width={30} />
              <span>
                {t("statuses.done")}
                <br />
                {t("order")}({sales?.completed})
              </span>
            </div>
            <div className="flex flex-col place-items-center text-center text-sm">
              <img src="/assets/clipboard.png" alt="Pending" width={30} />
              <span>
                {t("statuses.pending")}
                <br />
                {t("order")}({sales?.pending})
              </span>
            </div>
            <div className="flex flex-col place-items-center text-center text-sm">
              <img src="/assets/settings.png" alt="Progress" width={30} />
              <span>
                {t("statuses.inProgress")}
                <br />
                {t("order")}({sales?.in_progress})
              </span>
            </div>
          </div>
        </div>
        <p className="text-xl m-4">{t("salesGraphic")}</p>
        <div className="w-full mb-4">
          <ReactEcharts option={optionLineChart} />
        </div>
      </div>
    </div>
  );
};
