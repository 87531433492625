import { useEffect, useState } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { AnimatePresence, motion } from "framer-motion";

export const SelectInput = (props) => {
  const [showItems, setShowItems] = useState(false);
  const [searchWords, setSearchWords] = useState("");
  const [list, setList] = useState([]);
  const handleSearch = (e) => {
    setSearchWords(e.target.value);
  };

  const handleShowItems = () => {
    setShowItems(false);
  };

  useEffect(() => {
    const result = props.data?.filter((item) =>
      item.label?.toLowerCase().includes(searchWords.toLowerCase())
    );
    setList(searchWords ? result : props.data);
  }, [searchWords, props.data]);

  return (
    <div className={props.label && "w-full mt-4"}>
      <div className="w-full">
        <span className="text-sm">{props.label}</span>
        <div className="w-full border border-gray-300 rounded-full px-4 py-2 relative mt-2  cursor-pointer">
          <div
            onClick={() => setShowItems(!showItems)}
            className="flex flex-row w-full"
          >
            <div className="w-11/12 flex place-items-center">
              {props.select ? (
                <div
                  className="justify-center truncate text-sm"
                  dangerouslySetInnerHTML={{
                    __html: props.select,
                  }}
                />
              ) : (
                <span className="text-gray-400 text-sm">
                  {props.placeholder}
                </span>
              )}
            </div>
            <div className=" w-1/12 justify-center flex flex-row py-1">
              {showItems ? <FiChevronUp /> : <FiChevronDown />}
            </div>
          </div>
        </div>
        {showItems && (
          <AnimatePresence>
            <motion.div
              initial={{
                opacity: 0,
                y: -50,
              }}
              animate={{
                opacity: 1,
                y: 0,
              }}
              className="w-full border border-gray-400 pt-2 rounded-xl relative mt-2  "
            >
              <div className="py-1 px-4 ">
                {props.isSearchable && (
                  <input
                    type="text"
                    placeholder="Search..."
                    className="w-full focus:outline focus:outline-0   text-sm border-0 m-0 px-0 py-2"
                    onChange={handleSearch}
                    value={searchWords}
                  />
                )}
              </div>
              <div className="overflow-y-scroll max-h-44 px-4 ">
                {list.map((item, index) => (
                  <div
                    data-type={item.dataType}
                    key={index}
                    className="justify-center cursor-pointer my-2 text-sm"
                    dangerouslySetInnerHTML={{
                      __html: item.label,
                    }}
                    onClick={() => {
                      props.handleSelectItem(
                        item.value,
                        item.label,
                        item.dataType
                      );
                      handleShowItems();
                    }}
                  />
                ))}
              </div>
            </motion.div>
          </AnimatePresence>
        )}
      </div>
    </div>
  );
};
