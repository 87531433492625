import React, { useEffect, useState } from "react";
import { OrangeButton } from "../../components/mobile/OrangeButton";
import { Input } from "../../components/mobile/Input";
import { putChangePassword } from "../../services/service";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

export const ChangePasswordPage = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [body, setBody] = useState({
    passlama: "",
    passbaru1: "",
    passbaru2: "",
  });
  const [message, setMessage] = useState({
    error: "",
    message: "",
  });

  const handleOldPasswordsChange = (e) => {
    setBody((prevState) => ({ ...prevState, passlama: e.target.value }));
  };

  const handleNewPasswordsChange = (e) => {
    setBody((prevState) => ({ ...prevState, passbaru1: e.target.value }));
  };

  const handleConfirmPasswordsChange = (e) => {
    setBody((prevState) => ({ ...prevState, passbaru2: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage("");
    setIsLoading(true);
    putChangePassword(body)
      .then((response) => {
        response.data.success
          ? setMessage({
              success: `${t("success")} ${response.data.message}`,
            })
          : setMessage({ error: response.data[0] });
        setIsLoading(false);
      })
      .catch((error) => {
        setMessage({ error: error.response.data.message });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="p-4 w-screen sm:w-128 mx-auto shadow bg-white min-h-screen">
      <p className="text-center text-xl mt-6">{t("changePassword")}</p>
      <form onSubmit={handleSubmit}>
        {message.error && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="bg-red-200 text-red-500 p-2 rounded w-full"
          >
            {message.error}
          </motion.div>
        )}
        {message.success && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="bg-green-200 text-green-500 p-2 rounded w-full"
          >
            {message.success}
          </motion.div>
        )}
        <Input
          label={t("oldPassword")}
          type="password"
          change={handleOldPasswordsChange}
          autoComplete="current-password"
        />
        <Input
          label={t("newPassword")}
          type="password"
          change={handleNewPasswordsChange}
          autoComplete="new-password"
        />
        <Input
          label={t("confirmNewPassword")}
          type="password"
          change={handleConfirmPasswordsChange}
          autoComplete="new-password"
        />
        <OrangeButton
          type="submit"
          className="rounded-xl bg-orange-500 w-full p-2 mt-2 text-white text-center"
          title={t("submit")}
          isLoading={isLoading}
        />
      </form>
    </div>
  );
};
