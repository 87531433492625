import { useState } from "react";
import ArticlesDesktop from "../ArticlesDesktop";
import BannerDesktop from "../BannerDesktop";
import Header from "../Header";
import Marquee from "../Marquee";
import MassOrderDesktop from "./MassOrderDesktop";
import SingleOrderDesktop from "./SingleOrderDesktop";
import TabHeaderOrder from "./TabHeaderOrder";

const OrderDesktop = () => {
  const [selectedTab, setSelectedTab] = useState("Single Order");
  return (
    <div>
      <Header label={"Order"} />
      <Marquee />
      <div className="w-full p-8">
        <TabHeaderOrder
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <div className="h-4"></div>
        {selectedTab === "Single Order" && <SingleOrderDesktop />}
        {selectedTab === "Mass Order" && <MassOrderDesktop />}
      </div>
      <div className=" p-4 grid grid-cols-2 space-x-4">
        <div className="rounded-xl">
          <BannerDesktop />
        </div>
        <div className="rounded-xl bg-white shadow-lg w-full h-96 overflow-y-auto">
          <ArticlesDesktop label={"Blog"} id={16} />
        </div>
      </div>
    </div>
  );
};

export default OrderDesktop;
