import { Input } from "../Input";
import { OrangeButton } from "../OrangeButton";
import { useState } from "react";
import { useStateContext } from "../../../contexts/ContextProvider";
import { postTicket } from "../../../services/service";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

export const FormNewTicket = () => {
  const { t } = useTranslation();
  const { bodyTicket, setBodyTicket } = useStateContext();
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useStateContext();
  const formData = new FormData();
  const [isSuccess, setIsSuccess] = useState(false);

  // const handleChangeImage = (e) => {
  //   formData.append("image", e.target.files[0]);
  // };

  const handleChangeSubject = (e) => {
    setBodyTicket((prevState) => ({ ...prevState, subject: e.target.value }));
  };

  const handleChangeMessage = (e) => {
    setBodyTicket((prevState) => ({ ...prevState, message: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsSuccess(false);
    formData.append("subject", bodyTicket.subject);
    formData.append("message", bodyTicket.message);
    postTicket(formData)
      .then((response) => {
        response.data.status === "success" && setIsSuccess(true);
        setBodyTicket({ subject: "", message: "" });
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };
  return (
    <div className="mx-4 text-start">
      <form onSubmit={handleSubmit}>
        <div className="text-sm flex flex-col">
          <Input
            value={user.nama}
            disabled={true}
            type="text"
            label={t("username")}
          />
          {/* <Input
            change={handleChangeImage}
            type="file"
            accept="image/*"
            label="Image"
          /> */}
          <div className="mt-2">
            <label>Subject</label>
            <textarea
              onChange={handleChangeSubject}
              value={bodyTicket.subject}
              className="border rounded-xl p-3 border-gray-300 w-full mt-2"
              placeholder="Write Down in you have a noted for me !"
            />
          </div>
          <div>
            <label>Message</label>
            <textarea
              onChange={handleChangeMessage}
              value={bodyTicket.message}
              className="border rounded-xl p-3 border-gray-300 w-full mt-2"
              placeholder="Write the message you want to send to us"
            />
          </div>
          {isSuccess && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="bg-green-200 text-green-600 rounded-lg px-2 py-4"
            >
              Berhasil request ticket!
            </motion.div>
          )}
          <OrangeButton
            disabled={isSuccess}
            type="submit"
            title="Submit"
            isLoading={isLoading}
          />
          <div className="h-20" />
        </div>
      </form>
    </div>
  );
};
