import React from "react";
import { useStateContext } from "../../../contexts/ContextProvider";
import { FormTopUp } from "./FormTopUp";
import { HistoryTopUp } from "./HistoryTopUp";

export const TopUpContent = () => {
  const { selectedTabTopUp } = useStateContext();

  switch (selectedTabTopUp) {
    case "Top up":
      return <FormTopUp />;
    case "History":
      return <HistoryTopUp />;
    default:
      return null;
  }
};
