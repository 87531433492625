import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../../contexts/ContextProvider";
import {
  getCheckService,
  getProductMajorCategories,
  postMonitoringLayanan,
} from "../../../services/service";
import { Loading } from "../../mobile/Loading";
import moment from "moment";
import { useTranslation } from "react-i18next";

const MonitoringLayananDesktop = () => {
  const { t } = useTranslation();
  const {
    setAutoCompleteService,
    setSelectedMenuDesktop,
    setSelectedMenuOrder,
  } = useStateContext();
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isServiceMaintenance, setIsServiceMaintenance] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const [selectedFilter, setSelectedFilter] = useState("updated_at");
  const days = [
    { label: `1 ${t("lastDays")}`, value: 1 },
    { label: `2 ${t("lastDays")}`, value: 2 },
    { label: `3 ${t("lastDays")}`, value: 3 },
    { label: `4 ${t("lastDays")}`, value: 4 },
    { label: `5 ${t("lastDays")}`, value: 5 },
    { label: `6 ${t("lastDays")}`, value: 6 },
    { label: `7 ${t("lastDays")}`, value: 7 },
  ];
  const [jenisKategori, setJenisKategori] = useState([]);

  const [selectedDays, setSelectedDays] = useState(days[6].value);
  const [selectedPage, setSelectedPage] = useState(1);
  const [selectedPMC, setSelectedPMC] = useState([]);
  const [selectedOpenPMC, setSelectedOpenPMC] = useState("");

  const [checkedCategories, setCheckedCategories] = useState([]);
  const navigate = useNavigate();

  const handleTranslate = (word) => {
    const result = word
      .replace("years", t("time.year"))
      .replace("year", t("time.year"))
      .replace("months", t("time.month"))
      .replace("month", t("time.month"))
      .replace("weeks", t("time.week"))
      .replace("week", t("time.week"))
      .replace("days", t("time.day"))
      .replace("day", t("time.day"))
      .replace("hours", t("time.hour"))
      .replace("hour", t("time.hour"))
      .replace("minutes", t("time.minute"))
      .replace("minute", t("time.minute"))
      .replace("seconds", t("time.second"))
      .replace("second", t("time.second"));
    return result;
  };

  const handleOrder = (categoryId, serviceId) => {
    getCheckService(serviceId)
      .then((response) => {
        if (response.data.data.status_service) {
          setSelectedMenuDesktop("Order");
          setSelectedMenuOrder("Order");
          setAutoCompleteService({
            serviceId: serviceId,
            categoryId: categoryId,
          });
          navigate(`/`);
        } else {
          setIsServiceMaintenance(true);
          setTimeout(() => {
            setIsServiceMaintenance(false);
          }, 3000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleNavigation = (page) => {
    setSelectedPage(page);
  };

  const handleFilterDay = (day) => {
    setSelectedDays(day);
  };

  const handleSort = () => {
    if (selectedFilter === "updated_at") {
      setSelectedFilter("processing_time");
    } else {
      setSelectedFilter("updated_at");
    }
  };

  const handleSubmit = () => {
    setShowDropdown(false);
    setSelectedPage(1);
    postMonitoringLayanan({
      day: selectedDays,
      sort: selectedFilter,
      product_major_category_id: selectedPMC,
      category_id: checkedCategories,
    })
      .then((response) => {
        setMeta(response.data.data);
        setData(response.data.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleOpenJenisKategori = () => {
    setCheckedCategories([]);
    setSelectedPMC("");
    setShowDropdown(!showDropdown);
  };

  const handleCheck = (e, id) => {
    const { checked } = e.target;
    setCheckedCategories([...checkedCategories, id]);
    if (!checked) {
      setCheckedCategories(checkedCategories.filter((item) => item !== id));
    }
  };

  const handleCheckPMC = (e, item) => {
    // if (selectedPMC.length > 0) {
    //   setSelectedPMC([]);
    //   setCheckedCategories([]);
    // } else {
    const { checked } = e.target;
    setSelectedPMC([...selectedPMC, item]);
    if (!checked) {
      setSelectedPMC(selectedPMC.filter((pmc) => pmc !== item));
    }

    let updatedListCategories = [...checkedCategories];
    jenisKategori.forEach((kategori) => {
      if (item === kategori.id) {
        kategori.product_category.forEach((element) => {
          updatedListCategories = [
            ...updatedListCategories,
            element.id_category,
          ];
        });
      }
      setCheckedCategories(updatedListCategories);
    });
    // }
  };

  const handleClickPMC = (item) => {
    if (selectedOpenPMC === item) {
      setSelectedOpenPMC("");
    } else {
      setSelectedOpenPMC(item);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getProductMajorCategories()
      .then((response) => {
        setJenisKategori(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
    postMonitoringLayanan({
      sort: selectedFilter,
      page: selectedPage,
      day: selectedDays,
      product_major_category_id: selectedPMC,
      category_id: checkedCategories,
    })
      .then((response) => {
        setMeta(response.data.data);
        setData(response.data.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [selectedDays, selectedFilter, selectedPage]);
  return (
    <div className="p-4">
      {isServiceMaintenance && (
        <div
          className="absolute top-0 w-full z-50 p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
          role="alert"
        >
          <span className="font-medium">{t("failed")}</span> {t("maintenance")}
        </div>
      )}
      {showDropdown && (
        <div className=" p-4  bg-white">
          <div
            className=" h-96 overflow-y-auto text-sm  rounded-lg p-4  "
            role="alert"
          >
            {jenisKategori.map((item) => {
              return (
                <>
                  <div className="border-b py-1  px-2 rounded flex justify-between cursor-pointer">
                    <div
                      className="flex place-items-center"
                      onClick={() => handleClickPMC(item.id)}
                    >
                      {selectedOpenPMC !== item.id ? (
                        <i
                          className="fa fa-chevron-right mr-2"
                          style={{ fontSize: 8 }}
                        ></i>
                      ) : (
                        <i
                          className="fa fa-chevron-down mr-2"
                          style={{ fontSize: 8 }}
                        ></i>
                      )}
                      <p className=" ">{item.name}</p>
                    </div>
                    <input
                      type="checkbox"
                      name={`checklist_pmc${item.id}`}
                      value={item.id}
                      checked={selectedPMC.includes(item.id)}
                      // disabled={selectedPMC && item.id !== selectedPMC}
                      onChange={(e) => handleCheckPMC(e, item.id)}
                    />
                  </div>
                  {selectedOpenPMC === item.id &&
                    item.product_category.map((product, index) => {
                      return (
                        <div className="ml-6 flex justify-between border-b py-1">
                          <div
                            className="justify-center truncate text-sm"
                            dangerouslySetInnerHTML={{
                              __html: product.nama_category,
                            }}
                          />
                          <input
                            onChange={(e) =>
                              handleCheck(e, product.id_category)
                            }
                            type="checkbox"
                            name={`checklist${index}`}
                            className="mr-2"
                            checked={checkedCategories.includes(
                              product.id_category
                            )}
                            // disabled={
                            //   selectedPMC
                            //     ? selectedPMC !==
                            //       product.product_major_category_id
                            //       ? true
                            //       : false
                            //     : false
                            // }
                            value={product.id_category}
                          />
                        </div>
                      );
                    })}
                </>
              );
            })}
          </div>
          <div className="mt-4 w-full flex justify-end space-x-2">
            <button
              className="border-2 border-red-500 text-red-500 px-4 py-2 rounded-lg "
              onClick={() => setShowDropdown(false)}
            >
              {t("cancel")}
            </button>
            <button
              className=" bg-blue-bisnison text-white px-4 py-2 rounded-lg "
              onClick={handleSubmit}
            >
              {checkedCategories.length > 0 ? t("submit") : t("showAll")}
            </button>
          </div>
        </div>
      )}
      <div
        className="bg-white shadow-lg rounded-2xl overflow-y-auto p-4"
        style={{ height: "90vh" }}
      >
        <div className="flex justify-between place-items-center px-2 my-4">
          <div className="flex space-x-2 place-items-center">
            <div className="rounded-lg p-1 bg-gray-200 ">
              <select
                id="page"
                onChange={(e) => handleFilterDay(e.target.value)}
                className="text-xs bg-transparent"
              >
                {days.reverse().map((item) => {
                  return (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  );
                })}
              </select>
            </div>
            <div
              className="rounded-lg p-2 bg-gray-200 cursor-pointer"
              onClick={handleOpenJenisKategori}
            >
              <p className="text-xs whitespace-nowrap">{t("categoryType")}</p>
            </div>
          </div>
          <div className="flex place-items-center space-x-2">
            <div
              className="w-8 h-8 rounded-lg p-1 bg-gray-200 cursor-pointer"
              onClick={() => handleSort()}
            >
              {selectedFilter === "updated_at" ? (
                <img
                  alt="bolt"
                  src="https://img.icons8.com/material-rounded/24/null/lightning-bolt--v1.png"
                />
              ) : (
                <img
                  alt="up"
                  src="https://img.icons8.com/ios-filled/50/000000/up--v1.png"
                />
              )}
            </div>

            <div className="flex space-x-4 place-items-center ml-4">
              <div className="rounded-lg p-1 bg-gray-200 ">
                <select
                  id="page"
                  className="bg-transparent text-xs"
                  onChange={(e) => handleNavigation(e.target.value)}
                >
                  <option defaultValue={meta?.current_page}>
                    {t("page")} {meta?.current_page}
                  </option>
                  {meta?.links?.map((item) => {
                    if (isNaN(item.label)) {
                      return null;
                    }
                    if (item.active) {
                      return null;
                    }
                    return (
                      <option key={item.label} value={item.label}>
                        Page {item.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>
        {isLoading ? (
          <Loading />
        ) : (
          <div className={`overflow-x-auto ${showDropdown && "blur"} h-full`}>
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-white uppercase sticky top-0 bg-blue-bisnison">
                <tr>
                  <th
                    scope="col"
                    className="px-3 py-3 text-center whitespace-nowrap"
                  >
                    {t("id-service")}
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-center whitespace-nowrap"
                  >
                    {t("quantity")}
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-center whitespace-nowrap"
                  >
                    {t("processTime")}
                  </th>
                  <th scope="col" className="px-3 py-3 text-center">
                    {t("created")}
                  </th>
                  <th scope="col" className="px-3 py-3 text-center">
                    {t("completed")}
                  </th>
                  <th scope="col" className="px-3 py-3 text-center">
                    {t("order")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  return (
                    <tr key={index} className="bg-white border-b text-sm">
                      <td className="px-3 py-3 ">
                        <p className="w-80 sm:w-114" style={{ width: "" }}>
                          {item.service_id} - {item.service_name}
                        </p>
                      </td>
                      <td className="px-3 py-3 ">{item.jumlah}</td>
                      <td className="px-3 py-3 whitespace-nowrap ">
                        {handleTranslate(item.processing_time)}
                      </td>
                      <td className="px-3 py-3 whitespace-nowrap ">
                        {moment(item.tanggal).format("DD/MMM/YYYY, HH:mm")}
                      </td>
                      <td className="px-3 py-3 whitespace-nowrap ">
                        {moment(item.updated_at).format("DD/MMM/YYYY, HH:mm")}
                      </td>
                      <td>
                        <button
                          onClick={() =>
                            handleOrder(item.id_category, item.service_id)
                          }
                          className="rounded-lg px-4 py-2 bg-blue-bisnison text-white"
                        >
                          {t("order")}
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default MonitoringLayananDesktop;
