import React, { useState } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

export const NewsHeader = (props) => {
  const filter = [
    "All",
    "Enabled",
    "Disabled",
    "Price Increased",
    "Price Decreased",
  ];
  const [showFilter, setShowFilter] = useState(false);
  const [filterSelected, setFilterSelected] = useState(filter[0]);

  const handleFilter = (value) => {
    setShowFilter(false);
    setFilterSelected(value);
    props.onFiltered(value);
  };

  return (
    <div className="flex flex-row justify-between mt-2">
      <div />
      <div className="">
        <div
          onClick={() => setShowFilter(!showFilter)}
          className="py-1 px-4 text-sm bg-gray-100 flex flex-row rounded-full justify-center space-x-1 cursor-pointer"
        >
          <span>{filterSelected}</span>
          {showFilter ? (
            <FiChevronUp className="text-gray-400 mt-1" />
          ) : (
            <FiChevronDown className="text-gray-400 mt-1" />
          )}
        </div>
        {showFilter && (
          <div className="bg-gray-100 rounded mt-1 text-xs p-2 flex flex-col space-y-2">
            {filter.map((item) => (
              <p className="cursor-pointer" onClick={() => handleFilter(item)}>
                {item}
              </p>
            ))}
          </div>
        )}
      </div>
      {/* <div className="px-4 py-2 bg-gray-200 flex flex-row rounded-full justify-evenly">
          <span>25 April 2022</span>
        </div> */}
    </div>
  );
};
