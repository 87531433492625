import React from 'react'

export const PremiumReward = () => {
  return (
    <div className="W-full">
      <div className="w-full">
      <div className="text-center">
        <div className=" font-medium text-lg">
          <span>Reward Member Of The Month</span>
        </div>

        <div className="my-4">
          <span>Member Of The Month Reward is given to the member with the most purchases every month, the reward is given in the form of a Biscoin balance. The amount of the reward is </span>
        </div>

        <div className="w-full text-start">
          <div className="bg-gradient-to-b from-yellow-600 to-yellow-300 text-white px-4 py-2 my-2 flex flex-row justify-between">
            <span>Rank 1:</span>
            <span>10% Cashback</span>
          </div>
          <div className="bg-gray-400 text-white px-4 py-2 my-2 flex flex-row justify-between">
            <span>Rank 2:</span>
            <span>10% Cashback</span>
          </div>
          <div className="bg-gradient-to-b from-orange-600 to-orange-400 text-white px-4 py-2 my-2 flex flex-row justify-between">
            <span>Rank 3:</span>
            <span>10% Cashback</span>
          </div>
          <div className="bg-gray-200 px-4 py-2 my-2 flex flex-row justify-between">
            <span>Rank 4:</span>
            <span>10% Cashback</span>
          </div>
          <div className="bg-gray-200 px-4 py-2 my-2 flex flex-row justify-between">
            <span>Rank 5:</span>
            <span>10% Cashback</span>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}
