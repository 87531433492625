import React from "react";
import { useStateContext } from "../../../contexts/ContextProvider";

import { Tab } from "../Tab";
import { useTranslation } from "react-i18next";

export const NotificationTab = () => {
  const { t } = useTranslation();
  const { selectedTabNotification, setSelectedTabNotification } =
    useStateContext();
  const items = [t("promo"), t("updates"), t("notification")];

  const handlePress = (index) => {
    setSelectedTabNotification(index);
  };

  return (
    <Tab
      items={items}
      onChange={handlePress}
      selectedTab={selectedTabNotification}
    />
  );
};
