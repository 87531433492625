import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineCloseCircle } from "react-icons/ai";

const PromoPopUpDesktop = (props) => {
  const { t } = useTranslation();
  const [scale, setScale] = useState("scale-0");
  useEffect(() => {
    // if (showTommorow) {
    //   const interval = setInterval(() => {
    //     setRandomNumber(randomInteger(0, articles.length || 0 - 1));
    //   }, 10000);
    //   return () => clearInterval(interval);
    // }
    props.isShowing ? setScale("scale-100") : setScale("scale-0");
  });
  return (
    <>
      <div className="w-screen fixed left-0 mx-auto h-full bg-black opacity-50 z-50" />
      <div className="fixed z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-132 p-6 ">
        <div
          className={`transition ease-in-out bg-white delay-150 shadow rounded-xl p-4 ${scale}`}
        >
          <div className="text-white text-center flex flex-col font-medium">
            <img src="/promo-popup.jpeg" alt="promo" />
            <div className="w-full flex flex-row mt-2 justify-between place-items-center">
              <div className="flex place-items-center">
                <input
                  type="checkbox"
                  className="w-4 h-4 mr-1 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  onChange={props.handleCheckboxChange}
                />
                <span className="text-xs text-black">
                  {t("disableForADay")}
                </span>
              </div>
              <AiOutlineCloseCircle
                className="text-black"
                size={30}
                onClick={props.close}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PromoPopUpDesktop;
