import React from "react";

const arrayz = [
  {
    id: 1,
    syarat: "Lorem ipsum dolor sit amet, consectetur ",
  },
  {
    id: 2,
    syarat: "Lorem ipsum dolor sit amet, consectetur ",
  },
  {
    id: 3,
    syarat: "Lorem ipsum dolor sit amet, consectetur ",
  },
  {
    id: 4,
    syarat: "Lorem ipsum dolor sit amet, consectetur ",
  },
];

export const DetailPromoPage = () => {
  return (
    <div className=" w-screen sm:w-128 mx-auto p-4 shadow bg-white">
      <div className="flex flex-col w-full">
        <img src="/promoimages.jpg" alt="" className="w-full mb-4" />
        <span className="text-xl">Syarat & Ketentuan Promo</span>
        <ul className="p-4">
          {arrayz.map((service) => (
            <li className="list-decimal" key={service.id}>
              {" "}
              {service.syarat}
            </li>
          ))}
        </ul>

        <span className="text-xl mb-4">Detail Promo</span>
        <span>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ligula
          justo, varius vitae vestibulum eu, consequat id tortor. Maecenas
          ultricies eu urna vitae pellentesque. Pellentesque habitant morbi
          tristique senectus et netus et malesuada fames ac turpis egestas.
          Aenean in libero vitae urna luctus euismod. Nulla facilisi. Cras non
          rutrum purus, in lobortis enim. Aliquam tempor velit a dolor tristique
          dapibus.
        </span>
      </div>
    </div>
  );
};
