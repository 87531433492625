import React from "react";
import { Link } from "react-router-dom";

export const PremiumUpgradeBtn = () => {
  return (
    <div className="flex flex-row justify-center mt-4">
      <Link to="/upgrade-premium">
        <button className="bg-blue-800 rounded-full px-4 py-2 font-medium text-white">Upgrade Premium Now</button>
      </Link>
    </div>
  );
};
