import { HelpsIcons } from "../HelpsIcons";

export const CardDetail = (props) => {
  return (
    <div className="border border-gray-400 mt-6 mx-3 rounded-xl flex flex-col justify-between px-4 py-2">
      <div className="flex justify-center mt-2">
        <span className="text-xs mr-2">{props.title}</span>
        <HelpsIcons helptext={props.helptext}/>
      </div>
      <span className="text-sm my-3">{props.detail}</span>
      <span />
    </div>
  );
};
