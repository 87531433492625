import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { OrangeButton } from "../../components/mobile/OrangeButton";
import { getStatusKTP } from "../../services/service";

export const GuideCaptureIDPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getStatusKTP()
      .then((response) => {
        response.data.forEach((element) => {
          if (element.status === "accept") {
            navigate("/affiliate/my-bank");
          }
        });
        setIsLoading(false);
      })
      .catch((error) => {
        navigate("/affiliate");
        setIsLoading(false);
        console.log(error);
      });
  }, []);
  return isLoading ? (
    <div className="w-screen sm:w-128 mx-auto min-h-screen bg-white p-4" />
  ) : (
    <div className="w-screen sm:w-128 mx-auto min-h-screen bg-white p-4">
      <p>KTP Photo Guide</p>
      <p className="text-sm mb-8">Make sure you follow the photo rules below</p>
      <div className="p-4 rounded-lg" style={{ backgroundColor: "#ECFFF4" }}>
        <img src="/assets/ktp.png" alt="KTP" className="mx-auto" />
      </div>
      <ul className="ml-4 text-sm my-8">
        <li>Prepare the original ID card, not a fake / copy</li>
        <li>Position when taking a vertical cellphone camera photo</li>
        <li>The photo must be clear and there is no interference at all</li>
      </ul>
      <Link to="/affiliate/capture">
        <OrangeButton title="Take Photo" />
      </Link>
    </div>
  );
};
