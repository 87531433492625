import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { OrangeButton } from "../../components/mobile/OrangeButton";
import { Input } from "../../components/mobile/Input";
import { OrangeButtonOutline } from "../../components/mobile/OrangeButtonOutline";
import { getDetailLogin, postLogin } from "../../services/service";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import ReCAPTCHA from "react-google-recaptcha";
import Countdown from "react-countdown";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../components/desktop/LanguageSwitcher";

export const LoginPage = () => {
  const { t } = useTranslation();

  const SITE_KEY = process.env.REACT_APP_SITE_KEY;
  const [body, setBody] = useState({
    username: localStorage.getItem("username") || "",
    password: localStorage.getItem("password") || "",
    token: "",
  });
  const [infoLogin, setInfoLogin] = useState();
  const [timeRemining, setTimeRemining] = useState(0);
  const [isEyeOpen, setIsEyeOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const captchaRef = useRef(null);

  const handleEmailChange = (e) => {
    setBody((prevState) => ({ ...prevState, username: e.target.value }));
  };

  const handlePasswordChange = (e) => {
    setBody((prevState) => ({ ...prevState, password: e.target.value }));
  };

  const handleEyeOpen = () => {
    setIsEyeOpen(!isEyeOpen);
  };
  const captchaChange = () => {
    const captchaToken = captchaRef.current.getValue();
    setBody((prevState) => ({
      ...prevState,
      token: captchaToken,
    }));
    setErrorMessage("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessage("");
    if (!body.token) {
      setErrorMessage(t("recaptchaCheckRequired"));
    } else {
      if (body.username && body.password) {
        setIsLoading(true);
        postLogin(body)
          .then((response) => {
            captchaRef.current.reset();
            if (response.data.success) {
              localStorage.setItem("token", response.data.token);
              localStorage.setItem("user", JSON.stringify(response.data.user));
              window.location.reload();
            } else {
              setErrorMessage(t("incorrectPassword"));
            }
            setIsLoading(false);
          })
          .catch((error) => {
            captchaRef.current.reset();
            setIsLoading(false);
            setErrorMessage(
              error.response.data.errors?.error[0] || t("loginFailed")
            );
            setTimeRemining(error.response.data.errors?.time[0] || 0);
          });
      } else {
        setErrorMessage(t("enterUsernameAndPassword"));
      }
    }
  };

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      setErrorMessage("");
      setTimeRemining(0);
      return <span>Selesai</span>;
    } else {
      // Render a countdown
      return (
        <span>
          {minutes}:{seconds}
        </span>
      );
    }
  };

  useEffect(() => {
    getDetailLogin()
      .then((response) => {
        setInfoLogin(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="bg-orange-200 w-screen sm:w-128 mx-auto min-h-screen shadow">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login SMM Panel Indonesia BisnisOn</title>
      </Helmet>
      <img src="/assets/login-banner.jpg" alt="" className="w-full" />
      <div className=" absolute top-2 right-2">
        <LanguageSwitcher />
      </div>

      <div className="bg-white rounded-t-xl w-full mx-auto">
        <div className="flex flex-col px-2  mx-2 pb-4">
          <img
            src="/assets/bisnison.svg"
            alt=""
            className="w-36 mx-auto pt-2"
          />
          <form onSubmit={handleSubmit}>
            {errorMessage && (
              <div className="bg-red-200 text-red-500 p-2 rounded w-full mt-2">
                {errorMessage}

                {timeRemining > 0 && (
                  <Countdown
                    date={Date.now() + timeRemining * 1000}
                    renderer={renderer}
                  />
                )}
              </div>
            )}
            <Input
              label={t("username")}
              value={body.username}
              change={handleEmailChange}
            />
            <Input
              type={isEyeOpen ? "text" : "password"}
              label={t("password")}
              value={body.password}
              change={handlePasswordChange}
              icon={
                isEyeOpen ? (
                  <AiFillEyeInvisible
                    onClick={handleEyeOpen}
                    size={20}
                    className="text-orange-500 my-auto mx-3"
                  />
                ) : (
                  <AiFillEye
                    onClick={handleEyeOpen}
                    size={20}
                    className="text-orange-500 my-auto mx-3"
                  />
                )
              }
            />
            <div className="flex justify-between my-2">
              {/* <div className="flex flex-row ">
                <input
                  type="checkbox"
                  className="mr-3"
                  onChange={() => setCheckedRememberMe(!checkedRememberMe)}
                />
                <label>Remember me</label>
              </div> */}
              <div className="flex flex-row">
                <Link to="/forgot-password" className="text-orange-500">
                  <p>{t("forgotPassword")}</p>
                </Link>
              </div>
            </div>
            <ReCAPTCHA
              sitekey={SITE_KEY}
              ref={captchaRef}
              onExpired={() => captchaRef.current.reset()}
              onChange={captchaChange}
              className="w-max mx-auto"
            />
            <OrangeButton
              type="submit"
              className="rounded-xl bg-orange-500 w-full p-2 mt-2 text-white text-center"
              title={t("loginNow")}
              isLoading={isLoading}
              disabled={isLoading}
            />
            <p className="mt-4 text-center">
              {t("dontHaveAccount")}{" "}
              <Link to="/register">
                <span className="text-orange-500 font-bold">
                  {t("registerHere")}
                </span>
              </Link>
            </p>
          </form>
          <a
            href="https://smm.bisnison.com"
            className=" mt-4 text-xs text-center text-gray-400"
          >
            <p className="">{t("backToOldVersion")}</p>
          </a>

          <div className="w-max mt-4 mx-auto  ">
            <Link to={"/pricelist"}>
              <p className="text-sm text-orange-500 underline">
                {t("registerService")}
              </p>
            </Link>
          </div>

          <div
            className="justify-center text-sm mt-6"
            dangerouslySetInnerHTML={{
              __html: infoLogin?.info,
            }}
          />
        </div>
      </div>
    </div>
  );
};
