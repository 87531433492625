import { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { Link } from "react-router-dom";
import { NotAllowed } from "../../components/mobile/TopMonthly/NotAllowed";
import { TopMember } from "../../components/mobile/TopMonthly/TopMember";
import { TopService } from "../../components/mobile/TopMonthly/TopService";
import { useStateContext } from "../../contexts/ContextProvider";
import { getRankings } from "../../services/service";
import { useTranslation } from "react-i18next";

export const TopMonthlyPage = () => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState("mo30");
  const { user, setRankings } = useStateContext();
  useEffect(() => {
    getRankings()
      .then((response) => {
        setRankings(response.data.data);
      })
      .catch((error) => console.log(error));
  }, []);
  if (user.user_type !== "PREMIUM") {
    return <NotAllowed />;
  }

  return (
    <div
      className=" w-screen sm:w-128 mx-auto shadow min-h-screen"
      style={{
        background:
          "radial-gradient(89% 2958.4% at 93.31% 11%, #252525 0%, #101010 100%)",
        color: "#FFDA75",
      }}
    >
      <header
        className={`sticky top-0 z-20 transition-shadow w-screen sm:w-128 mx-auto `}
        style={{
          background:
            "radial-gradient(89% 2958.4% at 93.31% 11%, #252525 0%, #101010 100%)",
          color: "#FFDA75",
        }}
      >
        <nav>
          <div className="flex justify-between items-center p-4 ">
            <Link to="/">
              <BiArrowBack size={30} />
            </Link>
            <p>{t("topMonthly")}</p>
            <div />
          </div>
        </nav>
      </header>

      <div
        className="rounded-full flex justify-center w-max mx-auto space-x-2 place-items-center p-1 cursor-pointer"
        style={{ backgroundColor: "#FFFFFF1A" }}
      >
        <div
          className="rounded-full px-4 py-3"
          style={{
            background:
              selectedTab === "mo30" &&
              "linear-gradient(180deg, #FFDA75 0%, #FFDE84 0.01%, #E9C258 100%)",
            color: selectedTab === "mo30" ? "#252525" : "#FFDA75",
            fontWeight: 500,
          }}
          onClick={() => setSelectedTab("mo30")}
        >
          <p>{t("topMember")}</p>
        </div>
        <div
          className="rounded-full px-4 py-3"
          style={{
            background:
              selectedTab === "so30" &&
              "linear-gradient(180deg, #FFDA75 0%, #FFDE84 0.01%, #E9C258 100%)",
            color: selectedTab === "so30" ? "#252525" : "#FFDA75",
            fontWeight: 500,
          }}
          onClick={() => setSelectedTab("so30")}
        >
          <p>{t("topService")}</p>
        </div>
      </div>
      {selectedTab === "mo30" ? <TopMember /> : <TopService />}
    </div>
  );
};
