import React from "react";

export const UserChat = (props) => {
  return (
    <div className="text-black w-full h-auto relative flex flex-row justify-end">
      <div
        className=" bg-gray-200 p-4 rounded-xl my-4"
        style={{ maxWidth: "70%" }}
      >
        <p>{props.message}</p>
      <p className="text-xs text-right text-gray-400 mt-2">{props.time}</p>
      </div>
    </div>
  );
};
