import React, { useEffect, useState } from "react";
export const SuccessPopUp = (props) => {
  const [scale, setScale] = useState("scale-0");
  useEffect(() => {
    props.isShowing ? setScale("scale-100") : setScale("scale-0");
  });
  return (
    <div className="w-screen">
      <div className="fixed z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full p-6 ">
        <div
          className={`transition ease-in-out delay-150 bg-green-500 rounded-xl p-4 ${scale}`}
        >
          <div className="text-white flex flex-col font-medium">
            <div className="w-full flex flex-row justify-end">
              <img
                src="/assets/exitbtn.svg"
                alt=""
                className=""
                onClick={props.close}
              />
            </div>
            <span className=" text-center">
              Success ! <br />
              Order was succesfully submitted
            </span>
            <div className="mx-auto my-4">
              <img src="/assets/success.svg" alt="" className="" />
            </div>
            <div className="text-xs">
              <p>Order ID : {props.orderId}</p>
              <p>Link : {props.link}</p>
              <p>Service : {props.service}</p>
              <p>Quantity : {props.quantity}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
