import { useStateContext } from "../../../contexts/ContextProvider";
import { CardDetail } from "../../mobile/Store/CardDetail";
import { useTranslation } from "react-i18next";

const DetailServiceDesktop = () => {
  const { t } = useTranslation();
  const { service } = useStateContext();

  function convertHMS(value) {
    const sec = parseInt(value, 10);
    let hours = Math.floor(sec / 3600);
    let minutes = Math.floor((sec - hours * 3600) / 60);
    return value
      ? `${hours} ${t("hours")} ${minutes} ${t("minutes")}`
      : t("notEnoughData");
  }

  return (
    <div>
      <div className="grid grid-cols-3 text-center w-full">
        <CardDetail
          title={t("speedAverage")}
          detail={convertHMS(service?.speed || 0)}
          helptext={t("averageSpeedDescription")}
        />
        <CardDetail
          title={t("minimum")}
          detail={service && service.min}
          helptext={t("minimumOrder")}
        />
        <CardDetail
          title={t("maximum")}
          detail={service && service.max}
          helptext={t("maximumOrder")}
        />
      </div>

      <div
        style={{ maxHeight: "27.5rem" }}
        className="border border-gray-400 mt-6 w-full text-left rounded-xl px-4 py-4 text-sm overflow-x-auto overflow-y-auto"
      >
        <p>{t("description")}</p>
        <p className="text-gray-400 whitespace-pre-wrap">
          {service ? service.member_info : t("chooseCategoryFirst")}
        </p>
      </div>
    </div>
  );
};

export default DetailServiceDesktop;
