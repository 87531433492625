import { useEffect, useRef, useState } from "react";
import { getCategoriesSMM, getServicesByCategories } from "../services/service";
import { HelpsIcons } from "../components/mobile/HelpsIcons";
import { useTranslation } from "react-i18next";

const PriceListPublicPage = () => {
  const { t } = useTranslation();
  const searchWord = useRef();
  const [categories, setCategories] = useState([]);
  const [servicesBySearch, setServicesBySearch] = useState([]);
  const [list, setList] = useState([]);
  const allList = useRef();
  const [showSearch, setShowSearch] = useState(false);

  const handleSorter = (e) => {
    console.log(e);
    getServicesByCategories({ sorter: e })
      .then((response) => {
        allList.current = response.data.data;
        setList(allList.current);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleTranslate = (word) => {
    const result = word
      .replace("years", t("time.year"))
      .replace("year", t("time.year"))
      .replace("months", t("time.month"))
      .replace("month", t("time.month"))
      .replace("weeks", t("time.week"))
      .replace("week", t("time.week"))
      .replace("days", t("time.day"))
      .replace("day", t("time.day"))
      .replace("hours", t("time.hour"))
      .replace("hour", t("time.hour"))
      .replace("minutes", t("time.minute"))
      .replace("minute", t("time.minute"))
      .replace("seconds", t("time.second"))
      .replace("second", t("time.second"));
    return result;
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (searchWord.current.value) {
        setShowSearch(true);
        const result = servicesBySearch.filter((items) =>
          items["service_name"]
            ?.toString()
            .toLowerCase()
            .includes(searchWord.current.value.toLowerCase())
        );
        setList(result);
        if (result.length <= 20) {
        }
      } else {
        setShowSearch(false);
        setList(allList.current);
      }
    }
  };
  const handleCategory = (e) => {
    if (e !== t("allLabel")) {
      allList.current.forEach((element) => {
        if (element.nama_category === e) {
          setList([element]);
        }
      });
    } else {
      setList(allList.current);
    }
  };

  useEffect(() => {
    getCategoriesSMM()
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    getServicesByCategories({ sorter: "rate" })
      .then((response) => {
        setShowSearch(false);
        setServicesBySearch([]);
        allList.current = response.data.data;
        setList(response.data.data);
        response.data.data.forEach((element) => {
          element.products.forEach((product) => {
            setServicesBySearch((prevState) => [...prevState, product]);
          });
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="bg-white">
      <div className=" overflow-y-auto p-4" style={{ height: "90vh" }}>
        <div className="text-black flex place-items-center justify-between mb-4">
          <div className="w-full flex space-x-2 text-sm">
            <div className="rounded-lg p-1 border bg-gray-50 ">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500 "
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
            </div>
            <input
              type="search"
              id="search"
              className=" w-full py-1 px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Search Services"
              ref={searchWord}
              autoComplete="off"
              onKeyDown={handleKeyDown}
            />
          </div>

          <select
            id="page"
            className="w-full mx-2 border rounded-full py-1 px-4 text-gray-500 text-sm"
            onChange={(e) => handleCategory(e.target.value)}
          >
            <option defaultValue={t("allLabel")} value={t("allLabel")}>
              All Category
            </option>
            {categories.map((item) => {
              return (
                <option key={item.id_category} value={item.nama_category}>
                  <div
                    className="justify-center truncate text-sm"
                    dangerouslySetInnerHTML={{
                      __html: item.nama_category,
                    }}
                  />
                </option>
              );
            })}
          </select>
        </div>
        <table className="w-full text-sm text-left ">
          <thead className="text-xs uppercase bg-gray-50 sticky top-0 ">
            <tr className="border">
              <th scope="col" className="px-6 py-3 ">
                <div className="flex items-center text-black ">ID</div>
              </th>
              <th scope="col" className="px-6 py-3 ">
                <div className="flex items-center text-black ">
                  {t("category")}
                </div>
              </th>
              <th scope="col" className="px-6 py-3 ">
                <div className="flex items-center text-black whitespace-nowrap">
                  {t("service")}
                  <div
                    onClick={() => handleSorter("service_name")}
                    className="cursor-pointer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-3 h-3 ml-1"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 320 512"
                    >
                      <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" />
                    </svg>
                  </div>
                </div>
              </th>
              <th scope="col" className="px-6 py-3">
                <div className="flex items-center whitespace-nowrap">
                  {t("price")} / K
                  <div
                    onClick={() => handleSorter("rate")}
                    className="cursor-pointer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-3 h-3 ml-1"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 320 512"
                    >
                      <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" />
                    </svg>
                  </div>
                </div>
              </th>
              <th scope="col" className="px-6 py-3 whitespace-nowrap">
                <p>{t("minMax")}</p>
              </th>
              <th scope="col" className="px-6 py-3">
                <div className="flex items-center whitespace-nowrap">
                  <p className="mr-1">{t("speedAvg")}</p>
                  <HelpsIcons helptext={t("speedAverageHelptext")} />
                  <div
                    onClick={() => handleSorter("speed")}
                    className="cursor-pointer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-3 h-3 ml-1"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 320 512"
                    >
                      <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" />
                    </svg>
                  </div>
                </div>
              </th>
              <th scope="col" className="px-6 py-3">
                <div className="flex items-center">{t("description")}</div>
              </th>
            </tr>
          </thead>

          <tbody className="overflow-x-scroll">
            {showSearch
              ? list.map((item) => {
                  return (
                    <tr key={item.id} className="bg-white border ">
                      <th scope="row" className="px-6 py-4 font-medium  ">
                        {item.id}
                      </th>
                      <th scope="row" className="px-6 py-4 font-medium  ">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.nama_category,
                          }}
                        />
                      </th>
                      <th scope="row" className="px-6 py-4 font-medium  ">
                        {item.service_name}
                      </th>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <img
                          src="/assets/biscoin.svg"
                          alt="saldo"
                          width={20}
                          className="mr-2 float-left w-max"
                        />
                        {/* {item.rate.toLocaleString("id")} */}
                        {item.rate}
                      </td>
                      <td className="px-6 py-4">
                        {item.min} / {item.max}
                      </td>
                      <td className="px-6 py-4">{item.speed}</td>
                      <td
                        className="px-6 py-1 overflow-auto w-80 md:w-96"
                        style={{
                          maxHeight: "5em",
                          textOverflow: "ellipsis",
                          display: "block",
                        }}
                      >
                        {item.member_info}
                      </td>
                    </tr>
                  );
                })
              : list.map((service) => {
                  return (
                    <>
                      {service.products.map((item) => {
                        return (
                          <tr key={item.id} className="bg-white border ">
                            <th scope="row" className="px-6 py-4 font-medium  ">
                              {item.id}
                            </th>
                            <th scope="row" className="px-6 py-4 font-medium  ">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: service.nama_category,
                                }}
                              />
                            </th>
                            <th scope="row" className="px-6 py-4 font-medium  ">
                              {item.service_name}
                            </th>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <img
                                src="/assets/biscoin.svg"
                                alt="saldo"
                                width={20}
                                className="mr-2 float-left w-max"
                              />
                              {item.rate.toLocaleString("id")}
                            </td>
                            <td className="px-6 py-4">
                              {item.min} / {item.max}
                            </td>
                            <td className="px-6 py-4">
                              {handleTranslate(item.speed)}
                            </td>
                            <td
                              className="px-6 py-1 overflow-auto w-80 md:w-96"
                              style={{
                                maxHeight: "5em",
                                textOverflow: "ellipsis",
                                display: "block",
                              }}
                            >
                              {item.member_info}
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  );
                })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PriceListPublicPage;
