import React from "react";
import { useStateContext } from "../../../contexts/ContextProvider";
import { Tab } from "../Tab";

export const TakeAffiliateTab = () => {
  const { selectedTabTakeAffiliate, setSelectedTabTakeAffiliate } =
    useStateContext();
  const items = ["Ambil", "History"];

  const handlePress = (index) => {
    setSelectedTabTakeAffiliate(index);
  };

  return (
    <Tab
      items={items}
      onChange={handlePress}
      selectedTab={selectedTabTakeAffiliate}
    />
  );
};
