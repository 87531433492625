import { useTranslation } from "react-i18next";

const TabHeaderOrder = ({ selectedTab, setSelectedTab }) => {
  const { t } = useTranslation();
  return (
    <div className="rounded-xl bg-white shadow-lg w-full p-4 grid grid-cols-2 space-x-4">
      <div
        onClick={() => setSelectedTab("Single Order")}
        className={`${
          selectedTab === "Single Order"
            ? "bg-orange-400 text-white"
            : "bg-white text-orange-400 border border-gray-500"
        } font-bold rounded-full text-center py-4 text-2xl cursor-pointer`}
      >
        {t("singleOrder")}
      </div>
      <div
        onClick={() => setSelectedTab("Mass Order")}
        className={`${
          selectedTab === "Mass Order"
            ? "bg-orange-400 text-white"
            : "bg-white text-orange-400 border border-gray-500"
        } font-bold rounded-full text-center py-4 text-2xl cursor-pointer`}
      >
        {t("massOrder")}
      </div>
    </div>
  );
};

export default TabHeaderOrder;
