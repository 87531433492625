import React from "react";
import { useStateContext } from "../../../contexts/ContextProvider";
import { FormNewTicket } from "./FormNewTicket";
import { ListHistoryTicket } from "./ListHistoryTicket";

export const TicketContent = () => {
  const { selectedTabTicket } = useStateContext();

  switch (selectedTabTicket) {
    case "New Ticket":
      return <FormNewTicket />;
    case "History":
      return <ListHistoryTicket />;
    default:
      return null;
  }
};
