import { Navigate } from "react-router-dom";

export const ProtectedRoute = (props) => {
  if (!props.user || !props.token) {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    return <Navigate to="/login" replace />;
  }
  return props.children;
};
