import { useEffect, useState } from "react";
import { NewsContent } from "../../components/mobile/News/NewsContent";
import { NewsHeader } from "../../components/mobile/News/NewsHeader";
import { useStateContext } from "../../contexts/ContextProvider";

export const NewsPage = () => {
  const { news } = useStateContext();
  const [filterNews, setFilterNews] = useState();

  const onFiltered = (value) => {
    if (value === "Price Increased") {
      setFilterNews(
        news.filter(
          (text) =>
            text.status_new === 1 &&
            text.status_old === 1 &&
            text.rate_new > text.rate_old
        )
      );
    } else if (value === "Price Decreased") {
      setFilterNews(
        news.filter(
          (text) =>
            text.status_new === 1 &&
            text.status_old === 1 &&
            text.rate_new < text.rate_old
        )
      );
    } else if (value === "Enabled") {
      setFilterNews(
        news.filter((text) => text.status_new === 1 && text.status_old === 0)
      );
    } else if (value === "Disabled") {
      setFilterNews(news.filter((text) => text.status_new === 0));
    } else if (value === "All") {
      setFilterNews(news);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className=" w-screen sm:w-128 mx-auto min-h-screen flex flex-col shadow bg-white">
      <div className="text-left w-full h-full">
        <div className="p-4">
          <NewsHeader onFiltered={onFiltered} />
          {filterNews ? (
            <div className="overflow-scroll h-full flex flex-col w-full justify-start">
              {filterNews.map((item, index) => {
                return (
                  <NewsContent
                    key={item.id}
                    product_id={item.product_id}
                    content={item.server}
                    tanggal={item.created_at}
                    status_old={item.status_old}
                    status_new={item.status_new}
                    nama_category={item.nama_category}
                    rate_old={item.rate_old}
                    rate_new={item.rate_new}
                  />
                );
              })}
            </div>
          ) : (
            <div className="overflow-scroll h-full flex flex-col w-full justify-start">
              {news.map((item, index) => {
                return (
                  <NewsContent
                    key={item.id}
                    product_id={item.product_id}
                    content={item.server}
                    tanggal={item.created_at}
                    status_old={item.status_old}
                    status_new={item.status_new}
                    nama_category={item.nama_category}
                    rate_old={item.rate_old}
                    rate_new={item.rate_new}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div className="h-20" />
    </div>
  );
};
