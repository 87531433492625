import { useEffect } from "react";
import { useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { IoLogOutOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { useStateContext } from "../../contexts/ContextProvider";
import { postLogout, postPremiumUpgrade } from "../../services/service";
import { useTranslation } from "react-i18next";

export const PremiumUpgradeConfirmPage = () => {
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { user } = useStateContext();
  const handleBuyNow = () => {
    if (user.ppob_saldo < 350000) {
      setMessage(`Please top up your IDR balance`);
    } else {
      setIsLoading(true);
      postPremiumUpgrade(user.email)
        .then((response) => {
          setIsLoading(false);
          response.data.message === "Member upgraded to PREMIUM MEMBERSHIP" &&
            setIsSuccess(true);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      {message && (
        <div
          onClick={() => setMessage("")}
          className="absolute h-screen w-full flex place-items-center justify-center"
        >
          <div className="absolute top-0 left-0 z-20 bg-black opacity-90 w-screen h-screen"></div>
          <div
            className="rounded-full z-30 relative px-4 py-2"
            style={{
              backgroundImage:
                "linear-gradient(180deg, #FFDA75, #FFDE84, #E9C258)",
            }}
          >
            <p className=" ">{message}</p>
          </div>
        </div>
      )}
      <div className="w-screen sm:w-128 h-screen mx-auto shadow">
        <div className="w-screen sm:w-128 mx-auto h-screen fixed">
          <img
            src="/assets/premium-bg.png"
            alt=""
            className="w-screen sm:w-128 mx-auto"
          />
        </div>
        <div className="relative">
          <TopNavbar />
          {isSuccess ? <SuccessBody /> : <Body />}
          <BottomNavbar
            handleBuyNow={handleBuyNow}
            isLoading={isLoading}
            isSuccess={isSuccess}
            message={message}
          />
        </div>
      </div>
    </>
  );
};

const TopNavbar = () => {
  const handleLogout = () => {
    postLogout()
      .then((response) => {
        if (response.data.success) {
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <header className="sticky top-0 z-10 transition-shadow w-screen sm:w-128 mx-auto">
      <nav className="transition-colors">
        <ul className="flex items-center justify-between p-4 ">
          <div className="w-1/4">
            <Link to="/premium-upgrade">
              <BiArrowBack size={30} className="text-white" />
            </Link>
          </div>
          <Link to="/">
            <img
              src="/logo-long.png"
              alt="logo-long"
              size={130}
              className="max-w-3/6"
            />
          </Link>
          <div className="w-1/4 flex place-content-end">
            {/* <NotificationIcon /> */}
            <IoLogOutOutline
              className="ml-2 text-white"
              size={30}
              onClick={handleLogout}
            />
          </div>
        </ul>
      </nav>
    </header>
  );
};

const Body = () => {
  const { user } = useStateContext();
  return (
    <>
      <div style={{ height: 150 }} />
      <div
        className="w-screen sm:w-128 h-full mx-auto flex flex-col place-items-center p-2 rounded-t-lg"
        style={{
          backgroundImage: "linear-gradient(180deg, #1E1E1E, #151515)",
          color: "#E9C258",
        }}
      >
        <div className="-top-28 relative flex flex-col place-items-center text-xl">
          <img
            src="/assets/astro-crown.png"
            alt="astro crown"
            className="w-28"
          />
          <div
            className="border-2 px-8 py-2 rounded-full text-white font-bold"
            style={{ borderColor: "#E9C258", backgroundColor: "#363636" }}
          >
            <span style={{ color: "#E9C258" }}>Premium Member </span>Payment
          </div>
        </div>
        <div className="relative -top-20 w-full px-2">
          <div className="flex flex-col w-full">
            <p>Service</p>
            <div
              className="px-4 py-3 w-full rounded-full border-2 text-base mt-2"
              style={{ borderColor: "#E9C258", backgroundColor: "#363636" }}
            >
              Upgrade to Premium Member (Lifetime)
            </div>
          </div>
          <div className="flex flex-col w-full my-5">
            <p>Payment Method</p>
            <div
              className="px-4 py-3 w-full rounded-full border-2 text-base mt-2"
              style={{ borderColor: "#E9C258", backgroundColor: "#363636" }}
            >
              IDR - Rp {user.ppob_saldo.toLocaleString("id")}
            </div>
          </div>
          <div className="flex flex-col w-full">
            <p>Price</p>
            <div
              className="px-4 py-3 w-full rounded-full border-2 text-base mt-2"
              style={{ borderColor: "#E9C258", backgroundColor: "#363636" }}
            >
              Rp 350.000
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const SuccessBody = () => {
  return (
    <>
      <div style={{ height: 150 }} />
      <div
        className="w-screen sm:w-128 h-full mx-auto flex flex-col place-items-center p-2 rounded-t-lg"
        style={{
          backgroundImage: "linear-gradient(180deg, #1E1E1E, #151515)",
          color: "#E9C258",
        }}
      >
        <div className="-top-24 relative flex flex-col place-items-center text-xl">
          <img
            src="/assets/premium/premium-member-logo.png"
            alt="astro crown"
            className="w-44"
          />
          <div
            className="border-2 px-8 py-2 rounded-full text-white font-bold text-base mt-8"
            style={{
              borderColor: "#E9C258",
              color: "#E9C258",
              backgroundColor: "#363636",
            }}
          >
            Welcome New Premium Member !
          </div>
        </div>
        <div className="relative -top-14 w-full px-2">
          <img src="/assets/premium/people-celebrate.png" alt="" />
        </div>
        <div style={{ height: 150 }} />
      </div>
    </>
  );
};

const BottomNavbar = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      className="w-screen sm:w-128 mx-auto block fixed bottom-0 inset-x-0 z-10"
      style={{ backgroundColor: "#1E1E1E" }}
    >
      <nav className="transition-colors shadow-inner">
        <div className="my-3 px-4">
          <div
            className="rounded-full py-3 w-36 flex justify-center text-black cursor-pointer"
            style={{
              backgroundImage:
                "linear-gradient(180deg, #FFDA75, #FFDE84, #E9C258)",
            }}
            onClick={props.isSuccess ? () => navigate("/") : props.handleBuyNow}
          >
            <span>
              {props.isSuccess
                ? t("backToHome")
                : props.isLoading
                ? t("loading")
                : t("buyNow")}
            </span>
          </div>
        </div>
      </nav>
    </div>
  );
};
