import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input } from "../../components/mobile/Input";
import { OrangeButton } from "../../components/mobile/OrangeButton";
import { SelectInput } from "../../components/mobile/SelectInput";
import { listBank } from "../../services/bank";
import { postAddBank } from "../../services/service";
import { useTranslation } from "react-i18next";

export const AffiliateAddBankPage = () => {
  const { t } = useTranslation();
  const [selectedBank, setSelectedBank] = useState("");
  const [body, setBody] = useState({
    noRekening: "",
    codeBank: "",
    atasNama: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const handleSelectItem = (value, label) => {
    setSelectedBank(label);
    setBody((prevState) => ({ ...prevState, codeBank: value }));
  };

  const handleNoRekChange = (e) => {
    setBody((prevState) => ({ ...prevState, noRekening: e.target.value }));
  };

  const handleAtasNamaChange = (e) => {
    setBody((prevState) => ({ ...prevState, atasNama: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage("");
    if (body.noRekening.length < 10) {
      setMessage("Nomor rekening tidak boleh kurang dari 10");
    } else {
      setIsLoading(true);
      postAddBank(body.codeBank, body.noRekening, body.atasNama)
        .then((response) => {
          navigate("/affiliate/my-bank");
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="w-screen sm:w-128 mx-auto">
      <div className="w-screen sm:w-128 mx-auto min-h-screen p-4 bg-white">
        <form onSubmit={handleSubmit}>
          <Input
            label={t("destinationAccount")}
            change={handleNoRekChange}
            value={body.noRekening}
          />
          <SelectInput
            data={listBank}
            label={t("accountNumber")}
            handleSelectItem={handleSelectItem}
            select={selectedBank}
            isSearchable={true}
          />
          <Input
            label={t("accountName")}
            change={handleAtasNamaChange}
            value={body.atasNama}
          />
          {/* <Link to="/affiliate/capture">
            <div className="mt-4">
              <label className="text-sm">
                Verification KTP / Residence Indentification Card
              </label>
              <div
                className="w-full py-4 text-gray-300 rounded-full border border-gray-300 mt-2 border-dashed flex flex-col justify-center place-items-center"
                onClick={handleOpenMedia}
              >
                <BsCamera size={40} />
                <p className="text-sm mt-2">Take Photo</p>
              </div>
            </div>
          </Link> */}
          {message && (
            <p className="mt-4 text-red-400 text-center">{message}</p>
          )}
          <OrangeButton
            type="submit"
            title="Save"
            isLoading={isLoading}
            disabled={isLoading}
          />
        </form>
      </div>
    </div>
  );
};
