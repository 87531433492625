import React, { useEffect, useRef } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

export const AffiliateDetail = (props) => {
  const { t } = useTranslation();
  const userRef = useRef();

  useEffect(() => {
    userRef.current = JSON.parse(localStorage.getItem("user"));
  });
  return (
    <div className="w-full mt-4 ">
      <div className="w-full mt-4">
        {/* <Link to="/affiliate-take" className="ml-4"> */}
        {/* </Link> */}
        <div className="p-3 flex flex-row justify-between w-full">
          <span>{t("commissionHistory")}</span>
        </div>
        <div
          className="overflow-x-auto overflow-y-auto"
          // style={{ height: "80vh" }}
        >
          <table className="w-full text-sm text-left text-gray-500">
            <thead
              className="text-xs text-white uppercase "
              style={{ backgroundColor: "#FF8D23" }}
            >
              <tr>
                <th
                  scope="col"
                  className="px-3 py-3 text-center whitespace-nowrap"
                >
                  No
                </th>
                <th scope="col" className="px-3 py-3 text-center ">
                  {t("username")} {/* Translate "Username" */}
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 text-center whitespace-nowrap"
                >
                  {t("registerDate")} {/* Translate "Tanggal Daftar" */}
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 text-center whitespace-nowrap"
                >
                  {t("commission")} {/* Translate "Komisi" */}
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 text-center whitespace-nowrap"
                >
                  {t("upgradeCommission")} {/* Translate "Komisi Upgrade" */}
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 text-center whitespace-nowrap"
                >
                  {t("upgradeDate")} {/* Translate "Tanggal Upgrade" */}
                </th>
              </tr>
            </thead>
            <tbody>
              {props.data.downlines &&
                props.data?.downlines.map((affiliate, index) => {
                  return (
                    <tr key={index} className="bg-white border-b text-sm">
                      <td className="px-3 py-3 ">
                        <p className="" style={{ width: "" }}>
                          {affiliate?.id}
                        </p>
                      </td>
                      <td className="px-3 py-3 ">
                        {affiliate?.user_down_line?.username || ""}
                      </td>
                      <td className="px-3 py-3 whitespace-nowrap ">
                        {moment(
                          affiliate?.user_down_line?.created_at || ""
                        ).format("DD MMM YYYY")}
                      </td>
                      <td className="px-3 py-3 whitespace-nowrap ">
                        Rp{" "}
                        {affiliate?.affiliate_bonus_logs.length > 0
                          ? Number(
                              affiliate?.affiliate_bonus_logs[0]?.total_value ||
                                0
                            ).toLocaleString("id")
                          : 0}
                      </td>
                      <td>
                        Rp.{" "}
                        {affiliate?.user_down_line_logs.length > 0
                          ? Number(
                              affiliate?.user_down_line_logs[0]
                                ?.upgrade_commission || 0
                            ).toLocaleString("id")
                          : 0}
                      </td>
                      <td className="px-3 py-3 whitespace-nowrap ">
                        {affiliate?.user_down_line_logs.length > 0
                          ? moment(
                              affiliate?.user_down_line_logs[0]?.tanggal_upgrade
                            ).format("DD MMM YYYY")
                          : "Tidak ada"}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className="h-20" />
      </div>
    </div>
  );
};
