import moment from "moment";
import React, { useState } from "react";
import { FiChevronsDown, FiChevronsUp } from "react-icons/fi";
import { ExpandPremiumBenefits } from "./ExpandPremiumBenefits";

export const MySpecialPrice = (props) => {
  const [isOpen, setIsOpen] = useState(true);

  function toggleExpander(idService) {
    if (isOpen !== idService) {
      setIsOpen(idService);
    } else {
      setIsOpen(undefined);
    }
  }
  return (
    <div className="min-h-screen mt-6">
      <table className="font-normal w-full table-fixed">
        <thead
          className="w-full relative border"
          style={{ color: "#E9C258", borderColor: "#E9C258" }}
        >
          <tr className="text-xs">
            <th className="font-light py-3 w-6/12">Layanan</th>
            <th className="font-light py-3 w-3/12">Value</th>
            <th className="font-light py-3 w-2/12">Type</th>
            <th className="font-light py-3 w-3/12">Actual</th>
          </tr>
        </thead>
        {props.specialPrice.map((mock, index) => {
          return (
            <tbody
              key={mock?.id}
              className="w-full text-xs"
              style={{ color: "#E9C258" }}
            >
              <tr
                width={40}
                className="py-4 cursor-pointer"
                onClick={() => toggleExpander(index)}
              >
                <td className="py-3 flex flex-row text-center justify-center">
                  {isOpen !== index ? (
                    <FiChevronsDown
                      className="mr-2"
                      style={{ color: "#E9C258" }}
                    />
                  ) : (
                    <FiChevronsUp
                      className="mr-2"
                      style={{ color: "#E9C258" }}
                    />
                  )}
                  {mock.layanan}
                </td>
                <td className="text-center">{mock.value}</td>
                <td className="text-center">{mock.type}</td>
                <td className="text-center">{mock.actual}</td>
              </tr>
              <tr>
                <td colSpan={4}>
                  {isOpen === index && (
                    <ExpandPremiumBenefits
                      tanggal={moment(mock.createdAt).format("DD MMM YYYY")}
                      komisi={mock.value}
                    />
                  )}
                </td>
              </tr>
            </tbody>
          );
        })}
      </table>
    </div>
  );
};
