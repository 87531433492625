import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const NotFoundPage = () => {
  const { t } = useTranslation();
  return (
    <div className="w-screen h-screen bg-black flex flex-col justify-center place-items-center">
      <img
        src="/assets/404.png"
        alt="404"
        className="w-full md:w-1/2 absolute"
      />
      <img
        src="/assets/astronout-inject.png"
        alt="404"
        className="w-1/2 top-20 left-14 md:w-1/4 md:top-32 md:left-32 relative"
        id="astronout"
      />
      <img
        src="/assets/rocket.png"
        alt="404"
        className="w-1/2 right-20 md:w-1/5 md:bottom-16 md:right-32 relative"
        id="rocket"
      />
      <Link to="/">
        <div className="bg-blue-bisnison rounded-full py-2 px-4 text-white relative -bottom-36">
          {t("backToHome")}
        </div>
      </Link>
    </div>
  );
};
