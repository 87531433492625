import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { BiArrowBack } from "react-icons/bi";
import { ImSpinner8 } from "react-icons/im";
import { Link, useNavigate } from "react-router-dom";
import { Input } from "../../components/mobile/Input";
import { motion } from "framer-motion";
import { OrangeButton } from "../../components/mobile/OrangeButton";
import {
  getCheckAffiliateCode,
  getDetailRegister,
  getIpPublic,
  postRegister,
  postSendOtp,
  postVisitorCount,
} from "../../services/service";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../components/desktop/LanguageSwitcher";
import { HelpsIcons } from "../../components/mobile/HelpsIcons";

export const RegisterPage = () => {
  const { t } = useTranslation();

  const [isEyeOpen, setIsEyeOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [successSendOTP, setSuccessSendOTP] = useState("");
  const [errorSendOTP, setErrorSendOTP] = useState("");

  const [isLoading, setIsLoading] = useState({
    submit: false,
    otp: false,
  });
  const [isAcceptTOS, setIsAcceptTOS] = useState(false);
  const [body, setBody] = useState({
    username: "",
    email: "",
    password: "",
    phoneNumber: "",
    otp: "",
    code: "",
    token: "",
  });
  const [affiliateCodeStatus, setAffiliateCodeStatus] = useState("bg-gray-200");
  const [affiliateLoading, setAffiliateLoading] = useState(false);
  const [affiliateCodeValid, setAffiliateCodeValid] = useState(
    JSON.parse(localStorage.getItem("affiliate_code"))
  );
  const [infoRegister, setInfoRegister] = useState();
  const SITE_KEY = process.env.REACT_APP_SITE_KEY;

  let errorLength = 0;

  const url = window.location.pathname;
  const affiliate_code = url.split("/").pop();

  const navigate = useNavigate();

  const formValidations = () => {
    errorLength = 0;
    if (!body.username) {
      setErrorMessage((prevState) => [
        ...prevState,
        t("validation.usernameRequired"),
      ]);
      errorLength++;
    }
    if (!body.email) {
      setErrorMessage((prevState) => [
        ...prevState,
        t("validation.emailRequired"),
      ]);
      errorLength++;
    }
    if (!body.password) {
      setErrorMessage((prevState) => [
        ...prevState,
        t("validation.passwordRequired"),
      ]);
      errorLength++;
    }
    if (!body.phoneNumber) {
      setErrorMessage((prevState) => [
        ...prevState,
        t("validation.phoneNumberRequired"),
      ]);
      errorLength++;
    }
    if (!body.otp) {
      setErrorMessage((prevState) => [
        ...prevState,
        t("validation.otpRequired"),
      ]);
      errorLength++;
    }
    if (!isAcceptTOS) {
      setErrorMessage((prevState) => [
        ...prevState,
        t("validation.tosNotAccepted"),
      ]);
      errorLength++;
    }
  };
  // const handleCheckOtp = () => {
  //   errorLength <= 0 &&
  //     postCheckOtp(body)
  //       .then((response) => {
  //         if (!response.data.message) {
  //           setErrorMessage((prevState) => [...prevState, "Wrong OTP code"]);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         setErrorMessage((prevState) => [
  //           ...prevState,
  //           "Error when checked OTP code: " + error,
  //         ]);
  //       });
  // };

  const handleEyeOpen = () => {
    setIsEyeOpen(!isEyeOpen);
  };
  const handleUsernameChange = (e) => {
    setBody((prevState) => ({ ...prevState, username: e.target.value }));
  };
  const handleEmailChange = (e) => {
    setBody((prevState) => ({ ...prevState, email: e.target.value }));
  };
  const handlePasswordChange = (e) => {
    setBody((prevState) => ({ ...prevState, password: e.target.value }));
  };
  const handlePhoneChange = (e) => {
    setBody((prevState) => ({ ...prevState, phoneNumber: e.target.value }));
  };
  const handleOTPChange = (e) => {
    setBody((prevState) => ({ ...prevState, otp: e.target.value }));
  };
  const handleTOSChange = (e) => {
    setIsAcceptTOS(!isAcceptTOS);
  };
  const handleSendOtp = (e) => {
    setErrorSendOTP("");
    setErrorMessage([]);
    setSuccessSendOTP("");
    if (body.username) {
      setIsLoading((prevState) => ({ ...prevState, otp: true }));
      postSendOtp(body)
        .then((response) => {
          if (response.data.hp) {
            setErrorMessage((prevState) => [
              ...prevState,
              t("phoneNumberAlreadyInUse"),
            ]);
          }
          if (response.data.username) {
            setErrorMessage((prevState) => [
              ...prevState,
              t("usernameAlreadyInUse"),
            ]);
          }
          if (response.data.status) {
            setSuccessSendOTP(t("successfullySentOTP"));
          } else if (
            response.data.email[0] === "The email has already been taken."
          ) {
            setErrorMessage((prevState) => [...prevState, t("emailTaken")]);
          }
          setIsLoading((prevState) => ({ ...prevState, otp: false }));
        })
        .catch((error) => {
          console.log(error);
          setErrorSendOTP(t("failedToSendOTP"));
          setIsLoading((prevState) => ({ ...prevState, otp: false }));
        });
    } else {
      setErrorMessage((prevState) => [...prevState, t("usernameRequired")]);
    }
  };

  const handleCheckAffiliate = (ip) => {
    if (affiliate_code !== "register") {
      setAffiliateLoading(true);
      getCheckAffiliateCode(affiliate_code, ip)
        .then(() => {
          setAffiliateCodeStatus("bg-green-100");
          localStorage.setItem(
            "affiliate_code",
            JSON.stringify({
              code: affiliate_code,
              date: moment().add(30, "days").format("DD/MM/YYYY"),
            })
          );
          setBody((prevState) => ({ ...prevState, code: affiliate_code }));
          setAffiliateCodeValid(
            JSON.parse(localStorage.getItem("affiliate_code"))
          );
          postVisitorCount(affiliate_code)
            .then(() => {
              navigate("/register", { replace: true });

              setAffiliateLoading(false);
            })
            .catch((error) => {
              console.log(error);
              navigate("/register", { replace: true });

              setAffiliateLoading(false);
            });
        })
        .catch((error) => {
          console.log(error);
          setAffiliateCodeStatus("bg-red-100");
          navigate("/register", { replace: true });

          setAffiliateLoading(false);
        });
    }
  };

  const captchaRef = useRef();

  const captchaChange = () => {
    const captchaToken = captchaRef.current.getValue();
    setBody((prevState) => ({
      ...prevState,
      token: captchaToken,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessage([]);
    setSuccessMessage("");
    formValidations();
    if (!body.token) {
      setErrorMessage((prevState) => [
        ...prevState,
        t("recaptchaCheckRequired"),
      ]);
    }
    if (errorLength <= 0 && body.token) {
      setIsLoading((prevState) => ({ ...prevState, submit: true }));
      postRegister(body)
        .then((response) => {
          if (!response.data.success) {
            setErrorMessage((prevState) => [...prevState, response.data.data]);
          } else {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("user", JSON.stringify(response.data.user));
            setTimeout(() => {
              window.location.reload();
              setSuccessMessage(t("successfullyRegistered"));
            }, 1000);
          }
          setIsLoading((prevState) => ({ ...prevState, submit: false }));
          captchaRef.current.reset();
        })
        .catch((error) => {
          console.log(error);
          setErrorMessage((prevState) => [...prevState, t("failedToRegister")]);
          setIsLoading((prevState) => ({ ...prevState, submit: false }));
          captchaRef.current.reset();
        });
    } else {
      setIsLoading((prevState) => ({ ...prevState, submit: false }));
    }
  };

  useEffect(() => {
    getIpPublic()
      .then((response) => {
        setBody((prevState) => ({ ...prevState, ip: response.data.ip }));
        if (affiliate_code !== "register") {
          if (!affiliateCodeValid) {
            handleCheckAffiliate(response.data.ip);
            setBody((prevState) => ({
              ...prevState,
              code: affiliateCodeValid?.code,
            }));
          } else {
            navigate("/register", { replace: true });
          }
        }
      })
      .catch((error) => {
        setErrorMessage((prevState) => [...prevState, t("ipAddressError")]);
      });
    if (moment().isSameOrAfter(affiliateCodeValid?.date)) {
      localStorage.removeItem("affiliate_code");
    }

    getDetailRegister()
      .then((response) => {
        setInfoRegister(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className=" w-screen sm:w-128 mx-auto min-h-screen shadow bg-white">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Register SMM Panel Indonesia BisnisOn</title>
      </Helmet>

      <div className=" absolute top-2 right-2">
        <LanguageSwitcher />
      </div>
      <div className="flex flex-col px-2 py-4 mx-2">
        <div className="flex justify-between">
          <Link to="/login" className="my-auto">
            <BiArrowBack size={25} />
          </Link>
          <img src="/assets/bisnison.svg" alt="BisnisOn" className="w-36" />
          <ImSpinner8 size={25} className="text-transparent" />
        </div>
        <form
          className="h-full justify-evenly flex flex-col mt-5"
          onSubmit={handleSubmit}
        >
          <Input
            label={t("username")}
            iconHelp={<HelpsIcons helptext={t("helpText.usernameLabel")} />}
            type="text"
            placeholder={t("yourUsername")}
            change={handleUsernameChange}
          />
          <Input
            label={t("password")}
            type={isEyeOpen ? "text" : "password"}
            placeholder={t("typeYourPassword")}
            iconHelp={<HelpsIcons helptext={t("helpText.passwordLabel")} />}
            icon={
              isEyeOpen ? (
                <AiFillEyeInvisible
                  onClick={handleEyeOpen}
                  size={20}
                  className="text-orange-500 my-auto mx-3"
                />
              ) : (
                <AiFillEye
                  onClick={handleEyeOpen}
                  size={20}
                  className="text-orange-500 my-auto mx-3"
                />
              )
            }
            change={handlePasswordChange}
          />
          <Input
            label={t("email")}
            placeholder={t("yourEmail")}
            type="email"
            change={handleEmailChange}
            iconHelp={<HelpsIcons helptext={t("helpText.emailLabel")} />}
          />
          <Input
            label={t("phoneNumber")}
            type="number"
            placeholder="081388******"
            change={handlePhoneChange}
            iconHelp={<HelpsIcons helptext={t("helpText.phoneLabel")} />}
          />
          <Input
            label={t("otpCode")}
            placeholder={t("codeVerification")}
            change={handleOTPChange}
            iconHelp={<HelpsIcons helptext={t("helpText.otpLabel")} />}
            icon={
              isLoading.otp ? (
                <ImSpinner8 className="animate-spin mr-4 text-orange-500" />
              ) : (
                <p
                  onClick={
                    body.phoneNumber && body.email
                      ? handleSendOtp
                      : () => alert(t("validation.emailAndPhoneRequired"))
                  }
                  className={`${
                    body.phoneNumber && body.email
                      ? "text-orange-500"
                      : "text-gray-300"
                  } text-xs mr-4 cursor-pointer`}
                >
                  {t("sendCode")}
                </p>
              )
            }
          />
          {errorSendOTP && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="bg-red-200 text-red-500 p-2 my-2 rounded w-full"
            >
              {errorSendOTP}
            </motion.div>
          )}

          {successSendOTP && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="bg-green-200 text-green-500 p-2 my-2 rounded w-full"
            >
              {successSendOTP}
            </motion.div>
          )}
          <Input
            label={t("affiliateCode")}
            disabled={true}
            iconHelp={<HelpsIcons helptext={t("helpText.affiliateCode")} />}
            value={
              affiliateLoading
                ? t("checkingCodeAffiliate")
                : affiliateCodeStatus === "bg-red-100"
                ? t("codeNotValid")
                : affiliateCodeValid?.code
            }
            className={affiliateCodeStatus}
          />
          <div className="flex flex-row text-xs my-2">
            <input
              type="checkbox"
              className="mr-3"
              value={isAcceptTOS}
              onChange={handleTOSChange}
            />
            <label>
              {t("iAcceptTerms")}{" "}
              <Link to="/tos">
                <span className="text-orange-500">
                  {" "}
                  {t("termsAndPrivacyPolicy")}{" "}
                </span>
              </Link>
            </label>
          </div>
          {errorMessage &&
            errorMessage.map((response, index) => (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                key={index}
                className="bg-red-200 text-red-500 p-2 my-2 rounded w-full"
              >
                {response}
              </motion.div>
            ))}

          {successMessage && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="bg-green-200 text-green-500 p-2 my-2 rounded w-full"
            >
              {successMessage}
            </motion.div>
          )}
          <ReCAPTCHA
            sitekey={SITE_KEY}
            ref={captchaRef}
            onChange={captchaChange}
            className="w-max mx-auto"
          />
          <OrangeButton
            type="submit"
            title={t("registerNow")}
            isLoading={isLoading.submit}
            disabled={isLoading.submit}
          />
        </form>

        <p className="mt-4 text-center">
          {t("alreadyHaveAccount")}{" "}
          <Link to="/login">
            <span className="text-orange-500 font-bold">{t("loginHere")}</span>
          </Link>
        </p>

        <a
          href="https://smm.bisnison.com"
          className=" mt-4 text-xs text-center text-gray-400"
        >
          <p className="">{t("backToOldVersion")}</p>
        </a>

        <div className="w-max mt-4 mx-auto  ">
          <Link to={"/pricelist"}>
            <p className="text-sm text-orange-500 underline">
              {t("registerService")}
            </p>
          </Link>
        </div>

        <div
          className="justify-center text-sm mt-6"
          dangerouslySetInnerHTML={{
            __html: infoRegister?.info,
          }}
        />
      </div>
    </div>
  );
};
