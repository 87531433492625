import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PremiumBenefitsContent } from "../../components/mobile/PremiumBenefits/PremiumBenefitsContent";
import { PremiumBenefitsTab } from "../../components/mobile/PremiumBenefits/PremiumBenefitsTab";
import { TopNavbar } from "../../components/mobile/PremiumBenefits/TopNavbar";
import { useStateContext } from "../../contexts/ContextProvider";

export const PremiumBenefitsPage = () => {
  const navigate = useNavigate();
  const { user } = useStateContext();
  useEffect(() => {
    if (user.user_type === "PREMIUM") {
      navigate("/premium-benefits");
    } else {
      navigate("/premium-upgrade");
    }
  }, []);
  return (
    <div
      className="w-screen sm:w-128 min-h-screen mx-auto shadow"
      style={{
        backgroundImage: "linear-gradient(180deg, #1E1E1E, #151515)",
      }}
    >
      <TopNavbar />
      <div className="">
        <PremiumBenefitsTab />
        <PremiumBenefitsContent />
      </div>
    </div>
  );
};
