import React from "react";
import Slider from "react-slick";
import { PremiumBenefit } from "./PremiumBenefit";
import { PremiumLevel } from "./PremiumLevel";
import { PremiumReward } from "./PremiumReward";
import { PremiumUpgradeBtn } from "./PremiumUpgradeBtn";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const PremiumContent = () => {
  const settings = {
    dots: true,
    lazyLoad: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  return (
    <div>
      <Slider {...settings}>
        <div>
          <PremiumBenefit />
          <PremiumUpgradeBtn />
        </div>
        <div>
          <PremiumLevel />
          <PremiumUpgradeBtn />
        </div>
        <div>
          <PremiumReward />
          <PremiumUpgradeBtn />
        </div>
      </Slider>
    </div>
  );
};
