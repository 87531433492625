import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Input } from "../../components/mobile/Input";
import { OrangeButton } from "../../components/mobile/OrangeButton";
import { getDetailLogin, postResetPassword } from "../../services/service";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const ResetPasswordPageDesktop = () => {
  const { pathname } = window.location;
  const arrayPath = pathname.split("/");
  const lastPathname = arrayPath[arrayPath.length - 1];
  const [infoLogin, setInfoLogin] = useState();
  const [body, setBody] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    token: lastPathname,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({
    success: "",
    failed: "",
  });
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setBody((prevState) => ({ ...prevState, email: e.target.value }));
  };
  const handlePasswordChange = (e) => {
    setBody((prevState) => ({ ...prevState, password: e.target.value }));
  };
  const handleConfirmPasswordChange = (e) => {
    setBody((prevState) => ({ ...prevState, confirmPassword: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage({
      success: "",
      failed: "",
    });
    if (
      body.password === body.confirmPassword &&
      body.password.length >= 8 &&
      body.email
    ) {
      setIsLoading(true);
      postResetPassword({
        email: body.email,
        password: body.password,
        confirmPassword: body.confirmPassword,
        token: body.token,
      })
        .then((response) => {
          if (response.data.status === "success") {
            setMessage((prevState) => ({
              prevState,
              success: response.data.message,
            }));
            setTimeout(() => {
              navigate("/login");
            }, 2000);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setMessage((prevState) => ({
            prevState,
            failed: error.response.data.message,
          }));
          setIsLoading(false);
        });
    }
  };
  useEffect(() => {
    getDetailLogin()
      .then((response) => {
        setInfoLogin(response.data);
      })
      .catch((error) => console.log(error));
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Forgot Password SMM Panel Indonesia BisnisOn</title>
      </Helmet>
      <div className="grid gap-x-8 grid-cols-2 h-screen p-4">
        <div className="h-full bg-blue-bisnison rounded-3xl p-4 flex flex-col justify-center text-white">
          <div
            className="justify-center text-sm mt-4"
            dangerouslySetInnerHTML={{
              __html: infoLogin?.info,
            }}
          />
        </div>
        <div className="h-full">
          <div
            className="flex flex-col px-2 mx-2 pb-4 justify-center"
            style={{ height: "85%" }}
          >
            <img
              src="/assets/bisnison.svg"
              alt=""
              className="w-36 mx-auto pt-2"
            />
            <form onSubmit={handleSubmit}>
              <p className="text-3xl font-semibold mb-4">Reset Password</p>
              <Input
                placeholder="Email"
                type="email"
                change={handleEmailChange}
              />
              <Input
                placeholder="New Password"
                type={"password"}
                autocomplete="new-password"
                change={handlePasswordChange}
              />
              {body.password.length < 8 && (
                <p className={"text-red-400 text-sm mt-2 text-right"}>
                  {body.password.length || 0} / 8
                </p>
              )}
              <Input
                placeholder="Confirm Password"
                type={"password"}
                autocomplete="new-password"
                change={handleConfirmPasswordChange}
              />
              {message.success && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="bg-green-200 text-green-500 p-4 rounded mt-4"
                >
                  {message.success}
                </motion.div>
              )}
              {message.failed && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="bg-red-200 text-red-500 p-4 rounded mt-4"
                >
                  {message.failed}
                </motion.div>
              )}
              <OrangeButton
                type="submit"
                title="Reset Password"
                isLoading={isLoading}
              />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordPageDesktop;
