import { BsArrowRight, BsDownload } from "react-icons/bs";

export const MarketingKit = () => {
  return (
    <div style={{ color: "#E9C258" }} className="blur px-4">
      <p className="my-8">Testimoni Screenshot</p>
      <div className="flex w-full justify-around space-x-8">
        <div
          className="w-1/2 h-48 rounded-lg"
          style={{ backgroundColor: "#D9D9D9" }}
        />
        <div className="w-1/2">
          <p>
            Kumpulan testimoni dari real customer bisnison, bisa kamu gunakan
            untuk promo.
          </p>
          <div
            className="rounded-lg text-black px-4 py-2 mt-8"
            style={{
              backgroundImage:
                "linear-gradient(180deg, #FFDA75, #FFDE84, #E9C258)",
            }}
          >
            <div className="flex place-items-center justify-center">
              <BsDownload className="mr-2" /> Download
            </div>
          </div>
        </div>
      </div>
      <p className="my-8">Template Desain Promo</p>
      <div className="flex w-full justify-around space-x-8">
        <div
          className="w-1/2 h-48 rounded-lg"
          style={{ backgroundColor: "#D9D9D9" }}
        />
        <div className="w-1/2">
          <p>
            Kumpulan tamplate desain promo hari-hari besar, bisa kamu gunakan
            untuk promo.
          </p>
          <div
            className="rounded-lg text-black px-4 py-2 mt-8"
            style={{
              backgroundImage:
                "linear-gradient(180deg, #FFDA75, #FFDE84, #E9C258)",
            }}
          >
            <div className="flex place-items-center justify-center space-x-2">
              See in canva <BsArrowRight className="ml-2" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
