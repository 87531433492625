import { NewWallet } from "../mobile/NewWallet";

const Header = ({ label }) => {
  return (
    <div className="flex justify-between pb-4 px-4 place-items-center">
      <p className="text-2xl font-bold">{label}</p>
      <div className="w-1/3">
        <NewWallet />
      </div>
    </div>
  );
};

export default Header;
