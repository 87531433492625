import { useState } from "react";
import { useStateContext } from "../../../contexts/ContextProvider";
import { putOrderService } from "../../../services/service";
import { motion } from "framer-motion";
import { OrangeButton } from "../../mobile/OrangeButton";
import { useTranslation } from "react-i18next";

const MassOrderDesktop = () => {
  const { t } = useTranslation();
  const { valueMassOrder, setValueMassOrder } = useStateContext();
  const [isLoading, setIsLoading] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [orderFailed, setOrderFailed] = useState([]);
  const [orderSuccess, setOrderSuccess] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const massOrder = valueMassOrder.replace(/ /g, "").trim();
    const orders = massOrder.split(/\r?\n/);
    let orderPromises = [];
    orders.forEach((order) => {
      const [serviceId, link, quantity] = order.split("|"),
        data = {
          service: serviceId,
          link: link,
          quantity: quantity,
        };
      orderPromises.push(
        putOrderService(data)
          .then((response) => {
            console.log(response.data);
            if (response.data.success) {
              setOrderSuccess((prevState) => [
                ...prevState,
                `${data.service} - ${t("orderSuccess")}`,
              ]);
            } else {
              setOrderFailed((prevState) => [
                ...prevState,
                `${data.service} - ${response.data.error}`,
              ]);
            }
            setShowPopUp(true);
          })
          .catch((error) => {
            setOrderFailed((prevState) => [
              ...prevState,
              `${data.service} - Error!`,
            ]);
            setShowPopUp(true);
          })
      );
    });
    Promise.allSettled(orderPromises).then((response) => {
      setShowPopUp(true);
      setIsLoading(false);
      setTimeout(() => {
        setShowPopUp(false);
        setOrderFailed([]);
        setOrderSuccess([]);
      }, orders.length * 3000);
    });
  };

  const handleChangeTextArea = (e) => {
    setValueMassOrder(e.target.value);
  };

  return (
    <div className="rounded-xl bg-white shadow-lg p-4">
      {showPopUp && (
        <div className="">
          {orderSuccess.map((item, index) => {
            return (
              <motion.div
                key={index}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="w-full sm:w-128 bg-green-200 text-green-500 p-4 mb-1"
              >
                {item}
              </motion.div>
            );
          })}
          {orderFailed.map((item, index) => {
            return (
              <motion.div
                key={index}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="w-full sm:w-128 bg-red-200 text-red-500 p-4  mb-1"
              >
                {item}
              </motion.div>
            );
          })}
        </div>
      )}
      <form onSubmit={handleSubmit} className="w-full  text-sm">
        <textarea
          rows="25"
          // ref={orderRef}
          onChange={handleChangeTextArea}
          value={valueMassOrder}
          className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "
          placeholder={t("massOrderPlaceholder")}
        ></textarea>

        <OrangeButton
          type="submit"
          title={t("buyNow")}
          isLoading={isLoading}
          // disabled={true}
        />
      </form>
    </div>
  );
};

export default MassOrderDesktop;
