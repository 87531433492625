import { useEffect, useState } from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import {
  getHistoryOrderSMM,
  getPricelistView,
  postPricelistView,
} from "../../services/service";
import { Loading } from "../../components/mobile/Loading";
import { HistorySMMList } from "../../components/mobile/HistoryOrder/HistorySMMList";
import HistorySMMTable from "../../components/mobile/HistoryOrder/HistorySMMTabel";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

export const HistoryPage = () => {
  const { t } = useTranslation();
  const histories = useRef();
  const historyView = localStorage.getItem("history_view");
  const { setHistoriesOrderSMM } = useStateContext();
  const [isSearch, setIsSearch] = useState("");
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [listPages, setListPages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [entryPerPage, setEntryPerPage] = useState(0);
  const [historyViewType, setHistoryViewType] = useState("");

  const listFilter = [
    {
      label: t("completedLabel"),
      id: "Completed",
    },
    {
      label: t("pendingLabel"),
      id: "Pending",
    },
    {
      label: t("canceledLabel"),
      id: "Canceled",
    },
    {
      label: t("partialLabel"),
      id: "Partial",
    },
    {
      label: t("inProgressLabel"),
      id: "In Progress",
    },
  ];
  const handleSetView = () => {
    if (historyViewType === "table") {
      localStorage.setItem("history_view", "list");
      setHistoryViewType("list");
      postPricelistView("list")
        .then((responsePost) => {
          setHistoryViewType("list");
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (historyViewType === "list") {
      localStorage.setItem("history_view", "table");
      setHistoryViewType("table");
      postPricelistView("table")
        .then((responsePost) => {
          setHistoryViewType("table");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleSelectFilter = (e) => {
    setSelectedFilter(e);
    const history = histories.current;
    const result = history.filter((item) =>
      item.status.toLowerCase().includes(e.toLowerCase())
    );
    setHistoriesOrderSMM(result);
    setIsFilter(false);
  };

  const changePage = (selectedPage) => {
    setListPages([]);
    setLoading(true);
    getHistoryOrderSMM({ id: selectedPage })
      .then((response) => {
        setHistoriesOrderSMM(response.data.data);
        setPage(response.data.meta.current_page);
        for (let index = 0; index < response.data.meta.last_page; index++) {
          if (index + 1 !== response.data.meta.current_page) {
            setListPages((prevState) => [...prevState, index + 1]);
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (!historyView) {
      setHistoryViewType("table");
    } else {
      setHistoryViewType(historyView);
    }

    getPricelistView()
      .then((response) => {
        if (!response.data.data) {
          postPricelistView("table")
            .then((responsePost) => {
              console.log("Get Data");
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          setHistoryViewType(response.data.data.view);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    getHistoryOrderSMM()
      .then((response) => {
        setListPages([]);
        histories.current = response.data.data.data;
        setPage(response.data.data.current_page);
        setHistoriesOrderSMM(response.data.data.data);
        setEntryPerPage(response.data.data.per_page);
        setTotalPage(response.data.data.last_page);
        for (let index = 0; index < response.data.data.last_page; index++) {
          if (index + 1 !== response.data.data.current_page) {
            setListPages((prevState) => [...prevState, index + 1]);
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className=" w-screen sm:w-128 mx-auto h-full text-center shadow bg-white pt-4">
      {isFilter && (
        <div className="w-full z-10 sm:w-128 fixed bottom-0 bg-white shadow-lg rounded-t-2xl p-4 ">
          <div className="w-full sm:w-128 flex flex-wrap gap-4">
            {listFilter.map((item) => {
              return (
                <div
                  onClick={() => handleSelectFilter(item.id)}
                  className={`border px-3 py-1 rounded-full text-sm ${
                    selectedFilter === item.id
                      ? "border-orange-400 text-orange-400"
                      : "border-gray-400 text-gray-400"
                  }`}
                >
                  {item.label}
                </div>
              );
            })}
          </div>
          <div className="h-24"></div>
        </div>
      )}
      <div
        className={`text-black w-full flex place-items-center justify-between px-2 mb-4 bg-white  ${
          isFilter && "blur"
        }`}
      >
        <div className="flex place-items-center space-x-2 ">
          <div className="rounded-lg p-2 bg-gray-200 ">
            <select
              id="page"
              className="bg-transparent border-0 p-0"
              onChange={(e) => changePage(e.target.value)}
            >
              <option defaultValue={page}>{page}</option>
              {listPages.map((item) => {
                return (
                  <option key={item} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="flex space-x-2 place-items-center">
            <div
              className="w-10 h-10 rounded-lg p-2 bg-gray-200 "
              onClick={() => setIsSearchOpen(!isSearchOpen)}
            >
              <img src="/assets/search.svg" alt="search icon" className="w-6" />
            </div>
            {isSearchOpen && (
              <div className="">
                <input
                  className="rounded-lg h-10 bg-gray-200 px-2"
                  type="text"
                  onChange={(e) => setIsSearch(e.target.value)}
                  placeholder={t("searchTarget")}
                />
              </div>
            )}
          </div>
        </div>
        <div className="flex space-x-2 place-items-center">
          <div
            className="w-10 h-10 rounded-lg p-2 bg-gray-200 "
            onClick={() => setIsFilter(!isFilter)}
          >
            <img src="/assets/filter.svg" alt="filter icon" className="w-6" />
          </div>

          <div className="w-10 h-10 rounded-lg p-2 bg-gray-200 flex justify-center">
            <div
              onClick={handleSetView}
              className="w-max cursor-pointer"
              style={{ color: "#292D32" }}
            >
              {historyViewType === "table" ? (
                <img
                  alt="table icon"
                  src="https://img.icons8.com/ios-glyphs/60/null/overview-pages-3--v1.png"
                />
              ) : (
                <img
                  alt="list icon "
                  src="https://img.icons8.com/fluency-systems-regular/48/null/table-1.png"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        onClick={() => setIsFilter(false)}
        className={`${isFilter && "blur"}`}
      >
        {historyViewType === "table" ? (
          <HistorySMMTable
            isSearch={isSearch}
            currentPage={page}
            entryPerPage={entryPerPage}
            totalPage={totalPage}
          />
        ) : (
          <HistorySMMList
            isSearch={isSearch}
            // meta={meta}
            entryPerPage={entryPerPage}
            totalPage={totalPage}
          />
        )}
      </div>
    </div>
  );
};
