import React, { useEffect, useState } from "react";
import { useStateContext } from "../../../contexts/ContextProvider";
import { getSpecialPrice } from "../../../services/service";
import { MarketingKit } from "./MarketingKit";
import { MyLevel } from "./MyLevel";
import { MySpecialPrice } from "./MySpecialPrice";

export const PremiumBenefitsContent = (props) => {
  const { selectedTabPremiumBenefits } = useStateContext();

  const [specialPrice, setSpecialPrice] = useState([]);

  useEffect(() => {
    getSpecialPrice()
      .then((response) => {
        setSpecialPrice(response.data.data);
      })
      .catch((error) => console.log(error));
  }, []);
  switch (selectedTabPremiumBenefits) {
    case "My Level":
      return <MyLevel />;
    case "My Special Price":
      return <MySpecialPrice specialPrice={specialPrice} />;
    case "Marketing Kit":
      return <MarketingKit />;
    default:
      return null;
  }
};
