import React from "react";
import { Link } from "react-router-dom";

const arrayz = [
  {
    img: "/promoimages.jpg",
  },
  {
    img: "/promoimages.jpg",
  },
  {
    img: "/promoimages.jpg",
  },
  {
    img: "/promoimages.jpg",
  },
];
export const PromoPage = () => {
  return (
    <div className="p-4 w-screen sm:w-128 mx-auto shadow bg-white">
      <Link to="/promo/detail-promo">
        {arrayz.map((service, index) => (
          <img key={index} src={service.img} alt="" className="mb-3" />
        ))}
      </Link>
    </div>
  );
};
