import { useEffect, useState } from "react";
import { BsMegaphoneFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../contexts/ContextProvider";
import { getProductNotification } from "../../services/service";
import { useTranslation } from "react-i18next";

export const RunningText = () => {
  const { t } = useTranslation();
  const { setSelectedTabNotification } = useStateContext();
  const navigate = useNavigate();
  const [randomNumber, setRandomNumber] = useState(0);
  const [productUpdate, setProductUpdate] = useState([]);
  const [status, setStatus] = useState({
    className: "bg-white text-white",
    status: "",
    image: "",
  });

  function randomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  const handleNavigation = () => {
    setSelectedTabNotification(t("updates"));
    navigate("/notification");
  };

  const handleUpdateCondition = (data) => {
    if (data.status_old === 1 && data.status_new === 1) {
      if (data.rate_old > data.rate_new) {
        setStatus({
          status: `Rp ${data.rate_old.toLocaleString(
            "id"
          )} -> Rp ${data.rate_new.toLocaleString("id")}`,
          className: "bg-green-100 text-green-500 ",
          image: "decreased",
        });
      } else if (data.rate_old < data.rate_new) {
        setStatus({
          status: `Rp ${data.rate_old.toLocaleString(
            "id"
          )} -> Rp ${data.rate_new.toLocaleString("id")}`,
          className: "bg-yellow-100 text-yellow-500 ",
          image: "increased",
        });
      }
    } else if (data.status_new === 0) {
      setStatus({
        status: "Disabled",
        className: "bg-red-100 text-red-500",
      });
    } else if (data.status_new === 1) {
      setStatus({
        status: `Enabled Rp ${data.rate_new.toLocaleString("id")}`,
        className: "bg-purple-100 text-purple-500",
      });
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const rand = randomInteger(0, 10);
      if (productUpdate.length > 0) {
        setRandomNumber(rand);
        handleUpdateCondition(productUpdate[rand]);
      }
    }, 1500);
    return () => clearInterval(interval);
  }, [productUpdate]);

  useEffect(() => {
    getProductNotification()
      .then((response) => {
        setProductUpdate(response.data.data);
        handleUpdateCondition(response.data.data[0]);
      })
      .catch((error) => console.log(error));
  }, []);
  return (
    <div
      onClick={handleNavigation}
      className={`w-screen sm:w-128 flex items-center justify-between p-1 cursor-pointer ${status.className}`}
    >
      <div className="flex w-2/4">
        <BsMegaphoneFill size={15} className=" mr-1" />
        <p className="truncate font-semibold text-center text-xs">
          [{productUpdate[randomNumber]?.product_id}] {" - "}
          {productUpdate[randomNumber]?.server}
        </p>
      </div>
      <div className="flex justify-center ">
        {status.image === "increased" && (
          <div className="h-1" style={{ marginTop: 3 }}>
            <img
              src="/assets/price-increased.png"
              alt="price-increased"
              width={13}
              className="mr-1"
            />
          </div>
        )}
        {status.image === "decreased" && (
          <div className="h-1" style={{ marginTop: 3 }}>
            <img
              src="/assets/price-decreased.png"
              alt="price-decreased"
              width={13}
              className="mr-1"
            />
          </div>
        )}
        <p className="text-xs">{status.status}</p>
      </div>
    </div>
  );
};
