import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../../contexts/ContextProvider";
import {
  getBonusTopUp,
  getCategoryBank,
  getTopUpPending,
  postReqTopUp,
  putCancelTopUp,
} from "../../../services/service";
import { Input } from "../../mobile/Input";
import { OrangeButton } from "../../mobile/OrangeButton";
import { useTranslation } from "react-i18next";

const TopUpForm = () => {
  const { t } = useTranslation();
  const {
    user,
    categoriesBank,
    setCategoriesBank,
    topUpPending,
    setBonusTopUp,
    topUpBody,
    setTopUpBody,
    listBank,
    bankById,
    bonusTopUp,
    setTopUpPending,
  } = useStateContext();
  const [select, setSelect] = useState({
    saldo_type: "",
    categoryId: "",
    bankId: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [nominalYangDidapat, setNominalYangDidapat] = useState(0);
  const [isPulsa, setIsPulsa] = useState(false);
  const [minTopUp, setMinTopUp] = useState(0);
  const [labelValue, setLabelValue] = useState();
  const [phoneValue, setPhoneValue] = useState();
  const [message, setMessage] = useState({ failed: "" });
  const [exchangeRate, setExchangeRate] = useState("");
  const [bonusBank, setBonusBank] = useState(0);
  const [isUsd, setIsUsd] = useState(false);

  const navigate = useNavigate();

  const handleSelectBank = (id, nama) => {
    setBonusTopUp([]);
    setIsPulsa(false);
    setTopUpBody((prevState) => ({ ...prevState, bankId: id, saldo: "" }));
    setSelect((prevState) => ({ ...prevState, bankId: nama }));
    getBonusTopUp(id)
      .then((response) => {
        setBonusBank(response.data.data.bank);
        setBonusTopUp(response.data.data.topup_config);
      })
      .catch((error) => console.log(error));
    if (id === 21 || id === 27) {
      setIsPulsa(true);
    } else {
      setIsPulsa(false);
    }
  };

  const handleChangeSaldo = (e) => {
    // const addCommas = (num) =>
    //   num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    // const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");
    // setLabelValue(addCommas(removeNonNumeric(e.target.value)));
    // setTopUpBody((prevState) => ({
    //   ...prevState,
    //   saldo: e.target.value.replaceAll(".", ""),
    // }));
    setTopUpBody((prevState) => ({
      ...prevState,
      saldo: e.target.value,
    }));
  };

  const handleChangePhone = (e) => {
    setPhoneValue(e.target.value);
    setTopUpBody((prevState) => ({
      ...prevState,
      noHp: e.target.value,
    }));
  };

  const handleCancelTopUp = (e) => {
    putCancelTopUp(e)
      .then((response) => {
        response.data.status === "success" && navigate("/", { replace: true });
        localStorage.removeItem("midtrans_token");
      })
      .catch((error) => {
        if (
          error.response.data.message ===
          "Topup status has been cancelled, no changes have been made"
        ) {
          navigate("/", { replace: true });
        }
        console.log(error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (topUpBody.saldo >= minTopUp) {
      setIsLoading(true);
      postReqTopUp(topUpBody)
        .then((response) => {
          setIsLoading(false);
          if (response.data.token) {
            localStorage.setItem("midtrans_token", response.data.token);
          }
          if (response.data.success) {
            const myMidtransClientKey = "Mid-client-dGUkpvAawY8Srn1T"; //change this according to your client-key
            const snapSrcUrl = "https://app.midtrans.com/snap/snap.js";
            const midtrans_token = localStorage.getItem("midtrans_token");

            const script = document.createElement("script");
            script.src = snapSrcUrl;
            script.setAttribute("data-client-key", myMidtransClientKey);
            script.async = true;

            document.body.appendChild(script);

            getTopUpPending()
              .then((response) => {
                console.log(response.data.data);
                if (response.data.data) {
                  if (
                    response.data.data?.bank.payment_gateway === "midtrans" &&
                    response.data.data.status === "Pending" &&
                    midtrans_token
                  ) {
                    window.snap.pay(midtrans_token, {
                      gopayMode: "qr",
                      onSuccess: function (result) {
                        localStorage.removeItem("midtrans_token");
                      },
                      onPending: function (result) {
                        /* You may add your own implementation here */
                        alert("waiting your payment!");
                      },
                      onError: function (result) {
                        /* You may add your own implementation here */
                        handleCancelTopUp(topUpPending?.id);
                        alert("payment failed!");
                      },
                      onClose: function (result) {
                        alert("payment closed!");
                      },
                    });
                  }
                }
                setTopUpPending(response.data.data);
              })
              .catch((error) => console.log(error));
          } else {
            setMessage({ failed: response.data.message });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
          setMessage({ failed: "Connection Error" });
        });
    } else {
      setMessage({
        failed: `Minimum Top Up ${
          user.currency === "IDR" ? "Rp" : "$"
        } ${minTopUp.toLocaleString("id")}`,
      });
    }
  };

  const handleSelectCategoryBank = (id, nama) => {
    setBonusTopUp([]);
    setIsPulsa(false);

    setTopUpBody((prevState) => ({ ...prevState, categoryId: id, bankId: "" }));
    setSelect((prevState) => ({ ...prevState, categoryId: nama, bankId: "" }));
  };

  const handleBankImage = (bank) => {
    const bankToLower = bank.toLowerCase();
    if (bankToLower.includes("qris")) {
      return "assets/banks/qris.png";
    } else if (bankToLower.includes("bca")) {
      return "assets/banks/bca.png";
    } else if (bankToLower.includes("bni")) {
      return "assets/banks/bni.png";
    } else if (bankToLower.includes("akulaku")) {
      return "assets/banks/akulaku.png";
    } else if (bankToLower.includes("bri")) {
      return "assets/banks/bri.png";
    } else if (bankToLower.includes("permata")) {
      return "assets/banks/permata.png";
    } else if (bankToLower.includes("mandiri")) {
      return "assets/banks/mandiri.png";
    } else if (bankToLower.includes("xl")) {
      return "assets/banks/xl.png";
    } else if (bankToLower.includes("telkomsel")) {
      return "assets/banks/telkomsel.png";
    } else if (bankToLower.includes("perfect")) {
      return "assets/banks/pm.png";
    }
  };

  useEffect(() => {
    let saldo = 0;
    if (topUpBody.saldo) {
      saldo = Number(topUpBody.saldo.replace(".", ""));
    }
    bonusTopUp.forEach((item) => {
      if (topUpBody.saldo >= item.top_up) {
        if (bonusBank >= item.bonus) {
          setNominalYangDidapat(
            user.currency === "IDR"
              ? Math.floor(
                  Number(saldo * exchangeRate) * (bonusBank / 100) +
                    Number(saldo * exchangeRate)
                )
              : Number(saldo * exchangeRate) * (bonusBank / 100) +
                  Number(saldo * exchangeRate)
          );
        } else {
          setNominalYangDidapat(
            user.currency === "IDR"
              ? Math.floor(
                  Number(saldo * exchangeRate) * (item.bonus / 100) +
                    Number(saldo * exchangeRate)
                )
              : Number(saldo * exchangeRate) * (item.bonus / 100) +
                  Number(saldo * exchangeRate)
          );
        }
      }
    });
  }, [topUpBody.saldo]);

  useEffect(() => {
    listBank.forEach((bank) => {
      if (bank.id === topUpBody.bankId) {
        setExchangeRate(bank.exchange_rate);
        setMinTopUp(bank.min_topup);
        if (bank.is_usd) {
          setIsUsd(true);
        } else {
          setIsUsd(false);
        }
      }
    });
  }, [topUpBody.bankId]);

  useEffect(() => {
    setBonusTopUp([]);
    setTopUpBody({ saldo_type: 1 });
    setSelect({ saldo_type: "Biscoin" });
    getCategoryBank()
      .then((response) => {
        response.data?.id === 3
          ? setCategoriesBank([
              {
                value: response.data.id,
                label: response.data.category,
              },
            ])
          : setCategoriesBank(
              response.data.map((c) => {
                return (
                  c.category !== "Disable" &&
                  c.category !== "Manual" && {
                    value: c.id,
                    label: c.category,
                  }
                );
              })
            );
      })
      .catch((error) => console.log(error));
    if (topUpPending) {
      topUpPending.status === "Pending" &&
        navigate("/topup/confirm", { replace: true });
    }
  }, []);
  return (
    <form onSubmit={handleSubmit} className="">
      <div className="bg-white shadow-lg rounded-lg p-4 h-max">
        <p className="font-semibold mb-3">{t("paymentMethod")}</p>
        <div className="flex flex-wrap gap-2">
          {categoriesBank.map((bank) => {
            return (
              <div
                key={bank.value}
                onClick={() => handleSelectCategoryBank(bank.value, bank.label)}
                className={`${
                  select.categoryId === bank.label && "bg-orange-400 text-white"
                } px-3 py-1 rounded-full border cursor-pointer hover:border-orange-400  `}
              >
                <span className="text-xs">{bank.label}</span>
              </div>
            );
          })}
        </div>
        <div className="mt-4 grid grid-cols-3 gap-4">
          {select.categoryId &&
            bankById.map((bank) => {
              return (
                <div
                  key={bank.value}
                  onClick={() => handleSelectBank(bank.value, bank.label)}
                  className={`${
                    select.bankId === bank.label && "bg-orange-400 text-white"
                  }  border rounded-2xl font-semibold text-xs cursor-pointer`}
                >
                  <div className="bg-gray-50 p-4 border rounded-t-2xl flex justify-center h-20">
                    <img src={handleBankImage(bank.label)} alt="bank" />
                  </div>
                  <div className="p-4">{bank.label}</div>
                </div>
              );
            })}
        </div>

        <div className="mt-2">
          <Input
            label={t("minimumTopUp")}
            type="number"
            disabled={true}
            placeholder={`${isUsd ? "$" : "Rp"} ${minTopUp.toLocaleString(
              "id"
            )}`}
          />
        </div>
        {isPulsa && (
          <div className="mt-2">
            <Input
              label={t("mobileNumber")}
              value={phoneValue}
              change={handleChangePhone}
            />
          </div>
        )}
        <div className="grid grid-cols-2 space-x-4">
          <div className="mt-2">
            <Input
              label={t("nominalTopUp")}
              value={labelValue}
              change={handleChangeSaldo}
              disabled={select.bankId ? false : true}
            />
          </div>
          <div className="mt-2">
            <Input
              label={t("receivedNominal")}
              type="number"
              disabled={true}
              placeholder={`${
                user.currency === "IDR" ? "Rp" : "$"
              } ${nominalYangDidapat.toLocaleString("id")}`}
            />
          </div>
        </div>

        {message.failed && (
          <div className="text-red-400 bg-red-200 rounded p-4 mt-2">
            {message.failed}
          </div>
        )}
        <OrangeButton type="submit" title={t("submit")} isLoading={isLoading} />
      </div>
    </form>
  );
};

export default TopUpForm;
