import React from "react";
import { useTranslation } from "react-i18next";

export const PremiumBenefit = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full">
      <div className="mb-4 text-center">
        <p className="font-medium text-lg pt-1">{t("benefitPremiumMember")}</p>
      </div>
      <div className="w-64 mx-auto">
        <div className="flex flex-row w-full my-4">
          <img src="/assets/puzzle.svg" alt="" className="mr-3" />
          <p>{t("allFeaturesInRegular")}</p>
        </div>
        <div className="flex flex-row w-full my-4">
          <img src="/assets/point.svg" alt="" className="mr-3" />
          <p>{t("pointAffiliate")}</p>
        </div>
        <div className="flex flex-row w-full my-4">
          <img src="/assets/marketing.svg" alt="" className="mr-3" />
          <p>{t("marketingKit")}</p>
        </div>
        <div className="flex flex-row w-full my-4">
          <img src="/assets/ebook.svg" alt="" className="mr-3" />
          <p>{t("eBookGuide")}</p>
        </div>
        <div className="flex flex-row w-full my-4">
          <img src="/assets/deadline.svg" alt="" className="mr-3" />
          <p>{t("lifetimeMember")}</p>
        </div>
        <div className="flex flex-row w-full my-4">
          <img src="/assets/customize.svg" alt="" className="mr-3" />
          <p>{t("customizeAffiliate")}</p>
        </div>
        <div className="flex flex-row w-full my-4">
          <img src="/assets/credit.svg" alt="" className="mr-3" />
          <p>{t("vipPremiumMemberCard")}</p>
        </div>
      </div>
    </div>
  );
};
