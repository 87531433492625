import React from "react";
import { useState } from "react";

export const ExpandColumn = (props) => {
  const [isCopied, setIsCopied] = useState(false);
  const handleCopyText = () => {
    window.navigator.clipboard.writeText(props.value).then(
      () => {
        setIsCopied(true);
      },
      () => {
        alert("Failed!");
        setIsCopied(false);
      }
    );
  };
  return (
    <div className="flex flex-row w-full my-2">
      <span className="w-4/12 text-start px-2">{props.title}</span>
      <div className="flex flex-row w-8/12 justify-start text-start">
        {props.discount && (
          <span className="text-ellipsis no-wrap overflow-hidden line-through italic text-gray-200 mr-2">
            {props.discount}
          </span>
        )}
        <span className="text-ellipsis no-wrap overflow-hidden mr-2">
          {" "}
          {props.value}
        </span>
        <div className="flex">
          <img
            src={props.icons}
            className="mr-2"
            alt=""
            onClick={handleCopyText}
          />
          {isCopied && <span>Copied!</span>}
        </div>

        {props.button && (
          <button className="rounded-xl bg-white text-orange-400 py-2 px-4">
            {props.button}
          </button>
        )}
      </div>
    </div>
  );
};
