export const Tab = (props) => (
  <div
    className="flex rounded-full w-full justify-between place-items-center p-2"
    style={{ backgroundColor: "rgba(255, 255, 255, 0.04)" }}
  >
    {props.items.map((item) => (
      <div
        className=" rounded-full text-sm px-2 sm:text-base sm:px-4 py-3 cursor-pointer"
        style={{
          backgroundColor:
            props.selectedTab === item ? "#E9C258" : "transparent",
          color: props.selectedTab === item ? "#000" : "#E9C258",
        }}
        onClick={() => props.onChange(item)}
        key={JSON.stringify(item)}
      >
        {item}
      </div>
    ))}
  </div>
);
