import React, { useEffect, useState } from "react";
import { ExpandHistoryOrder } from "./ExpandHistoryOrder";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { useStateContext } from "../../../contexts/ContextProvider";

export const HistorySMMList = (props) => {
  const { historiesOrderSMM } = useStateContext();
  const [isOpen, setIsOpen] = useState(true);
  const [list, setList] = useState([]);

  function toggleExpander(idService) {
    if (isOpen !== idService) {
      setIsOpen(idService);
    } else {
      setIsOpen(undefined);
    }
  }

  useEffect(() => {
    const result = historiesOrderSMM.filter((items) =>
      items["data"]
        ?.toString()
        .toLowerCase()
        .includes(props.isSearch.toLowerCase())
    );
    setList(props.isSearch ? result : historiesOrderSMM);
  }, [props.isSearch, historiesOrderSMM]);

  // useEffect(() => {
  //   setData(openFilter.historySMM ? isFilters.historySMM : historiesOrderSMM);
  // }, [historiesOrderSMM, isFilters.historySMM, openFilter.historySMM]);

  return (
    <div className="w-full">
      {/* <div className="flex flex-row mb-2 justify-between">
        <FilterButton
          data={historiesOrderSMM}
          text={filter}
          target="status"
          type="historySMM"
        />
        <SearchTable
          data={data}
          searchWord="id"
          searchText="Search By Order ID"
        />
      </div> */}
      <table className="w-full table-fixed">
        <thead className=" w-full relative border border-y-1">
          <tr className="">
            <th className="py-3 font-medium">Order ID</th>
            <th className="py-3 font-medium">Target</th>
            <th className="py-3 font-medium">Status</th>
          </tr>
        </thead>

        {list.map((history, index) => {
          let colorCondition = "text-green-500";
          if (history.status === "Partial") {
            colorCondition = "text-orange-500";
          } else if (history.status === "Completed") {
            colorCondition = "text-green-500";
          } else {
            colorCondition = "text-red-500";
          }
          return (
            <tbody key={history?.id} className="w-full text-xs">
              <tr
                width={20}
                className="py-4 cursor-pointer"
                onClick={() => toggleExpander(index)}
              >
                <td className="py-3 flex flex-row text-center justify-center">
                  {isOpen !== index ? (
                    <FiChevronDown className="mr-2" />
                  ) : (
                    <FiChevronUp className="mr-2" />
                  )}
                  {history.id}
                </td>
                <td width={40} className="py-3 text-left truncate">
                  {history.data}
                </td>
                <td width={40} className={colorCondition}>
                  {history.status}
                </td>
              </tr>
              <tr>
                <td colSpan={3}>
                  {isOpen === index && (
                    <ExpandHistoryOrder
                      id={history.id}
                      tanggal={history.tanggal}
                      server={history.product.server}
                      data={history.data}
                      jumlah={history.jumlah}
                      remains={history.remains}
                      harga={history.harga}
                      sisa_saldo={history.sisa_saldo}
                      komen={history.komen}
                      status={history.status}
                    />
                  )}
                </td>
              </tr>
            </tbody>
          );
        })}
      </table>
      {/* <div className="mt-6">
        <div className="flex flex-col items-center">
          <span className="text-sm text-gray-700 ">
            Showing{" "}
            <span className="font-semibold text-gray-900 ">{currentPage}</span>{" "}
            to{" "}
            <span className="font-semibold text-gray-900 ">
              {props.totalPage}
            </span>{" "}
            of{" "}
            <span className="font-semibold text-gray-900 ">
              {props.entryPerPage}
            </span>{" "}
            Entries
          </span>
          <div className="inline-flex mt-2 xs:mt-0">
            {currentPage > 1 && (
              <button
                onClick={prevPage}
                className="inline-flex items-center px-4 py-2 text-sm font-medium text-black rounded-l"
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                Prev
              </button>
            )}
            {currentPage < props.totalPage && (
              <button
                onClick={nextPage}
                className="inline-flex items-center px-4 py-2 text-sm font-medium text-black rounded-r"
              >
                Next
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 ml-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            )}
          </div>
          <div className="h-9"></div>
        </div>
      </div> */}
      <div className="h-20"></div>
    </div>
  );
};
