import { useEffect, useState } from "react";
import { BsPersonFill } from "react-icons/bs";
import { Input } from "../../components/mobile/Input";
import { OrangeButton } from "../../components/mobile/OrangeButton";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import {
  getUser,
  postCheckOTPVerifikasiWA,
  postSendOTPVerifikasiWA,
  putChangeCurrency,
} from "../../services/service";
import { SelectInput } from "../../components/mobile/SelectInput";
import { WarningPopUp } from "../../components/mobile/WarningPopUp";
import { ImSpinner8 } from "react-icons/im";
import OTPInput from "../../components/mobile/OTPPopUp";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

export const MyProfilePage = () => {
  const { t } = useTranslation();

  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [countDown, setCountDown] = useState(localStorage.getItem("otpTimer"));
  const [isLoading, setIsLoading] = useState({
    apply: false,
    sendOtp: false,
    confirmOtp: false,
  });
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [showPopUp, setShowPopUp] = useState(false);
  const [messageResponse, setMessageResponse] = useState({
    success: "",
    failed: "",
  });
  const currency = [
    {
      label: "IDR",
      value: "IDR",
    },
    {
      label: "USD",
      value: "USD",
    },
  ];
  const [isOTPSended, setIsOTPSended] = useState(false);
  const [otp, setOtp] = useState();
  const [inCorrect, setInCorrect] = useState(false);
  const [applyButton, setApplyButton] = useState(false);

  const handleSelectItem = (label, value) => {
    if (value !== user.currency) {
      setApplyButton(true);
      setSelectedCurrency(value);
      setShowPopUp(!showPopUp);
    } else {
      setSelectedCurrency(value);
      setApplyButton(false);
    }
  };

  const handleApplyNow = (e) => {
    e.preventDefault();
    setIsLoading((prevState) => ({ ...prevState, apply: true }));
    putChangeCurrency(selectedCurrency)
      .then((response) => {
        window.location.reload();
        setMessageResponse({ success: response.data.status });
        setIsLoading((prevState) => ({ ...prevState, apply: false }));
      })
      .catch((error) => {
        setMessageResponse({ failed: error.response.data.message });
        setIsLoading((prevState) => ({ ...prevState, apply: false }));
      });
  };

  const handleCancelPopUp = () => {
    setShowPopUp(false);
    setApplyButton(false);
    setSelectedCurrency(user.currency);
  };

  const handleSendOtp = () => {
    setIsLoading((prevState) => ({ ...prevState, sendOtp: true }));
    setInCorrect(false);
    postSendOTPVerifikasiWA(user.hp)
      .then((response) => {
        if (response.status === 200) {
          setIsOTPSended(true);
          localStorage.setItem("otpTimer", 120);
          setCountDown(localStorage.getItem("otpTimer"));
        } else {
          setMessageResponse((prevState) => ({
            ...prevState,
            failed: response.data.message,
          }));
        }
        setIsLoading((prevState) => ({ ...prevState, sendOtp: false }));
      })
      .catch((error) => {
        console.log(error);
        setMessageResponse((prevState) => ({
          ...prevState,
          failed: "Error: " + error.response.status,
        }));
        setIsLoading((prevState) => ({ ...prevState, sendOtp: false }));
      });
  };

  const handleCheckOtp = (e) => {
    e.preventDefault();
    setIsLoading((prevState) => ({ ...prevState, confirmOtp: true }));
    postCheckOTPVerifikasiWA(user.hp, otp)
      .then((response) => {
        setIsOTPSended(false);
        setIsLoading((prevState) => ({ ...prevState, confirmOtp: false }));
      })
      .catch((error) => {
        console.log(error);
        setInCorrect(true);
        setIsLoading((prevState) => ({ ...prevState, confirmOtp: false }));
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (countDown > 0) {
        setCountDown(countDown - 1);
        localStorage.setItem("otpTimer", countDown - 1);
      } else {
        localStorage.setItem("otpTimer", 0);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countDown]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getUser()
      .then((response) => {
        setUser(response.data);
        localStorage.setItem("user", JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className=" w-screen sm:w-128 mx-auto min-h-screen flex flex-col justify-center shadow bg-white">
      <div className="p-4">
        <div className="flex place-items-center rounded-full h-48 w-48 mx-auto bg-gray-200">
          <BsPersonFill className="text-gray-600 mx-auto" size={120} />
        </div>
        <Input label={t("username")} disabled={true} value={user?.nama} />
        <Input label={t("email")} disabled={true} value={user?.email} />
        <Input
          label={t("phoneNumber")}
          disabled={true}
          value={user?.hp}
          icon={
            user.wa_verified_at ? (
              <span className="text-green-500 text-xs mr-3">
                {t("verified")}
              </span>
            ) : countDown > 0 ? (
              <span className="text-orange-500 text-xs mr-3">{countDown}</span>
            ) : isLoading.sendOtp ? (
              <ImSpinner8 className="animate-spin mr-4 text-orange-500 cursor-pointer" />
            ) : (
              <span
                className="text-xs text-orange-500 mr-3 top-2 cursor-pointer"
                onClick={handleSendOtp}
              >
                {t("verification")}
              </span>
            )
          }
          iconLabel={
            user.wa_verified_at ? null : (
              <AiOutlineExclamationCircle
                className="text-orange-500"
                size={20}
              />
            )
          }
        />
        <Input label={t("userType")} disabled={true} value={user?.user_type} />
        <Input
          label="Level Member"
          disabled={true}
          value={user?.user_level.level}
        />
        <form onSubmit={handleApplyNow}>
          <SelectInput
            data={currency}
            placeholder={user?.currency}
            select={selectedCurrency}
            label={t("currency")}
            handleSelectItem={handleSelectItem}
          />
          {messageResponse.failed && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="bg-red-200 text-red-500 p-2 rounded mt-2"
            >
              {messageResponse.failed}
            </motion.div>
          )}
          {messageResponse.success && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="bg-green-200 text-green-500 p-2 rounded mt-2"
            >
              {messageResponse.success}
            </motion.div>
          )}
          {applyButton ? (
            <OrangeButton
              type="submit"
              title="Apply Now"
              isLoading={isLoading.apply}
            />
          ) : (
            <div className="h-16" />
          )}
        </form>
      </div>

      {showPopUp && (
        <WarningPopUp
          continue={() => setShowPopUp(!showPopUp)}
          cancel={handleCancelPopUp}
        />
      )}
      {isOTPSended && (
        <OTPInput
          autoFocus
          isNumberInput
          length={6}
          onChangeOTP={(otp) => setOtp(otp)}
          cancel={() => setIsOTPSended(!isOTPSended)}
          hp={user.hp}
          countDown={countDown}
          sendOtp={handleSendOtp}
          handleSubmit={handleCheckOtp}
          isLoading={isLoading.confirmOtp}
          inCorrect={inCorrect}
          otpLoading={isLoading.sendOtp}
        />
      )}
    </div>
  );
};
