import { BiCopy } from "react-icons/bi";
import { FiDownload } from "react-icons/fi";

export const InvoicePage = (props) => {
  return (
    <div className=" w-screen sm:w-128 mx-auto shadow bg-white">
      <div className="w-full p-4">
        <div className="flex flex-row justify-between text-xl">
          <p>Invoice</p>
          <div className="flex flex-row w-16 justify-between">
            <FiDownload className="bg-blue-bisnison text-white rounded-lg w-6 h-6 p-1" />
            <BiCopy className="text-blue-bisnison  w-6 h-6 p-1" />
          </div>
        </div>
        <div className="flex flex-row justify-between text-xs mt-2">
          <div className="flex flex-col">
            <p className="text-xl">Batman</p>
            <p>ID :241252</p>
          </div>
          <div className="flex flex-col">
            <p>Invoice No #00001</p>
            <p>Date : 12 April 2021</p>
          </div>
        </div>
        <div className="mt-2 flex flex-row justify-between">
          <p>Payment Method :</p>
          <img src="" alt="" />
        </div>
      </div>
      <div className="w-full mt-2 text-center">
        <table className="w-full">
          <thead className="w-full bg-orange-400 text-white text-xs font-medium">
            <tr>
              <th className="p-3">Item</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="py-3">Saldo Bisnison</td>
              <td>Rp.800.000</td>
            </tr>
          </tbody>
        </table>
        <hr />
      </div>
      <div className="w-full p-4">
        <div className="flex flex-row justify-between mt-2">
          <p>Tax :</p>
          <p>Rp. 0</p>
        </div>
        <div className="flex flex-row justify-between mt-2">
          <p>Discount :</p>
          <p>Rp. 0</p>
        </div>
        <div className="flex flex-row justify-between mt-2 font-medium">
          <p>Total :</p>
          <p>Rp.800.000</p>
        </div>
      </div>
    </div>
  );
};
