import { useEffect, useState } from "react";
import {
  getHistorySalesThisWeek,
  getProductNotification,
} from "../../../services/service";
import ArticlesDesktop from "../ArticlesDesktop";
import BannerDesktop from "../BannerDesktop";
import Header from "../Header";
import Marquee from "../Marquee";
import NewsServiceUpdates from "./NewsServiceUpdates";
import SalesGraphic from "./SalesGraphic";
import moment from "moment";
import PromoPopUpDesktop from "./PromoPopUpDesktop";
import { useTranslation } from "react-i18next";

const DashboardDesktop = () => {
  const { t } = useTranslation();
  const [promoShow, setPromoShow] = useState({});
  const [historySalesThisWeek, setHistorySalesThisWeek] = useState([
    {
      data: [],
    },
    {
      data: [],
    },
    {
      data: [],
    },
  ]);
  const [isLoading, setIsLoading] = useState(true);
  const [news, setNews] = useState([]);
  const [filterNews, setFilterNews] = useState([]);

  const handleCheckboxChange = () => {
    localStorage.setItem(
      "promo-show",
      JSON.stringify({
        enable: !promoShow.enable,
        time: moment().add(1, "days").format("DD/MM/YYYY"),
      })
    );
    setPromoShow(JSON.parse(localStorage.getItem("promo-show")));
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setPromoShow(
      JSON.parse(localStorage.getItem("promo-show")) || {
        enable: true,
        time: "",
      }
    );
    Promise.allSettled([
      getProductNotification()
        .then((response) => {
          setNews(response.data.data);
          setFilterNews(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        }),
      getHistorySalesThisWeek()
        .then((response) => {
          let formattedData = [];
          response.data.forEach((dataSales, indexSales) => {
            switch (dataSales.id) {
              case "pending":
                formattedData.push({ id: t("statuses.pending"), data: [] });
                break;
              case "in_progress":
                formattedData.push({
                  id: t("statuses.inProgress"),
                  data: [],
                });
                break;
              case "done":
                formattedData.push({ id: t("statuses.done"), data: [] });
                break;
              default:
            }
            dataSales.data.map((item) =>
              formattedData[indexSales].data.push(item.y)
            );
          });
          setHistorySalesThisWeek(formattedData);
        })
        .catch((error) => {
          console.log(error);
        }),
    ])
      .then((response) => {
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
    if (promoShow.date <= moment().format("DD/MM/YYYY")) {
      localStorage.removeItem("promo-show");
    }
  }, []);
  return (
    <div>
      {promoShow.enable && (
        <PromoPopUpDesktop
          isShowing={promoShow.enable}
          handleCheckboxChange={handleCheckboxChange}
          close={() => setPromoShow({ enable: false, time: "" })}
        />
      )}
      <Header label={t("menu.dashboard")} />
      <Marquee />
      <div className="grid grid-cols-2 p-8 gap-8">
        <div className="  flex flex-col gap-4">
          <div className="bg-white rounded-2xl mb-4">
            <BannerDesktop />
          </div>
          <div className="bg-white shadow-lg rounded-2xl ">
            <SalesGraphic historySalesThisWeek={historySalesThisWeek} />
          </div>
        </div>
        <div
          className="bg-white shadow-lg rounded-2xl overflow-y-auto p-4"
          style={{ height: 568 }}
        >
          <NewsServiceUpdates
            news={news}
            filterNews={filterNews}
            setFilterNews={setFilterNews}
            isLoading={isLoading}
          />
        </div>
      </div>
      <div className="grid grid-cols-3 pb-8 px-8 gap-8">
        <div className="bg-white shadow-lg rounded-2xl h-96 overflow-y-auto">
          <ArticlesDesktop label={t("news")} id={456} />
        </div>
        <div className="bg-white shadow-lg rounded-2xl h-96 overflow-y-auto">
          <ArticlesDesktop label={"Tips"} id={455} />
        </div>
        <div className="bg-white shadow-lg rounded-2xl h-96 overflow-y-auto">
          <ArticlesDesktop label={"BisnisOn"} id={16} />
        </div>
      </div>
    </div>
  );
};

export default DashboardDesktop;
