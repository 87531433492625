import { useEffect } from "react";
import { BiArrowBack } from "react-icons/bi";
import { IoLogOutOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { useStateContext } from "../../contexts/ContextProvider";
import { postLogout } from "../../services/service";
import { useTranslation } from "react-i18next";

export const PremiumUpgradePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useStateContext();

  useEffect(() => {
    if (user.user_type === "PREMIUM") {
      navigate("/premium-benefits");
    } else {
      navigate("/premium-upgrade");
    }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="w-screen sm:w-128 min-h-screen mx-auto shadow">
      <div className="w-screen sm:w-128 mx-auto h-screen fixed">
        <img
          src="/assets/premium-bg.png"
          alt=""
          className="w-screen sm:w-128 mx-auto"
        />
      </div>
      <div className="relative">
        <TopNavbar />
        <Body />
        <BottomNavbar />
      </div>
    </div>
  );
};

const TopNavbar = () => {
  const handleLogout = () => {
    postLogout()
      .then((response) => {
        if (response.data.success) {
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <header className="sticky top-0 z-10 transition-shadow w-screen sm:w-128 mx-auto">
      <nav className="transition-colors">
        <ul className="flex items-center justify-between p-4 ">
          <div className="w-1/4">
            <Link to="/">
              <BiArrowBack size={30} className="text-white" />
            </Link>
          </div>
          <Link to="/">
            <img
              src="/logo-long.png"
              alt="logo-long"
              size={130}
              className="max-w-3/6"
            />
          </Link>
          <div className="w-1/4 flex place-content-end">
            {/* <NotificationIcon /> */}
            <IoLogOutOutline
              className="ml-2 text-white"
              size={30}
              onClick={handleLogout}
            />
          </div>
        </ul>
      </nav>
    </header>
  );
};

const Body = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="h-36" />
      <div
        className="w-screen sm:w-128 min-h-screen mx-auto flex flex-col place-items-center p-2"
        style={{
          backgroundImage: "linear-gradient(180deg, #1E1E1E, #151515)",
          color: "#E9C258",
          borderRadius: 40,
        }}
      >
        <div className="-top-28 relative flex flex-col place-items-center text-xl">
          <img
            src="/assets/astro-crown.png"
            alt="astro crown"
            className="w-28"
          />
          <div
            className="border-2 px-8 py-2 rounded-full text-white font-bold mb-8"
            style={{ borderColor: "#E9C258", backgroundColor: "#363636" }}
          >
            Benefit <span style={{ color: "#E9C258" }}>Premium</span> Plan
          </div>
          {/* <span>Affiliate</span> */}
          <div className="text-base p-4">
            <div className="flex place-items-center my-6">
              <img
                src="/assets/premium/link.png"
                alt="link"
                className="w-10 mr-4"
              />
              <p className="text-lg">{t("customUrlAffiliate")}</p>
              <div />
            </div>
            <div className="flex place-items-center my-6">
              <img
                src="/assets/premium/money.png"
                alt="link"
                className="w-10 mr-4"
              />
              <p className="text-lg">{t("benefitAffiliate")}</p>
              <div />
            </div>
            <p className="ml-14">
              {`${t("wallet.premium")} : `}
              <br />
              <br />
              {t("benefitDescriptions.1")}
              <br />
              <br />

              {t("benefitDescriptions.2")}

              <br />
              <br />
              {t("benefitDescriptions.3")}

              <br />
              <br />
              {t("benefitDescriptions.4")}
            </p>
            <div
              className="w-full mt-6 mb-4"
              style={{ height: 1, backgroundColor: "#FFDA7566" }}
            />
            <div className="flex place-items-center my-6">
              <img
                src="/assets/premium/message-question.png"
                alt="link"
                className="w-10 mr-4"
              />
              <p className="text-lg">#1 {t("customerServicePriority")}</p>
              <div />
            </div>
            <div className="flex place-items-center my-6">
              <img
                src="/assets/premium/whatsapp.png"
                alt="link"
                className="w-10 mr-4"
              />
              <p className="text-lg">{t("joinPremiumGroup")}</p>
              <div />
            </div>
            <div
              className="w-full mt-6 mb-4"
              style={{ height: 1, backgroundColor: "#FFDA7566" }}
            />
            <div className="flex place-items-center my-6">
              <img
                src="/assets/premium/discount-shape.png"
                alt="link"
                className="w-10 mr-4"
              />
              <p className="text-lg">{t("eventPromoDiscount")}</p>
              <div />
            </div>
            <div className="flex place-items-center my-6">
              <img
                src="/assets/premium/money-recive.png"
                alt="link"
                className="w-10 mr-4"
              />
              <p className="text-lg">{t("specialPriceRequest")} </p>
              <div />
            </div>
            <div
              className="w-full mt-6 mb-4"
              style={{ height: 1, backgroundColor: "#FFDA7566" }}
            />
            <div className="flex place-items-center my-6">
              <img
                src="/assets/premium/cup.png"
                alt="link"
                className="w-10 mr-4"
              />
              <p className="text-lg">{t("unlockMemberLevel")}</p>
              <div />
            </div>
            <div className="flex place-items-center my-6">
              <img
                src="/assets/premium/refresh-square-2.png"
                alt="link"
                className="w-10 mr-4"
              />
              <p className="text-lg">{t("bonusPoints")}</p>
              <div />
            </div>
            <div
              className="w-full mt-6 mb-4"
              style={{ height: 1, backgroundColor: "#FFDA7566" }}
            />
            <div className="flex place-items-center my-6">
              <img
                src="/assets/premium/note-2.png"
                alt="link"
                className="w-10 mr-4"
              />
              <p className="text-lg">{t("eBookTutorial")}</p>
              <div />
            </div>
            <div className="flex place-items-center my-6">
              <img
                src="/assets/premium/speakerphone.png"
                alt="link"
                className="w-10 mr-4"
              />
              <p className="text-lg">{t("freeMarketingKit")}</p>
              <div />
            </div>
            <div
              className="w-full mt-6 mb-4"
              style={{ height: 1, backgroundColor: "#FFDA7566" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const BottomNavbar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div
      className="w-screen sm:w-128 mx-auto block fixed bottom-0 inset-x-0 z-10"
      style={{ backgroundColor: "#1E1E1E" }}
    >
      <nav className="transition-colors shadow-inner">
        <div className="text-gray-400 text-sm mx-auto flex items-center justify-around my-3">
          <div style={{ color: "#E9C258" }}>
            <p>{t("price")}</p>
            <p>
              <span className="font-bold">Rp 350.000</span> / {t("lifetime")}
            </p>
          </div>
          <div
            className="rounded-full py-3 w-36 flex justify-center text-black cursor-pointer"
            style={{
              backgroundImage:
                "linear-gradient(180deg, #FFDA75, #FFDE84, #E9C258)",
            }}
            onClick={() => navigate("/premium-upgrade/confirm")}
          >
            <span>{t("continue")}</span>
          </div>
        </div>
      </nav>
    </div>
  );
};
