export const MyLevel = () => {
  return (
    <div>
      <div className="px-4 blur">
        <p className="text-white text-xs mt-8 mb-4">
          Total Transactions Last 12 Months Currently
        </p>
        <p className="text-xl" style={{ color: "#E9C258" }}>
          Rp 12.000.000
        </p>
        <div
          className="p-4 w-full rounded-lg mt-6"
          style={{
            backgroundImage: "linear-gradient(225deg, #252525, #101010)",
            color: "#E9C258",
          }}
        >
          <div
            className="flex justify-between place-items-center"
            style={{ color: "#E9C258" }}
          >
            <div className="flex">
              <img
                src="/assets/premium/cockroach.png"
                alt="cockroach"
                className="w-5 mr-3"
              />
              <span>Cockroach</span>
            </div>
            <div
              className="text-xs p-2 rounded-lg text-black"
              style={{ backgroundColor: "#E9C258" }}
            >
              Current Level
            </div>
          </div>
          <div className="my-4 rounde-lg">
            <input
              id="current_level"
              type="range"
              min={0}
              max={100}
              className="w-full"
            />
          </div>
          <p className="text-xs">
            Transaksi <span className="font-bold">Rp (100.000.000)</span> untuk
            naik ke level Ponies
          </p>
        </div>
        <div className="h-10" />
        <p className="text-xl" style={{ color: "#E9C258" }}>
          Benefit Level Cockroach
        </p>
        <div
          className="flex p-4 place-items-center mb-4 rounded"
          style={{
            backgroundImage: "linear-gradient(225deg, #252525, #101010)",
            color: "#E9C258",
          }}
        >
          <img src="/assets/premium/user-tag.png" alt="" className="w-9 mr-2" />
          <span className="text-sm sm:text-base">
            Bantuan via Telegram dan Whatsapp
          </span>
        </div>
        <div
          className="flex p-4 place-items-center mb-4 rounded"
          style={{
            backgroundImage: "linear-gradient(225deg, #252525, #101010)",
            color: "#E9C258",
          }}
        >
          <img src="/assets/premium/crown.png" alt="" className="w-9 mr-2" />
          <span className="text-sm sm:text-base">
            Reward Member Of The Month
          </span>
        </div>
        <div
          className="flex p-4 place-items-center mb-4 rounded"
          style={{
            backgroundImage: "linear-gradient(225deg, #252525, #101010)",
            color: "#E9C258",
          }}
        >
          <img src="/assets/premium/bank.png" alt="" className="w-9 mr-2" />
          <span className="text-sm sm:text-base">Auto Top Up Local Bank</span>
        </div>
        <div
          className="flex p-4 place-items-center mb-4 rounded"
          style={{
            backgroundImage: "linear-gradient(225deg, #252525, #101010)",
            color: "#E9C258",
          }}
        >
          <img
            src="/assets/premium/refresh-square-2.png"
            alt=""
            className="w-9 mr-2"
          />
          <span className="text-sm sm:text-base">
            Exchange Point to Biscoin Rate 1 Point = Rp 10
          </span>
        </div>
      </div>
    </div>
  );
};
