import React, { useState } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { ExpandPriceList } from "./ExpandPriceList";
import InfiniteScroll from "react-infinite-scroller";
import { Loading } from "../Loading";
import { useTranslation } from "react-i18next";

export const PriceListSMM = ({ services, showSearch, hasMore }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);

  function toggleExpander(idService) {
    if (isOpen !== idService) {
      setIsOpen(idService);
    } else {
      setIsOpen(undefined);
    }
  }

  return (
    <div className="w-full min-h-screen">
      <InfiniteScroll pageStart={0} hasMore={hasMore} loader={<Loading />}>
        <table className="font-normal w-full table-auto text-center text-xs">
          <thead className=" w-full relative border border-y-1 ">
            <tr className="">
              <th className="py-3">{t("serviceId")}</th>
              <th className="py-3">{t("minMax")}</th>
              <th className="py-3">{t("ratePer1K")}</th>
            </tr>
          </thead>

          <tbody className="w-full">
            {showSearch
              ? services?.map((service, index) => {
                  return (
                    <>
                      <tr
                        className="py-4 cursor-pointer justify-between"
                        key={service.id}
                        onClick={() => toggleExpander(service.id)}
                      >
                        <td className="py-3 flex flex-row justify-start w-full text-start">
                          {isOpen !== service.id ? (
                            <FiChevronDown className="mr-2" />
                          ) : (
                            <FiChevronUp className="mr-2" />
                          )}
                          <div>
                            <p>{service.service_name}</p>
                            <div className="grid grid-cols-2 mt-2">
                              {service.isFavorite && (
                                <div className="flex flex-row text-center text-yellow-500">
                                  <img
                                    src="/assets/star.svg"
                                    alt=""
                                    className="mr-1"
                                  />
                                  <span>Favorite</span>
                                </div>
                              )}
                              {service.isRecomended && (
                                <div className="flex flex-row text-center text-blue-500">
                                  <img
                                    src="/assets/like.svg"
                                    alt=""
                                    className="mr-1"
                                  />
                                  <span>Recomended</span>
                                </div>
                              )}
                              {service.isPromo && (
                                <div className="flex flex-row text-center text-orange-500">
                                  <img
                                    src="/assets/promos.svg"
                                    alt=""
                                    className="mr-1 "
                                  />
                                  <span>Promo</span>
                                </div>
                              )}
                            </div>
                          </div>
                        </td>
                        <td className="w-3/12">
                          {service?.min}/{service?.max}
                        </td>
                        <td className="w-3/12">
                          Rp {service.rate?.toLocaleString("id")}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={3}>
                          {isOpen === service.id && (
                            <ExpandPriceList
                              id={service.id}
                              server={service.service_name}
                              refill={service.refillable}
                              min={service.min}
                              max={service.max}
                              speed={service.speed}
                              rate={service.rate.toLocaleString("id")}
                              member_info={service.member_info}
                            />
                          )}
                        </td>
                      </tr>
                    </>
                  );
                })
              : services?.map((service, index) => {
                  return (
                    <>
                      <tr key={service.nama_category}>
                        <td colSpan={3} className=" bg-gray-500 p-2">
                          <div
                            className="text-base ml-4 text-white"
                            dangerouslySetInnerHTML={{
                              __html: service.nama_category,
                            }}
                          />
                        </td>
                      </tr>
                      {service.products.map((service) => {
                        return (
                          <>
                            <tr
                              className="py-4 cursor-pointer justify-between"
                              key={service.id}
                              onClick={() => toggleExpander(service.id)}
                            >
                              <td className="py-3 flex flex-row justify-start w-full text-start">
                                {isOpen !== service.id ? (
                                  <FiChevronDown className="mr-2" />
                                ) : (
                                  <FiChevronUp className="mr-2" />
                                )}
                                <div>
                                  <p>{service.service_name}</p>
                                  <div className="grid grid-cols-2 mt-2">
                                    {service.isFavorite && (
                                      <div className="flex flex-row text-center text-yellow-500">
                                        <img
                                          src="/assets/star.svg"
                                          alt=""
                                          className="mr-1"
                                        />
                                        <span>Favorite</span>
                                      </div>
                                    )}
                                    {service.isRecomended && (
                                      <div className="flex flex-row text-center text-blue-500">
                                        <img
                                          src="/assets/like.svg"
                                          alt=""
                                          className="mr-1"
                                        />
                                        <span>Recomended</span>
                                      </div>
                                    )}
                                    {service.isPromo && (
                                      <div className="flex flex-row text-center text-orange-500">
                                        <img
                                          src="/assets/promos.svg"
                                          alt=""
                                          className="mr-1 "
                                        />
                                        <span>Promo</span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td className="w-3/12">
                                {service?.min}/{service?.max}
                              </td>
                              <td className="w-3/12">
                                Rp {service.rate?.toLocaleString("id")}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={3}>
                                {isOpen === service.id && (
                                  <ExpandPriceList
                                    id={service.id}
                                    server={service.service_name}
                                    refill={service.refillable}
                                    min={service.min}
                                    max={service.max}
                                    speed={service.speed}
                                    rate={service.rate.toLocaleString("id")}
                                    member_info={service.member_info}
                                  />
                                )}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </>
                  );
                })}
          </tbody>
        </table>
      </InfiniteScroll>
    </div>
  );
};
