import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillQuestionCircle } from "react-icons/ai";

export const InfoPopUp = (props) => {
  const { t } = useTranslation();
  const [scale, setScale] = useState("scale-0");
  useEffect(() => {
    setScale("scale-100");
  }, []);
  return (
    <div className="w-screen">
      <div className="fixed z-40 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-screen sm:w-128 p-6 ">
        <div
          className={`transition ease-in-out delay-150 bg-blue-500 rounded-xl p-4 ${scale}`}
        >
          <div className="text-white text-center flex flex-col font-medium">
            <div className="w-full flex flex-row justify-end cursor-pointer">
              <img
                src="/assets/exitbtn.svg"
                alt=""
                className=""
                onClick={props.close}
              />
            </div>
            <span className="">Info</span>
            <div className="mx-auto my-4">
              <AiFillQuestionCircle size={120} />
            </div>
            <p>
              <span>{props.text}</span>
              <br />
              {props.url && (
                <span>
                  {t("useVersion")} 2.0{" "}
                  <a
                    href={props.url}
                    className="underline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {props.linkText}
                    <br />
                  </a>
                </span>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
