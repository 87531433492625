import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoCloseCircleOutline } from "react-icons/io5";

export const ErrorPopUp = (props) => {
  const { t } = useTranslation();
  const [scale, setScale] = useState("scale-0");
  useEffect(() => {
    props.isShowing ? setScale("scale-100") : setScale("scale-0");
  });
  return (
    <div className="w-screen">
      <div className="fixed z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-screen sm:w-128 p-6 ">
        <div
          className={`transition ease-in-out delay-150 bg-red-500 rounded-xl p-4 ${scale}`}
        >
          <div className="text-white text-center flex flex-col font-medium">
            <div className="w-full flex flex-row justify-end">
              <img
                src="/assets/exitbtn.svg"
                alt=""
                className=""
                onClick={props.close}
              />
            </div>
            <span className="">
              {t("failed")} ! <br />
              {t("orderUnsuccessful")}
            </span>
            <div className="mx-auto my-4">
              <IoCloseCircleOutline size={120} />
            </div>
            <p>{props.text}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
