import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../contexts/ContextProvider";

export const GameTopupPage = () => {
  const [selectedDiamond, setSelectedDiamond] = useState({
    name: "",
    price: "",
  });
  const { setPpobCheckoutType } = useStateContext();
  const navigate = useNavigate();
  const handleSelectDiamond = (item) => {
    setSelectedDiamond({ name: item.name, price: item.price });
  };

  const handleCheckout = () => {
    setPpobCheckoutType("game");
    navigate("/checkout-ppob");
  };

  return (
    <div className="w-screen sm:w-128 min-h-screen mx-auto bg-white">
      <img src="/assets/ml-bg.png" alt="game-bg" className="w-full" />
      <div className="p-4 mb-16">
        <div className="flex space-x-2 place-items-center">
          <div className="rounded bg-gray-100 pt-2 pb-3 w-10 h-10">
            <p className="text-center">1</p>
          </div>
          <p>User Data</p>
        </div>
        <div className="grid grid-cols-2 my-2 space-x-2">
          <input
            type="text"
            className="bg-gray-100 rounded-full px-4 py-2 text-gray-400"
            placeholder="User ID"
          />
          <input
            type="text"
            className="bg-gray-100 rounded-full px-4 py-2 text-gray-400"
            placeholder="Server ID"
          />
        </div>
        <div className="flex space-x-2 place-items-center my-4">
          <div className="rounded bg-gray-100 pt-2 pb-3 w-10 h-10">
            <p className="text-center">2</p>
          </div>
          <p>Choose Nominal</p>
        </div>
        <div className="grid grid-cols-3 gap-4">
          {diamond.map((item) => {
            return (
              <div
                onClick={() => handleSelectDiamond(item)}
                className={`border ${
                  selectedDiamond.name === item.name
                    ? "border-blue-bisnison bg-blue-50"
                    : "border-gray-300"
                } rounded-lg p-3 flex flex-col justify-center place-items-center cursor-pointer`}
              >
                <div className="flex space-x-2">
                  {item.diamond && (
                    <img
                      src="/assets/diamond.png"
                      alt="diamond"
                      className="w-8"
                    />
                  )}
                  {item.starlight && (
                    <img
                      src="/assets/starlight.png"
                      alt="starlight"
                      className="w-8"
                    />
                  )}
                </div>
                <p className="my-2 text-center">{item.name}</p>
                <p className="text-sm text-center" style={{ color: "#101010" }}>
                  {item.price}
                </p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="w-screen sm:w-128 mx-auto block fixed bottom-0 inset-x-0 z-10">
        <nav className="bg-white transition-colors shadow-inner flex justify-between px-4 py-2">
          <div className="">
            <p>Total</p>
            <p className="font-bold">{selectedDiamond.price || "Rp 0"}</p>
          </div>
          <div
            onClick={handleCheckout}
            className="px-4 py-1 w-48 text-white bg-blue-bisnison rounded-full flex place-items-center justify-center cursor-pointer"
          >
            <p>Pay Now</p>
          </div>
        </nav>
      </div>
    </div>
  );
};

const diamond = [
  {
    name: "2 Diamond",
    price: "Rp16.000",
    diamond: true,
  },
  {
    name: "12 Diamond",
    price: "Rp16.000",
    diamond: true,
  },
  {
    name: "123 Diamond",
    price: "Rp16.000",
    diamond: true,
  },
  {
    name: "1412 Diamond",
    price: "Rp16.000",
    diamond: true,
  },
  {
    name: "2103 Diamond",
    price: "Rp16.000",
    diamond: true,
  },
  {
    name: "1412 Diamond +Starlight pas",
    price: "Rp16.000",
    diamond: true,
    starlight: true,
  },
  {
    name: "Starlight pas",
    price: "Rp16.000",
    starlight: true,
  },
];
