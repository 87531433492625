import React from "react";
import { ExpandColumn } from "../ExpandColumn";
import moment from "moment";
import { useTranslation } from "react-i18next";

export const ExpandHistoryOrder = (props) => {
  const { t } = useTranslation();
  return (
    <div className="w-full px-2">
      <div
        className="w-full bg-orange-400 p-4 rounded-xl text-white"
        key={props.key}
      >
        <ExpandColumn
          title={`${t("order")} ID`}
          value={props.id}
          icons="/assets/copy.svg"
        />
        <ExpandColumn
          title={t("date")}
          value={moment(props.tanggal).format("dddd, DD MMMM YYYY")}
        />
        <ExpandColumn title={t("servicesLabel")} value={props.server} />
        <ExpandColumn title="Target" value={props.data} />
        <ExpandColumn title={t("total")} value={props.jumlah} />
        <ExpandColumn title={t("remains")} value={props.remains} />
        <ExpandColumn
          title={t("price")}
          value={"Rp " + props.harga.toLocaleString("id")}
        />
        <ExpandColumn
          title={t("balance")}
          value={"Rp " + props.sisa_saldo.toLocaleString("id")}
        />
        <ExpandColumn title={t("comment")} value={props.komen} />
        <ExpandColumn title="Status" value={props.status} />
        {/* <div className="flex flex-row w-full my-2">
          <span className="w-4/12 text-start px-2">Refill</span>
          <div className="flex flex-row w-8/12">
            <div className="">
              <button className="rounded-md bg-white text-orange-400 flex flex-row p-2">
                <span>Print</span>
                <img src="/assets/copy.svg" alt="" />
              </button>
              <button className="rounded-md bg-white text-orange-400 flex flex-row p-2 mt-2">
                <span>Excel</span>
                <img src="/assets/copy.svg" alt="" />
              </button>
            </div>
            <div className="mx-2">
              <button className="rounded-md bg-white text-orange-400 flex flex-row p-2">
                <span>PDF</span>
                <img src="/assets/copy.svg" alt="" />
              </button>
              <button className="rounded-md bg-white text-orange-400 flex flex-row p-2 mt-2">
                <span>View Only</span>
                <img src="/assets/copy.svg" alt="" />
              </button>
            </div>
          </div>
        </div> */}
        {/* <div className="flex flex-row w-full my-2">
          <span className="w-4/12 text-start px-2">Refill</span>
          <div className="flex flex-row w-8/12">
            <button className="rounded-md bg-white text-orange-400 flex flex-row py-2 px-6">
              <span>Refil on proses</span>
              <img src="/assets/copy.svg" alt="" />
            </button>
          </div>
        </div> */}
      </div>
    </div>
  );
};
