import { useEffect, useState } from "react";
import { PriceListSMM } from "../../components/mobile/PriceList/PriceListSMMList";
import PriceListTable from "../../components/mobile/PriceList/PriceListTable";
import {
  getCategoriesSMM,
  getPricelistView,
  getServicesByCategories,
  postPricelistView,
} from "../../services/service";
import { useRef } from "react";
import Navbar from "../../components/mobile/Navbar";
import { useTranslation } from "react-i18next";

export const PriceListPage = () => {
  const { t } = useTranslation();
  const historyView = localStorage.getItem("pricelist_view");
  const [pricelistViewType, setPricelistViewType] = useState("");
  const searchWord = useRef();
  const [hasMore, setHasMore] = useState(true);
  const [categories, setCategories] = useState([]);
  const [servicesBySearch, setServicesBySearch] = useState([]);
  const [servicesByCategories, setServicesByCategories] = useState([]);
  const [list, setList] = useState([]);
  const [showSearch, setShowSearch] = useState(false);

  const handleSetView = () => {
    if (pricelistViewType === "table") {
      setPricelistViewType("list");
      postPricelistView("list")
        .then((responsePost) => {
          setPricelistViewType("list");
        })
        .catch((error) => {
          console.log(error);
        });
      localStorage.setItem("pricelist_view", "list");
    }
    if (pricelistViewType === "list") {
      setPricelistViewType("table");
      postPricelistView("table")
        .then((responsePost) => {
          setPricelistViewType("table");
        })
        .catch((error) => {
          console.log(error);
        });
      localStorage.setItem("pricelist_view", "table");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (searchWord.current.value) {
        setShowSearch(true);
        const result = servicesBySearch.filter((items) =>
          items["service_name"]
            ?.toString()
            .toLowerCase()
            .includes(searchWord.current.value.toLowerCase())
        );
        setList(result);
        if (result.length <= 20) {
          setHasMore(false);
        }
      } else {
        setHasMore(true);
        setShowSearch(false);
        setList(servicesByCategories);
      }
    }
  };
  const handleCategory = (e) => {
    if (e !== t("allLabel")) {
      servicesByCategories.forEach((element) => {
        if (element.nama_category === e) {
          setList([element]);
          setHasMore(false);
        }
      });
    } else {
      setHasMore(true);

      setList(servicesByCategories);
    }
  };

  useEffect(() => {
    if (!historyView) {
      setPricelistViewType("table");
    } else {
      setPricelistViewType(historyView);
    }
    getCategoriesSMM()
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    getServicesByCategories({ sorter: "rate" })
      .then((response) => {
        setShowSearch(false);
        setServicesBySearch([]);
        setServicesByCategories(response.data.data);
        setList(response.data.data);
        response.data.data.forEach((element) => {
          element.products.forEach((product) => {
            setServicesBySearch((prevState) => [...prevState, product]);
          });
        });
      })
      .catch((error) => {
        console.log(error);
      });
    getPricelistView()
      .then((response) => {
        if (!response.data.data) {
          postPricelistView("table")
            .then((responsePost) => {})
            .catch((error) => {
              console.log(error);
            });
        } else {
          setPricelistViewType(response.data.data.view);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  // if (isLoading) {
  //   return <Loading />;
  // }

  return (
    <div className=" w-screen sm:w-128 mx-auto text-center h-screen shadow bg-white">
      <Navbar isBack="/" />
      <div className="h-4"></div>
      <div className="text-black flex place-items-center justify-between px-2 mb-4">
        <select
          id="page"
          className="w-1/2 mx-2"
          onChange={(e) => handleCategory(e.target.value)}
        >
          <option defaultValue={t("allLabel")}>{t("allLabel")}</option>
          {categories.map((item) => {
            return (
              <option key={item.id_category} value={item.nama_category}>
                <div
                  className="justify-center truncate text-sm"
                  dangerouslySetInnerHTML={{
                    __html: item.nama_category,
                  }}
                />
              </option>
            );
          })}
        </select>
        <div className="w-full">
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
            </div>
            <input
              type="search"
              id="search"
              className="block w-full py-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
              placeholder={t("serviceName")}
              ref={searchWord}
              autoComplete="off"
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>
        <div className="ml-4">
          <div className="" onClick={handleSetView}>
            {pricelistViewType === "table" ? (
              <i className="fa fa-list w-max cursor-pointer"></i>
            ) : (
              <i className="fa fa-table w-max cursor-pointer"></i>
            )}
          </div>
        </div>
      </div>
      {pricelistViewType === "table" ? (
        <PriceListTable
          showSearch={showSearch}
          servicesByCategories={list}
          setServicesByCategories={setList}
          hasMore={hasMore}
        />
      ) : (
        <PriceListSMM
          showSearch={showSearch}
          services={list}
          setServices={setList}
          hasMore={hasMore}
        />
      )}
    </div>
  );
};
