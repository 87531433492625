import { useEffect, useState } from "react";
import { FiMenu } from "react-icons/fi";

import { useStateContext } from "../../contexts/ContextProvider";
import { NotificationIcon } from "./NotificationIcon";
import { Link } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import LanguageSwitcher from "../desktop/LanguageSwitcher";

export default function Navbar(props) {
  const [onTop, setOnTop] = useState(true);
  const { isSidebarOpen, setIsSidebarOpen } = useStateContext();

  const handleScroll = () => {
    if (onTop !== (window.pageYOffset === 0)) {
      setOnTop(window.pageYOffset === 0);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <header
      className={`${
        onTop ? "" : "shadow"
      } sticky top-0 z-20 transition-shadow w-screen sm:w-128 mx-auto `}
    >
      <nav className="bg-white transition-colors">
        <ul className="flex items-center justify-between p-4 ">
          <div className="w-1/4">
            {props.isBack ? (
              <Link to={props.isBack}>
                <BiArrowBack size={30} />
              </Link>
            ) : (
              <button onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                <FiMenu size={30} />
              </button>
            )}
          </div>
          <Link to="/">
            <img
              src="/logo-long.png"
              alt="logo-long"
              size={130}
              className="max-w-3/6"
            />
          </Link>
          <div className="w-1/4 flex place-content-end gap-2">
            <NotificationIcon />
            <LanguageSwitcher />
          </div>
        </ul>
      </nav>
    </header>
  );
}
