import React, { createContext, useContext, useEffect, useState } from "react";
import {
  getArticles,
  getBankById,
  getCategoriesSMM,
  getDetailService,
  getPPOBById,
  getProductTags,
  getServicesByCategoryId,
  getTags,
} from "../services/service";
import { useTranslation } from "react-i18next";

const StateContext = createContext();

export const ContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(t("orderTypes.singleOrder"));
  const [selectedTabHistory, setSelectedTabHistory] = useState("SMM");
  const [selectedTabTicket, setSelectedTabTicket] = useState("New Ticket");
  const [selectedTabPriceList, setSelectedTabPriceList] =
    useState("Price List SMM");
  const [selectedTabOrderSMM, setSelectedTabOrderSMM] =
    useState("Single Order");
  const [selectedTabPremium, setSelectedTabPremium] = useState(1);
  const [selectedTabTakeAffiliate, setSelectedTabTakeAffiliate] =
    useState("Ambil");
  const [selectedTabTopUp, setSelectedTabTopUp] = useState("Top up");
  const [selectedMenu, setSelectedMenu] = useState("Home");

  const [valueCommission, setValueCommission] = useState("");

  const [categoriesSMM, setCategoriesSMM] = useState([]);
  const [categoriesPPOB, setCategoriesPPOB] = useState([]);
  const [servicePPOB, setServicePPOB] = useState();
  const [service, setService] = useState();
  const [historiesOrderSMM, setHistoriesOrderSMM] = useState([]);
  const [historiesOrderPPOB, setHistoriesOrderPPOB] = useState([]);
  const [selectedCategorySMM, setSelectedCategorySMM] = useState();
  const [selectedCategoryPPOB, setSelectedCategoryPPOB] = useState();
  const [selectedServiceSMM, setSelectedServiceSMM] = useState();
  const [tickets, setTickets] = useState([]);
  const [news, setNews] = useState([]);
  const [historyTopUp, setHistoryTopUp] = useState([]);
  const [categoriesBank, setCategoriesBank] = useState([]);
  const [bankById, setBankById] = useState([]);
  const [topUpPending, setTopUpPending] = useState();
  const [articles, setArticles] = useState([]);
  const [rankings, setRankings] = useState([]);
  const [infoPage, setInfoPage] = useState({
    contact: "",
    api: "",
    cara_pesan: "",
    terms: "",
  });
  const [bodyTicket, setBodyTicket] = useState({
    subject: "",
    message: "",
  });
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [topUpBody, setTopUpBody] = useState({
    categoryId: 0,
    bankId: 0,
    saldo: 0,
    saldo_type: 0,
  });

  const [optionsSMM, setOptionsSMM] = useState({
    categories: [],
    services: [],
  });

  const [optionsPPOB, setOptionsPPOB] = useState();
  const [showComingSoon, setShowComingSoon] = useState(false);
  const [list, setList] = useState([]);
  const [bonusTopUp, setBonusTopUp] = useState([]);
  const [listBank, setListBank] = useState([]);
  const [isFilters, setIsFilters] = useState({
    historySMM: [],
    historyPPOB: [],
    priceListSMM: [],
    historyTopUp: [],
    historyTicket: [],
  });
  const [openFilter, setOpenFilter] = useState({
    historySMM: false,
    historyPPOB: false,
    priceListSMM: false,
    historyTopUp: false,
    historyTicket: false,
  });
  const [selectedWallet, setSelectedWallet] = useState({
    name: "Wallet IDR",
    value: user?.saldo?.toLocaleString("id") || 0,
  });
  const [selectedTabPremiumBenefits, setSelectedTabPremiumBenefits] =
    useState("My Level");
  const [selectedTabNotification, setSelectedTabNotification] =
    useState("Promo");
  const [ppobCheckoutType, setPpobCheckoutType] = useState("");
  const [autoCompleteService, setAutoCompleteService] = useState({
    categoryId: "",
    serviceId: "",
  });

  const [select, setSelect] = useState({
    category: "",
    service: "",
  });

  const [loading, setLoading] = useState(false);
  const [valueMassOrder, setValueMassOrder] = useState("");

  const [selectedMenuDesktop, setSelectedMenuDesktop] = useState("Dashboard");
  const [selectedMenuOrder, setSelectedMenuOrder] = useState("Order");

  const token = localStorage.getItem("token");

  const handlePrice = (isDiscount, currency, rate, unit, discountRate) => {
    if (isDiscount) {
      return ` - <s style="color: red; margin-right: 5px;">${
        currency === "IDR" ? "Rp" : "$"
      } ${rate.toLocaleString(currency === "IDR" ? "id" : "us")}${
        unit >= 1000 ? "/K" : ""
      }
      </s> -> <span style="color: green">
      ${currency === "IDR" ? "Rp" : "$"} ${(
        rate -
        (rate / 100) * discountRate
      ).toLocaleString(currency === "IDR" ? "id" : "us")}${
        unit >= 1000 ? "/K" : ""
      }</span>
        `;
    } else {
      return ` ${currency === "IDR" ? "Rp" : "$"} ${rate?.toLocaleString(
        currency === "IDR" ? "id" : "us"
      )}${unit >= 1000 ? "/K" : ""}
          `;
    }
  };

  useEffect(() => {
    if (user && token) {
      setLoading(true);
      getTags()
        .then((responseTags) => {
          getCategoriesSMM()
            .then((responseCategories) => {
              const dataTags = responseTags.data.data.map((c) => {
                return {
                  value: c.id,
                  label: c.name,
                  dataType: "tags",
                };
              });
              const dataCategories = responseCategories.data.map((c) => {
                return {
                  value: c.id_category,
                  label: c.nama_category,
                  dataType: "categories",
                };
              });
              const data = dataTags.concat(dataCategories);
              if (autoCompleteService.categoryId) {
                data.forEach((item) => {
                  if (item.value === autoCompleteService.categoryId) {
                    setSelect((prevState) => ({
                      ...prevState,
                      category: item.label,
                    }));
                    setSelect((prevState) => ({ ...prevState, service: "" }));
                    setSelectedCategorySMM([item.value, item.dataType]);
                  }
                });
              }
              setOptionsSMM((prevState) => ({
                ...prevState,
                categories: data,
              }));
              setLoading(false);
            })
            .catch((error) => {
              setLoading(false);
              console.log(error);
            });
        })
        .catch((error) => console.log(error));
      getArticles()
        .then((response) => {
          setArticles(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [autoCompleteService, token, user]);

  useEffect(() => {
    if (selectedCategorySMM) {
      if (selectedCategorySMM[1] === "categories") {
        getServicesByCategoryId({ id: selectedCategorySMM[0] })
          .then((responseService) => {
            const sortByPrice = responseService.data.data[0].sort((a, b) => {
              return parseFloat(a.price) - parseFloat(b.price);
            });
            const data = sortByPrice.map((c) => {
              return {
                value: c.id,
                label:
                  `${
                    c.discounts[0]?.discount
                      ? "<img src='https://smm.bisnison.com/images/discount_16x16.png' style='display: inline'/>"
                      : ""
                  }` +
                  `[${c.id}] - ` +
                  c.server +
                  handlePrice(
                    c.discounts[0]?.discount,
                    user.currency,
                    c.price,
                    c.unit,
                    c.discounts[0]?.discount
                  ),
              };
            });
            if (autoCompleteService.serviceId) {
              data.forEach((item) => {
                if (item.value === autoCompleteService.serviceId) {
                  setSelect((prevState) => ({
                    ...prevState,
                    service: item.label,
                  }));
                  setSelectedServiceSMM(item.value);
                }
              });
            }
            setOptionsSMM((prevState) => ({
              ...prevState,
              services: data,
            }));
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (selectedCategorySMM[1] === "tags") {
        setOptionsSMM((prevState) => ({
          ...prevState,
          services: [],
        }));
        getProductTags(selectedCategorySMM[0])
          .then((responseProductTags) => {
            const data = responseProductTags.data.data.map((c) => {
              return {
                value: c.list_product?.id,
                label:
                  `${
                    c.list_product?.discounts[0]?.discount
                      ? "<img src='https://smm.bisnison.com/images/discount_16x16.png' style='display: inline'/>"
                      : ""
                  }` +
                  `[${c.list_product_id}] - ` +
                  c.list_product?.server +
                  handlePrice(
                    c.list_product?.discounts[0]?.discount,
                    user.currency,
                    c.list_product?.rate,
                    c.list_product?.unit,
                    c.list_product?.discounts[0]?.discount
                  ),
              };
            });
            if (autoCompleteService.serviceId) {
              data.forEach((item) => {
                if (item.value === autoCompleteService.serviceId) {
                  setSelect((prevState) => ({
                    ...prevState,
                    service: item.label,
                  }));
                  setSelectedServiceSMM(item.value);
                }
              });
            }
            setOptionsSMM((prevState) => ({
              ...prevState,
              services: data,
            }));
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }, [selectedCategorySMM, autoCompleteService.serviceId]);

  useEffect(() => {
    selectedCategoryPPOB &&
      getPPOBById({ id: selectedCategoryPPOB })
        .then((response) => {
          setServicePPOB(response.data);
          setOptionsPPOB(
            response.data.map((c) => {
              return {
                value: c.id,
                label: c.name,
              };
            })
          );
        })
        .catch((error) => console.log(error));
  }, [selectedCategoryPPOB]);

  useEffect(() => {
    selectedServiceSMM &&
      getDetailService({ id: selectedServiceSMM })
        .then((response) => {
          setService(response.data.data[0]);
        })
        .catch((error) => console.log(error));
  }, [selectedServiceSMM]);

  useEffect(() => {
    topUpBody.categoryId &&
      getBankById(topUpBody.categoryId)
        .then((response) => {
          setListBank(response.data);
          setBankById(
            response.data.map((c) => {
              return {
                value: c.id,
                label: c.nama,
              };
            })
          );
        })
        .catch((error) => console.log(error));
  }, [topUpBody.categoryId]);

  return (
    <StateContext.Provider
      value={{
        user,
        setUser,
        token,
        optionsSMM,
        setOptionsSMM,
        optionsPPOB,
        setOptionsPPOB,
        categoriesSMM,
        setCategoriesSMM,
        categoriesPPOB,
        setCategoriesPPOB,
        selectedCategoryPPOB,
        setSelectedCategoryPPOB,
        service,
        setService,
        tickets,
        setTickets,
        news,
        setNews,
        servicePPOB,
        setServicePPOB,
        selectedCategorySMM,
        setSelectedCategorySMM,
        historiesOrderSMM,
        setHistoriesOrderSMM,
        historiesOrderPPOB,
        setHistoriesOrderPPOB,
        selectedServiceSMM,
        setSelectedServiceSMM,
        isSidebarOpen,
        setIsSidebarOpen,
        selectedTab,
        setSelectedTab,
        selectedMenu,
        setSelectedMenu,
        selectedTabTicket,
        setSelectedTabTicket,
        selectedTabHistory,
        setSelectedTabHistory,
        selectedTabPriceList,
        setSelectedTabPriceList,
        selectedTabOrderSMM,
        setSelectedTabOrderSMM,
        selectedTabTopUp,
        setSelectedTabTopUp,
        historyTopUp,
        setHistoryTopUp,
        categoriesBank,
        setCategoriesBank,
        topUpBody,
        setTopUpBody,
        bankById,
        setBankById,
        topUpPending,
        setTopUpPending,
        articles,
        setArticles,
        rankings,
        setRankings,
        selectedTabPremium,
        setSelectedTabPremium,
        infoPage,
        setInfoPage,
        bodyTicket,
        setBodyTicket,
        selectedTabTakeAffiliate,
        setSelectedTabTakeAffiliate,
        showComingSoon,
        setShowComingSoon,
        list,
        setList,
        bonusTopUp,
        setBonusTopUp,
        listBank,
        setListBank,
        isFilters,
        setIsFilters,
        openFilter,
        setOpenFilter,
        selectedWallet,
        setSelectedWallet,
        selectedTabPremiumBenefits,
        setSelectedTabPremiumBenefits,
        selectedTabNotification,
        setSelectedTabNotification,
        ppobCheckoutType,
        setPpobCheckoutType,
        autoCompleteService,
        setAutoCompleteService,
        select,
        setSelect,
        loading,
        setLoading,
        valueCommission,
        setValueCommission,
        valueMassOrder,
        setValueMassOrder,
        selectedMenuDesktop,
        setSelectedMenuDesktop,
        selectedMenuOrder,
        setSelectedMenuOrder,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
