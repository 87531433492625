import { useTranslation } from "react-i18next";
import { BiArrowBack } from "react-icons/bi";
import { Link } from "react-router-dom";

export const TOSPage = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col text-sm text-center font-poppins w-screen sm:w-128 mx-auto h-screen p-4 shadow bg-white">
      <div className="my-2 h-2/12 flex justify-between">
        <Link to="/register">
          <BiArrowBack size={25} />
        </Link>
        <span className="bg-yellow-300 text-red-500 font-bold rounded py-1 px-2">
          {t("tosPage.requiredRead")}
        </span>
        <BiArrowBack size={25} className="text-transparent" />
      </div>
      <div className="my-2 h-3/12">
        <p className="font-bold">{t("tosPage.tosTitle")}</p>
        <p>{t("tosPage.contactAdmin")}</p>
      </div>
      <div className="overflow-y-auto h-full h-8/12">
        <Pasal1 />
        <Pasal2 />
        <Pasal3 />
        <Pasal4 />
        <Pasal5 />
      </div>
    </div>
  );
};

const Pasal1 = () => {
  return (
    <p className="mt-5 text-justify">
      <span className="font-bold">
        PASAL 1. UMUM <br /> <br />
      </span>
      <span className="font-bold">Ayat 1 : </span>
      Dengan melakukan pembelian pada panel BisnisOn, maka Anda otomatis
      menyetujui semua ketentuan yang berlaku dibawah ini baik Anda baca maupun
      tidak. <br />
      <br />
      <span className="font-bold">Ayat 2 :</span> Kami berhak mengubah ketentuan
      perjanjian Layanan BisnisOn jika memang diperlukan tanpa pemberitahuan
      terlebih dahulu. <br /> <br />
      <span className="font-bold">Ayat 3 :</span> Anda diharapkan membaca semua
      ketentuan layanan, petunjuk dan pengumuman yang ada di dashboard sebelum
      melakukan pembelian untuk memastikan Anda mengikuti setiap perubahan pada
      ketentuan layanan kedepannya. <br /> <br />
      <span className="font-bold">Ayat 4 :</span> Tarif yang berlaku pada
      BisnisOn dapat berubah sewaktu-waktu yang akan diinfokan pada pengumuman
      di Dashboard Anda. <br /> <br />
      <span className="font-bold">Ayat 5 :</span> Disclaimer: BisnisOn tidak
      akan bertanggung jawab atas kerugian Anda atau bisnis Anda yang mungkin
      terjadi. <br /> <br />
      <span className="font-bold">Ayat 6 :</span> Liabilities: BisnisOn sama
      sekali tidak bertanggung jawab atas setiap akun yang disuspend,
      penghapusan gambar, video dan semacamnya yang dilakukan oleh Instagram /
      Twitter / Facebook / Youtube / Google / Web Traffic dan lainnya.
    </p>
  );
};

const Pasal2 = () => {
  return (
    <p className="mt-5 text-justify">
      <span className="font-bold">
        PASAL 2. TENTANG LAYANAN <br /> <br />
      </span>
      <span className="font-bold">Ayat 1 :</span> BisnisOn hanya digunakan untuk
      membuat akun Instagram / Twitter / Facebook / Youtube / Google / Web
      Traffic Anda “terlihat” popular saja.
      <br /> <br />
      <span className="font-bold">Ayat 2 :</span> Kami TIDAK dapat memberi
      jaminan bahwa followers/subscriber akan berinteraksi dengan Anda. <br />{" "}
      <br />
      <span className="font-bold">Ayat 3 :</span> Kami tidak memberikan jaminan
      untuk setiap layanan akan bertahan selamanya, Kami tidak akan bertanggung
      jawab atas hal itu dan tidak ada pengembalian deposit. <br /> <br />
      <span className="font-bold">Ayat 4 :</span> Pembelian pada umumnya selesai
      dalam hitungan menit atau jam tergantung dari layanan yang Anda pilih.{" "}
      <br /> <br />
      <span className="font-bold">Ayat 5 :</span> Setiap pesanan tidak bisa
      dibatalkan begitu saja, kecuali dibatalkan oleh server. Pastikan link yang
      Anda masukkan sudah benar sesuai petunjuk. <br /> <br />
      <span className="font-bold">Ayat 6 :</span> Untuk layanan non garansi
      tidak ada refund / refill, bahkan jika berkurang 5 menit sejak status
      orderan completed, order with your own risk. <br /> <br />
      <span className="font-bold">Ayat 7 :</span> Untuk layanan bergaransi
      apabila berkurang, silahkan request refill dengan menyertakan order id
      melalui Telegram / WA admin.
      <br /> <br /> <span className="font-bold">Ayat 8 :</span> Silahkan follow
      up proses refill setelah 24 jam. <br /> <br />
      <span className="font-bold">Ayat 9 :</span> Jika tidak terjadi penambahan
      pada orderan Anda selama 24 jam atau lama pengerjaan melebihi waktu
      estimasi speed yang tertera pada halaman harga layanan silahkan request
      speed up dengan menyertakan order id melalui Telegram / / WA admin untuk
      mempercepat pengerjaan orderan Anda. Melakukan komplain di sosial media
      kami tidak akan membantu menyelesaikan masalah Anda. <br /> <br />
      <span className="font-bold">Ayat 10 :</span> Data username dan url yang
      Anda input saat proses order hanya untuk keperluan transaksi orderan dan
      kami tidak akan menyebarluaskannya.
    </p>
  );
};

const Pasal3 = () => {
  return (
    <p className="mt-5 text-justify">
      <span className="font-bold">
        PASAL 3. PEMBAYARAN / KEBIJAKAN PENGEMBALIAN DANA <br /> <br />
      </span>
      <span className="font-bold">Ayat 1 :</span> Pastikan mentransfer hingga
      tiga digit terakhir untuk mempercepat proses penambahan saldo.
      <br /> <br /> <span className="font-bold">Ayat 2 :</span> Orderan yang
      berstatus partial/cancel, saldo akan di refund otomatis ke akun Anda. Anda
      dapat mengeceknya dihalaman Saldo {">"} Refund List.
      <br /> <br /> <span className="font-bold">Ayat 3 :</span> Tidak ada
      pengembalian dana ke rekening Anda apabila deposit telah berhasil
      dilakukan, kecuali Anda menjual kembali layanan yang ada di BisnisOn.
      <br /> <br /> <span className="font-bold">Ayat 4 :</span> Tidak ada
      pengembalian dana bagi member yang telah melanggar syarat dan ketentuan
      layanan.
      <br /> <br /> <span className="font-bold">Ayat 5 :</span> Apabila user
      menggunakan bukti transfer palsu, maka kami akan langsung membanned akun
      Anda tanpa pemberitahuan dan pengembalian dana.
    </p>
  );
};

const Pasal4 = () => {
  return (
    <p className="mt-5 text-justify">
      <span className="font-bold">
        PASAL 4. PERSONAL INFO <br /> <br />
      </span>
      <span className="font-bold">Ayat 1 :</span> Dimohon mendaftar menggunakan
      No HP dan email yang valid guna mempermudahkan pelayanan kami terkait
      masalah yang terjadi pada akun anda seperti lupa password.
      <br /> <br /> <span className="font-bold">Ayat 2 :</span> Kami berhak
      menggunakan informasi pada akun Anda seperti no HP dan email untuk
      mengirimkan info dan promo terkait layanan BisnisOn.
      <br /> <br /> <span className="font-bold">Ayat 3 :</span> Kami tidak akan
      memberikan data pribadi Anda kepada pihak ketiga, kecuali pihak berwajib
      apabila itu memang diperlukan.
    </p>
  );
};

const Pasal5 = () => {
  return (
    <p className="mt-5 text-justify">
      <span className="font-bold">
        PASAL 5. LAIN - LAIN <br /> <br />
      </span>
      <span className="font-bold">Ayat 1 :</span> Segala bentuk komplain dan
      konfirmasi top up akan dihandle oleh tim customer support kami.
      <br /> <br /> <span className="font-bold">Ayat 2 :</span> Mohon hargai
      customer support kami dengan berbicara sopan dan tidak melakukan ancaman.
      <br /> <br />
      <span className="font-bold">Ayat 3 : </span> Kami berhak menolak/susspend
      member yang tidak memenuhi kriteria ini di panel kami.
      <br /> <br /> <span className="font-bold">Ayat 4 :</span> Member yang
      sudah pernah disusspend tidak diperbolehkan mendaftar ulang menggunakan
      data baru. Kami akan susspend, dan saldo yang tersisa dianggap hangus.
    </p>
  );
};
