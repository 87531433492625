import React, { useEffect, useState } from "react";
import { OrangeButton } from "../../components/mobile/OrangeButton";
import { Input } from "../../components/mobile/Input";
import { getUser, postExchangePoints } from "../../services/service";
import { useStateContext } from "../../contexts/ContextProvider";
import { ImLoop2 } from "react-icons/im";
import { motion } from "framer-motion";
import { t } from "i18next";

export const PointPage = () => {
  const { user, setUser } = useStateContext();
  const [isLoading, setIsLoading] = useState(false);
  const [point, setPoint] = useState("");
  const [rate, setRate] = useState(0);
  const [total, setTotal] = useState(0);
  const [message, setMessage] = useState({
    error: "",
    message: "",
  });

  const handleChange = (e) => {
    setPoint(e.target.value);
  };

  const handleReset = () => {
    setPoint("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage("");
    if (point >= 500) {
      setIsLoading(true);
      postExchangePoints(point)
        .then((response) => {
          response.data.status === "success"
            ? setMessage({ success: response.data.message })
            : setMessage({ error: response.data.points[0] });
          getUser()
            .then((responseUser) => {
              setUser(responseUser.data);
              localStorage.setItem("user", JSON.stringify(response.data));
            })
            .catch((errorUser) => console.log(errorUser));
          setIsLoading(false);
        })
        .catch((error) => {
          setMessage({ error: "Connection error" });
          console.log(error);
          setIsLoading(false);
        });
    } else {
      setMessage({ error: t("minimumPayoutPoints") });
    }
  };

  useEffect(() => {
    setTotal(point * rate);
  }, [point]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    switch (user.user_level.level) {
      case "Newbie":
        setRate(5);
        break;
      case "Cockroach":
        setRate(10);
        break;
      case "Ponies":
        setRate(25);
        break;
      case "Centaurs":
        setRate(50);
        break;
      case "Unicorn":
        setRate(75);
        break;
      case "Decacorn":
        setRate(100);
        break;
      case "Hectocorn":
        setRate(125);
        break;
      default:
        setRate(5);
    }
  }, []);

  return (
    <div className="p-4 w-screen sm:w-128 mx-auto shadow bg-white min-h-screen">
      <p className="text-orange-500 mb-4 flex place-items-center">
        <ImLoop2 className="mr-1" /> {t("payoutPoints")}
      </p>
      <div className="flex justify-between">
        <div className="rounded bg-blue-bisnison p-2 justify-between flex text-white place-items-center w-36">
          <div className="text-xs flex space-x-2">
            <img src="/assets/white-coin.svg" alt="white coin" />{" "}
            <span>{t("myPoints")}</span>
          </div>
          <span>{user.point}</span>
        </div>
        <div className="text-amber-300 text-center">
          <p className="text-xs">{t("levelMember")}</p>
          <p>{user.user_level.level}</p>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <Input
          label={t("exchangePointToBiscoin")}
          type="number"
          value={point}
          change={handleChange}
          placeholder={`0 Point`}
          icon={
            <span
              className="text-orange-500 text-xs mr-2 cursor-pointer"
              onClick={(e) => setPoint(user.point)}
            >
              {t("allIn")}
            </span>
          }
        />
        <Input label="Minimum Payout" placeholder={"500"} disabled={true} />
        <div className="flex space-x-4 mb-3">
          <Input
            label={t("rate1Point")}
            placeholder={`Rp ${rate}`}
            disabled={true}
          />
          <Input
            label={t("pointsEarned")}
            placeholder={`Rp ${total}`}
            disabled={true}
          />
        </div>

        {message.error && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="bg-red-200 text-red-500 p-2 rounded w-full"
          >
            {message.error}
          </motion.div>
        )}
        {message.success && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="bg-green-200 text-green-500 p-2 rounded w-full"
          >
            {message.success}
          </motion.div>
        )}
        <div className="flex space-x-2">
          <div
            className={`bg-red-500 text-white text-center w-full py-3 mt-4 rounded-full border-none w-1/3`}
            onClick={handleReset}
          >
            Reset
          </div>
          <OrangeButton
            type="submit"
            title={t("exchangeNow")}
            isLoading={isLoading}
          />
        </div>
        <div className="mt-4 text-xs">
          <p>{t("pointsTerms")}</p>
          <ul className="ml-4 mt-2">
            <li>{t("premiumMembersOnly")}</li>

            <li>
              {t("exchangePointRates")}
              {t("transactionValueToPoints")}
            </li>
          </ul>
          <ol className="ml-4 my-2">
            <li>{t("newbie")}</li>
            <li>{t("cockroach")}</li>
            <li>{t("ponies")}</li>
            <li>{t("centaurs")}</li>
            <li>{t("unicorn")}</li>
            <li>{t("decacorn")}</li>
            <li>{t("hectocorn")}</li>
          </ol>
          <p>*{t("pointsFromSMKTransactions")}</p>
        </div>
      </form>
    </div>
  );
};
