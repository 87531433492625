import NewsHeaderDesktop from "./NewsHeaderDesktop";
import NewsContentDesktop from "./NewsContentDesktop";

const NewsServiceUpdates = ({ filterNews, setFilterNews, news, isLoading }) => {
  const onFiltered = (value) => {
    if (value === "Price Increased") {
      setFilterNews(
        news.filter(
          (text) =>
            text.status_new === 1 &&
            text.status_old === 1 &&
            text.rate_new > text.rate_old
        )
      );
    } else if (value === "Price Decreased") {
      setFilterNews(
        news.filter(
          (text) =>
            text.status_new === 1 &&
            text.status_old === 1 &&
            text.rate_new < text.rate_old
        )
      );
    } else if (value === "Enabled") {
      setFilterNews(
        news.filter((text) => text.status_new === 1 && text.status_old === 0)
      );
    } else if (value === "Disabled") {
      setFilterNews(news.filter((text) => text.status_new === 0));
    } else if (value === "All") {
      setFilterNews(news);
    }
  };

  return (
    <div>
      <NewsHeaderDesktop onFiltered={onFiltered} />
      {filterNews.length > 0 ? (
        <div className=" flex flex-col w-full justify-start">
          {filterNews.map((item, index) => {
            return (
              <NewsContentDesktop
                key={item.id}
                product_id={item.product_id}
                content={item.server}
                tanggal={item.created_at}
                status_old={item.status_old}
                status_new={item.status_new}
                nama_category={item.nama_category}
                rate_old={item.rate_old}
                rate_new={item.rate_new}
              />
            );
          })}
        </div>
      ) : (
        <div className="h-full w-full ">
          {isLoading ? (
            [1, 2, 3, 4, 5].map((item) => (
              <div
                key={item}
                className="flex flex-row text-start w-full justify-evenly my-4"
              >
                <div className="w-9 h-8 animate-pulse bg-gray-400 rounded-full"></div>
                <div className="ml-2 w-full">
                  <div className="flex flex-row justify-between  ">
                    <div className="w-32 h-7 animate-pulse bg-gray-400 rounded-lg"></div>
                    <div className="w-32 h-7 animate-pulse bg-gray-400 rounded-lg"></div>
                  </div>
                  <div className="w-48 h-7 animate-pulse bg-gray-400 mt-2 rounded-lg"></div>
                </div>
              </div>
            ))
          ) : (
            <div className="h-96 w-full justify-center flex flex-col">
              <p className="text-center">Tidak ada layanan</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default NewsServiceUpdates;
