import { Link } from "react-router-dom";
import { motion } from "framer-motion";

export const BottomNavbarItem = (props) => {
  return (
    <Link to={props.url} className="flex flex-col place-items-center">
      <motion.button
        initial={{
          y: 0,
        }}
        animate={{
          y: props.selectedMenu === props.text ? -20 : 0,
        }}
        onClick={() => props.onChange(props.text)}
        className={`${
          props.selectedMenu === props.text &&
          "bg-blue-bisnison text-white p-2 rounded-full relative mb-1"
        }`}
      >
        {props.icon}
      </motion.button>
      <motion.span
        initial={{
          y: 0,
        }}
        animate={{
          y: props.selectedMenu === props.text ? -20 : 0,
        }}
        className={`${
          props.selectedMenu === props.text &&
          "text-blue-bisnison text-sm relative"
        }`}
      >
        {props.text}
      </motion.span>
    </Link>
  );
};
