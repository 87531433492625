import { getServicesByCategories } from "../../../services/service";
import { Loading } from "../Loading";
import InfiniteScroll from "react-infinite-scroller";
import { HelpsIcons } from "../HelpsIcons";
import { useTranslation } from "react-i18next";

const PriceListTable = ({
  showSearch,
  servicesByCategories,
  setServicesByCategories,
  hasMore,
}) => {
  const { t } = useTranslation();
  const handleSorter = (e) => {
    getServicesByCategories({ sorter: e })
      .then((response) => {
        setServicesByCategories(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleTranslate = (word) => {
    const result = word
      .replace("years", t("time.year"))
      .replace("year", t("time.year"))
      .replace("months", t("time.month"))
      .replace("month", t("time.month"))
      .replace("weeks", t("time.week"))
      .replace("week", t("time.week"))
      .replace("days", t("time.day"))
      .replace("day", t("time.day"))
      .replace("hours", t("time.hour"))
      .replace("hour", t("time.hour"))
      .replace("minutes", t("time.minute"))
      .replace("minute", t("time.minute"))
      .replace("seconds", t("time.second"))
      .replace("second", t("time.second"));
    return result;
  };

  return (
    <div
      className="relative overflow-x-auto sm:rounded-lg"
      style={{ height: "78%" }}
    >
      <InfiniteScroll pageStart={0} hasMore={hasMore} loader={<Loading />}>
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 sticky top-0 ">
            <tr>
              <th scope="col" className="px-6 py-3">
                <div className="flex items-center text-black whitespace-nowrap">
                  {t("id-service")}
                  <div onClick={() => handleSorter("service_name")}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-3 h-3 ml-1"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 320 512"
                    >
                      <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" />
                    </svg>
                  </div>
                </div>
              </th>
              <th scope="col" className="px-6 py-3">
                <div className="flex items-center whitespace-nowrap">
                  {t("price")} / K
                  <div onClick={() => handleSorter("rate")}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-3 h-3 ml-1"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 320 512"
                    >
                      <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" />
                    </svg>
                  </div>
                </div>
              </th>
              <th scope="col" className="px-6 py-3 whitespace-nowrap">
                <p>{t("minMax")}</p>
              </th>
              <th scope="col" className="px-6 py-3">
                <div className="flex items-center whitespace-nowrap">
                  <p className="mr-1">{t("speedAvg")} </p>
                  <HelpsIcons helptext={t("speedAverageHelptext")} />
                  <div onClick={() => handleSorter("speed")}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-3 h-3 ml-1"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 320 512"
                    >
                      <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" />
                    </svg>
                  </div>
                </div>
              </th>
              <th scope="col" className="px-6 py-3">
                <div className="flex items-center">{t("description")}</div>
              </th>
            </tr>
          </thead>

          <tbody className="overflow-x-scroll">
            {showSearch
              ? servicesByCategories.map((item) => {
                  return (
                    <tr key={item.id} className="bg-white border-b ">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 "
                      >
                        {item.id} - {item.service_name}
                      </th>
                      <td className="px-6 py-4">
                        {item.min} / {item.max}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <img
                          src="/assets/biscoin.svg"
                          alt="saldo"
                          width={20}
                          className="mr-2 float-left w-max"
                        />
                        {/* {item.rate.toLocaleString("id")} */}
                        {item.rate}
                      </td>
                      <td className="px-6 py-4">{item.speed}</td>
                      <td
                        className="px-6 py-1 overflow-auto w-80 md:w-96"
                        style={{
                          maxHeight: "5em",
                          textOverflow: "ellipsis",
                          display: "block",
                        }}
                      >
                        {item.member_info}
                      </td>
                    </tr>
                  );
                })
              : servicesByCategories.map((service) => {
                  return (
                    <>
                      <tr key={service.nama_category}>
                        <td colSpan={5} className=" bg-gray-500 p-2">
                          <div
                            className="text-base ml-4 text-white"
                            dangerouslySetInnerHTML={{
                              __html: service.nama_category,
                            }}
                          />
                        </td>
                      </tr>
                      {service.products.map((item) => {
                        return (
                          <tr key={item.id} className="bg-white border-b ">
                            <th
                              scope="row"
                              className="px-6 py-4 font-medium text-gray-900 "
                            >
                              {item.id} - {item.service_name}
                            </th>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <img
                                src="/assets/biscoin.svg"
                                alt="saldo"
                                width={20}
                                className="mr-2 float-left w-max"
                              />
                              {item.rate.toLocaleString("id")}
                            </td>
                            <td className="px-6 py-4">
                              {item.min} / {item.max}
                            </td>
                            <td className="px-6 py-4">
                              {handleTranslate(item.speed)}
                            </td>
                            <td
                              className="px-6 py-1 overflow-auto w-80 md:w-96"
                              style={{
                                maxHeight: "5em",
                                textOverflow: "ellipsis",
                                display: "block",
                              }}
                            >
                              {item.member_info}
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  );
                })}
          </tbody>
        </table>
      </InfiniteScroll>
    </div>
  );
};

export default PriceListTable;
