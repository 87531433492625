import moment from "moment";
import { useStateContext } from "../../contexts/ContextProvider";
import { ArticleCard } from "./ArticleCard";
import { HighlightArticle } from "./HighlightArticle";
import { useTranslation } from "react-i18next";

export const Articles = () => {
  const { t } = useTranslation();
  const { articles } = useStateContext();

  return (
    <div className="w-full mt-5 p-4">
      <p className="text-left">{t("articlesTitle")}</p>
      <p className="text-left text-gray-400 text-sm">
        {t("articlesDescription")}
      </p>
      <HighlightArticle />
      <p className="text-left my-4">{t("otherArticle")}</p>
      <div className="w-full flex overflow-x-scroll space-x-4 sm:space-x-8">
        {articles?.map((article, index) => {
          return (
            index !== 0 && (
              <div key={index}>
                <a key={index} href={article.link}>
                  <ArticleCard
                    title={article.title.rendered}
                    createdAt={moment(article.date).format("DD MMMM YYYY")}
                    image={
                      article.better_featured_image
                        ? article.better_featured_image.source_url
                        : "https://teelindy.com/wp-content/uploads/2019/03/default_image.png"
                    }
                  />
                </a>
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};
