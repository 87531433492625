import moment from "moment";
import { useEffect, useState } from "react";
import { BiCopy } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { Input } from "../../components/mobile/Input";
import { useStateContext } from "../../contexts/ContextProvider";
import { getTopUpPending, putCancelTopUp } from "../../services/service";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { OrangeButton } from "../../components/mobile/OrangeButton";
import { useTranslation } from "react-i18next";

export const ConfirmTopUpPage = () => {
  const { t } = useTranslation();
  const { user, topUpPending, setTopUpPending } = useStateContext();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTutorTopup, setSelectedTutorTopup] = useState(0);

  const transferMethods = [
    {
      bank: "bri",
      methods: [
        {
          method: "Mobile Banking BRI",
          steps: [
            "<p>Buka aplikasi Mobile Banking BRI.</p>",
            "<p>Masuk ke akun Anda.</p>",
            '<p>Pilih "Transfer."</p>',
            '<p>Pilih "Ke Rekening BRI."</p>',
            `<p>Masukkan nomor rekening tujuan <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">${
              topUpPending?.bank.no_rek
            }</span> dan jumlah yang akan ditransfer <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">Rp ${topUpPending?.jumlah.toLocaleString(
              "id"
            )}</span>.</p>`,
            "<p>Konfirmasi transaksi dan ikuti petunjuk selanjutnya.</p>",
          ],
        },
        {
          method: "Internet Banking BRI",
          steps: [
            "<p>Buka situs web Internet Banking BRI.</p>",
            "<p>Masuk ke akun Anda.</p>",
            '<p>Pilih "Transfer."</p>',
            '<p>Pilih "Ke Rekening BRI."</p>',
            `<p>Masukkan nomor rekening tujuan <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">${
              topUpPending?.bank.no_rek
            }</span> dan jumlah yang akan ditransfer <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">Rp ${topUpPending?.jumlah.toLocaleString(
              "id"
            )}</span>.</p>`,
            "<p>Konfirmasi transaksi dan ikuti petunjuk selanjutnya.</p>",
          ],
        },
        {
          method: "ATM BRI",
          steps: [
            "<p>Masukkan kartu ATM BRI dan PIN Anda.</p>",
            '<p>Pilih "Menu Transaksi Lainnya."</p>',
            '<p>Pilih "Transfer."</p>',
            '<p>Pilih "Ke Rekening BRI."</p>',
            `<p>Masukkan nomor rekening tujuan <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">${topUpPending?.bank.no_rek}</span>.</p>`,
            `<p>Masukkan jumlah yang akan ditransfer <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">Rp ${topUpPending?.jumlah.toLocaleString(
              "id"
            )}</span>.</p>`,
            "<p>Konfirmasi transaksi dan simpan bukti transaksi.</p>",
          ],
        },
      ],
    },
    {
      bank: "bni",
      methods: [
        {
          method: "Mobile Banking BNI",
          steps: [
            "<p>Buka aplikasi Mobile Banking BNI.</p>",
            "<p>Masuk ke akun Anda.</p>",
            '<p>Pilih "Transfer."</p>',
            '<p>Pilih "Transfer antar rekening BNI."</p>',
            `<p>Masukkan nomor rekening tujuan <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">${
              topUpPending?.bank.no_rek
            }</span> dan jumlah yang akan ditransfer <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">Rp ${topUpPending?.jumlah.toLocaleString(
              "id"
            )}</span>.</p>`,
            "<p>Konfirmasi transaksi dan ikuti petunjuk selanjutnya.</p>",
          ],
        },
        {
          method: "Internet Banking BNI",
          steps: [
            "<p>Buka situs web Internet Banking BNI.</p>",
            "<p>Masuk ke akun Anda.</p>",
            '<p>Pilih "Transfer."</p>',
            '<p>Pilih "Transfer antar rekening BNI."</p>',
            `<p>Masukkan nomor rekening tujuan <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">${
              topUpPending?.bank.no_rek
            }</span> dan jumlah yang akan ditransfer <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">Rp ${topUpPending?.jumlah.toLocaleString(
              "id"
            )}</span>.</p>`,
            "<p>Konfirmasi transaksi dan ikuti petunjuk selanjutnya.</p>",
          ],
        },
        {
          method: "ATM BNI",
          steps: [
            "<p>Masukkan kartu ATM BNI dan PIN Anda.</p>",
            '<p>Pilih "Menu Transfer."</p>',
            '<p>Pilih "Transfer antar rekening BNI."</p>',
            `<p>Masukkan nomor rekening tujuan <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">${topUpPending?.bank.no_rek}</span>.</p>`,
            `<p>Masukkan jumlah yang akan ditransfer <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">Rp ${topUpPending?.jumlah.toLocaleString(
              "id"
            )}</span>.</p>`,
            "<p>Konfirmasi transaksi dan simpan bukti transaksi.</p>",
          ],
        },
      ],
    },
    {
      bank: "mandiri",
      methods: [
        {
          method: "Mobile Banking Mandiri",
          steps: [
            "<p>Buka aplikasi Mobile Banking Mandiri.</p>",
            "<p>Masuk ke akun Anda.</p>",
            '<p>Pilih "Transfer."</p>',
            '<p>Pilih "Ke Rekening Bank Lain."</p>',
            '<p>Pilih "Transfer ke Rekening Mandiri."</p>',
            `<p>Masukkan nomor rekening tujuan <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">${
              topUpPending?.bank.no_rek
            }</span> dan jumlah yang akan ditransfer <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">Rp ${topUpPending?.jumlah.toLocaleString(
              "id"
            )}</span>.</p>`,
            "<p>Konfirmasi transaksi dan ikuti petunjuk selanjutnya.</p>",
          ],
        },
        {
          method: "Internet Banking Mandiri",
          steps: [
            "<p>Buka situs web Internet Banking Mandiri.</p>",
            "<p>Masuk ke akun Anda.</p>",
            '<p>Pilih "Transfer."</p>',
            '<p>Pilih "Ke Rekening Bank Lain."</p>',
            '<p>Pilih "Transfer ke Rekening Mandiri."</p>',
            `<p>Masukkan nomor rekening tujuan <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">${
              topUpPending?.bank.no_rek
            }</span> dan jumlah yang akan ditransfer <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">Rp ${topUpPending?.jumlah.toLocaleString(
              "id"
            )}</span>.</p>`,
            "<p>Konfirmasi transaksi dan ikuti petunjuk selanjutnya.</p>",
          ],
        },
        {
          method: "ATM Mandiri",
          steps: [
            "<p>Masukkan kartu ATM Mandiri dan PIN Anda.</p>",
            '<p>Pilih "Menu Transfer."</p>',
            '<p>Pilih "Ke Rekening Bank Lain."</p>',
            '<p>Pilih "Transfer ke Rekening Mandiri."</p>',
            `<p>Masukkan nomor rekening tujuan <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">${topUpPending?.bank.no_rek}</span>.</p>`,
            `<p>Masukkan jumlah yang akan ditransfer <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">Rp ${topUpPending?.jumlah.toLocaleString(
              "id"
            )}</span>.</p>`,
            "<p>Konfirmasi transaksi dan simpan bukti transaksi.</p>",
          ],
        },
      ],
    },
    {
      bank: "bca",
      methods: [
        {
          method: "Mobile Banking BCA",
          steps: [
            "<p>Buka aplikasi Mobile Banking BCA.</p>",
            "<p>Masuk ke akun Anda.</p>",
            '<p>Pilih "Transfer."</p>',
            '<p>Pilih "Ke Rekening BCA."</p>',
            `<p>Masukkan nomor rekening tujuan <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">${
              topUpPending?.bank.no_rek
            }</span> dan jumlah yang akan ditransfer <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">Rp ${topUpPending?.jumlah.toLocaleString(
              "id"
            )}</span>.</p>`,
            "<p>Konfirmasi transaksi dan ikuti petunjuk selanjutnya.</p>",
          ],
        },
        {
          method: "Internet Banking BCA",
          steps: [
            "<p>Buka situs web Internet Banking BCA.</p>",
            "<p>Masuk ke akun Anda.</p>",
            '<p>Pilih "Transfer."</p>',
            '<p>Pilih "Ke Rekening BCA."</p>',
            `<p>Masukkan nomor rekening tujuan <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">${
              topUpPending?.bank.no_rek
            }</span> dan jumlah yang akan ditransfer <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">Rp ${topUpPending?.jumlah.toLocaleString(
              "id"
            )}</span>.</p>`,
            "<p>Konfirmasi transaksi dan ikuti petunjuk selanjutnya.</p>",
          ],
        },
        {
          method: "ATM BCA",
          steps: [
            "<p>Masukkan kartu ATM BCA dan PIN Anda.</p>",
            '<p>Pilih "Menu Transfer."</p>',
            '<p>Pilih "Ke Rekening BCA."</p>',
            `<p>Masukkan nomor rekening tujuan <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">${topUpPending?.bank.no_rek}</span>.</p>`,
            `<p>Masukkan jumlah yang akan ditransfer <span style="font-weight: 600; color: rgb(249 115 22 / var(--tw-bg-opacity));">Rp ${topUpPending?.jumlah.toLocaleString(
              "id"
            )}</span>.</p>`,
            "<p>Konfirmasi transaksi dan simpan bukti transaksi.</p>",
          ],
        },
      ],
    },
  ];

  const handleItemClick = (item) => {
    if (selectedTutorTopup === item) {
      // Jika item yang sama diklik lagi, tutup dropdown
      setSelectedTutorTopup(null);
    } else {
      setSelectedTutorTopup(item);
    }
  };

  const handleCancelTopUp = (e) => {
    setIsLoading(true);
    putCancelTopUp(e)
      .then((response) => {
        response.data.status === "success" && navigate("/", { replace: true });
        localStorage.removeItem("midtrans_token");
        setIsLoading(false);
      })
      .catch((error) => {
        if (
          error.response.data.message ===
          "Topup status has been cancelled, no changes have been made"
        ) {
          navigate("/", { replace: true });
        }
        setIsLoading(false);
        console.log(error);
      });
  };

  const handleCopyText = (text) => {
    window.navigator.clipboard.writeText(text).then(
      () => {
        alert("Copied!");
      },
      () => {
        alert("Failed!");
      }
    );
  };

  useEffect(() => {
    // const snapSrcUrl = "https://app.sandbox.midtrans.com/snap/snap.js";
    // const myMidtransClientKey = "SB-Mid-client-oEWqtn1bpyN1DjCT";

    // for production environments
    const myMidtransClientKey = "Mid-client-dGUkpvAawY8Srn1T"; //change this according to your client-key
    const snapSrcUrl = "https://app.midtrans.com/snap/snap.js";
    const midtrans_token = localStorage.getItem("midtrans_token");

    const script = document.createElement("script");
    script.src = snapSrcUrl;
    script.setAttribute("data-client-key", myMidtransClientKey);
    script.async = true;

    document.body.appendChild(script);

    getTopUpPending()
      .then((response) => {
        if (response.data.data) {
          if (
            response.data.data?.bank.payment_gateway === "midtrans" &&
            response.data.data.status === "Pending" &&
            midtrans_token
          ) {
            window.snap.pay(midtrans_token, {
              gopayMode: "qr",
              onSuccess: function (result) {
                localStorage.removeItem("midtrans_token");
              },
              onPending: function (result) {
                /* You may add your own implementation here */
                alert("waiting your payment!");
              },
              onError: function (result) {
                /* You may add your own implementation here */
                handleCancelTopUp(topUpPending?.id);
                alert("payment failed!");
              },
              onClose: function (result) {
                alert("payment closed!");
              },
            });
          }
        } else {
          navigate("/topup", { replace: true });
        }
        setTopUpPending(response.data.data);
      })
      .catch((error) => console.log(error));
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    topUpPending && (
      <>
        <div className="w w-screen sm:w-128 min-h-screen mx-auto py-4 px-6 shadow bg-white">
          <div>
            <h1 className="text-center text-lg">{t("pendingTopUp")}</h1>
          </div>
          <div className="mt-4 flex flex-row justify-between">
            <span>{t("paymentMethod")}</span>
            <span className="font-bold text-blue-bisnison text-xl">
              {topUpPending?.type.toUpperCase()}
            </span>
          </div>
          <span className="text-orange-500 text-xs">
            {t("payBefore")}{" "}
            {moment(topUpPending?.date_request)
              .add(1, "days")
              .format("HH:mm:ss - DD MMMM YYYY")}
          </span>
          <Input
            label={t("topupId")}
            disabled={true}
            value={topUpPending?.id}
            icon={
              <button onClick={() => handleCopyText(topUpPending?.id)}>
                <BiCopy className="mx-3 text-orange-500" />
              </button>
            }
          />
          <Input
            label="Bank"
            disabled={true}
            value={topUpPending?.bank.bank.toUpperCase()}
          />
          <Input
            label={t("accountNumber")}
            type="text"
            disabled={true}
            value={topUpPending?.bank.no_rek}
            icon={
              <button onClick={() => handleCopyText(topUpPending?.bank.no_rek)}>
                <BiCopy className="mx-3 text-orange-500" />
              </button>
            }
          />
          <Input
            label={t("accountName")}
            disabled={true}
            value={topUpPending?.bank.atas_nama}
          />
          <Input
            label={t("totalPayment")}
            type="text"
            disabled={true}
            value={`${
              topUpPending?.type === "perfectmoney" ? "$" : "Rp"
            } ${topUpPending?.jumlah.toLocaleString("id")}`}
            icon={
              <button onClick={() => handleCopyText(topUpPending?.jumlah)}>
                <BiCopy className="mx-3 text-orange-500" />
              </button>
            }
          />
          <Input
            label="Target Top up ID"
            disabled={true}
            value={topUpPending?.saldo_type}
          />
          <div className="flex flex-col space-y-2 mt-4 border-y-2 pt-2 ">
            {transferMethods.map((item) => {
              if (item.bank === topUpPending?.bank.bank) {
                return (
                  <div key={item.bank} className="">
                    <div className="cursor-pointer flex items-center justify-between mb-2">
                      <p>
                        {t("transferInstructions")} {item.bank.toUpperCase()}
                      </p>
                    </div>
                    <div>
                      <div>
                        {item.methods.map((i, index) => {
                          return (
                            <div className="">
                              <div
                                key={i.method}
                                className={`w-full flex justify-between`}
                                onClick={() => handleItemClick(index)}
                              >
                                <p className="font-semibold mb-2">{i.method}</p>
                                {selectedTutorTopup === item.bank ? (
                                  <BsChevronUp />
                                ) : (
                                  <BsChevronDown />
                                )}
                              </div>
                              <div
                                className={`${
                                  selectedTutorTopup === index
                                    ? "block"
                                    : "hidden"
                                } border-b border-gray-200 mb-2`}
                              >
                                {i.steps.map((step, indexStep) => {
                                  return (
                                    <div
                                      key={step}
                                      className="flex space-x-2 mb-2"
                                    >
                                      <div className="w-fit">
                                        <div className="flex justify-center items-center rounded-full h-5 w-5 text-xs text-white bg-orange-500 ">
                                          <p>{indexStep + 1}</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: step,
                                          }}
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              } else {
                return <></>;
              }
            })}
          </div>
          <div className="z-50">
            <OrangeButton
              className="rounded-xl bg-orange-500 w-full p-2 mt-2 text-white text-center"
              title={t("alreadyTransferred")}
              isLoading={isLoading}
              disabled={isLoading}
              onClick={() => navigate("/")}
            />
            <div className="h-5" />
            <p
              onClick={() => handleCancelTopUp(topUpPending?.id)}
              className="cursor-pointer text-center font-semibold text-sm text-red-500"
            >
              {t("cancelTopup")}
            </p>
          </div>
        </div>
      </>
    )
  );
};
