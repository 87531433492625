export const Input = (props) => {
  return (
    <div className="text-left mt-4 w-full">
      <div className="flex justify-between text-left text-sm">
        <div className="flex gap-x-2 items-center">
          <label>{props.label}</label>
          {props.iconHelp}
        </div>
        {props.iconLabel}
      </div>
      <div className="mt-2 relative">
        <input
          accept={props.accept}
          onChange={props.change}
          type={props.type}
          disabled={props.disabled}
          className={`w-full rounded-full py-2 px-4 border border-gray-300 text-sm ${props.className}`}
          placeholder={props.placeholder}
          value={props.value}
          autoComplete={props.autoComplete}
        />
        <div className="absolute right-0 top-2">{props.icon}</div>
      </div>
    </div>
  );
};
