import { useTranslation } from "react-i18next";
import { useStateContext } from "../../contexts/ContextProvider";

const LanguageSwitcher = () => {
  const { t, i18n } = useTranslation();
  const { setSelectedTab } = useStateContext();

  const handleToggle = () => {
    if (i18n.language === "en") {
      localStorage.setItem("language", "id");
      i18n.changeLanguage("id");
    } else {
      localStorage.setItem("language", "en");
      i18n.changeLanguage("en");
    }
    setTimeout(() => {
      setSelectedTab(t("orderTypes.singleOrder"));
    }, 1000);
  };

  return (
    <button
      onClick={handleToggle}
      className="rounded-lg flex items-center  px-2 py-1.5 space-x-2"
    >
      <img
        src={
          i18n.language === "en"
            ? "/flags/US.png"
            : i18n.language === "id"
            ? "/flags/ID.png"
            : ""
        }
        alt="flag"
        className="shadow-md"
      />
      {/* <span className="font-semibold">{i18n.language.toUpperCase()}</span> */}
    </button>
  );
};

export default LanguageSwitcher;
