export const PremiumLevel = () => {
  return (
    <div className="mb-10">
      <div className="text-center">
        <div className=" font-medium text-lg">
          <span>Your Level Member</span>
        </div>
        <div className="w-44 mx-auto my-4">
          <div className="bg-blue-800 rounded-full px-4 py-2 uppercase text-white">
            <span>COCKROACH</span>
          </div>
        </div>
      </div>
      <div className=" text-center">
        <p className="font-medium text-lg">Benefit Premium Member</p>
      </div>
      <div className="text-sm">
        <div className="flex flex-row w-full my-4">
          <img src="/assets/technicalsupport.svg" alt="" className="mr-3" />
          <p>Support Whatsapp,Telegram and Support Ticket</p>
        </div>
        <div className="flex flex-row w-full my-4">
          <img src="/assets/gift.svg" alt="" className="mr-3" />
          <p>Bonus Every Top up 11%</p>
        </div>
        <div className="flex flex-row w-full my-4">
          <img src="/assets/medal.svg" alt="" className="mr-3" />
          <p>Reward Member Of The Month</p>
        </div>
        <div className="flex flex-row w-full my-4">
          <img src="/assets/change.svg" alt="" className="mr-3" />
          <p>Exchange Point to Biscoin Balance</p>
        </div>
      </div>
    </div>
  );
};
