// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const getLanguageFromLocalStorage = () => {
  const storedLanguage = localStorage.getItem("language");
  return storedLanguage || "en"; // Bahasa default jika tidak ada yang tersimpan
};

i18n
  .use(initReactI18next) // inisialisasi react-i18next
  .init({
    resources: {
      en: {
        translation: {
          signIn: "Sign In",
          submit: "Submit",
          username: "Username",
          password: "Password",
          loginNow: "Login Now",
          forgotPassword: "Forgot Password",
          registerService: "Service List",
          dontHaveAccount: "Don't have an account?",
          registerHere: "Register Here",
          backToOldVersion: "Back to old version",
          register: "Register",
          yourUsername: "Your Username",
          email: "Email",
          yourEmail: "youremail@gmail.com",
          typeYourPassword: "Type your password",
          passwordConfirmation: "Password Confirmation",
          typeConfirmPassword: "Type your confirm password",
          phoneNumber: "Phone Number",
          otpCode: "OTP Code",
          codeVerification: "Code Verification",
          affiliateCode: "Affiliate Code",
          iAcceptTerms: "I accept the ",
          termsAndPrivacyPolicy: "Terms of Service and Privacy Policy",
          registerNow: "Register Now",
          alreadyHaveAccount: "Already have an account?",
          loginHere: "Login Here",
          sendCode: "Send Code",
          checkingCodeAffiliate: "Checking code affiliate",
          codeNotValid: "Code not valid",
          smmPanelIndonesia: "Cheapest SMM Panel in Indonesia!",
          recaptchaCheckRequired: "ReCAPTCHA must be checked!",
          successfullyRegistered: "Successfully registered",
          failedToRegister: "Failed to register",
          ipAddressError: "Error IP Address",
          usernameRequired: "You must enter a username first",
          failedToSendOTP: "Failed to send OTP code",
          usernameAlreadyInUse: "This username is already in use",
          successfullySentOTP:
            "Succesfully sent OTP code, please check your WA / Email",
          phoneNumberAlreadyInUse: "This phone number is already in use",
          incorrectPassword: "Incorrect password",
          loginFailed: "Failed to log in, there's an issue",
          enterUsernameAndPassword: "Please enter your username and password",
          emailTaken: "The email has already been taken",
          validation: {
            usernameRequired: "You must enter a username",
            emailAndPhoneRequired: "You must enter an email and phone number",
            emailRequired: "You must enter an email address",
            passwordRequired: "You must enter a password",
            passwordConfirmationRequired:
              "You must enter a password confirmation",
            phoneNumberRequired: "You must enter a phone number",
            otpRequired: "You must enter an OTP code",
            passwordNotMatch: "Password must match the password confirmation",
            tosNotAccepted: "Terms of Service must be accepted",
          },
          success: "Success!",
          forgotPasswordPage: {
            pageTitle: "Forgot Password SMM Panel Indonesia BisnisOn",
            successSendTo: "Success send to",
            title: "Forgot Password",
            emailLabel: "Email",
            loginNow: "Login Now",
          },
          tosPage: {
            requiredRead: "MUST READ",
            tosTitle: "Terms and Conditions of BisnisOn Services",
            contactAdmin:
              "If you have any questions, please contact the Admin.",
          },
          logo: {
            alt: "logo bisnison",
          },
          menu: {
            dashboard: "Dashboard",
            order: "Order",
            history: "History",
            help: "Help",
            home: "Home",
            affiliate: "Affiliate",
            profile: "Profile",
            saldo: "Balance",
            topupSaldo: "Topup Balance",
            tradePoint: "Trade Point",
            monitoringService: "Monitoring Service",
            priceList: "Price List",
            logout: "Logout",
          },
          version: "BisnisOn Version",
          salesGraphic: "Sales Graphic",
          statuses: {
            pending: "Pending",
            inProgress: "In Progress",
            done: "Done",
          },
          disableForADay: "Disable for a day",
          news: "News",
          topMonthly: "Top Monthly",
          topMember: "Top Member",
          topService: "Top Service",
          wallet: {
            walletIdr: "Wallet IDR",
            walletBiscoin: "Biscoin Wallet",
            premium: "Premium",
            seeBenefits: "See Benefits",
            clickToUpgrade: "Click To Upgrade",
          },
          orderTypes: {
            singleOrder: "Single Order",
            massOrder: "Mass Order",
          },
          selectCategory: "Select a category",
          categoryLabel: "Category",
          orderSuccess: "Order Success",
          selectCategoryPlaceholder: "Please select a category",
          servicesLabel: "Services",
          selectCategoryFirst: "Select a category first please",
          selectServicePlaceholder: "Please select a service first",
          linkTargetLabel: "Target",
          linkTargetPlaceholder: "Input your target",
          buyNow: "Buy Now",
          qualityTitle: "Quality",
          qualityDetail: "HQ",
          qualityHelptext: "Account quality HQ: High Quality, LQ: Low Quality",
          minimumTitle: "Minimum",

          minimumHelptext: "Minimum order quantity.",
          speedAverageHelptext:
            "Average service speed for 1000 qty over the last 10 completed orders.",
          maximumHelptext: "Maximum order quantity.",
          speedAverageTitle: "Average Speed",
          maximumTitle: "Maximum",
          descriptionPlaceholder: "Please choose a service first",
          notEnoughData: "Not Enough Data",
          quantity: "Quantity",
          minimum: "Minimum",
          maximum: "Maximum",
          discount: "Discount",
          articlesTitle: "Articles",
          articlesDescription: "Some articles that will add to your insight",
          otherArticle: "Other Article",

          statistic: "Statistic",
          price_list: "Price List",
          monitoring_services: "Monitoring Services",
          top_monthly: "Top Monthly",
          ticket: "Ticket",
          premium: "Premium",
          refill: "Refill",
          coming_soon: "Coming Soon",

          hours: "Hours",
          minutes: "Minutes",
          speedAverage: "Speed Average",
          averageSpeedDescription:
            "Average speed of service for 1000 qty for the last 10 completed orders",
          minimumOrder: "Minimum order quantity",
          maximumOrder: "Maximum order quantity",
          description: "Description",
          chooseCategoryFirst: "Please choose a category first",
          readMore: "Read More",

          category: "Category",
          customComment: "Custom Comment",
          total: "Total",
          cost: "Cost",
          linkTarget: "Link Target",
          inputTarget: "Input your target",
          selectService: "Please select a service",
          massOrderPlaceholder: "service_id | link | quantity",
          completedLabel: "Completed",
          pendingLabel: "Pending",
          canceledLabel: "Canceled",
          partialLabel: "Partial",
          inProgressLabel: "In Progress",
          allLabel: "All",
          orderLabel: "Order",
          historyOrderLabel: "History Order",
          page: "Page",

          order: "Order",
          date: "Date",
          service: "Service",
          target: "Target",
          startCounts: "Start Counts",
          remains: "Remains",
          price: "Price",
          balance: "Balance",
          comment: "Comment",
          status: "Status",
          lastUpdate: "Last Update",
          failed: "Failed",
          orderUnsuccessful: "Order was unsuccessfully submitted",
          orderSuccessful: "Order was successfully submitted",
          singleOrder: "Single Order",
          massOrder: "Mass Order",
          faq: "Frequently Asked Questions",
          contactAdmin: "Contact Admin",
          howToOrder: "How to Order",
          termsAndConditions: "Terms and Conditions",

          benefitAffiliate: "Benefit Affiliate",
          affiliateLink: "Affiliate Link",
          person: "Person",
          people: "People",
          commissionAmount: "Commission Amount",
          registeredAmount: "Registered Amount",
          withdrawalAffiliate: "Withdrawal affiliate",
          takeCommission: "Take Commission",
          comingSoon: "Coming Soon!",
          featureNotAvailable: "This feature is not available",
          ok: "OK",
          copied: "Copied!",
          numberVisitors: "Number of visitors",
          commission1: "Commission 50.000 when their level 1 downline upgrades",
          commission2:
            "1% commission from their level 1 downline's first top-up. Max 10K (Biscoin top-up).",
          commission3:
            "10% commission from their level 1 downline's first top-up. Max 100K (Biscoin top-up).",
          commission4:
            "Commission 100.000, when their level 1 downline upgrades",
          commission5:
            "Commission 25.000, when their level 2 downline upgrades",
          bonusTopup:
            "First topup bonus is applicable within 30 days since the downline user registers",
          close: "Close",
          registerDate: "Registration Date",
          commission: "Commission",
          upgradeCommission: "Upgrade Commission",
          upgradeDate: "Upgrade Date",
          commissionHistory: "Commission History",
          withdrawCommission: "Withdraw Commission",
          addBank: "Add Bank",
          myBankAccount: "My Bank Account",
          support: "Support",
          changePassword: "Change Password",
          myProfile: "My Profile",
          apiKey: "API Key",
          userType: "User Type",
          currency: "Currency",
          verification: "Verification",
          verified: "Verified",
          otpVerification: "Verify OTP",
          otpSentMessage: "A verification code has been sent to",
          resendOTP: "Send OTP Again",
          resendIn: "Resend OTP in",
          warning: "Warning",
          balanceNotConverted:
            "The balance will not be converted to the new currency",
          servicePriceInfo: "Regarding the price of the service",
          topUpMethodInfo: "Regarding the top-up method",

          point: "Point",
          myPoint: "My Point",
          history: "History",
          myHistory: "My History",
          selectStatus: "Select a Status",
          cancel: "Cancel",
          paid: "Paid",
          continue: "Continue",
          oldPassword: "Old Password",
          newPassword: "New Password",
          confirmNewPassword: "Confirm New Password",
          generateApiKey: `Generate API Key`,
          apiDocs: "API Docs",
          getServiceId: "Get Service ID",
          earned: "Earned",
          serviceId: "Service ID",
          whatsappRegularMember: "WhatsApp Regular Member",
          whatsappPremiumMember: "WhatsApp Premium & VIP Member",
          fastResponse:
            "Fast Response, Chat is replied during working hours only",
          upgradeToPremium:
            "Upgrade to Premium Member to get Whatsapp service support Fast Response (First Priority)",
          chatRepliesBasedOnQueue: "Chat replies based on Chat Queue",

          promo: "Promo",
          updates: "Updates",
          notification: "Notification",
          salesStatistic: "Sales Statistics",
          serviceName: "Service Name",
          minMax: "Min/Max",
          ratePer1K: "Rate/1K",
          speedAvg: "Speed AVG",
          "id-service": "ID - Service",
          last7Days: "Last 7 Days",
          categoryType: "Category Type",
          processTime: "Process Time",
          created: "Created",
          completed: "Completed",
          showAll: "Show All",
          maintenance: "Service is currently under maintenance.",
          time: {
            year: "Year",
            month: "Month",
            week: "Week",
            day: "Day",
            hour: "Hour",
            minute: "Minute",
            second: "Second",
          },
          searchTarget: "Search Target",

          targetSaldo: "Target Balance",
          chooseTargetSaldo: "Choose Target Balance",
          paymentMethod: "Payment Method",
          chooseTargetSaldoFirst: "Choose Target Balance First",
          destinationAccount: "Destination Account",
          chooseDestinationAccount: "Choose Destination Account",
          alreadyTransferred: "I have already transferred",
          cancelTopup: "Cancel Top-up",
          minimumTopUp: "Minimum Top-up",
          nominalTopUp: "Top-up Amount",
          receivedNominal: "Received Amount",
          pendingTopUp: "Pending Top-up",
          payBefore: "Pay Before",
          topupId: "Top-up ID",
          accountNumber: "Account Number",
          accountName: "Account Name",
          totalPayment: "Total Payment",
          targetTopupId: "Target Top-up ID",
          transferInstructions: "Transfer Instructions",
          mobileNumber: "Mobile Number",
          topupNow: "Top-up Now",
          premiumContentMessage:
            "Access to this content is only available to the TOP 10 members or premium members. Upgrade your membership to become a Premium Member and enjoy the benefits!",
          backToHome: "Back to Home",
          payoutPoints: "Payout Points",

          minimumPayout: "Minimum Payout",
          myPoints: "My Points",
          levelMember: "Level Member",
          exchangePointToBiscoin: "Exchange Point to Biscoin",
          allIn: "All In",
          rate1Point: "Rate 1 Point",
          pointsEarned: "Points Earned",
          exchangeNow: "Exchange Now",
          pointsTerms: "Points Terms",
          premiumMembersOnly:
            "Points are only earned by premium members, regular members do not get points.",
          exchangePointRates:
            "Exchange point rates vary depending on the level of the member.",
          transactionValueToPoints:
            "For every IDR 10,000 total transaction, you will get 1 point.",
          minimumPayoutPoints: "Minimum payout is 500 points.",
          newbie: "Newbie: Rate Payout 1 Point ≈ Rp 5.",
          cockroach: "Cockroach: Rate Payout 1 Point ≈ Rp 10.",
          ponies: "Ponies: Rate Payout 1 Point ≈ Rp 25.",
          centaurs: "Centaurs: Rate Payout 1 Point ≈ Rp 50.",
          unicorn: "Unicorn: Rate Payout 1 Point ≈ Rp 75.",
          decacorn: "Decacorn: Rate Payout 1 Point ≈ Rp 100.",
          hectocorn: "Hectocorn: Rate Payout 1 Point ≈ Rp 125.",
          pointsFromSMKTransactions:
            "Points are obtained from SMK transactions.",

          searchByOrderId: "Search By Order ID",
          promotion: "Promotion",
          subject: "Subject",
          useVersion: "Use version",
          ticketPageNotAvailable: "Ticket Page is not available in version 4.0",
          premiumPageNotAvailable:
            "Premium Page is not available in version 4.0",
          viewPremiumDetails: "View Premium Details",
          refillPageNotAvailable: "Refill Page is not available in version 4.0",

          benefitPremiumMember: "Benefit Premium Member",
          allFeaturesInRegular: "Have All Feature in Regular",
          pointAffiliate: "Have a Point Affiliate",
          marketingKit: "Marketing Kit",
          eBookGuide: "E-Book Giude BisnisOn",
          lifetimeMember: "Lifetime Member",
          lifetime: "Lifetime",
          customizeAffiliate: "Customize Affiliate",
          vipPremiumMemberCard: "VIP Premium Member Card",

          benefitPremiumPlan: "Benefit Premium Plan",
          customUrlAffiliate: "Custom Affiliate URL",
          benefitDescriptions: {
            1: "10% commission from the first top-up of level 1 downline (Biscoin top-up). Max 100K (withdrawable to commission balance).",
            2: "1% commission from subsequent top-ups of level 1 downline (Biscoin top-up). Max 10K (withdrawable to commission balance).",
            3: "Rp 100,000 commission when level 1 downline upgrades (withdrawable to commission balance).",
            4: "Rp 25,000 commission when level 2 downline upgrades (withdrawable to commission balance).",
          },
          customerServicePriority:
            "First Priority Customer Service via Whatsapp & Telegram.",
          joinPremiumGroup: "Join Premium Member Group",
          eventPromoDiscount: "Event Promo Service Discounts",
          specialPriceRequest: "Special Price By Request",
          unlockMemberLevel: "Unlock Member Levels",
          bonusPoints:
            "Bonus Points can be exchanged for balance & attractive Merchandise",
          eBookTutorial: "E-Book Tutorials",
          freeMarketingKit:
            "Free Marketing Kit (Testimonial Screenshots, Promo Design Templates)",

          takePhoto: "Take an ID Card Photo",
          photoSent:
            "Your photo has been sent to our server. Our customer will confirm if you have checked your photo ID.",
          loading: "Loading",
          delete: "Delete",
          setMainBank: "Set as main bank",
          deleteBank: "Delete Bank",
          confirmDeleteAccount: "Do you want to delete the account?",
          cannotRecoverMessage: "By deleting it, you can't recover it again",
          helpText: {
            usernameLabel:
              "Please enter your username to log in to the BisnisOn app, minimum 5 characters, no spaces or symbols",
            passwordLabel:
              "Please enter your password to log in to the BisnisOn app, minimum 6 characters, a combination of letters & numbers",
            emailLabel: "Enter your active email to receive the OTP code",
            phoneLabel:
              "Enter the phone number connected to WhatsApp to receive the OTP code",
            otpLabel: "Enter the OTP code you received via WhatsApp / Email",
            affiliateCode:
              "Affiliate code will be automatically filled by the system",
          },
          inputEmailUsernamePhone:
            "Enter your email, phone, or username and we'll send you a link to get back into your account",
          forgotPasswordPlaceholder: "Email, Phone, or Username",
          troubleLoggingIn: "Trouble Logging In",
        },
      },
      id: {
        translation: {
          signIn: "Masuk",
          submit: "Kirim",
          username: "Nama Pengguna",
          password: "Kata Sandi",
          loginNow: "Login Sekarang",
          forgotPassword: "Lupa Kata Sandi",
          registerService: "Daftar Layanan",
          dontHaveAccount: "Belum punya akun?",
          registerHere: "Daftar Di Sini",
          backToOldVersion: "Kembali ke versi lama",
          register: "Daftar",
          yourUsername: "Nama Pengguna Anda",
          email: "Email",
          yourEmail: "emailanda@gmail.com",
          typeYourPassword: "Ketik kata sandi Anda",
          passwordConfirmation: "Konfirmasi Kata Sandi",
          typeConfirmPassword: "Ketik konfirmasi kata sandi Anda",
          phoneNumber: "Nomor Telepon",
          otpCode: "Kode OTP",
          codeVerification: "Verifikasi Kode",
          affiliateCode: "Kode Afiliasi",
          iAcceptTerms: "Saya menerima ",
          termsAndPrivacyPolicy: "Syarat Layanan dan Kebijakan Privasi",
          registerNow: "Daftar Sekarang",
          alreadyHaveAccount: "Sudah punya akun?",
          loginHere: "Masuk di Sini",
          sendCode: "Kirim Kode",
          checkingCodeAffiliate: "Memeriksa kode afiliasi",
          codeNotValid: "Kode tidak valid",
          smmPanelIndonesia: "Panel SMM Indonesia Termurah!",
          recaptchaCheckRequired: "ReCAPTCHA harus dicentang!",
          successfullyRegistered: "Pendaftaran berhasil",
          failedToRegister: "Gagal mendaftar",
          ipAddressError: "Error Alamat IP",
          usernameRequired: "Anda harus mengisi username terlebih dahulu",
          failedToSendOTP: "Gagal mengirim kode OTP",
          usernameAlreadyInUse: "Username ini sudah digunakan",
          successfullySentOTP:
            "Berhasil mengirim OTP, silahkan cek WA / Email Anda",
          phoneNumberAlreadyInUse: "Nomor telepon ini sudah digunakan",
          incorrectPassword: "Password anda salah",
          loginFailed: "Gagal masuk, ada masalah",
          enterUsernameAndPassword: "Masukkan username dan password",
          emailTaken: "Email ini sudah digunakan",
          validation: {
            usernameRequired: "Anda harus mengisi nama pengguna",
            emailAndPhoneRequired: "Anda harus mengisi email dan nomor hp",
            emailRequired: "Anda harus mengisi alamat email",
            passwordRequired: "Anda harus mengisi kata sandi",
            passwordConfirmationRequired:
              "Anda harus mengisi konfirmasi kata sandi",
            phoneNumberRequired: "Anda harus mengisi nomor telepon",
            otpRequired: "Anda harus mengisi kode OTP",
            passwordNotMatch:
              "Kata sandi harus sama dengan konfirmasi kata sandi",
            tosNotAccepted: "Syarat Layanan harus diterima",
          },
          success: "Sukses!",
          forgotPasswordPage: {
            pageTitle: "Lupa Kata Sandi SMM Panel Indonesia BisnisOn",
            successSendTo: "Berhasil dikirim ke ",
            title: "Lupa Kata Sandi",
            emailLabel: "Email",
            loginNow: "Login Sekarang",
          },
          tosPage: {
            requiredRead: "WAJIB BACA",
            tosTitle: "Syarat dan Ketentuan Layanan BisnisOn",
            contactAdmin: "Jika ada pertanyaan, silahkan kontak Admin.",
          },
          logo: {
            alt: "logo bisnison",
          },
          menu: {
            dashboard: "Dasbor",
            order: "Pesanan",
            history: "Riwayat",
            help: "Bantuan",
            home: "Beranda",
            affiliate: "Afiliasi",
            profile: "Profil",
            saldo: "Saldo",
            topupSaldo: "Isi Saldo",
            tradePoint: "Tukar Poin",
            monitoringService: "Layanan Pemantauan",
            priceList: "Daftar Harga",
            logout: "Keluar",
          },
          version: "Versi BisnisOn",
          salesGraphic: "Grafik Penjualan",
          statuses: {
            pending: "Menunggu",
            inProgress: "Sedang Berlangsung",
            done: "Selesai",
          },
          disableForADay: "Nonaktifkan selama satu hari.",
          news: "Berita",
          topMonthly: "Top Bulanan",
          topMember: "Top Member",
          topService: "Top Layanan",
          wallet: {
            walletIdr: "Dompet Rupiah",
            walletBiscoin: "Dompet Biscoin",
            premium: "Premium",
            seeBenefits: "Lihat Manfaat",
            clickToUpgrade: "Upgrade disini",
          },
          orderTypes: {
            singleOrder: "Pesanan Tunggal",
            massOrder: "Pesanan Massal",
          },
          selectCategory: "Pilih kategori",
          categoryLabel: "Kategori",
          selectCategoryPlaceholder: "Silakan pilih kategori ",
          servicesLabel: "Layanan",
          selectCategoryFirst: "Pilih kategori terlebih dahulu",
          selectServicePlaceholder: "Silakan pilih kategori terlebih dahulu",
          linkTargetLabel: "Target",
          linkTargetPlaceholder: "Masukkan tujuan Anda",
          buyNow: "Beli Sekarang",
          qualityTitle: "Kualitas",
          qualityDetail: "HQ",
          qualityHelptext: "Kualitas akun HQ: High Quality, LQ: Low Quality",
          minimumTitle: "Minimal",

          minimumHelptext: "Jumlah pesanan minimal.",
          speedAverageHelptext:
            "Rata-rata kecepatan layanan untuk 1000 qty dari 10 pesanan terakhir yang telah selesai.",
          maximumHelptext: "Jumlah pesanan maksimal.",
          speedAverageTitle: "Rata-rata Kecepatan",
          maximumTitle: "Maksimum",
          descriptionPlaceholder: "Silakan pilih layanan terlebih dahulu",
          notEnoughData: "Data tidak cukup",
          quantity: "Jumlah",
          orderSuccess: "Berhasil Diorder",
          minimum: "Minimal",
          maximum: "Maksimal",
          discount: "Diskon",
          articlesTitle: "Artikel",
          articlesDescription:
            "Beberapa artikel yang akan menambah wawasan Anda",
          otherArticle: "Artikel Lainnya",
          statistic: "Statistik",
          priceList: "Daftar Harga",
          monitoringServices: "Pemantauan Layanan",
          ticket: "Tiket",
          premium: "Premium",
          refill: "Isi Ulang",
          comingSoon: "Segera Hadir",

          speedAverage: "Rata-rata Kecepatan",
          averageSpeedDescription:
            "Rata-rata kecepatan layanan untuk 1000 qty selama 10 pesanan terakhir yang selesai",
          minimumOrder: "Jumlah pesanan minimal",
          maximumOrder: "Jumlah pesanan maksimal",
          description: "Deskripsi",
          chooseCategoryFirst: "Silakan pilih kategori terlebih dahulu",

          category: "Kategori",
          customComment: "Komentar Kustom",
          total: "Total",
          cost: "Biaya",
          linkTarget: "Link Tujuan",
          inputTarget: "Masukkan tujuan Anda",
          selectService: "Silakan pilih layanan",
          massOrderPlaceholder: "id_layanan | link | jumlah",
          completedLabel: "Selesai",
          pendingLabel: "Menunggu",
          canceledLabel: "Dibatalkan",
          partialLabel: "Sebagian",
          inProgressLabel: "Sedang Berlangsung",
          allLabel: "Semua",
          orderLabel: "Pesanan",
          historyOrderLabel: "Riwayat Pesanan",
          page: "Halaman",
          order: "Pesanan",
          date: "Tanggal",
          service: "Layanan",
          target: "Target",
          startCounts: "Semula",
          remains: "Sisa",
          price: "Harga",
          balance: "Saldo",
          comment: "Komentar",
          status: "Status",
          lastUpdate: "Pembaruan Terakhir",
          failed: "Gagal",
          orderUnsuccessful: "Pemesanan tidak berhasil disubmit",
          orderSuccessful: "Pemesanan berhasil disubmit",
          singleOrder: "Pemesanan Tunggal",
          massOrder: "Pemesanan Massal",
          faq: "Pertanyaan yang Sering Diajukan",
          contactAdmin: "Hubungi Admin",
          howToOrder: "Cara Pesan",
          termsAndConditions: "Syarat dan Ketentuan",
          readMore: "Baca Selengkapnya",

          benefitAffiliate: "Keuntungan Afiliasi",
          affiliateLink: "Tautan Afiliasi",
          person: "Orang",
          people: "Orang",
          commissionAmount: "Jumlah Komisi",
          registeredAmount: "Jumlah yang Terdaftar",
          withdrawalAffiliate: "Penarikan Afiliasi",
          takeCommission: "Ambil Komisi",
          featureNotAvailable: "Fitur ini tidak tersedia",
          ok: "OK",
          copied: "Tersalin!",
          numberVisitors: "Jumlah pengunjung",
          commission1: "Komisi 50.000 ketika downline lv 1 nya upgrade",
          commission2:
            "Komisi 1% dari top up pertama downline lv 1 nya. Maksimal 10K (top up Biscoin).",
          commission3:
            "Komisi 10% dari top up pertama downline lv 1 nya. Maksimal 100K (top up Biscoin).",
          commission4: "Komisi 100.000 ketika downline lv 1 nya upgrade",
          commission5: "Komisi 25.000 ketika downline lv 2 nya upgrade",
          bonusTopup:
            "Bonus topup pertama hanya berlaku dalam waktu 30 hari sejak pendaftaran downline",
          close: "Tutup",
          registerDate: "Tanggal Pendaftaran",
          commission: "Komisi",
          upgradeCommission: "Komisi Upgrade",
          upgradeDate: "Tanggal Upgrade",
          commissionHistory: "Riwayat Komisi Yang didapat",
          withdrawCommission: "Tarik Komisi",
          addBank: "Tambah Bank",
          myBankAccount: "Akun Bank Saya",
          support: "Bantuan",
          changePassword: "Ganti Kata Sandi",
          myProfile: "Profil Saya",
          apiKey: "Kunci API",
          userType: "Tipe Pengguna",
          currency: "Mata Uang",
          verification: "Verifikasi",
          verified: "Terverifikasi",
          otpVerification: "Verifikasi OTP",
          otpSentMessage: "Sebuah kode verifikasi telah dikirim ke",
          resendOTP: "Kirim OTP Kembali",
          resendIn: "Kirim Ulang OTP dalam",
          warning: "Peringatan",
          balanceNotConverted: "Saldo tidak akan dikonversi ke mata uang baru",
          servicePriceInfo: "Mengenai harga layanan",
          topUpMethodInfo: "Mengenai metode top up",
          point: "Poin",
          myPoint: "Poin Saya",
          history: "Riwayat",
          myHistory: "Riwayat Saya",
          selectStatus: "Pilih status",
          cancel: "Batal",
          paid: "Dibayar",
          continue: "Lanjutkan",
          oldPassword: "Kata Sandi Lama",
          newPassword: "Kata Sandi Baru",
          confirmNewPassword: "Konfirmasi Kata Sandi Baru",
          generateApiKey: "Buat API Key",
          apiDocs: "Dok. API",
          getServiceId: "Dapatkan ID Layanan",
          earned: "Dapat",
          serviceId: "ID Layanan",
          whatsappRegularMember: "WhatsApp Anggota Reguler",
          whatsappPremiumMember: "WhatsApp Anggota Premium & VIP",
          fastResponse: "Respon cepat, Chat akan dijawab selama jam kerja",
          upgradeToPremium:
            "Upgrade ke Anggota Premium untuk mendapatkan dukungan layanan Whatsapp dengan Respon Cepat (Prioritas Utama)",
          chatRepliesBasedOnQueue: "Balasan chat berdasarkan Antrian Chat",

          promo: "Promosi",
          updates: "Pembaruan",
          notification: "Notifikasi",
          salesStatistic: "Statistik Penjualan",
          serviceName: "Nama Layanan",
          minMax: "Min/Maks",
          ratePer1K: "Tarif/1K",
          speedAvg: "Kecepatan Rata-rata",
          "id-service": "ID - Layanan",
          lastDays: "Hari Terakhir",
          categoryType: "Jenis Kategori",
          processTime: "Waktu Proses",
          created: "Dibuat",
          completed: "Selesai",
          showAll: "Tampilkan Semua",
          maintenance: "Layanan sedang dalam pemeliharaan.",
          time: {
            year: "Tahun",
            month: "Bulan",
            week: "Minggu",
            day: "Hari",
            hour: "Jam",
            minute: "Menit",
            second: "Detik",
          },
          searchTarget: "Cari Target",
          payoutPoints: "Pembayaran Poin",

          targetSaldo: "Target Saldo",
          chooseTargetSaldo: "Pilih Target Saldo",
          paymentMethod: "Metode Pembayaran",
          chooseTargetSaldoFirst: "Pilih Target Saldo Terlebih Dahulu",
          destinationAccount: "Nomor Rekening Tujuan",
          chooseDestinationAccount: "Pilih Nomor Rekening Tujuan",
          alreadyTransferred: "Saya sudah mentransfer",
          cancelTopup: "Batal Top Up",
          minimumTopUp: "Minimal Top Up",
          nominalTopUp: "Nominal Top Up",
          receivedNominal: "Nominal yang Diterima",
          pendingTopUp: "Top Up Menunggu",
          payBefore: "Bayar Sebelum",
          topupId: "ID Top Up",
          accountNumber: "Nomor Rekening",
          accountName: "Atas Nama",
          totalPayment: "Total Pembayaran",
          targetTopupId: "ID Top Up Target",
          transferInstructions: "Petunjuk Transfer",
          mobileNumber: "Nomor HP",
          topupNow: "Top Up Sekarang",
          premiumContentMessage:
            "Data hanya dapat dilihat jika kamu termasuk dari TOP 10 atau jika kamu adalah premium member. Ayooo tingkatkan transaksimu atau upgrade keanggotaan menjadi Premium Member.",
          backToHome: "Kembali ke Beranda",
          minimumPayout: "Pembayaran Minimum",
          myPoints: "Poin Saya",
          levelMember: "Level Member",
          exchangePointToBiscoin: "Tukar Poin ke Biscoin",
          allIn: "Semua",
          rate1Point: "Tarif 1 Poin",
          pointsEarned: "Poin Yang Didapat",
          exchangeNow: "Tukar Sekarang",
          pointsTerms: "Syarat Poin",
          premiumMembersOnly:
            "Poin hanya diperoleh oleh anggota premium, anggota reguler tidak mendapatkan poin.",
          exchangePointRates:
            "Tarif pertukaran poin bervariasi tergantung pada level anggota.",
          transactionValueToPoints:
            "Untuk setiap transaksi total IDR 10.000, Anda akan mendapatkan 1 poin.",
          minimumPayoutPoints: "Pembayaran minimum adalah 500 poin.",
          newbie: "Pemula: Tarif Pembayaran 1 Poin ≈ Rp 5.",
          cockroach: "Kecoa: Tarif Pembayaran 1 Poin ≈ Rp 10.",
          ponies: "Ponies: Tarif Pembayaran 1 Poin ≈ Rp 25.",
          centaurs: "Centaurs: Tarif Pembayaran 1 Poin ≈ Rp 50.",
          unicorn: "Unicorn: Tarif Pembayaran 1 Poin ≈ Rp 75.",
          decacorn: "Decacorn: Tarif Pembayaran 1 Poin ≈ Rp 100.",
          hectocorn: "Hectocorn: Tarif Pembayaran 1 Poin ≈ Rp 125.",
          pointsFromSMKTransactions: "Poin diperoleh dari transaksi SMK.",

          searchByOrderId: "Cari Berdasarkan ID Pesanan",
          promotion: "Sedang Promo",
          subject: "Subjek",
          useVersion: "Gunakan versi",
          ticketPageNotAvailable: "Halaman Tiket belum tersedia di versi 4.0",
          premiumPageNotAvailable:
            "Halaman Premium belum tersedia di versi 4.0",
          viewPremiumDetails: "Lihat Detail Premium",
          refillPageNotAvailable:
            "Halaman Isi Ulang belum tersedia di versi 4.0",

          benefitPremiumMember: "Keuntungan Anggota Premium",
          allFeaturesInRegular: "Miliki Semua Fitur Reguler",
          pointAffiliate: "Memiliki Poin Afiliasi",
          marketingKit: "Peralatan Pemasaran",
          eBookGuide: "Panduan E-Book BisnisOn",
          lifetimeMember: "Anggota Seumur Hidup",
          lifetime: "Seumur Hidup",
          customizeAffiliate: "Kustomisasi Afiliasi",
          vipPremiumMemberCard: "Kartu Anggota Premium VIP",

          benefitPremiumPlan: "Manfaat Paket Premium",
          customUrlAffiliate: "URL Afiliasi Kustom",
          benefitDescriptions: {
            1: "Komisi 10% dari top up pertama downline level 1 (topup Biscoin). Maks. 100K (bisa ditarik ke saldo komisi).",
            2: "Komisi 1% dari top up selanjutnya downline level 1 (top up Biscoin). Maks. 10K (bisa ditarik ke saldo komisi).",
            3: "Komisi Rp 100.000 ketika downline level 1 meng-upgrade (bisa ditarik ke saldo komisi).",
            4: "Komisi Rp 25.000 ketika downline level 2 meng-upgrade (bisa ditarik ke saldo komisi).",
          },
          customerServicePriority:
            "Layanan Pelanggan Prioritas Utama melalui Whatsapp & Telegram.",
          joinPremiumGroup: "Bergabung dengan Grup Anggota Premium",
          eventPromoDiscount: "Promo Diskon Layanan Acara",
          specialPriceRequest: "Harga Khusus Dengan Permintaan",
          unlockMemberLevel: "Buka Kunci Tingkatan Anggota",
          bonusPoints:
            "Poin Bonus dapat ditukar dengan saldo & Merchandise menarik",
          eBookTutorial: "Buku Elektronik Tutorial",
          freeMarketingKit:
            "Peralatan Pemasaran Gratis (Tangkapan Layar Testimoni, Templat Desain Promo)",
          takePhoto: "Ambil Foto Kartu Identitas",
          photoSent:
            "Foto Anda telah dikirim ke server kami. Pelanggan kami akan mengonfirmasi apakah Anda telah memeriksa foto ID Anda.",
          loading: "Memuat",
          delete: "Hapus",
          setMainBank: "Jadikan sebagai bank utama",
          deleteBank: "Hapus Bank",
          confirmDeleteAccount: "Apakah Anda ingin menghapus akun?",
          cannotRecoverMessage:
            "Dengan menghapusnya, Anda tidak dapat memulihkannya lagi",
          helpText: {
            usernameLabel:
              "Silahkan masukkan username untuk login ke aplikasi BisnisOn, minimal 5 karakter, tanpa spasi dan simbol",
            passwordLabel:
              "Silahkan masukkan password untuk login ke aplikasi BisnisOn, minimal 6 karakter kombinasi huruf & angka",
            emailLabel: "Masukkan email aktif untuk menerima kode OTP",
            phoneLabel:
              "Masukkan no HP yang terhubung ke WA untuk menerima kode OTP",
            otpLabel: "Masukkan kode OTP yang Anda terima di WA / Email",
            affiliateCode: "Kode affiliate otomatis terisi oleh sistem",
          },
          inputEmailUsernamePhone:
            "Masukkan email, No HP, atau nama pengguna Anda dan kami akan mengirimkan link untuk masuk Kembali ke akun Anda",
          forgotPasswordPlaceholder: "Email, Nomor HP, atau Username",
          troubleLoggingIn: "Kesulitan Masuk",
        },
      },
    },
    lng: getLanguageFromLocalStorage(), // Lokal awal
    fallbackLng: "en", // Fallback jika tidak ditemukan
    interpolation: {
      escapeValue: false, // Tidak perlu menghindari tanda '%' escape
    },
  });

export default i18n;
