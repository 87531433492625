import { useEffect } from "react";

export const Loading = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="w-screen sm:w-128 h-screen mx-auto bg-white flex flex-col place-items-center justify-center overflow-y-hidden">
      <div className="h-1/2 fixed">
        <img src="/assets/loading.gif" alt="loading" width={200} />
      </div>
    </div>
  );
};
