import { IoNotificationsOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

export const NotificationIcon = () => {
  const navigate = useNavigate();
  return (
    <button className="flex" onClick={() => navigate("/notification")}>
      <IoNotificationsOutline size={30} className="relative" />
      <span className="flex h-3 w-3 absolute ml-4">
        <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-red-400 opacity-75" />
        <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500" />
      </span>
    </button>
  );
};
