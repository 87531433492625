import React from "react";

export const AdminChat = (props) => {
  return (
    <div className="text-white w-full relative flex flex-row align-top">
        <div>

        <img src="/icons/bisnisoncircle.svg" alt="" />
        </div>
      <div className="bg-blue-bisnison rounded-xl p-4 mx-2" style={{"maxWidth": "70%"}}>
        <p>{props.message}</p>
        <p>{props.time}</p>
      </div>
    </div>
  );
}