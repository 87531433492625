import React from "react";
import { useStateContext } from "../../../contexts/ContextProvider";
import { Tab } from "./Tab";

export const PremiumBenefitsTab = () => {
  const { selectedTabPremiumBenefits, setSelectedTabPremiumBenefits } =
    useStateContext();
  const items = ["My Level", "My Special Price", "Marketing Kit"];

  const handlePress = (index) => {
    setSelectedTabPremiumBenefits(index);
  };

  return (
    <div className="px-4">
      <Tab
        items={items}
        onChange={handlePress}
        selectedTab={selectedTabPremiumBenefits}
      />
    </div>
  );
};
