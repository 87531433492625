import { useEffect, useState } from "react";
import { useStateContext } from "../../../contexts/ContextProvider";
import { motion } from "framer-motion";
import { getUser, postExchangePoints } from "../../../services/service";
import { Input } from "../../mobile/Input";
import { OrangeButton } from "../../mobile/OrangeButton";
import BannerDesktop from "../BannerDesktop";
import Header from "../Header";
import Marquee from "../Marquee";
import { useTranslation } from "react-i18next";

const TradePointDesktop = () => {
  const { t } = useTranslation();
  const { user, setUser } = useStateContext();
  const [isLoading, setIsLoading] = useState(false);
  const [point, setPoint] = useState("");
  const [rate, setRate] = useState(0);
  const [total, setTotal] = useState(0);
  const [message, setMessage] = useState({
    error: "",
    message: "",
  });

  const handleChange = (e) => {
    setPoint(e.target.value);
  };

  const handleReset = () => {
    setPoint("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage("");
    if (point >= 500) {
      setIsLoading(true);
      postExchangePoints(point)
        .then((response) => {
          response.data.status === "success"
            ? setMessage({ success: response.data.message })
            : setMessage({ error: response.data.points[0] });
          getUser()
            .then((responseUser) => {
              setUser(responseUser.data);
              localStorage.setItem("user", JSON.stringify(response.data));
            })
            .catch((errorUser) => console.log(errorUser));
          setIsLoading(false);
        })
        .catch((error) => {
          setMessage({ error: "Connection error" });
          console.log(error);
          setIsLoading(false);
        });
    } else {
      setMessage({ error: t("minimumPayoutPoints") });
    }
  };

  useEffect(() => {
    setTotal(point * rate);
  }, [point]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    switch (user.user_level.level) {
      case "Newbie":
        setRate(5);
        break;
      case "Cockroach":
        setRate(10);
        break;
      case "Ponies":
        setRate(25);
        break;
      case "Centaurs":
        setRate(50);
        break;
      case "Unicorn":
        setRate(75);
        break;
      case "Decacorn":
        setRate(100);
        break;
      case "Hectocorn":
        setRate(125);
        break;
      default:
        setRate(5);
    }
  }, []);
  return (
    <div>
      <Header label={"Saldo"} />
      <Marquee />
      <div className="grid grid-cols-2 p-8 gap-8">
        <div className="bg-white shadow-lg h-max rounded-lg p-4">
          <div className="w-full bg-blue-bisnison rounded-2xl p-2 flex justify-between">
            <div className="flex space-x-2 place-items-center">
              <img
                src="/assets/white-coin.svg"
                alt="white coin"
                className="w-12"
              />
              <div className="flex flex-col space-y-1">
                <p className="text-white text-sm">{t("myPoints")}</p>
                <div className="bg-orange-400 rounded-full text-white px-4 text-sm">
                  {user.point}
                </div>
              </div>
            </div>
            <div className="rounded-2xl bg-orange-400 text-white px-4 py-2 text-center text-sm">
              <p>{t("levelMember")}</p>
              <p className="font-semibold">{user.user_level.level}</p>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <Input
              label={t("exchangePointToBiscoin")}
              type="number"
              value={point}
              change={handleChange}
              placeholder={`0 Point`}
              icon={
                <span
                  className="text-orange-500 text-xs mr-2 cursor-pointer"
                  onClick={(e) => setPoint(user.point)}
                >
                  {t("allIn")}
                </span>
              }
            />
            <Input
              label={t("minimumPayout")}
              placeholder={"500"}
              disabled={true}
            />
            <Input
              label={t("rate1Point")}
              placeholder={`Rp ${rate}`}
              disabled={true}
            />
            <Input
              label={t("pointsEarned")}
              placeholder={`Rp ${total}`}
              disabled={true}
            />

            {message.error && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="bg-red-200 text-red-500 p-2 rounded w-full"
              >
                {message.error}
              </motion.div>
            )}
            {message.success && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="bg-green-200 text-green-500 p-2 rounded w-full"
              >
                {message.success}
              </motion.div>
            )}
            <div className="flex space-x-4">
              <div
                className={`bg-blue-bisnison text-white text-center py-3 mt-4 rounded-full border-none w-1/3 cursor-pointer`}
                onClick={handleReset}
              >
                Reset
              </div>
              <OrangeButton
                type="submit"
                title={t("exchangeNow")}
                isLoading={isLoading}
              />
            </div>
          </form>
        </div>
        <div className="bg-white shadow-lg h-max rounded-lg p-4">
          <div className="mt-4 text-xs">
            <p className="font-semibold">{t("pointsTerms")} :</p>
            <ul className="ml-4 mt-2">
              <li>{t("premiumMembersOnly")}</li>
              <li>
                {t("exchangePointRates")}
                {t("transactionValueToPoints")}
              </li>
            </ul>
            <ol className="ml-4 my-2">
              <li>{t("newbie")}</li>
              <li>{t("cockroach")}</li>
              <li>{t("ponies")}</li>
              <li>{t("centaurs")}</li>
              <li>{t("unicorn")}</li>
              <li>{t("decacorn")}</li>
              <li>{t("hectocorn")}</li>
            </ol>
            <p>*{t("pointsFromSMKTransactions")}</p>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 p-8 gap-8">
        <div className="">
          <BannerDesktop />
        </div>
        <div className="">
          <BannerDesktop />
        </div>
      </div>
    </div>
  );
};

export default TradePointDesktop;
