import React from "react";
import { useStateContext } from "../../../contexts/ContextProvider";
import { TakeAffiliateForm } from "./TakeAffiliateForm";
import { TakeAffiliateHistory } from "./TakeAffiliateHistory";

export const TakeAffiliateContent = (props) => {
  const { selectedTabTakeAffiliate } = useStateContext();
  switch (selectedTabTakeAffiliate) {
    case "Ambil":
      return (
        <TakeAffiliateForm
          min={props.min}
          max={props.max}
          setPopUp={props.setPopUp}
        />
      );
    case "History":
      return <TakeAffiliateHistory />;
    default:
      return null;
  }
};
