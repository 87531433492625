import { useState } from "react";
import Slider from "react-slick";

export const WalletAndBanner = () => {
  const [isActive, setIsActive] = useState(0);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    afterChange: (index) => setIsActive(index),
    appendDots: (dots) => {
      return (
        <div>
          <ul className={`pb-8`}> {dots} </ul>
        </div>
      );
    },
    customPaging: (i) => {
      return (
        <div
          className={`${
            isActive === i ? "bg-orange-500 " : " bg-gray-200"
          } rounded-full w-4 h-4 mt-10`}
        ></div>
      );
    },
  };
  return (
    <div className=" mx-auto ">
      <Slider {...settings}>
        <a href="/topup">
          <img src="/banner-ads1.jpeg" alt="promo" />
        </a>
        <img src="/banner-ads2.jpeg" alt="promo" />
        <img src="/banner-ads3.jpeg" alt="promo" />
        <img src="/banner-ads4.jpeg" alt="promo" />
        <img src="/banner-ads5.jpeg" alt="promo" />
        <img src="/banner-ads6.jpeg" alt="promo" />
        <img src="/banner-ads7.jpeg" alt="promo" />
        <img src="/banner-ads8.jpeg" alt="promo" />
      </Slider>
    </div>
  );
};
