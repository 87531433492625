import React from "react";
import { useStateContext } from "../../../contexts/ContextProvider";
import { Tab } from "../Tab";

export const TicketTab = () => {
  const { selectedTabTicket, setSelectedTabTicket } = useStateContext();
  const items = ["New Ticket", "History"];

  const handlePress = (index) => {
    setSelectedTabTicket(index);
  };

  return (
    <Tab items={items} onChange={handlePress} selectedTab={selectedTabTicket} />
  );
};
