import { useEffect, useState } from "react";
import { ImSpinner8 } from "react-icons/im";
import { IoAddCircleOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { OrangeButton } from "../../components/mobile/OrangeButton";
import {
  getCommission,
  getListBank,
  getStatusKTP,
} from "../../services/service";
import { useTranslation } from "react-i18next";

export const AffiliateMyBankPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [bankList, setBankList] = useState([]);
  const [commissionRemains, setCommissionRemains] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const statusOrder = [1, 0];
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setIsLoading(true);

    getStatusKTP()
      .then((response) => {
        const notContained = response.data.every((obj) => {
          return obj.status !== "accept";
        });
        if (notContained) {
          navigate("/affiliate/guide");
        }
      })
      .catch((error) => console.log(error));
    getListBank()
      .then((response) => {
        setBankList(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });

    getCommission()
      .then((response) => {
        setCommissionRemains(response.data.data.commission);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="w-screen sm:w-128 mx-auto bg-white min-h-screen p-4">
      <p>{t("myBankAccount")}</p>
      <p className="text-gray-400 text-xs mt-4 mb-2">{t("commissionAmount")}</p>
      <p className="text-2xl font-semibold">
        Rp {Number(commissionRemains).toLocaleString("id")}
      </p>
      {isLoading && <ImSpinner8 size={30} className="animate-spin mx-auto" />}

      {bankList
        .sort(
          (a, b) => statusOrder.indexOf(a.main) - statusOrder.indexOf(b.main)
        )
        .map((bank) => {
          return bank.status === "accept" ? (
            <BankVerified
              bankName={bank.nama_bank}
              noRekening={bank.no_rekening}
              nameHolder={bank.atas_nama}
              status={bank.status}
              main={bank.main}
              id={bank.id}
              key={bank.id}
            />
          ) : (
            <BankNonVerified
              bankName={bank.nama_bank}
              noRekening={bank.no_rekening}
              nameHolder={bank.atas_nama}
              status={bank.status}
              main={bank.main}
              id={bank.id}
              key={bank.id}
            />
          );
        })}
      <Link to="/affiliate/add-bank">
        <div className="w-full py-4 text-gray-300 cursor-pointer rounded-full border border-gray-300 mt-2 border-dashed flex flex-col justify-center place-items-center">
          <IoAddCircleOutline size={40} />
          <p className="text-sm mt-2">{t("addBank")}</p>
        </div>
      </Link>
      <Link to="/affiliate/take">
        <OrangeButton title={t("withdrawCommission")} />
      </Link>
    </div>
  );
};

const BankVerified = (props) => {
  const navigate = useNavigate();
  return (
    <div
      className="w-full rounded-lg my-8 shadow-lg px-4 py-8"
      style={{ backgroundColor: "#E4F3FF" }}
      onClick={() =>
        navigate("/affiliate/edit-bank", { state: { id: props.id } })
      }
    >
      <p className="text-2xl">{props.bankName}</p>
      <p className="text-xs mt-6 mb-2">{props.nameHolder}</p>
      <p className="text-xl">
        {props.noRekening.replace(/\d{4}(?=.)/g, "$&-")}
      </p>
      <div className="flex justify-between mt-8 place-items-center text-blue-400">
        <div className="rounded-lg border border-blue-400 p-2">
          {props.main === 1 ? "Main Bank" : props.status}
        </div>
        <p className="text-xl font-bold">{props.bankName}</p>
      </div>
    </div>
  );
};

const BankNonVerified = (props) => {
  const navigate = useNavigate();
  return (
    <div
      className="w-full rounded-lg my-8 shadow-lg px-4 py-8"
      style={{ backgroundColor: "#D4D4D4" }}
      onClick={() =>
        navigate("/affiliate/edit-bank", { state: { id: props.id } })
      }
    >
      <p className="text-2xl">{props.bankName}</p>
      <p className="text-xs mt-6 mb-2">{props.nameHolder}</p>
      <p className="text-xl">
        {props.noRekening.replace(/\d{4}(?=.)/g, "$&-")}
      </p>
      <div className="flex justify-between mt-8 place-items-center text-gray-400">
        <div className="rounded-lg border border-gray-400 p-2">
          {props.main === 1
            ? "Main Bank"
            : props.status.charAt(0).toUpperCase() + props.status.slice(1)}
        </div>
        <p className="text-xl font-bold">{props.bankName}</p>
      </div>
    </div>
  );
};
