import moment from "moment";
import { useStateContext } from "../../contexts/ContextProvider";
import { useTranslation } from "react-i18next";

export const HighlightArticle = () => {
  const { t } = useTranslation();
  const { articles } = useStateContext();
  return (
    <a href={articles[0]?.link}>
      <div className="flex space-x-4 my-4">
        <img
          src={
            articles[0]?.better_featured_image
              ? articles[0]?.better_featured_image.source_url
              : "https://teelindy.com/wp-content/uploads/2019/03/default_image.png"
          }
          className="w-1/2 rounded-3xl"
          alt="article-thumbnail"
        />
        <div className="w-full flex flex-col justify-center">
          <p className="text-gray-400 text-sm">
            {moment(articles[0]?.date).format("DD MMMM YYYY")}
          </p>
          <h1 className=" my-2">{articles[0]?.title.rendered}</h1>
          <p className="text-blue-bisnison text-sm">{t("readMore")}</p>
        </div>
      </div>
    </a>
  );
};
