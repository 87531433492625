import moment from "moment";
import { useEffect, useState } from "react";
import { getArticlesByCategory } from "../../services/service";
import { useTranslation } from "react-i18next";

const ArticlesDesktop = ({ label, id }) => {
  const { t } = useTranslation();
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    getArticlesByCategory({ id: id })
      .then((response) => {
        setArticles(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <div className="w-full p-4">
      <p className="text-left text-xl font-semibold mb-2">{label}</p>
      <div className="w-full ">
        {articles?.map((article, index) => {
          return (
            index !== 0 && (
              <div key={index} className="mb-2">
                <a key={index} href={article.link}>
                  <div className="flex rounded-2xl">
                    <img
                      src={
                        article.better_featured_image
                          ? article.better_featured_image.source_url
                          : "https://teelindy.com/wp-content/uploads/2019/03/default_image.png"
                      }
                      alt="article-thumbnail"
                      className="object-cover w-48 rounded-xl"
                    />
                    <div className="ml-2">
                      <p className="text-xs text-gray-400 ">
                        {moment(article.date).format("DD MMMM YYYY")}
                      </p>
                      <p className="">{article.title.rendered}</p>
                      <p className="text-xs text-blue-bisnison my-2">
                        {t("readMore")}
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};

export default ArticlesDesktop;
