import { useEffect } from "react";
import { useNavigate } from "react-router";

const NotFoundPageDesktop = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/");
  }, []);
  return (
    <div className="p-4">
      <p className="text-center">Loading...</p>
    </div>
  );
};

export default NotFoundPageDesktop;
