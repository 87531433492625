import Navbar from "./components/mobile/Navbar";
import BottomNavbar from "./components/mobile/BottomNavbar";
import { Sidebar } from "./components/mobile/Sidebar";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HomePage } from "./pages/mobile/HomePage";
import { TicketPage } from "./pages/mobile/TicketPage";
import { ProfilePage } from "./pages/mobile/ProfilePage";
import { NewsPage } from "./pages/mobile/NewsPage";
import { HistoryPage } from "./pages/mobile/HistoryPage";
import { LoginPage } from "./pages/mobile/LoginPage";
import { RegisterPage } from "./pages/mobile/RegisterPage";
import { TOSPage } from "./pages/mobile/TOSPage";
import { TicketChatsPage } from "./pages/mobile/TicketChatsPage";
import { ProtectedRoute } from "./services/protected-route";
import { useStateContext } from "./contexts/ContextProvider";
import { AuthRoute } from "./services/auth-route";
import { PriceListPage } from "./pages/mobile/PriceListPage";
import { ForgotPasswordPage } from "./pages/mobile/ForgotPasswordPage";
import { ChangePasswordPage } from "./pages/mobile/ChangePasswordPage";
import { APIPage } from "./pages/mobile/APIPage";
import { MyProfilePage } from "./pages/mobile/MyProfilePage";
import { AffiliatePage } from "./pages/mobile/AffiliatePage";
import { TopUpPage } from "./pages/mobile/TopUpPage";
import { InvoicePage } from "./pages/mobile/InvoicePage";
import { ConfirmTopUpPage } from "./pages/mobile/ConfirmTopUp";
import { SupportPage } from "./pages/mobile/SupportPage";
import { StatisticPage } from "./pages/mobile/StatisticPage";
import { TopMonthlyPage } from "./pages/mobile/TopMonthlyPage";
import { PromoPage } from "./pages/mobile/PromoPage";
import { DetailPromoPage } from "./pages/mobile/DetailPromoPage";
import { TransferSaldoPage } from "./pages/mobile/TransferSaldoPage";
import { PremiumPage } from "./pages/mobile/PremiumPage";
import { RefillPage } from "./pages/mobile/RefillPage";
import { BantuanPage } from "./pages/mobile/BantuanPage";
import { AffiliateTakePage } from "./pages/mobile/AffiliateTakePage";
import { PointPage } from "./pages/mobile/PointPage";
import { NotificationPage } from "./pages/mobile/NotificationPage";
import { HistoryTopUpPage } from "./pages/mobile/HistoryTopUpPage";
import { ResetPasswordPage } from "./pages/mobile/ResetPasswordPage";
import { WalletPage } from "./pages/mobile/WalletPage";
import { PremiumUpgradePage } from "./pages/mobile/PremiumUpgradePage";
import { PremiumUpgradeConfirmPage } from "./pages/mobile/PremiumUpgradeConfirmPage";
import { PremiumBenefitsPage } from "./pages/mobile/PremiumBenefitsPage";
import { NotFoundPage } from "./pages/mobile/NotFoundPage";
import { AffiliateAddBankPage } from "./pages/mobile/AffiliateAddBankPage";
import { AffiliateMyBankPage } from "./pages/mobile/AffiliateMyBankPage";
import { CaptureIDPage } from "./pages/mobile/CaptureIDPage";
import { GameTopupPage } from "./pages/mobile/GameTopupPage";
import { GuideCaptureIDPage } from "./pages/mobile/GuideCaptureIDPage";
import { AffiliateEditBankPage } from "./pages/mobile/AffiliateEditBankPage";
import CacheBuster from "react-cache-buster";
import packageInfo from "../package.json";
import { Loading } from "./components/mobile/Loading";
import MonitoringLayananPage from "./pages/mobile/MonitoringLayananPage";
import LoginPageDesktop from "./pages/desktop/LoginPageDesktop";
import { useEffect, useState } from "react";
import HomePageDesktop from "./pages/desktop/HomePageDesktop";
import RegisterPageDesktop from "./pages/desktop/RegisterPageDesktop";
import NotFoundPageDesktop from "./pages/desktop/NotFoundPageDesktop";
import PriceListDesktop from "./components/desktop/PriceList/PriceListDesktop";
import PriceListPublicPage from "./pages/PriceListPublicPage";
import ForgotPasswordPageDesktop from "./pages/desktop/ForgotPasswordPageDesktop";
import ResetPasswordPageDesktop from "./pages/desktop/ResetPasswordPageDesktop";

function App() {
  const { user, token } = useStateContext();
  const isProduction = process.env.NODE_ENV === "production";

  const hasWindow = typeof window !== "undefined";

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [hasWindow]);

  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      {windowDimensions.width <= 639 ? (
        <div className="font-poppins bg-gray-100 min-h-screen">
          <BrowserRouter>
            <Routes>
              <Route
                index
                path="/login"
                element={
                  <AuthRoute user={user} token={token}>
                    <LoginPage />
                  </AuthRoute>
                }
              />
              <Route
                path="/register"
                element={
                  <AuthRoute user={user} token={token}>
                    <RegisterPage />
                  </AuthRoute>
                }
              />
              <Route
                path="/pricelist"
                element={token ? <PriceListPage /> : <PriceListPublicPage />}
              />
              <Route
                path="/register/:code"
                element={
                  <AuthRoute user={user} token={token}>
                    <RegisterPage />
                  </AuthRoute>
                }
              />
              <Route
                path="/forgot-password"
                element={
                  <AuthRoute user={user} token={token}>
                    <ForgotPasswordPage />
                  </AuthRoute>
                }
              />
              <Route
                path="/tos"
                element={
                  <AuthRoute user={user} token={token}>
                    <TOSPage />
                  </AuthRoute>
                }
              />
              <Route
                path="/"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <Navbar />
                    <Sidebar />
                    <HomePage />
                    <BottomNavbar />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/change-password"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <Navbar isBack="/profile" />
                    <ChangePasswordPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/api"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <Navbar isBack="/profile" />
                    <APIPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/affiliate"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <AffiliatePage />
                    <BottomNavbar />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/affiliate/add-bank"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <Navbar isBack="/affiliate/my-bank" />
                    <AffiliateAddBankPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/affiliate/edit-bank"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <Navbar isBack="/affiliate/my-bank" />
                    <AffiliateEditBankPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/affiliate/my-bank"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <Navbar isBack="/affiliate" />
                    <AffiliateMyBankPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/affiliate/guide"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <Navbar isBack="/affiliate" />
                    <GuideCaptureIDPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/affiliate/capture"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <Navbar isBack="/affiliate" />
                    <CaptureIDPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/ticket"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <Navbar />
                    <Sidebar />
                    <TicketPage />
                    <BottomNavbar />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <Navbar />
                    <Sidebar />
                    <ProfilePage />
                    <BottomNavbar />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/news"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <Navbar />
                    <Sidebar />
                    <NewsPage />
                    <BottomNavbar />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/history"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <div className="min-h-screen">
                      {/* <Navbar /> */}
                      {/* <Sidebar /> */}
                      <HistoryPage />
                      <BottomNavbar />
                    </div>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/ticket/chat"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <Navbar isBack="/ticket" />
                    <TicketChatsPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/top-monthly"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <TopMonthlyPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/pricelist"
                element={
                  <div className="min-h-screen">
                    <PriceListPage />
                  </div>
                }
              />
              <Route
                path="/my-profile"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <Navbar isBack="/profile" />
                    <MyProfilePage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/statistic"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <Navbar isBack="/" />
                    <StatisticPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/support"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <Navbar isBack="/profile" />
                    <SupportPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/topup"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <Navbar isBack="/wallet" />
                    <TopUpPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/history-topup"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <Navbar isBack="/wallet" />
                    <HistoryTopUpPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/topup/invoice"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <Navbar isBack="/topup/confirm" />
                    <InvoicePage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/topup/confirm"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <Navbar isBack="/wallet" />
                    <ConfirmTopUpPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/transfer"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <Navbar isBack="/wallet" />
                    <TransferSaldoPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/invoice"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <Navbar isBack="/" />
                    <InvoicePage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/promo"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <Navbar isBack="/" />
                    <PromoPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/promo/detail-promo"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <Navbar isBack="/promo" />
                    <DetailPromoPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/premium"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <Navbar isBack="/" />
                    <PremiumPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/premium-upgrade"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <PremiumUpgradePage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/premium-benefits"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <PremiumBenefitsPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/refill"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <Navbar isBack="/" />
                    <RefillPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/bantuan"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <Navbar />
                    <Sidebar />
                    <BantuanPage />
                    <BottomNavbar />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/premium"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <Navbar isBack="/" />
                    <PremiumPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/premium-upgrade/confirm"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <PremiumUpgradeConfirmPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/affiliate/take"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <AffiliateTakePage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/point"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <Navbar isBack="/wallet" />
                    <PointPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/notification"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <Navbar isBack="/" />
                    <NotificationPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/auth/reset/:id"
                element={
                  <AuthRoute user={user} token={token}>
                    <Navbar isBack="/" />
                    <ResetPasswordPage />
                  </AuthRoute>
                }
              />
              <Route
                path="/wallet"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <Navbar isBack="/" />
                    <WalletPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/checkout-ppob"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <Navbar isBack="/" />
                    {/* <CheckoutPPOBPage /> */}
                    <div className="w-screen flex place-items-center sm:w-128 min-h-screen mx-auto bg-white">
                      <p className="mx-auto">Fitur ini belum tersedia</p>
                    </div>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/game-topup"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <GameTopupPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/monitoring-layanan"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <MonitoringLayananPage />
                  </ProtectedRoute>
                }
              />
              <Route path="/*" element={<NotFoundPage />} />
            </Routes>
          </BrowserRouter>
        </div>
      ) : (
        <div className="font-poppins bg-gray-100 min-h-screen">
          <BrowserRouter>
            <Routes>
              <Route
                index
                path="/login"
                element={
                  <AuthRoute user={user} token={token}>
                    <LoginPageDesktop />
                  </AuthRoute>
                }
              />
              <Route
                index
                path="/register"
                element={
                  <AuthRoute user={user} token={token}>
                    <RegisterPageDesktop />
                  </AuthRoute>
                }
              />
              <Route
                index
                path="/tos"
                element={
                  <AuthRoute user={user} token={token}>
                    <TOSPage />
                  </AuthRoute>
                }
              />
              <Route
                index
                path="/forgot-password"
                element={
                  <AuthRoute user={user} token={token}>
                    <ForgotPasswordPageDesktop />
                  </AuthRoute>
                }
              />
              <Route
                index
                path="/auth/reset/:id"
                element={
                  <AuthRoute user={user} token={token}>
                    <ResetPasswordPageDesktop />
                  </AuthRoute>
                }
              />
              <Route
                index
                path="/"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <HomePageDesktop />
                  </ProtectedRoute>
                }
              />
              <Route
                index
                path="/api"
                element={
                  <ProtectedRoute user={user} token={token}>
                    <APIPage />
                  </ProtectedRoute>
                }
              />
              <Route
                index
                path="/pricelist"
                element={token ? <PriceListDesktop /> : <PriceListPublicPage />}
              />
              <Route path="/*" element={<NotFoundPageDesktop />} />
            </Routes>
          </BrowserRouter>
        </div>
      )}
    </CacheBuster>
  );
}

export default App;
