import React from "react";
import { Link } from "react-router-dom";

export const IconButton = (props) => {
  return (
    <Link to={props.link} onClick={props.onClick} className="flex">
      <button className=" w-full rounded-full border-none flex flex-row">
        <img src={props.icons} alt="" className="mr-4"/>
        <p>{props.text}</p>
      </button>
      <div className="rounded-full shadow p-2">
        <img src="/assets/arrow-right.svg" alt="" className="mx-auto justify-center"/>
      </div>
    </Link>
  );
}