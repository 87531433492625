import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillExclamationCircle } from "react-icons/ai";

export const WarningPopUp = (props) => {
  const { t } = useTranslation();
  const [scale, setScale] = useState("scale-0");
  useEffect(() => {
    setScale("scale-100");
  });
  return (
    <>
      <div className="w-screen sm:w-128 mx-auto h-full bg-black opacity-50 fixed z-50" />
      <div className="fixed z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-screen sm:w-128 p-6 ">
        <div
          className={`transition ease-in-out delay-150 bg-yellow-500 rounded-xl p-4 ${scale}`}
        >
          <div className="text-white text-center flex flex-col font-medium">
            <span className="">{t("warning")} !</span>
            <div className="mx-auto my-4">
              <AiFillExclamationCircle size={120} />
            </div>
            <p>
              {t("balanceNotConverted")} <br />
              {t("servicePriceInfo")} <br />
              {t("topUpMethodInfo")} <br />
            </p>
          </div>
          <div className="flex space-x-4">
            <div
              className="p-2 bg-red-500 rounded-full text-center text-white mt-4 w-full cursor-pointer"
              onClick={props.cancel}
            >
              {t("cancel")}
            </div>
            <div
              className="p-2 border-2 border-white rounded-full text-center text-white mt-4 w-full cursor-pointer"
              onClick={props.continue}
            >
              {t("continue")}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
