import { useEffect, useRef, useState } from "react";
import { useStateContext } from "../../../contexts/ContextProvider";
import {
  getHistoryOrderSMM,
  getPricelistView,
  postPricelistView,
} from "../../../services/service";
import { HistorySMMList } from "../../mobile/HistoryOrder/HistorySMMList";
import HistorySMMTable from "../../mobile/HistoryOrder/HistorySMMTabel";
import Header from "../Header";
import Marquee from "../Marquee";
import { useTranslation } from "react-i18next";

const HistoryOrderDesktop = () => {
  const { t } = useTranslation();

  const histories = useRef();
  const { setHistoriesOrderSMM } = useStateContext();
  const [isSearch, setIsSearch] = useState("");
  const [page, setPage] = useState(1);
  const [listPages, setListPages] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [entryPerPage, setEntryPerPage] = useState(0);

  const listFilter = [
    {
      label: t("completedLabel"),
      id: "Completed",
    },
    {
      label: t("pendingLabel"),
      id: "Pending",
    },
    {
      label: t("canceledLabel"),
      id: "Canceled",
    },
    {
      label: t("partialLabel"),
      id: "Partial",
    },
    {
      label: t("inProgressLabel"),
      id: "In Progress",
    },
    {
      label: t("allLabel"),
      id: "",
    },
  ];
  const handleSelectFilter = (e) => {
    setSelectedFilter(e);
    const history = histories.current;
    const result = history.filter((item) =>
      item.status.toLowerCase().includes(e.toLowerCase())
    );
    setHistoriesOrderSMM(result);
    setIsFilter(false);
  };

  const changePage = (selectedPage) => {
    setListPages([]);
    getHistoryOrderSMM({ id: selectedPage })
      .then((response) => {
        setHistoriesOrderSMM(response.data.data);
        setPage(response.data.meta.current_page);
        for (let index = 0; index < response.data.meta.last_page; index++) {
          if (index + 1 !== response.data.meta.current_page) {
            setListPages((prevState) => [...prevState, index + 1]);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    getPricelistView()
      .then((response) => {
        if (!response.data.data) {
          postPricelistView("table");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    getHistoryOrderSMM()
      .then((response) => {
        setListPages([]);
        histories.current = response.data.data.data;
        setPage(response.data.data.current_page);
        setHistoriesOrderSMM(response.data.data.data);
        setEntryPerPage(response.data.data.per_page);
        setTotalPage(response.data.data.last_page);
        for (let index = 0; index < response.data.data.last_page; index++) {
          if (index + 1 !== response.data.data.current_page) {
            setListPages((prevState) => [...prevState, index + 1]);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <Header label={"Order"} />
      <Marquee />
      <div className="p-8">
        <div className="p-4 rounded-xl bg-white shadow-lg w-full">
          <p className="text-xl mb-4">{t("historyOrderLabel")}</p>
          <div className="text-black w-full flex place-items-center justify-between mb-4 bg-white">
            <div className="flex place-items-center space-x-2">
              <div className="rounded-lg  bg-gray-200 p-2">
                <select
                  id="page"
                  className="bg-transparent border-0"
                  onChange={(e) => changePage(e.target.value)}
                >
                  <option defaultValue={page}>
                    {t("page")} {page}
                  </option>
                  {listPages.map((item) => {
                    return (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="">
                <input
                  className="rounded-lg h-10  bg-gray-200 px-2 w-48"
                  type="text"
                  onChange={(e) => setIsSearch(e.target.value)}
                  placeholder={t("searchTarget")}
                />
              </div>
            </div>

            <div className="flex space-x-2 place-items-center">
              {listFilter.map((item) => {
                return (
                  <div
                    onClick={() => handleSelectFilter(item.id)}
                    className={`cursor-pointer border px-3 py-2 rounded-lg text-sm ${
                      selectedFilter === item.id
                        ? "border-orange-400 text-orange-400"
                        : "border-black text-black"
                    }`}
                  >
                    {item.label}
                  </div>
                );
              })}
            </div>
          </div>
          <div
            onClick={() => setIsFilter(false)}
            className={`${isFilter && "blur"}`}
          >
            <HistorySMMTable
              isSearch={isSearch}
              currentPage={page}
              entryPerPage={entryPerPage}
              totalPage={totalPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryOrderDesktop;
