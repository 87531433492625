import { useEffect, useState } from "react";
import { Input } from "../../components/mobile/Input";
import { OrangeButton } from "../../components/mobile/OrangeButton";
import { postTransferSaldo } from "../../services/service";
import { motion } from "framer-motion";

export const TransferSaldoPage = () => {
  const [targetTransfer, setTargetTransfer] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const saldo = JSON.parse(localStorage.getItem("user"));
  const [message, setMessage] = useState({
    success: "",
    error: "",
  });
  const [nominal, setNominal] = useState({
    jumlah: "",
    dapat: "",
  });

  const onChangeTargetTransfer = (e) => {
    setNominal({
      jumlah: "",
      dapat: "",
    });
    setTargetTransfer(e.target.value);
  };

  const handleChangeNominalBiscoin = (e) => {
    setNominal((prevState) => ({ ...prevState, jumlah: e.target.value }));
    setNominal((prevState) => ({
      ...prevState,
      dapat: targetTransfer === "SMM" ? e.target.value * 0.8 : e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage({
      success: "",
      error: "",
    });
    postTransferSaldo({ transferType: targetTransfer, jumlah: nominal.jumlah })
      .then((response) => {
        response.data.status === "success"
          ? setMessage((prevState) => ({
              ...prevState,
              success: response.data.message,
            }))
          : setMessage((prevState) => ({ ...prevState, error: "Gagal" }));
        setNominal({
          jumlah: "",
          dapat: "",
        });
        setIsLoading(false);
      })
      .catch((error) => {
        setMessage((prevState) => ({ ...prevState, error: "Gagal" }));
        setIsLoading(false);
        console.log(error);
      });
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="p-4 w-screen sm:w-128 mx-auto shadow bg-white min-h-screen">
      <p className="text-center text-lg">Transfer Saldo</p>
      <div className="h-16" />
      <form onSubmit={handleSubmit}>
        <div
          onChange={onChangeTargetTransfer}
          className="flex justify-between text-sm"
        >
          <div className="flex">
            <input type="radio" name="target" value="SMM" />
            <label htmlFor="biscoin">
              <p className="text-xs ml-2">{"Biscoin > IDR (1:0,8)"}</p>
            </label>
          </div>
          <div className="flex">
            <input type="radio" name="target" value="IDR" />
            <label htmlFor="ppob">
              <p className="text-xs ml-2">{"IDR > Biscoin (1:1)"}</p>
            </label>
          </div>
        </div>
        <Input
          label={`Nominal ${
            targetTransfer === "SMM"
              ? `Biscoin (Rp ${saldo.saldo.toLocaleString("id")})`
              : `IDR (Rp ${saldo.ppob_saldo.toLocaleString("id")})`
          }`}
          change={handleChangeNominalBiscoin}
          value={nominal.jumlah}
          disabled={!targetTransfer}
          type="number"
        />
        <Input
          label={`Get ${targetTransfer === "SMM" ? "IDR" : "Biscoin"}`}
          value={nominal.dapat}
          disabled={true}
        />
        {message.success && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="bg-green-200 text-green-500 p-4 rounded mt-4"
          >
            {message.success}
          </motion.div>
        )}
        {message.error && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="bg-red-200 text-red-500 p-4 rounded mt-4"
          >
            {message.error}
          </motion.div>
        )}
        <OrangeButton type="submit" title="Submit" isLoading={isLoading} />
      </form>
    </div>
  );
};
