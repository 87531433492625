import React, { useEffect, useRef, useState } from "react";
import { TakeAffiliateContent } from "../../components/mobile/Affiliate/TakeAffiliateContent";
import { TakeAffiliateTab } from "../../components/mobile/Affiliate/TakeAffiliateTab";
import { Input } from "../../components/mobile/Input";
import Navbar from "../../components/mobile/Navbar";
import { OrangeButton } from "../../components/mobile/OrangeButton";
import { OrangeButtonOutline } from "../../components/mobile/OrangeButtonOutline";
import { useStateContext } from "../../contexts/ContextProvider";
import {
  getListBank,
  getWdMinMax,
  postWdCommission,
} from "../../services/service";
import { motion } from "framer-motion";
export const AffiliateTakePage = () => {
  const [popUp, setPopUp] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const userRef = useRef();

  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    userRef.current = JSON.parse(localStorage.getItem("user"));
    getWdMinMax()
      .then((response) => {
        setMin(response.data.data.min);
        setMax(response.data.data.max);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="w-screen sm:w-128 mx-auto">
      {successMessage && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="absolute top-0 z-50 bg-green-200 text-green-500 p-4 w-full sm:w-128"
        >
          {successMessage}
        </motion.div>
      )}
      {errorMessage && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="absolute top-0 z-50 bg-red-200 text-red-500 p-4 w-full sm:w-128"
        >
          {errorMessage}
        </motion.div>
      )}
      {popUp && (
        <ConfirmPopUp
          handleClose={() => setPopUp(false)}
          setSuccessMessage={setSuccessMessage}
          setErrorMessage={setErrorMessage}
          min={min}
          max={max}
        />
      )}
      <div className=" w-screen h-screen sm:w-128 mx-auto shadow bg-white">
        <Navbar isBack="/affiliate/my-bank" />

        <TakeAffiliateTab />
        <TakeAffiliateContent min={min} max={max} setPopUp={setPopUp} />
      </div>
    </div>
  );
};

const ConfirmPopUp = (props) => {
  const [scale, setScale] = useState("scale-0");
  const [bankData, setBankData] = useState("");

  const { valueCommission } = useStateContext();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (valueCommission > props.max) {
      props.handleClose();
      props.setErrorMessage(
        `Jumlah tarik tidak boleh lebih besar dari Rp ${Number(
          props.max
        ).toLocaleString("id")} / Hari`
      );
    } else if (valueCommission < props.min) {
      props.handleClose();
      props.setErrorMessage(
        `Jumlah tarik tidak boleh lebih kecil dari Rp ${Number(
          props.min
        ).toLocaleString("id")}`
      );
    } else {
      postWdCommission(Number(valueCommission))
        .then((response) => {
          if (response.data.success) {
            props.handleClose();
            props.setSuccessMessage(response.data.data);
          } else {
            props.handleClose();
            props.setErrorMessage("Gagal");
          }
        })
        .catch((error) => {
          props.setErrorMessage(error.response.data.data.message);
          console.log(error);
          props.handleClose();
        });
    }
    setTimeout(() => {
      props.setSuccessMessage("");
      props.setErrorMessage("");
    }, 3000);
  };
  useEffect(() => {
    getListBank()
      .then((response) => {
        response.data.forEach((element) => {
          if (element.main) {
            setBankData(element);
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
    setScale("scale-100");
  }, []);
  return (
    <>
      <div className="w-screen sm:w-128 mx-auto h-full bg-black opacity-50 fixed z-40" />
      <div className="fixed z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-screen sm:w-128 p-6 ">
        <div
          className={`transition ease-in-out delay-150 bg-white rounded-xl p-4 shadow-lg ${scale}`}
        >
          <form onSubmit={handleSubmit}>
            <div>
              <p className="mb-8">Confirm</p>
              <p>Ambil Komisi Info :</p>
              <div className="text-sm ml-4">
                <p className="font-semibold">{bankData.nama_bank}</p>
                <p className="font-semibold">{bankData.atas_nama}</p>
              </div>
              <Input
                label="Nominal"
                type="number"
                disabled={true}
                value={valueCommission}
              />
              <div className="w-full bg-gray-300 my-4" style={{ height: 1 }} />
              {/* <div className="flex justify-between text-sm">
              <p>Fee : </p>
              <p>Rp 2000</p>
            </div>
            <div className="flex justify-between text-sm">
              <p>Total : </p>
              <p>Rp 8000</p>
            </div> */}
            </div>
            <div className="flex space-x-4 mt-4">
              <OrangeButtonOutline
                title="Kembali"
                onClick={props.handleClose}
              />
              <OrangeButton title="Ya, Benar" type="submit" />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
