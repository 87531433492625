import React, { useState } from "react";
import { Link } from "react-router-dom";
import { OrangeButton } from "../../components/mobile/OrangeButton";
import { Input } from "../../components/mobile/Input";
import { postForgotPassword } from "../../services/service";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../components/desktop/LanguageSwitcher";

export const ForgotPasswordPage = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({
    error: "",
    message: "",
  });
  const [email, setEmail] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  function transformResponse(response) {
    const emailMatch = response.email.match(/send to (.+)/);
    const whatsappMatch = response.whatsapp.match(/success send to (.+)/);

    const email = emailMatch ? emailMatch[1] : "";
    const whatsapp = whatsappMatch ? whatsappMatch[1] : "";

    return `${whatsapp} & ${email}`;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage("");
    setIsLoading(true);
    postForgotPassword(email)
      .then((response) => {
        setMessage({
          success: `${t(
            "forgotPasswordPage.successSendTo"
          )} ${transformResponse(response.data)}`,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        setMessage({ error: error.response.data.message });
        console.log(error);
        setIsLoading(false);
      });
  };
  return (
    <div className="bg-orange-200 w-screen sm:w-128 mx-auto h-screen shadow ">
      <div className=" absolute top-2 right-2">
        <LanguageSwitcher />
      </div>

      <img src="/loginz.svg" alt="" className="w-full h-2/5" />
      <div className="bg-white rounded-t-xl w-full h-3/5 mx-auto">
        <div className="flex flex-col px-2  mx-2">
          <img
            src="/assets/bisnison.svg"
            alt=""
            className="w-36 mx-auto pt-2"
          />
          <form onSubmit={handleSubmit}>
            {message.error && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="bg-red-200 text-red-500 p-2 rounded w-full"
              >
                {message.error}
              </motion.div>
            )}
            {message.success && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="bg-green-200 text-green-500 p-2 rounded w-full"
              >
                {message.success}
              </motion.div>
            )}
            {/* <Input
              label={}
              placeholder={}
              change={handleEmailChange}
            /> */}

            <div className=" mt-4 w-full">
              <div className="flex justify-between text-left text-sm">
                <label className="text-center">
                  {t("inputEmailUsernamePhone")}
                </label>
              </div>
              <div className="mt-2">
                <input
                  onChange={handleEmailChange}
                  className={`w-full rounded-full py-2 px-4 border border-gray-300 text-sm`}
                  placeholder={t("forgotPasswordPlaceholder")}
                />
              </div>
            </div>
            <OrangeButton
              type="submit"
              className="rounded-xl bg-orange-500 w-full p-2 mt-2 text-white text-center"
              title={t("submit", "Submit")}
              disabled={isLoading}
              isLoading={isLoading}
            />
            <div className="flex justify-between mt-2">
              <div>
                <Link to="/login" className="text-orange-500">
                  <p>{t("signIn")}</p>
                </Link>
              </div>
              <div>
                <Link to="/register" className="text-orange-500">
                  <p>{t("register")}</p>
                </Link>
              </div>
            </div>
          </form>

          <a
            href="https://smm.bisnison.com"
            className=" mt-4 text-xs text-center text-gray-400"
          >
            <p className="">{t("backToOldVersion")}</p>
          </a>
        </div>
      </div>
    </div>
  );
};
