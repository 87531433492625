import { useEffect, useRef, useState } from "react";
// import { useStateContext } from "../../../contexts/ContextProvider";
import { getProductNotification } from "../../../services/service";
import { NewsContent } from "../News/NewsContent";
import { NewsHeader } from "../News/NewsHeader";

export const News = () => {
  // const { news, setNews } = useStateContext();
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterNews, setFilterNews] = useState([]);

  const onFiltered = (value) => {
    if (value === "Price Increased") {
      setFilterNews(
        news.filter(
          (text) =>
            text.status_new === 1 &&
            text.status_old === 1 &&
            text.rate_new > text.rate_old
        )
      );
    } else if (value === "Price Decreased") {
      setFilterNews(
        news.filter(
          (text) =>
            text.status_new === 1 &&
            text.status_old === 1 &&
            text.rate_new < text.rate_old
        )
      );
    } else if (value === "Enabled") {
      setFilterNews(
        news.filter((text) => text.status_new === 1 && text.status_old === 0)
      );
    } else if (value === "Disabled") {
      setFilterNews(news.filter((text) => text.status_new === 0));
    } else if (value === "All") {
      setFilterNews(news);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setLoading(true);
    getProductNotification()
      .then((response) => {
        setNews(response.data.data);
        setFilterNews(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  return (
    <div>
      <div className="text-left w-full h-full">
        <div className="p-4">
          <NewsHeader onFiltered={onFiltered} />
          {filterNews.length > 0 ? (
            <div className=" h-full flex flex-col w-full justify-start">
              {filterNews.map((item, index) => {
                return (
                  <NewsContent
                    key={item.id}
                    product_id={item.product_id}
                    content={item.server}
                    tanggal={item.created_at}
                    status_old={item.status_old}
                    status_new={item.status_new}
                    nama_category={item.nama_category}
                    rate_old={item.rate_old}
                    rate_new={item.rate_new}
                  />
                );
              })}
            </div>
          ) : (
            <div className="h-full w-full ">
              {loading ? (
                [1, 2, 3, 4, 5, 1, 2, 3, 4, 5].map(() => (
                  <div className="flex flex-row text-start w-full justify-evenly my-4">
                    <div className="w-9 h-8 animate-pulse bg-gray-400 rounded-full"></div>
                    <div className="ml-2 w-full">
                      <div className="flex flex-row justify-between  ">
                        <div className="w-32 h-7 animate-pulse bg-gray-400 rounded-lg"></div>
                        <div className="w-32 h-7 animate-pulse bg-gray-400 rounded-lg"></div>
                      </div>
                      <div className="w-48 h-7 animate-pulse bg-gray-400 mt-2 rounded-lg"></div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="h-96 w-full justify-center flex flex-col">
                  <p className="text-center">Tidak ada layanan</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="h-20" />
    </div>
  );
};
