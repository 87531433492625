import { useTranslation } from "react-i18next";

export const ArticleCard = (props) => {
  const { t } = useTranslation();
  return (
    <div className="flex-shrink-0 shadow rounded-2xl w-36 sm:w-56">
      <img
        src={props.image}
        alt="article-thumbnail"
        className="object-cover h-28 w-96"
      />
      <div className="p-2 flex flex-col justify-between">
        <p className="text-xs sm:text-sm">{props.title}</p>
        <span className="text-xs text-red-500 my-2">{props.createdAt}</span>
        <span className="text-xs text-blue-bisnison my-2">{t("readMore")}</span>
      </div>
    </div>
  );
};
